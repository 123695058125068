import React from 'react';
import FilterInputTypes from './FilterInputTypes';
import queryString from 'query-string';
import './Filter.css';
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Layout,
  Popover,
  Row,
  Select,
  Switch,
  Checkbox
} from 'antd';
import { ComponentButton } from '../ComponentButton';
import listUtils from '../../../utils/listUtils';
import { ComponentIcon } from '../ComponentIcon';
import images from '../../../images/Images';
import { ComponentText } from '../ComponentText';
import Space from '../Spcae';
import { isItemExist } from '../../../utils/LocalStorage';
import { contentType } from '../../../utils/ActionTypes';
import { productGroupListPath } from '../../../utils/routerUtils';
import { loadData, loadLocalData } from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import { connect } from 'react-redux';

const {Option} = Select;
const {Content} = Layout;

class Filter extends React.Component {
  constructor(props) {
    super(props);

    const {location, queryFilterParams } = this.props;
    let filtersParams= listUtils.currentFilters(location.search, queryFilterParams);
    const values = queryString.parse(this.props.location.search);
    const appId = values["application_ids"] && values["application_ids"] !== "all-app" ? values["application_ids"] : "";
    const type = values["type"];
    this.state = {
      ...filtersParams,
      groups: [],
      appId: appId,
      disableGroup: type === "TYPE_CONSUMABLE",
      _visible: false
    };
  }
  componentDidMount () {
    const values = queryString.parse(this.props.location.search);
    const appId = values["application_ids"] && values["application_ids"] !== "all-app" ? values["application_ids"] : "";
    if(appId){
      this.loadProductGroups(appId);
    }
  }

  handleChange = (evt) => {
    this.setState({[evt.target.name]: evt.target.value});
  };

    handleChangeNameLess = (name, value) => {
      if(value === "TYPE_CONSUMABLE"){
        this.setState({[name]: value, product_group_name:"", disableGroup: true});
      }else if(value === "TYPE_SUBSCRIPTION"){
        this.setState({[name]: value, disableGroup: false});
      }else if(Array.isArray(value) && value.length === 0){
        this.setState({[name]: ""});
      }else{
        this.setState({[name]: value});
      }
    };

    handleOpen = () => this.setState({_visible: true});

    handleClose = () => this.setState({_visible: false});

    prepareOptions = (options) => {
      return options.map(e => <Option key={e.value} value={e.value}>{e.title}</Option>);
    };

    resetFilters = () => {
      let oldState = this.state;
      Object.keys(oldState).forEach(k => {
        if (k !== "_visible" && k !== "appId" && k !== "groups" && k !== "disableGroup") {
          oldState[k] = "";
        }
      });
      this.setState(oldState);
    };

    handleSearch = () => {
      const {queryFilterParams, history } = this.props;
      this.handleClose();
      let pathname = this.props.location.pathname;
      let searchParams = new URLSearchParams(this.props.location.search);

      queryFilterParams.forEach(e => {
        if( e === 'external_product_id' && this.state.application_ids === 'app-app') searchParams.delete(e);
        else if(this.state[e]) searchParams.set(e, this.state[e]);
        else searchParams.delete(e);
      } );

      if(searchParams.get("page")) {
        searchParams.set("page", "1");
      }
      history.push({
        pathname: pathname,
        search: searchParams.toString(),
      });
    };

    handleCancel = () => {
      this.handleClose();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
      const values = queryString.parse(this.props.location.search);
      const appId = values["application_ids"] && values["application_ids"] !== "all-app" ? values["application_ids"] : "";

      if(appId) {
        if (appId !== this.state.appId) {
          this.loadProductGroups(appId);
          this.setState({ appId: appId });

        }
      }

      if(prevProps.location !== this.props.location) {
        const values = queryString.parse(this.props.location.search);
        if (this.state.application_ids !== values["application_ids"]) {
          this.setState({ application_ids: values["application_ids"] });
        }
        const { queryFilterParams } = this.props;
        queryFilterParams.forEach(e => {
          if (!values[e])
            this.handleChangeNameLess(e, "");
        });
      }
      if (this.props.data && this.props.data !== prevProps.data && this.props.isFetching === false) {
        const list = [];
        this.props.data.forEach(e => {
          if(e.name !== "default")
            list.push({title: e.name, value: e.name});
        });
        this.setState({groups: list });
      }

    }

    render() {
      const {location, filterParams} = this.props;
      let formParams = listUtils.searchModalFormParams(location.search, filterParams);
      const form = <Content style ={{width: 250}} className="popover-filter">
        <Row>
          <Form onSubmit={this.handleSubmit} layout="vertical">
            {
              formParams.map((e) => {
                switch (e.type) {
                case FilterInputTypes.TEXT_INPUT:
                  return  e.name === 'external_product_id' && this.state.application_ids === 'all-app' ? applicationNotSelected("product ID"): (
                    <Form.Item key={e.name} className="no-bottom-margin" label={e.label}>
                      <Input name={e.name} placeholder={e.placeholder} value={this.state[e.name]} onChange={this.handleChange}/>
                    </Form.Item>
                  );
                case FilterInputTypes.SELECT_INPUT:
                  if(e.name === "product_group_name"){
                    e.options = this.state.groups;
                  }
                  return (
                    (e.name === 'product_group_name' && this.state.application_ids === 'all-app') ? applicationNotSelected("group"):
                      <Form.Item key={e.name} className="no-bottom-margin" label={e.label} hasFeedback>
                        <Select allowClear name={e.name}
                          disabled={(e.name === 'product_group_name' && this.state.disableGroup)}
                          value={this.state[e.name] ? this.state[e.name] : undefined }
                          placeholder={e.placeholder ? e.placeholder : ""}
                          onChange={(value) => this.handleChangeNameLess(e.name, value)}
                          suffixIcon={<ComponentIcon type="select" width={15}/>}>
                          {this.prepareOptions( e.options)}
                        </Select>
                      </Form.Item>
                  );
                case FilterInputTypes.TOGGLE_INPUT:
                  return (
                    <Switch defaultChecked onChange={(evt) => this.handleChangeNameLess(e.key, !this.state[e.key])} />
                  );
                case FilterInputTypes.CHECKBOX:
                  var value = [];
                  if(this.state[e.name]) {
                    value = ["true"];
                  }
                  return (
                    e.label ? <Form.Item key={e.name} className="no-bottom-margin" label={e.label} hasFeedback>
                      <Checkbox.Group options={e.options} defaultValue={value} value={value} onChange={(value) => this.handleChangeNameLess(e.name, value)} />
                    </Form.Item> : <Checkbox.Group key={e.name} options={e.options} defaultValue={value}  value={value} onChange={(value) => this.handleChangeNameLess(e.name, value)} />
                  );
                default:
                  alert("not supported yet");
                  return null;
                }
              })
            }
          </Form>
        </Row>
        <Row type="flex" justify="space-between" style={{marginTop: 16}} align="middle">
          <ComponentButton
            text={'Reset'}
            css="filter-cancel"
            handleClick={this.resetFilters}
          />
          <Col>
            <ComponentButton
              text={'Cancel'}
              css="filter-cancel"
              handleClick={this.handleCancel}
            />
            <ComponentButton
              text={'Apply'}
              css="filter-apply"
              handleClick={this.handleSearch}
            />
          </Col>
        </Row>
      </Content>;
      return (
        <Row type={"flex"} align={"middle"}>
          { formParams.map((e,i) => this.prepareFilterTag(e,i))}
          <Popover
            placement="bottomRight"
            content={form}
            trigger="click"
            onVisibleChange={this.handleVisibleChange}
            visible={this.state._visible}>
            <Button style={{height: 42}} onClick={this.handleOpen}>Filter<Icon type="caret-down" /></Button>
          </Popover>
        </Row>
      );
    }
    handleVisibleChange = _visible => {
      this.setState({ _visible });
    };

  loadProductGroups = (appId) => {
    const { onLoadData, onLoadLocalData } = this.props;

    if (isItemExist(contentType.PRODUCT_GROUP_LIST.name, 5)) {
      onLoadLocalData(contentType.PRODUCT_GROUP_LIST);
    } else
      onLoadData(contentType.PRODUCT_GROUP_LIST, productGroupListPath, {
        filters: { application_id: appId },
        pager: {
          skip: 0,
          limit: 15
        },
      });
  }

  prepareFilterTag = (e, i) => {
    const values = queryString.parse(this.props.location.search);

    let res;
    switch (e.name) {
    case "source":
      res = utils.getSource(values[e.name]);
      break;
    case "type":
      res = utils.getProductType(values[e.name]);
      break;
    case "email_externalid":
      res = "Who have email or external ID";
      break;
    case "payment_status_paid":
      res = "Who have purchased a product";
      break;
    default:
      res = values[e.name];

    }

    return values[e.name]?
      <Row key={i} className={"filter-tag"} type={"flex"} align={"middle"}>
        {res}
        <ComponentButton name="cancel" icon="cancel" text="" css="icon-only" width="10" iconSize={10} handleClick={()=>this.onRemoveTag(e)}/>
      </Row>: <div key={i}/>;
  }

  onRemoveTag = (e) => {
    const {history } = this.props;

    let pathname = this.props.location.pathname;
    let searchParams = new URLSearchParams(this.props.location.search);
    searchParams.delete(e.name);

    if(searchParams.get("page")) {
      searchParams.set("page", "1");
    }
    history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  }
}

const FilterPopup = Form.create({name: "filter"})(Filter);

function applicationNotSelected (title) {
  return  <Row key={"application-not-selected"} className={"application-not-selected-container"} type={"flex"} justify="start">
    <img height={28} width={28} src={images.info} alt={""}/>
    <Space width={12}/>
    <div className={"application-not-selected-container-content"}>
      <ComponentText
        text={`Select application to search by ${title}`}
        font="SFProDisplay-Medium"
        size={14}
        color="var(--dark)"
      />
    </div>
  </Row>;
}


const mapActionToProps = {
  onLoadData: loadData,
  onLoadLocalData: loadLocalData,
};

const mapStateToProps = state => {
  const { productGroupListRemoteData } = state;
  return {
    data: utils.getData(productGroupListRemoteData),
    isFetching: utils.getIsFetching(productGroupListRemoteData),
  };
};
export default connect(mapStateToProps, mapActionToProps)(FilterPopup);