import React from 'react';
import { Icon } from 'antd';

export class ComponentIcon extends React.Component {
  render() {
    const { type, antd, width, styl = null } = this.props;
    const isAntd = getSVG(type) === 'antd';

    let style = {};

    if (styl) {
      style = { ...styl };
    } else if (width) {
      style.width = width;
    } else {
      style.width = 12;
    }

    return antd || isAntd ? (
      <Icon type={type} />
    ) : (
      <Icon component={getSVG(type)} style={style} />
    );
  }
}
function getSVG(type) {
  switch (type) {
  case 'credit-card':
    return creditCard;
  case 'home':
    return home;
  case 'analytics':
    return analytics;
  case 'apps':
    return apps;

  case 'warning':
    return warning;
  case 'alert':
    return alert;
  case 'apps-dashboard':
    return appsDashboard;
  case 'yes':
    return yes;
  case 'no':
    return no;
  case 'notification':
    return notification;
  case 'documents':
    return documents;
  case 'support':
    return support;
  case 'account':
    return account;
  case 'customer':
    return customer;
  case 'next':
    return next;
  case 'previous':
    return previous;
  case 'ios':
    return ios;
  case 'android':
    return android;
  case 'add':
    return add;
  case 'edit':
    return edit;
  case 'print':
    return print;
  case 'download':
    return download;
  case 'cancel':
    return cancel;
  case 'copy':
    return copy;
  case 'star':
    return star;
  case 'promotion':
    return promotion;
  case 'lastSeen':
    return lastSeen;
  case 'calender':
    return calender;
  case 'install':
    return install;
  case 'segment':
    return segment;
  case 'subscription':
    return subscription;
  case 'users':
    return users;

  case 'arrow-down':
    return arrowDown;
  case 'arrow-down-alt':
    return arrowDownAlt;
  case 'search':
    return search;
  case 'help':
    return help;
  case 'transactions':
    return transactions;
  case 'more':
    return more;
  case 'more-vertical':
    return moreVertical;
  case 'upgrade-code-alt':
    return upgradeCodeAlt;
  case 'upgrade-notification':
    return upgradeNotificationng;
  case 'upgrade-team':
    return upgradeTeam;
  case 'upgrade-trending':
    return upgradeTrending;
  case 'check':
    return check;
  case 'set-up-app':
    return setYourApp;
  case 'select':
    return select;
  case 'right-arrow':
    return rightArrow;
  default:
    return 'antd';
  }
}

const home = () => (
  <svg viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#919EAB"
        d="M19.664 8.252l-9-8a1 1 0 0 0-1.328 0L8 1.44V1a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v5.773L.336 8.252a1.001 1.001 0 0 0 1.328 1.496L2 9.449V19a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9.449l.336.299a.997.997 0 0 0 1.41-.083 1.001 1.001 0 0 0-.082-1.413zM16 18h-2v-5a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v5H4V7.671l6-5.333 6 5.333V18zm-8 0h4v-4H8v4zM4 2h2v1.218L4 4.996V2z"
      />
    </g>
  </svg>
);

const creditCard = () => (
  <svg viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#919EAB"
        d="M2 16h16v-6H2v6zM2 6h16V4H2v2zM1 2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H1zm4 10a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2H5z"
      />
    </g>
  </svg>
);

const analytics = () => (
  <svg viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#919EAB"
        d="M19 18a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2h18zm0-18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h5V5a1 1 0 0 1 1-1h5V1a1 1 0 0 1 1-1h6zm-5 14h4V2h-4v12zm-6 0h4V6H8v8zm-6 0h4v-4H2v4z"
      />
    </g>
  </svg>
);

const apps = () => (
  <svg viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#8E9EAD"
        d="M19 11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h7zM8 11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h7zM8 0a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h7zM2 18h5v-5H2v5zM2 7h5V2H2v5zm11 11h5v-5h-5v5zM12 6a1 1 0 1 1 0-2h2V2a1 1 0 1 1 2 0v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0V6h-2z"
      />
    </g>
  </svg>
);

const alert = () => (
  <svg viewBox="0 0 17 18">
    <g fill="none" fillRule="evenodd">
      <path fill="#8E9EAD" d="M15.83 12.348c-.998-1.153-1.547-2.627-1.548-4.152 0-3.696-2.04-5.916-4.734-6.546.004-.056.004-.112 0-.168C9.548.664 8.885 0 8.066 0c-.818 0-1.482.664-1.482 1.482-.003.056-.003.112 0 .168-2.64.6-4.734 2.796-4.734 6.546 0 1.525-.55 2.999-1.548 4.152-.642.786-.228 2.196.924 2.196h13.74c1.164 0 1.5-1.458.864-2.196zM8.066 17.4c1.031-.002 1.942-.672 2.25-1.656h-4.5c.31.984 1.22 1.654 2.25 1.656z"/>
    </g>
  </svg>
);

const warning = () => (
  <svg viewBox="64 64 896 896">
    <path fill="#faad14"
      d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"/>
    <path fill="#faad14"
      d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"/>
  </svg>
);

const appsDashboard = () => (
  <svg width="16" height="16" viewBox="0 0 20 20">
    <path fill="#ffffff" fillRule="nonzero" d="M6.667 0h-5C.747 0 0 .746 0 1.667v5c0 .92.746 1.666 1.667 1.666h5c.92 0 1.666-.746 1.666-1.666v-5C8.333.747 7.587 0 6.667 0zm0 11.667h-5c-.92 0-1.667.746-1.667 1.666v5C0 19.253.746 20 1.667 20h5c.92 0 1.666-.746 1.666-1.667v-5c0-.92-.746-1.666-1.666-1.666zm11.666 0c.92 0 1.667.746 1.667 1.666v5c0 .92-.746 1.667-1.667 1.667h-5c-.92 0-1.666-.746-1.666-1.667v-5c0-.92.746-1.666 1.666-1.666zM16 0a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0V5h-2a1 1 0 1 1 0-2h2V1a1 1 0 0 1 1-1z"/>
  </svg>

);

const yes = () => (
  <svg viewBox="0 0 15 15">
    <g fill="#3BB278" fillRule="evenodd">
      <path
        id="a"
        d="M7.5 0C3.364 0 0 3.365 0 7.5 0 11.636 3.365 15 7.5 15c4.136 0 7.5-3.364 7.5-7.5S11.636 0 7.5 0m0 13.5c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6m1.72-8.03L6.75 7.94l-.97-.97a.75.75 0 1 0-1.06 1.06l1.5 1.5a.748.748 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06"
      />
    </g>
  </svg>
);

const no = () => (
  <svg viewBox="0 0 15 15">
    <g fill="#ED4D4D" fillRule="evenodd">
      <path
        id="a"
        d="M7.5 0C3.364 0 0 3.365 0 7.5 0 11.636 3.365 15 7.5 15c4.136 0 7.5-3.364 7.5-7.5S11.636 0 7.5 0m0 13.5c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6m2.78-8.78a.75.75 0 0 0-1.06 0L7.5 6.44 5.78 4.72a.75.75 0 1 0-1.06 1.06L6.44 7.5 4.72 9.22a.75.75 0 1 0 1.06 1.06L7.5 8.56l1.72 1.72a.748.748 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L8.56 7.5l1.72-1.72a.75.75 0 0 0 0-1.06"
      />
    </g>
  </svg>
);

const notification = () => (
  <svg viewBox="0 0 15 18">
    <g fill="#ED4D4D" fillRule="evenodd">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M13.125 12.462V7.846c0-2.834-1.537-5.206-4.219-5.834v-.627C8.906.618 8.278 0 7.5 0c-.778 0-1.406.618-1.406 1.385v.627c-2.69.628-4.219 2.991-4.219 5.834v4.616L0 14.308v.923h15v-.923l-1.875-1.846zM7.5 18c1.031 0 1.875-.83 1.875-1.846h-3.75C5.625 17.169 6.459 18 7.5 18z"
      />
    </g>
  </svg>
);

const support = () => (
  <svg viewBox="0 0 19 20">
    <path
      fill="#2F9CF5"
      fillRule="nonzero"
      d="M9.5 0a9.14 9.14 0 0 1 3.69.767 9.534 9.534 0 0 1 3.032 2.063c.848.864 1.523 1.894 2.025 3.089A9.612 9.612 0 0 1 19 9.677c0 1.31-.25 2.564-.753 3.76-.502 1.194-1.177 2.224-2.025 3.088a9.534 9.534 0 0 1-3.032 2.063 9.14 9.14 0 0 1-3.69.767 9.14 9.14 0 0 1-3.69-.767 9.534 9.534 0 0 1-3.032-2.063c-.848-.864-1.523-1.894-2.025-3.089A9.612 9.612 0 0 1 0 9.677c0-1.31.25-2.563.753-3.758.502-1.195 1.177-2.225 2.025-3.09A9.534 9.534 0 0 1 5.81.768 9.14 9.14 0 0 1 9.5 0zm0 1.382a7.986 7.986 0 0 0-3.828.973L7.73 4.45a5.354 5.354 0 0 1 3.542 0l2.057-2.095A7.986 7.986 0 0 0 9.5 1.382zM2.311 13.576l2.057-2.095a5.64 5.64 0 0 1 0-3.607L2.311 5.778a8.38 8.38 0 0 0-.954 3.9 8.38 8.38 0 0 0 .954 3.898zM9.5 17.972A7.986 7.986 0 0 0 13.328 17l-2.057-2.095a5.354 5.354 0 0 1-3.542 0L5.672 17a7.986 7.986 0 0 0 3.828.972zm0-4.147c1.124 0 2.083-.405 2.879-1.215.795-.81 1.192-1.788 1.192-2.933 0-1.144-.397-2.122-1.192-2.932-.796-.81-1.755-1.215-2.879-1.215s-2.083.405-2.879 1.215c-.795.81-1.192 1.788-1.192 2.932 0 1.145.397 2.123 1.192 2.933.796.81 1.755 1.215 2.879 1.215zm5.132-2.344l2.057 2.095a8.38 8.38 0 0 0 .954-3.899 8.38 8.38 0 0 0-.954-3.899l-2.057 2.096a5.64 5.64 0 0 1 0 3.607z"
    />
  </svg>
);

const calender = () => (
  <svg viewBox="0 0 20 20">
    <g fill="#637381" fillRule="evenodd">
      <path
        id="a"
        d="M19 2h-3V1a1 1 0 1 0-2 0v1H6V1a1 1 0 1 0-2 0v1H1a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM2 18h16V8H2v10zM2 6h16V4H2v2zm4 4a1 1 0 1 0 0 2 1 1 0 1 0 0-2m0 4a1 1 0 1 0 0 2 1 1 0 1 0 0-2m4 0a1 1 0 1 0 0 2 1 1 0 1 0 0-2m0-4a1 1 0 1 0 0 2 1 1 0 1 0 0-2m4 0a1 1 0 1 0 0 2 1 1 0 1 0 0-2"
      />
    </g>
  </svg>
);

const documents = () => (
  <svg viewBox="0 0 18 19">
    <path
      fill="#2F9CF5"
      fillRule="nonzero"
      d="M2.333 4.222H.667v12.315c0 .972.746 1.76 1.666 1.76H14v-1.76H2.333V4.222zM15.667.704h-10C4.746.704 4 1.49 4 2.463v10.556c0 .972.746 1.759 1.667 1.759h10c.92 0 1.666-.787 1.666-1.76V2.464c0-.972-.745-1.76-1.666-1.76zm-.834 7.916H6.5V6.861h8.333v1.76zM11.5 12.14h-5v-1.76h5v1.76zm3.333-7.037H6.5v-1.76h8.333v1.76z"
    />
  </svg>
);

const account = () => (
  <svg viewBox="0 0 20 20">
    <g fill="#2F9CF5" fillRule="evenodd">
      <path
        id="a"
        d="M19.492 11.897l-1.56-.88a7.8 7.8 0 0 0 0-2.035l1.56-.879a1.001 1.001 0 0 0 .37-1.38l-2.047-3.464a1.001 1.001 0 0 0-1.353-.362l-1.491.841A8.078 8.078 0 0 0 13 2.586V1a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v1.586a8.053 8.053 0 0 0-1.97 1.152l-1.492-.841a1 1 0 0 0-1.352.362L.139 6.723a1.001 1.001 0 0 0 .37 1.38l1.559.879A7.829 7.829 0 0 0 2 10c0 .335.023.675.068 1.017l-1.56.88a.998.998 0 0 0-.37 1.38l2.048 3.464a.999.999 0 0 0 1.352.362l1.492-.842A7.99 7.99 0 0 0 7 17.413V19a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1.587a8.014 8.014 0 0 0 1.97-1.152l1.492.842a1 1 0 0 0 1.353-.362l2.047-3.464a1.002 1.002 0 0 0-.37-1.38m-3.643-3.219c.1.448.15.893.15 1.322 0 .428-.05.873-.15 1.322-.097.43.1.873.484 1.089l1.287.726-1.03 1.742-1.252-.707a1 1 0 0 0-1.183.15 6.023 6.023 0 0 1-2.44 1.425 1 1 0 0 0-.715.959V18H9v-1.294a1 1 0 0 0-.714-.959 6.01 6.01 0 0 1-2.44-1.425 1.001 1.001 0 0 0-1.184-.15l-1.252.707-1.03-1.742 1.287-.726a.999.999 0 0 0 .485-1.089A6.043 6.043 0 0 1 4 10c0-.429.05-.874.152-1.322a1 1 0 0 0-.485-1.09L2.38 6.862 3.41 5.12l1.252.707a1 1 0 0 0 1.184-.149 6.012 6.012 0 0 1 2.44-1.426A1 1 0 0 0 9 3.294V2h2v1.294a1 1 0 0 0 .715.958c.905.27 1.749.762 2.44 1.426a1 1 0 0 0 1.183.149l1.253-.707 1.029 1.742-1.287.726a1 1 0 0 0-.484 1.09M9.999 6c-2.205 0-4 1.794-4 4s1.795 4 4 4c2.207 0 4-1.794 4-4s-1.793-4-4-4m0 6c-1.102 0-2-.897-2-2s.898-2 2-2c1.104 0 2 .897 2 2s-.896 2-2 2"
      />
    </g>
  </svg>
);

const customer = () => (
  <svg viewBox="0 0 16 20">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#8E9EAD"
        d="M15.707 15.293a.999.999 0 0 1 .241 1.023l-1 3A.999.999 0 0 1 14 20H2a1 1 0 0 1-.949-.684l-1-3a.999.999 0 0 1 .242-1.023C.427 15.158 3.635 12 8 12c4.364 0 7.572 3.158 7.707 3.293zM13.28 18l.562-1.687c-.92-.752-3.155-2.313-5.84-2.313-2.704 0-4.928 1.558-5.844 2.31L2.72 18h10.56zM8 2C6.346 2 5 3.346 5 5s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm0 8c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"
      />
    </g>
  </svg>
);

const next = () => (
  <div style={{ marginTop: 4, marginLeft: 4 }}>
    <svg viewBox="0 0 20 16">
      <g>
        <path
          fill="#919EAB"
          id="a"
          d="M12.297 6.453L8.824 2.981a.694.694 0 1 0-.982.982L10.13 6.25H2.083a.694.694 0 1 0 0 1.389h8.046L7.842 9.926a.694.694 0 1 0 .982.982l3.473-3.473a.694.694 0 0 0 0-.982"
        />
      </g>
    </svg>
  </div>
);

const previous = () => (
  <div style={{ marginTop: 4 }}>
    <svg viewBox="0 0 20 16">
      <g>
        <path
          fill="#919EAB"
          fillRule="evenodd"
          id="a"
          d="M3.414 5l3.293-3.293A.999.999 0 1 0 5.293.293l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L3.414 7H15a1 1 0 1 0 0-2H3.414z"
        />
      </g>
    </svg>
  </div>
);

const ios = () => (
  <svg viewBox="0 0 14 16">
    <path
      fill="#404040"
      fillRule="nonzero"
      d="M14 11.683c-.261.801-.673 1.602-1.236 2.404-.865 1.256-1.725 1.884-2.583 1.884-.328 0-.797-.102-1.407-.308-.576-.205-1.082-.307-1.518-.307-.408 0-.884.106-1.427.317-.543.218-.985.327-1.326.327-1.019 0-2.027-.83-3.026-2.49C.492 11.837 0 10.224 0 8.673c0-1.461.379-2.66 1.136-3.596.757-.923 1.708-1.385 2.854-1.385.482 0 1.075.096 1.779.289.697.192 1.159.288 1.387.288.301 0 .78-.109 1.437-.327.683-.218 1.263-.327 1.739-.327.797 0 1.51.209 2.14.625.349.231.697.552 1.046.962-.53.43-.912.808-1.146 1.135a3.322 3.322 0 0 0-.653 1.99c0 .795.23 1.51.693 2.144.462.635.992 1.039 1.588 1.212zM10.221.403c0 .392-.097.828-.291 1.309a3.94 3.94 0 0 1-.935 1.326c-.362.347-.724.577-1.085.693-.248.07-.597.125-1.046.163.02-.955.282-1.779.784-2.47C8.144.736 8.982.262 10.161 0c.007.02.015.054.025.106.01.051.018.086.025.106 0 .025.002.057.005.096.003.038.005.07.005.096z"
    />
  </svg>
);

const add = () => (
  <svg width="1em" height="1em" viewBox="0 0 12 12">
    <g>
      <path
        fill="#919EAB"
        fillRule="evenodd"
        d="M1.5 7.5C.67 7.5 0 6.83 0 6s.67-1.5 1.5-1.5h3v-3C4.5.67 5.17 0 6 0s1.5.67 1.5 1.5v3h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v3c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5v-3h-3z"
      />
    </g>
  </svg>
);

const edit = () => (
  <svg width="1em" height="1em" viewBox="0 0 13 13">
    <g>
      <path
        fill="#637381"
        fillRule="evenodd"
        d="M12.271.705c-.94-.94-2.579-.94-3.52 0l-7.26 7.261a.634.634 0 0 0-.148.239c-.002.006-.008.01-.01.015l-1.3 3.9a.65.65 0 0 0 .823.823l3.9-1.3c.006-.002.01-.008.015-.01a.634.634 0 0 0 .239-.148l7.261-7.26c.47-.47.73-1.096.73-1.76 0-.665-.26-1.29-.73-1.76zm-.92 2.6l-.3.302-1.682-1.681.302-.302c.45-.449 1.232-.449 1.68 0 .226.225.35.523.35.84 0 .318-.124.616-.35.841zM2.235 9.63l1.113 1.113-1.67.556.557-1.67zm2.316.478L2.87 8.426l5.58-5.581 1.681 1.68-5.58 5.582z"
      />
    </g>
  </svg>
);

const android = () => (
  <svg viewBox="0 0 14 16">
    <g fill="none" fillRule="evenodd">
      <path d="M-1 0h16v16H-1z" />
      <path
        fill="#404040"
        fillRule="nonzero"
        d="M3 12c0 .367.3.667.667.667h.666V15c0 .553.447 1 1 1 .554 0 1-.447 1-1v-2.333h1.334V15c0 .553.446 1 1 1 .553 0 1-.447 1-1v-2.333h.666c.367 0 .667-.3.667-.667V5.333H3V12zM1.333 5.333c-.553 0-1 .447-1 1V11c0 .553.447 1 1 1 .554 0 1-.447 1-1V6.333c0-.553-.446-1-1-1zm11.334 0c-.554 0-1 .447-1 1V11c0 .553.446 1 1 1 .553 0 1-.447 1-1V6.333c0-.553-.447-1-1-1zM9.353 1.44l.867-.867a.33.33 0 0 0 0-.473.33.33 0 0 0-.473 0l-.987.987A3.893 3.893 0 0 0 7 .667c-.64 0-1.24.153-1.773.42L4.233.1a.33.33 0 0 0-.473 0 .33.33 0 0 0 0 .473l.873.874A3.989 3.989 0 0 0 3 4.667h8c0-1.327-.647-2.5-1.647-3.227zM5.667 3.333H5v-.666h.667v.666zm3.333 0h-.667v-.666H9v.666z"
      />
    </g>
  </svg>
);
const print = () => (
  <svg viewBox="0 0 22 20">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#A0AFB9"
        fillRule="nonzero"
        d="M5.077 18.333h11.846V15H5.077v3.333zm0-8.333h11.846V5h-2.115c-.353 0-.653-.122-.9-.365a1.196 1.196 0 0 1-.37-.885V1.667H5.077V10zm15.23.833a.795.795 0 0 0-.25-.586.82.82 0 0 0-.595-.247.82.82 0 0 0-.595.247.795.795 0 0 0-.252.586c0 .226.084.421.252.586a.82.82 0 0 0 .595.248.82.82 0 0 0 .594-.248.795.795 0 0 0 .252-.586zm1.693 0v5.417c0 .113-.042.21-.126.293a.41.41 0 0 1-.297.124h-2.962v2.083c0 .347-.123.642-.37.885a1.234 1.234 0 0 1-.899.365H4.654c-.353 0-.652-.122-.9-.365a1.196 1.196 0 0 1-.37-.885v-2.083H.425a.41.41 0 0 1-.298-.124A.397.397 0 0 1 0 16.25v-5.417c0-.685.249-1.274.747-1.764a2.462 2.462 0 0 1 1.791-.736h.847V1.25c0-.347.123-.642.37-.885S4.3 0 4.654 0h8.884c.353 0 .74.087 1.164.26.423.174.758.382 1.005.625l2.01 1.98c.246.243.458.573.634.99.176.416.264.798.264 1.145v3.333h.847c.696 0 1.293.246 1.791.736S22 10.148 22 10.833z"
      />
    </g>
  </svg>
);

const download = () => (
  <svg viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#9DAFBA"
        fillRule="nonzero"
        d="M16.667 13.308H3.333a3.333 3.333 0 0 0 0 6.667h13.334a3.333 3.333 0 0 0 0-6.667zM15.5 16.641a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0zm-6.367-4.906c.534.32 1.2.32 1.734 0A15.547 15.547 0 0 0 15.66 6.94a1.687 1.687 0 1 0-2.893-1.733 11.52 11.52 0 0 1-.807 1.107l-.38-4.874a1.587 1.587 0 0 0-3.16 0l-.38 4.894a9.213 9.213 0 0 1-.807-1.127A1.687 1.687 0 1 0 4.34 6.941a15.553 15.553 0 0 0 4.793 4.794z"
      />
    </g>
  </svg>
);
const cancel = () => (
  <svg viewBox="0 0 17 17">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#A3AFB8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M16 1L1 16M1 1l15 15" />
    </g>
  </svg>
);
const copy = () => (
  <svg viewBox="0 0 17 20">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#919EAB"
        fillRule="nonzero"
        d="M12.333.833h-10C1.413.833.667 1.58.667 2.5v11.667h1.666V2.5h10V.833zm2.5 3.334H5.667C4.746 4.167 4 4.912 4 5.833V17.5c0 .92.746 1.667 1.667 1.667h9.166c.921 0 1.667-.746 1.667-1.667V5.833c0-.92-.746-1.666-1.667-1.666zm0 13.333H5.667V5.833h9.166V17.5z"
      />
    </g>
  </svg>
);
const lastSeen = () => (
  <svg viewBox="0 0 15 13">
    <path
      fill="#2f9cf5"
      fillRule="evenodd"
      d="M7.5 12.5C1.75 12.5.046 6.764.029 6.705a.75.75 0 0 1 0-.41C.045 6.236 1.75.5 7.5.5c5.725 0 7.445 4.963 7.461 5.013a.749.749 0 0 1-1.421.476A6.26 6.26 0 0 0 7.5 2C3.4 2 1.865 5.579 1.539 6.5c.326.919 1.86 4.5 5.961 4.5a.75.75 0 1 1 0 1.5zm0-3c-1.654 0-3-1.345-3-3 0-1.654 1.346-3 3-3 1.655 0 3 1.346 3 3a.75.75 0 1 1-1.5 0C9 5.673 8.327 5 7.5 5S6 5.673 6 6.5 6.673 8 7.5 8a.75.75 0 1 1 0 1.5zm3.75 3a.748.748 0 0 1-.53-.22l-1.5-1.5a.75.75 0 1 1 1.06-1.06l.97.97 2.47-2.47a.75.75 0 1 1 1.06 1.06l-3 3a.748.748 0 0 1-.53.22z"
    />
  </svg>
);
const promotion = () => (
  <svg viewBox="0 0 15 15">
    <g>
      <path
        fill="#3BB278"
        fillRule="evenodd"
        id="a"
        d="M14.295 5.8l-.526-.525a.913.913 0 0 1-.264-.638v-.743a2.404 2.404 0 0 0-2.402-2.402h-.742a.896.896 0 0 1-.639-.264L9.197.703a2.405 2.405 0 0 0-3.397 0l-.525.525a.896.896 0 0 1-.639.264h-.742a2.404 2.404 0 0 0-2.402 2.402v.743c0 .237-.097.47-.264.638L.702 5.8a2.405 2.405 0 0 0 0 3.397l.526.525c.167.168.264.4.264.638v.744a2.404 2.404 0 0 0 2.402 2.401h.742c.241 0 .468.094.639.265l.525.525a2.394 2.394 0 0 0 1.699.702c.615 0 1.23-.234 1.698-.702l.525-.525a.896.896 0 0 1 .639-.265h.742a2.404 2.404 0 0 0 2.402-2.401v-.744c0-.237.097-.47.264-.638l.527-.525a2.406 2.406 0 0 0-.001-3.397M9.217 4.717l-4.5 4.5a.75.75 0 1 0 1.06 1.06l4.5-4.5a.75.75 0 1 0-1.06-1.06m-3.595 2.03a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25m3.75 1.5a1.125 1.125 0 1 0 0 2.251 1.125 1.125 0 0 0 0-2.25m3.863-.111l-.526.525a2.386 2.386 0 0 0-.704 1.698v.744a.902.902 0 0 1-.902.901h-.742c-.642 0-1.245.25-1.7.704l-.524.525a.904.904 0 0 1-1.277 0l-.525-.525a2.384 2.384 0 0 0-1.699-.704h-.742a.902.902 0 0 1-.902-.901v-.744c0-.642-.25-1.245-.704-1.698l-.525-.525a.902.902 0 0 1 0-1.276l.525-.525a2.387 2.387 0 0 0 .704-1.699v-.743c0-.497.404-.902.902-.902h.742c.643 0 1.245-.25 1.7-.704l.524-.525a.904.904 0 0 1 1.277 0l.525.525a2.385 2.385 0 0 0 1.698.704h.743c.498 0 .902.405.902.902v.743c0 .641.25 1.245.704 1.699l.526.525a.903.903 0 0 1 0 1.276"
      />
    </g>
  </svg>
);
const star = () => (
  <svg viewBox="0 0 16 16">
    <g>
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#2f9cf5"
        strokeWidth="1.3"
        transform="translate(1 1)"
        id="a"
        d="M2.8 14a.7.7 0 0 1-.693-.8l.65-4.552L.205 6.095a.7.7 0 0 1 .38-1.185l3.848-.642 1.94-3.88A.7.7 0 1 1 7.63.394L9.487 4.21l3.935.7a.7.7 0 0 1 .373 1.184l-2.553 2.553.65 4.553a.7.7 0 0 1-1.009.723L7.05 11.983l-3.94 1.944A.693.693 0 0 1 2.8 14z"
      />
    </g>
  </svg>
);

const install = () => (
  <svg viewBox="0 0 15 15">
    <path
      fill="#26C1C9"
      fillRule="evenodd"
      d="M1.5 13.5h12v-3h-2.537l-.542 1.085A.75.75 0 0 1 9.75 12h-4.5a.75.75 0 0 1-.671-.415L4.037 10.5H1.5v3zM14.25.75a.75.75 0 0 1 .75.75v12.75a.75.75 0 0 1-.75.75H.75a.75.75 0 0 1-.75-.75V1.5A.75.75 0 0 1 .75.75h3a.75.75 0 0 1 0 1.5H1.5V9h3a.75.75 0 0 1 .671.415l.542 1.085h3.574l.542-1.085A.75.75 0 0 1 10.5 9h3V2.25h-2.25a.75.75 0 0 1 0-1.5h3zM4.72 5.03a.75.75 0 1 1 1.06-1.06l.97.97V.75a.75.75 0 0 1 1.5 0v4.19l.97-.97a.75.75 0 1 1 1.06 1.06L8.03 7.28a.748.748 0 0 1-1.06 0L4.72 5.03z"
    />
  </svg>
);

const arrowDown = () => (
  <svg viewBox="0 0 10 5">
    <g>
      <path fill="#212b36" fillRule="evenodd" id="a" d="M0 0l5 5 5-5H0z" />
    </g>
  </svg>
);
export const arrowDownAlt = () => (
  <svg width="13" height="8" viewBox="0 0 13 8">
    <path fill="#5A6872" fillRule="evenodd" d="M.5 1.892L1.99.5 6.5 4.715 11.01.5l1.49 1.392-6 5.608z"/>
  </svg>
);

const search = () => (
  <svg viewBox="0 0 18 18">
    <path
      fill="#5A6872"
      fillRule="evenodd"
      d="M17.85 16.394l-4.657-4.692a.52.52 0 0 1-.057-.654c1.91-2.929 1.464-6.957-1.353-9.363C9.072-.629 4.946-.55 2.319 1.861-.695 4.63-.77 9.34 2.092 12.204c2.442 2.444 6.221 2.741 8.997.905a.518.518 0 0 1 .657.057l4.647 4.682a.514.514 0 0 0 .73.001l.726-.728c.2-.2.201-.525.001-.727zM3.547 10.75a5.11 5.11 0 0 1 0-7.214 5.099 5.099 0 0 1 7.207 0 5.11 5.11 0 0 1 0 7.214 5.1 5.1 0 0 1-7.207 0z"
    />
  </svg>
);
const users = () => (
  <svg viewBox="0 0 19 12">
    <path
      fill="#A3AFB8"
      fillRule="evenodd"
      d="M12.955 5.143a2.57 2.57 0 1 0 0-5.143 2.578 2.578 0 0 0-2.591 2.571 2.578 2.578 0 0 0 2.59 2.572zm-6.91 0a2.57 2.57 0 1 0 0-5.143 2.578 2.578 0 0 0-2.59 2.571 2.578 2.578 0 0 0 2.59 2.572zm0 1.714C4.033 6.857 0 7.86 0 9.857V12h12.09V9.857c0-1.997-4.032-3-6.045-3zm6.91 0c-.25 0-.536.017-.838.043 1.002.72 1.701 1.689 1.701 2.957V12H19V9.857c0-1.997-4.033-3-6.045-3z"
    />
  </svg>
);
const segment = () => (
  <svg viewBox="0 0 20 20">
    <g>
      <path
        fill="#919EAB"
        fillRule="evenodd"
        id="a"
        d="M18 12.86h-5.59L18 7.269v5.591zM12.86 18H2V7.139h3.14v6.721c0 .55.45 1 1 1h6.72V18zM7.14 2h5.59L7.14 7.589V2zM18 4.44l-8.42 8.42H7.14v-2.44L15.56 2H18v2.44zM19 0H6.14c-.55 0-1 .45-1 1v4.139H1c-.55 0-1 .45-1 1V19c0 .549.45 1 1 1h12.86c.55 0 1-.451 1-1v-4.14H19c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1z"
      />
    </g>
  </svg>
);
const subscription = () => (
  <svg viewBox="0 0 15 15">
    <g>
      <path
        fill="#81C926"
        fillRule="evenodd"
        id="a"
        d="M11.905 10.259l-.54 3.24H3.508l-1.074-2.682c.443-.133 1.221-.1 2.286.963A.75.75 0 0 0 6 11.25v-6a.75.75 0 0 1 1.5 0v3c0 .323.206.61.513.712l3.892 1.297zM3.75 5.999A2.252 2.252 0 0 1 1.5 3.75c0-1.24 1.01-2.25 2.25-2.25h7.5c1.24 0 2.25 1.01 2.25 2.25C13.5 4.992 12.49 6 11.25 6H9v-.75C9 4.01 7.99 3 6.75 3S4.5 4.01 4.5 5.25V6h-.75zm7.5 1.5A3.754 3.754 0 0 0 15 3.75 3.754 3.754 0 0 0 11.25 0h-7.5A3.754 3.754 0 0 0 0 3.75 3.754 3.754 0 0 0 3.75 7.5h.75v2.229c-1.638-.91-2.97-.32-3.53.242a.751.751 0 0 0-.167.809l1.5 3.75c.115.284.39.47.697.47h9a.75.75 0 0 0 .74-.625l.75-4.5a.75.75 0 0 0-.503-.835L9 7.709V7.5h2.25z"
      />
    </g>
  </svg>
);

const help = () => (
  <svg viewBox="0 0 15 15">
    <g>
      <path
        fill="#A3AFB8"
        fillRule="nonzero"
        d="M7.5 0a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15zm-.2 12a1.095 1.095 0 1 1 .01-2.19A1.095 1.095 0 0 1 7.3 12zm.96-3.65a.94.94 0 0 1-1.88 0c0-1.205.75-1.815 1.3-2.26 1.5-1.21-.525-1.5-1.395-.97a.905.905 0 0 1-1.415-.745.92.92 0 0 1 .5-.805 3.61 3.61 0 0 1 2-.555c1.645 0 2.75.96 2.75 2.39.01 1.81-1.86 2.06-1.86 2.935v.01z"
      />
    </g>
  </svg>
);

const transactions = () => (
  <svg viewBox="0 0 15 15">
    <g>
      <path
        fill="#A0AFB9"
        fillRule="evenodd"
        d="M4.5 12c-.825 0-1.493.675-1.493 1.5S3.675 15 4.5 15c.825 0 1.5-.675 1.5-1.5S5.325 12 4.5 12zM0 0v1.5h1.5l2.7 5.692L3.187 9.03A1.45 1.45 0 0 0 3 9.75c0 .825.675 1.5 1.5 1.5h9v-1.5H4.815a.186.186 0 0 1-.188-.188l.023-.09.675-1.222h5.587c.563 0 1.058-.308 1.313-.772L14.91 2.61a.752.752 0 0 0-.66-1.11H3.158L2.453 0H0zm12 12c-.825 0-1.492.675-1.492 1.5S11.175 15 12 15s1.5-.675 1.5-1.5S12.825 12 12 12z"
      />
    </g>
  </svg>
);

const more = () => (
  <svg viewBox="0 0 23 5">
    <g>
      <path
        fill="#A3AFB8"
        fillRule="evenodd"
        d="M20.5 5C21.875 5 23 3.875 23 2.5S21.875 0 20.5 0A2.507 2.507 0 0 0 18 2.5C18 3.875 19.125 5 20.5 5zm-9 0C12.875 5 14 3.875 14 2.5S12.875 0 11.5 0A2.507 2.507 0 0 0 9 2.5C9 3.875 10.125 5 11.5 5zM5 2.5C5 1.125 3.875 0 2.5 0A2.507 2.507 0 0 0 0 2.5C0 3.875 1.125 5 2.5 5S5 3.875 5 2.5"
      />
    </g>
  </svg>
);
const moreVertical = () => (
  <svg height={'18'} viewBox="0 0 4 18">
    <g>
      <path
        fill="#A3AFB8"
        fillRule="evenodd"
        d="M0 16c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2zm0-7c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2zm2-5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2"
      />
    </g>
  </svg>
);

const upgradeCodeAlt = () => (
  <svg width="15" height="13" viewBox="0 0 15 13">
    <path
      fill="#5A6872"
      fillRule="nonzero"
      d="M4.315 5.38a1.2 1.2 0 1 0-1.23-2.06 9.12 9.12 0 0 0-2.81 2.81 1.2 1.2 0 0 0 0 1.235 9.12 9.12 0 0 0 2.81 2.81 1.2 1.2 0 1 0 1.235-2.06A7.06 7.06 0 0 1 2.78 6.75a6.2 6.2 0 0 1 1.535-1.37zm10.41.755a9.12 9.12 0 0 0-2.81-2.81 1.2 1.2 0 0 0-1.235 2.06 7.06 7.06 0 0 1 1.54 1.365 6.2 6.2 0 0 1-1.54 1.38 1.2 1.2 0 1 0 1.235 2.06 9.12 9.12 0 0 0 2.81-2.81 1.2 1.2 0 0 0 .005-1.25l-.005.005zM7.775 1l-2.5 10.5a1 1 0 0 0 1.945.465l2.5-10.5A1 1 0 0 0 7.775 1z"
    />
  </svg>
);
const upgradeNotificationng = () => (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#5A6872"
        strokeWidth="2"
        d="M5.128 4c-1.475 0-2.034.108-2.61.416a2.635 2.635 0 0 0-1.102 1.102C1.108 6.094 1 6.653 1 8.128v2.744c0 1.475.108 2.034.416 2.61.255.478.624.847 1.102 1.102.576.308 1.135.416 2.61.416h2.744c1.475 0 2.034-.108 2.61-.416a2.635 2.635 0 0 0 1.102-1.102c.308-.576.416-1.135.416-2.61V8.128c0-1.475-.108-2.034-.416-2.61a2.635 2.635 0 0 0-1.102-1.102C9.906 4.108 9.347 4 7.872 4H5.128z"
      />
      <path
        fill="#5A6872"
        fillRule="nonzero"
        stroke="#FFF"
        strokeWidth="1.5"
        d="M11.497 1a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7z"
      />
    </g>
  </svg>
);
const upgradeTeam = () => (
  <svg width="15" height="13" viewBox="0 0 15 13">
    <path
      fill="#5A6872"
      fillRule="nonzero"
      d="M11.568 7.618c-.671 0-1.316.262-1.796.731L7.804 7.215V5.059a2.578 2.578 0 1 0-1.407 0v2.156L4.43 8.349a2.606 2.606 0 1 0 .703 1.219L7.1 8.438l1.955 1.13a2.578 2.578 0 1 0 2.499-1.95h.014z"
    />
  </svg>
);
const upgradeTrending = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13">
    <path
      fill="#5A6872"
      fillRule="nonzero"
      d="M14.76 3.61A7.92 7.92 0 0 0 13.125.57 1.04 1.04 0 0 0 12.1.26a7.915 7.915 0 0 0-3.04 1.635 1.042 1.042 0 0 0 1.405 1.54c.16-.134.326-.26.5-.375A7.22 7.22 0 0 1 3.5 8.08a.876.876 0 0 0-.145 1.745c.24.02.478.03.715.03a9 9 0 0 0 8.5-6.185c.057.147.105.297.145.45a1.054 1.054 0 1 0 2.045-.51zM13.75 11H1.5V1.25a.75.75 0 0 0-1.5 0V12.5h13.75a.75.75 0 1 0 0-1.5z"
    />
  </svg>
);

const check = () => (
  <svg width="18" height="13" viewBox="0 0 10 7">
    <path
      fill="#00C880"
      fillRule="evenodd"
      d="M3.636 4.16L8.86.15a.736.736 0 0 1 .953.052c.25.242.25.631 0 .873L4.065 6.628a1.35 1.35 0 0 1-.995.37 1.342 1.342 0 0 1-.955-.459L.161 4.255a.66.66 0 0 1 .045-.912.76.76 0 0 1 .965-.067l1.256.902a1.02 1.02 0 0 0 1.209-.018z"
    />
  </svg>
);

const setYourApp = () => (
  <svg width="16" height="12" viewBox="0 0 16 12">
    <path fill="#FFF" fillRule="evenodd" id="a" d="M15.707 5.293l-5-5a.999.999 0 1 0-1.414 1.414L12.586 5H1a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414"/>
  </svg>

);


const select = () => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <path fill="#637381" fillRule="evenodd" d="M10 16l-4-4h8l-4 4zm0-12l4 4H6l4-4z"/>
  </svg>

);
const rightArrow = () => (
  <svg width="16" height="12" viewBox="0 0 16 12">
    <defs>
      <path id="a" d="M15.707 5.293l-5-5a.999.999 0 1 0-1.414 1.414L12.586 5H1a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414"/>
    </defs>
    <use fill="#FFF" fillRule="evenodd"/>
  </svg>
);
