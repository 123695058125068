import FilterInputTypes from './FilterInputTypes';

export default {
  name: {
    type: FilterInputTypes.TEXT_INPUT,
    name: "name",
    required: false,
    label: `Name`,
    placeholder: `Enter Name`,
  },
  external_product_id: {
    type: FilterInputTypes.TEXT_INPUT,
    name: "external_product_id",
    required: false,
    label: `Product ID`,
    placeholder: `Enter product ID`,
  },
  app: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "app",
    required: false,
    label: `App`,
    placeholder: `Enter App`,
    options: [
      {title: "Boron",
        value: 1},
      {title: "Chatflix",
        value: 2},
      {title: "PhoneLine",
        value: 3},
    ],
  },
  is_renewal: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "is_renewal",
    required: false,
    label: `Is enewal`,
    placeholder: `Select renewal`,
    options: [
      {title: "Yes",
        value: 1},
      {title: "No",
        value: 2},
    ],
  },

  is_trial: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "is_trial",
    required: false,
    label: `Is Trial`,
    placeholder: `Select trail`,
    options: [
      {title: "Yes",
        value: 1},
      {title: "No",
        value: 2},
    ],
  },
  store: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "source",
    required: false,
    label: `Store`,
    placeholder: `Select Store`,
    options: [
      {title: "App Store",
        value: "SOURCE_APP_STORE"},
      {title: "Play Store",
        value: "SOURCE_PLAY_STORE"},
    ],
  },

  type: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "type",
    required: false,
    label: `Type`,
    placeholder: `Select type`,
    options: [
      {title: "Consumable",
        value: "TYPE_CONSUMABLE"},
      {title: "Subscription",
        value: "TYPE_SUBSCRIPTION"},
    ],
  },

  product_group: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "product_group_name",
    required: false,
    label: `Group`,
    placeholder: `Select group`,
    options: [],
  },
  // include_users: {
  //   type: FilterInputTypes.CHECKBOX,
  //   name: "include_users",
  //   required: false,
  //   label: `Include Users :`,
  //   placeholder: `Include Users`,
  //   options: [
  //     { label: 'Who have email or external ID', value: 'external_id' },
  //     { label: 'Who have purchased a product', value: 'purchased' }
  //   ],
  // },

  email_externalid: {
    type: FilterInputTypes.CHECKBOX,
    name: "email_externalid",
    required: false,
    label: `Include Users :`,
    options: [
      { label: 'Who have email or external ID', value: 'true' },
    ],
  },

  payment_status_paid: {
    type: FilterInputTypes.CHECKBOX,
    name: "payment_status_paid",
    required: false,
    options: [
      { label: 'Who have purchased a product', value: 'true' },

    ],
  },
};