import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'antd/lib/index';
import listUtils from '../../../utils/listUtils';
import { loadData } from '../../../utils/ActionCreators';
import { contentType } from '../../../utils/ActionTypes';
import { ComponentText } from '../../shared/ComponentText';
import utils from '../../../utils/utils';
import { RangeDateTimePicker } from '../../shared/DateTime/RangeDateTimePicker';
import {
  metricsChurnApiPath,
  metricsChurnPath,
} from '../../../utils/routerUtils';
import { getUser } from '../../../utils/Repository';

import ComponentAppSelect from '../../shared/ComponentAppSelect';
import Space from '../../shared/Spcae';
import './Purchase.css';
import { localStorageKey } from '../../../utils/LocalStorage';
import RechartChart, {
  DEFAULT_MIN_DURATION,
  getInterval,
} from '../../shared/Chart/RechartChart';
import { ChartSegmentTable } from '../../shared/Chart/ChartSegmentTable';

const path = metricsChurnApiPath;
const title = 'Churn';

class MetricsChurn extends React.Component {

  componentDidMount() {
    this.loadListWithPageFilter();
  }


  componentDidUpdate (previousProps, prevState, snapshot) {
    const currentSearch = this.props.location.search;
    const previousSearch = previousProps.location.search;
    if (currentSearch !== previousSearch) {
      this.loadListWithPageFilter();
    }

    if(this.props.message && this.props.message !== previousProps.message){
      const text = this.props.message.error.charAt(0).toUpperCase() + this.props.message.error.slice(1);
      utils.showError(text);
    }
  }

  loadListWithPageFilter() {
    const { onLoadData } = this.props;



    const user = getUser();
    const query = new URLSearchParams(this.props.location.search);
    const applicationId = query.get('application_ids');
    const bucketInterval = query.get('bucket_interval');

    const from = query.get('from');
    const to = query.get('to');
    const between = utils.getDefaultDateRange(from, to);

    let duration = utils.getDateRangeDuration(between.from,between.to);

    let filters = listUtils.prepareFilterParamsWithDate(
      listUtils.prepareFilterParams(listUtils.currentFilters(this.props.location.search, [])),
      between
    );

    if(bucketInterval){
      filters['bucket_interval'] = bucketInterval;
    }else{
      const interval = localStorage.getItem(localStorageKey.METRICS_INTERVAL);
      filters['bucket_interval'] = getInterval(duration, interval);
    }
    if(applicationId) {
      if (applicationId && applicationId !== 'all-app') {
        filters['application_id'] = applicationId;
      }
      filters['organization_id'] = user.organizations_roles[0].organization_id;
      onLoadData(contentType.METRICS_CHURN, path, { filters: filters });
    }
  }

    onDateChanged = range => {
      const between = { from: range.from, to: range.to };
      const { history } = this.props;
      let filters = listUtils.prepareFilterParamsWithDate(
        listUtils.prepareFilterParams(listUtils.currentFilters(this.props.location.search, [])),
        between,
      );
      history.push(listUtils.prepareUrlWithoutPager(metricsChurnPath, filters));
    };

    render() {
      let { isFetching, data, message } = this.props;
      const blur = message && message.code && message.code === 426;

      let chartData = [];
      if(data){
        data.forEach(e => {
          chartData.push({
            subscriptions_lost: Math.round(e.subscriptions_lost),
            subscriptions: Math.round(e.subscriptions),
            date: e.key
          });
        });
      }
      return (
        <div className={"metrics-container"}>
          <Row type="flex" justify="space-between" align="middle">
            <Row type="flex">
              <ComponentText
                text={title}
                size="28"
                weight="600"
                color="#212b36"
                align="left"
                marginTop="0"
                marginBottom="0"
              />
            </Row>
            <Row type="flex">
              <ComponentAppSelect location={this.props.location} history={this.props.history} />
              <Space width="16" />
              <RangeDateTimePicker
                onDateChanged={this.onDateChanged}
                location={this.props.location}
                history={this.props.history}
                defaultInterval={DEFAULT_MIN_DURATION}
              />
            </Row>
          </Row>

          <RechartChart data={chartData} blur = {blur} line={"subscriber_lost"} title={"Churn"}
            location={this.props.location} history={this.props.history}
            isFetching={isFetching}
            isStacked={true} titles={chartTitles} keys={chartValueKeys} colors={chartColors}/>
          <Space height={20}/>
          {!blur && <ChartSegmentTable list={chartData} isFetching={isFetching}
            isStacked={true} titles={chartTitles} keys={chartValueKeys}/>}
          <Space height={20} />
        </div>
      );
    }
}

const chartTitles = ["Subscriptions Lost","Subscriptions"];
const chartValueKeys = ["subscriptions_lost", "subscriptions"];
const chartColors = ["#81c926", "#26c1c9" ];

const mapActionToProps = {
  onLoadData: loadData,
};

const mapStateToProps = state => {
  const { metricsChurnReducer } = state;
  return {
    data: utils.getMetrics(metricsChurnReducer),
    isFetching: utils.getIsFetching(metricsChurnReducer),
    isFailed: utils.getIsFailed(metricsChurnReducer),
    message: utils.getMessage(metricsChurnReducer),
  };
};
export default connect(mapStateToProps, mapActionToProps)(MetricsChurn);

