import React from 'react';
import { Avatar, Card, Col, Row, Skeleton } from 'antd';
import { ComponentText } from '../ComponentText';

export function CardInfoSmall({title, count, icon}) {
  return <Card style={{
    minWidth: 176,
    margin: 8,
    borderRadius: 10,
  }} hoverable bodyStyle={{padding: 16}}>
    <Row type="flex" align="middle">
      <Col>
        <Avatar shape="square" size={48} src={icon} style={{marginRight: 14}}/>
      </Col>
      <Col>
        <ComponentText text={title} font={"SFProDisplay-Semibold"} size="11" color="var(--greyblue)" align="left"/>
        <Skeleton className={"card-info"} loading={!count} active paragraph={0}>
          <ComponentText text={count} font={"SFProDisplay-Semibold"} size="23" color="var(--dark-grey-blue-two)" align="left" marginTop="4" marginBottom="0"/>
        </Skeleton>
      </Col>
    </Row>
  </Card>;
}
