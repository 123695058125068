import React from 'react';
import { Divider, Form, message, Row, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { InputComponent } from '../shared/FormComponent/InputComponent';
import {
  getInputEmail,
  getInputPassword,
} from '../../utils/InputFieldCongifurations';
import { PasswordComponent } from '../shared/FormComponent/PasswordComponent';
import { connect } from 'react-redux';
import { googleLogin, login } from './authActionCreators';
import { ComponentText } from '../shared/ComponentText';
import Space from '../shared/Spcae';
import { ComponentButton } from '../shared/ComponentButton';
import styled from 'styled-components';
import { fonts } from '../../utils/fonts';
import { TopBar } from './Topbar';
import { localStorageKey } from '../../utils/LocalStorage';
import GoogleLogin from 'react-google-login';
import utils from '../../utils/utils';

export const Styled = {};


Styled.FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 60px;
`;

Styled.EmailWrapper = styled.div`
  margin-top: 16px;
`;

Styled.PasswordWrapper = styled.div`
  margin-top: 6px;
`;

Styled.FooterWrapper = styled.div`
  display: flex;
	justify-content: space-between;
	margin-top: -3px;
`;

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: getInputEmail(),
      password: getInputPassword(),
    };
  }

  componentDidMount() {
    if (utils.isLoggedIn(this.props.data)) {
      this.navigateToDashboard();
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

    const { data, loginMessage,  isFailed, googleIsFailed, googleMessage } = this.props;

    if (isFailed === true) {
      if (prevProps.loginMessage !== loginMessage) this.error(loginMessage);
    }

    if(googleIsFailed){
      if (prevProps.googleMessage !== googleMessage) this.error(googleMessage.error);
      this.props.history.push(`social-sign-up?access_token=${this.googleAccessToken}&provider=PROVIDER_GOOGLE`);
    }

    if (data && prevProps.data !== data && !googleIsFailed) {
      this.success("Logged In");
      const previousLocation = localStorage.getItem(localStorageKey.REDIRECT_TO);
      this.navigateToDashboard(previousLocation);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.login(values);
      }
    });
  };

  success = text => {
    message.success(text);
  };

  error = text => {
    if (text === null) return;

    message.error(text);
  };

  render() {
    const { isFetching, googleIsFetching } = this.props;
    return (
      <Spin spinning={!!isFetching || !!googleIsFetching} >
        <TopBar/>
        <div style={{height:window.innerHeight - 71, backgroundColor:"#fafafa"}}>
          <Row justify={"center"} type={"flex"}>
            <div className={"login-container"}>
              <ComponentText
                text="Login to Purchasefly"
                size="23"
                color="var(--dark-grey-blue-two)"
                font={fonts.SFProDisplayRegular}
              />
              <Space height={12}/>
              <ComponentText
                text="We make your in-app subscription business fly."
                size="15"
                color="var(--cool-grey)"
                font={fonts.SFProDisplayRegular}
              />

              <Divider />

              <Styled.FormWrapper>
                <Form onSubmit={this.handleSubmit} layout="vertical">
                  <Styled.EmailWrapper>
                    <InputComponent
                      form={this.props.form}
                      layout={formItemLayout}
                      values={this.state.email}
                    />
                  </Styled.EmailWrapper>

                  <Styled.PasswordWrapper>
                    <PasswordComponent
                      form={this.props.form}
                      layout={formItemLayout}
                      values={this.state.password}
                    />
                  </Styled.PasswordWrapper>

                  <Form.Item className="no-bottom-margin">
                    <ComponentButton
                      htmlType="submit"
                      name="sign-in"
                      text="Sign in"
                      css="dark-sky-blue eina-semibold-white-15"
                      styl={{ height: '42px' }}
                      stylText={{fontFamily: 'SFProDisplay-Medium'}}
                    />
                  </Form.Item>

                </Form>

                <Styled.FooterWrapper>
                  <Link to={'forgot'}>
                    <ComponentText
                      text="Forgot your password?"
                      size="13"
                      color="var(--azure)"
                    />
                  </Link>

                  <Row type="flex" justify="center">
                    <ComponentText
                      text="Don’t have an account? "
                      size="13"
                      color="var(--greyblue)"
                    />
                    <Space width="6" />
                    <Link to={'register'}>
                      <ComponentText text="Sign Up" size="13" color="var(--azure)" />
                    </Link>
                  </Row>
                </Styled.FooterWrapper>
              </Styled.FormWrapper>
              <Space height={30}/>
              <Divider style={{background:"var(--white)"}}><ComponentText
                text="Sign in with social "
                size="13"
                color="var(--greyblue)"
              /></Divider>

              <Space height={30}/>
              <Row>
                <GoogleLogin
                  className={"google-sign-in-button"}
                  clientId="204796753469-5538jqmtk3eeks5f3650ipu3ui12a27m.apps.googleusercontent.com"
                  buttonText="Sign in with google"
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogle}
                  cookiePolicy={'single_host_origin'}
                />
              </Row>
            </div>
          </Row>
        </div>
      </Spin>
    );
  }

  responseGoogle = (response) => {
    console.log(response);
    if(response.accessToken) {
      this.googleAccessToken = response.accessToken;
      this.props.googleLogin({
        access_token: response.accessToken,
        provider: "PROVIDER_GOOGLE"
      });
    }
    else{
      this.error("Authentication Failed");
    }
  }

  navigateToDashboard =(previousLocation)=> {
    if(previousLocation && previousLocation.length > 0){
      localStorage.setItem(localStorageKey.REDIRECT_TO,"");
      this.props.history.push(previousLocation);
    }else
      this.props.history.push('/?application_ids=all-app');
  }
}

const Login = Form.create({ name: 'login' })(LoginForm);

const mapActionToProps = {
  login, googleLogin
};

const mapStateToProps = state => {
  const { loginRemoteData, signUpGoogleRemoteData} = state;

  console.log(loginRemoteData);
  return {
    data: utils.getItem(loginRemoteData),
    isFetching: utils.getIsFetching(loginRemoteData),
    isFailed: utils.getIsFailed(loginRemoteData),
    loginMessage: utils.getMessage(loginRemoteData),

    googleData: utils.getItem(signUpGoogleRemoteData),
    googleIsFetching: utils.getIsFetching(signUpGoogleRemoteData),
    googleIsFailed: utils.getIsFailed(signUpGoogleRemoteData),
    googleMessage: utils.getMessage(signUpGoogleRemoteData),
  };
};

export default connect(mapStateToProps, mapActionToProps)(Login);

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};
