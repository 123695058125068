import React from 'react';

export class ComponentDivider extends React.Component {

  render() {
    const {color, height, width, marginTop, marginBottom, marginLeft, marginRight, type} = this.props;
    const style = {
      backgroundColor: color ? color : "#e5eef5",
      height: height ? height : type === 'vertical' ? '100%' : 1,
      width: width ? width : type === 'vertical' ? 1 : "100%",
      marginTop: marginTop ? marginTop : 0,
      marginBottom: marginBottom ? marginBottom : 0,
      marginLeft: marginLeft ? marginLeft : 0,
      marginRight: marginRight ? marginRight : 0,
    };
    return <div style ={style}></div>;
  }

}
