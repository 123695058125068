import { Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dashboard from '../../Dashboard/Dashboard';

import {
  accountDetailsPath, alertCreatePath, alertListPath, alertUpdatePath,
  appsCreatePath,
  appsDetailsPath,
  appsListPath,
  customerDetailsPath,
  customerListPath,
  metricsActivePurchasePath, metricsChurnPath, metricsEventCountPath,
  metricsMRRPath,
  metricsNewPurchasePath,
  metricsRevenuePath, metricsTrialConversionPath,
  transactionDetailsPath,
  transactionListPath,
} from '../../../utils/routerUtils';
import AppsList from '../../Apps/List/AppsList';
import AppsDetails from '../../Apps/Details/AppsDetails';
import AppsCreate from '../../Apps/Create/AppsCreate';
import AccountDetails from '../../Account/Details/AccountDetails';
import { UIComponents } from '../../UIComponents';
import TransactionList from '../../Transaction/List/TransactionList';
import TransactionDetails from '../../Transaction/Details/TransactionDetails';
import CustomerList from '../../Customer/List/CustomerList';
import CustomerDetails from '../../Customer/Details/CustomerDetails';
import { contentType } from '../../../utils/ActionTypes';
import MetricsRevenue from '../../Analytics/Revenue/MetricsRevenue';
import MetricsMRR from '../../Analytics/MRR/MetricsMRR';
import OnBoarding from '../../Dashboard/onboarding/OnBoarding';
import MetricsActivePurchase from '../../Analytics/Purchase/ActivePurchase';
import MetricsNewPurchase from '../../Analytics/Purchase/NewPurchase';
import MetricsEventCount from '../../Analytics/EventCount/EventCount';
import MetricsTrialConversion from '../../Analytics/Purchase/TrialConversion';
import MetricsChurn from '../../Analytics/Purchase/Churn';
import AlertCreate from '../../Alert/Create/AlertCreate';
import AlertList from '../../Alert/List/AlertList';

const NoMatch = ({location}) => (
  <div>
    <h3>
            No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

class MainWindow extends Component {

  componentDidMount () {
    if(this.props.location.pathname === "/"){
      this.props.history.push({
        pathname: '/',
        search: "",
      });
    }
  }

  render() {
    const appList = JSON.parse(localStorage.getItem(contentType.APPS_LIST.name));
    const onBoarding = !!(appList && appList.data && appList.data.length === 0);
    const location = this.props.location;
    return (

      <div>
        <Switch>
          <Route location={location} path='/components' component={UIComponents} />
          <Route location={location} path="/dashboard" component={Dashboard} />
          <Route location={location} path="/onboard" component={OnBoarding} />

          {onBoarding === true ?
            <Route location={location} path={appsListPath} component={OnBoarding} /> :
            <Route location={location} path={appsListPath} component={AppsList} />
          }
          <Route location={location} path={appsCreatePath} component={AppsCreate} />
          <Route location={location} path={appsDetailsPath} component={AppsDetails} />

          <Route location={location} path={accountDetailsPath} component={AccountDetails} />

          <Route location={location} path={transactionListPath} component={TransactionList} />
          <Route location={location} path={transactionDetailsPath} component={TransactionDetails} />
          <Route location={location} path={customerListPath} component={CustomerList} />
          <Route location={location} path={customerDetailsPath} component={CustomerDetails} />

          <Route location={location} path={metricsRevenuePath} component={MetricsRevenue} />
          <Route location={location} path={metricsMRRPath} component={MetricsMRR} />
          <Route location={location} path={metricsActivePurchasePath} component={MetricsActivePurchase} />
          <Route location={location} path={metricsNewPurchasePath} component={MetricsNewPurchase} />
          <Route location={location} path={metricsEventCountPath} component={MetricsEventCount} />
          <Route location={location} path={metricsTrialConversionPath} component={MetricsTrialConversion} />
          <Route location={location} path={metricsChurnPath} component={MetricsChurn} />

          <Route location={location} path={alertListPath} component={AlertList} />
          <Route location={location} path={alertCreatePath} component={AlertCreate} />
          <Route location={location} path={alertUpdatePath} render={(props) => <AlertCreate {...props} edit={true} />}/>


          {/*_add_from_here*/}
          <Route location={location} component={Dashboard} />
        </Switch>
      </div>

    );
  }
}

MainWindow.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default MainWindow;