import React from 'react';
import { Col, Modal, Row } from 'antd';
import { ComponentText } from '../shared/ComponentText';
import { fonts } from '../../utils/fonts';
import images from '../../images/Images';
import Space from '../shared/Spcae';
import { ComponentButton } from '../shared/ComponentButton';
import { Link } from 'react-router-dom';
import { ComponentIcon } from '../shared/ComponentIcon';

export class ModalOnBoard extends React.Component {

    handleCancel = () => {
      this.props.handleClose();
    };


    render() {
      const {visible} = this.props;


      return <Modal
        className="model-upgrade-product"
        // title={ <ComponentText text="Upgrade to Startup" size="18" color="var(--black)" align="left" marginTop="0" marginBottom="0"/>}
        title= {<Row type="flex" align="middle" justify="center">
          <ComponentText text={`Welcome to Purchasefly!`} size={24} color="var(--black-two)" font={fonts.SFProDisplaySemiBold} />
          <Space height={8}/>
          <ComponentText text={"Get started with our enterprise-class mobile subscription management"} size={12} color="var(--battleship-grey-three)" font={fonts.SFProDisplayRegular} />
        </Row>}
        centered
        visible={visible}
        closable={true}
        footer={null}
        closeIcon={<ComponentIcon type="cancel" width={15}/>}
        onCancel={this.handleCancel}
      >
        <div style={{paddingLeft: 82, paddingRight:74}}>
          <Space height={36}/>
          {
            content.map((e,i)=><div key={i}><Features image={e.image}
              title={e.title}
              description={e.description}/>
            <Space height={30}/></div>)
          }
          <Row type={"flex"} justify={"center"}>
            <Link to={"onboard"}>
              <ComponentButton
                name="set-up-app"
                iconRight="set-up-app"
                text="Set up your app"
                css="set-up-app"
                width="206"
                height="42"
                iconSize="20px"
                spaceWidth={32}
              />
            </Link>
          </Row>
          <Space height={48}/>
        </div>
      </Modal>;
    }
}

function Features ({image, title, description}) {
  return <Row type={"flex"} justify={"center"} align={"middle"}>
    <Col span={4}>
      <img src={image} width={60} height={60} alt={""}/>
    </Col>
    <Col span={2}/>
    <Col span={18}>
      <ComponentText text={title} size={14} color="var(--black)" font={fonts.SFProDisplaySemiBold} />
      <span className={"onboard-modal-description"}>
        {description}
      </span>
      {/*<ComponentText text={description} size={12} color="var(--battleship-grey-three)" font={fonts.SFProDisplayRegular} />*/}
    </Col>
  </Row>;

}

const content = [
  {
    image: images.how_1,
    title: "Add your apps",
    description:"Connect and configure your android or iOS app with purchasefly."
  },{
    image:images.how_2,
    title:"Users pays for subscriptions",
    description:"Customer buys a in-app subscriptions plans and pay for it using in-app methods."
  },{
    image: images.how_3,
    title:"Get the revenue",
    description:"Check subscription status, while hosting and configuring products from our dedicated dashboard."
  },{
    image:images.how_4,
    title:"Keep track of everything",
    description:"Get all the key metrics of your in-app subscription business in one convenient nest."
  }
];