import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../Auth/authActionCreators';
import { Avatar, Badge, Col, Dropdown, Menu, Row } from 'antd';
import { Logo } from '../Logo';
import { ComponentText } from '../ComponentText';
import './TopMenu.css';
import { ComponentButton } from '../ComponentButton';
import Space from '../Spcae';
import { getUser } from '../../../utils/Repository';
import { fonts } from '../../../utils/fonts';
import utils from '../../../utils/utils';
import { localStorageKey } from '../../../utils/LocalStorage';

class TopMenu extends Component {

  constructor (props) {
    super(props);
    const user = getUser();
    this.state = {avatar_url: user.avatar_url, name: user.name, update_plan:localStorage.getItem(localStorageKey.UPGRADE_PLAN)};

  }

  handleButtonClick = e => {
    //message.info('Click on left button.')
    console.log('click left button', e);
  };

  handleMenuClick = e => {
    switch (e.key) {
    case 'account':
      this.props.history.push('/account');
      break;
    case 'billing':
      this.props.history.push('/account?menu=billing');
      break;
    case 'current-plan':
      break;
    case 'basic':
      break;
    case 'logout':
      //localStorage.clear();
      //setTimeout(()=> this.props.history.push('/login'), 1000);
      //return <Redirect to="/login" />
      this.props.onLogout();
      //this.props.history.push('login');
      // this.props.history.push({
      //   pathname: '/login',
      //   search: "",
      // });
      break;
    default:
      break;
    }
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    if(this.props.userAccountUpdateData && this.props.userAccountUpdateData !== prevProps.userAccountUpdateData){
      const user = getUser();
      if(user.avatar_url !== this.state.avatar_url){
        this.setState({avatar_url:user.avatar_url});
      }
      if(user.name !== this.state.name){
        this.setState({name:user.name});
      }
    }
    if(this.props.data && this.props.data !== prevProps.data){
      this.handleMenuClick({key:'logout'});
    }
    if(this.props.userOnFreePlanData && this.props.userOnFreePlanData !== prevProps.userOnFreePlanData){
      const {name} = this.props.userOnFreePlanData;
      if(name && name.length > 0){
        this.setState({update_plan: name});
        localStorage.setItem(localStorageKey.NEXT_PLAN, name);
      }

    }


  }

  render() {

    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="account">
          {/*<Link to={'/account'}>*/}
          <ComponentText
            text="My Account"
            font={fonts.SFProDisplayRegular}
            size="15"
            color="var(--dark)"
            align="left"
          />
          {/*</Link>*/}
        </Menu.Item>
        <Menu.Item key="billing">
          {/*<Link to={'/account?menu=billing'}>*/}
          <ComponentText
            text="Billing"
            font={fonts.SFProDisplayRegular}
            size="15"
            color="var(--dark)"
            align="left"
          />
          {/*</Link>*/}
        </Menu.Item>
        {/* <Divider style={{marginTop: 10,
                  marginBottom: 10}}/> */}
        {/*<Menu.Item key="current-plan">*/}
        {/*  <Link to={'/account/plan'}>*/}
        {/*    <ComponentText*/}
        {/*      text="Current Plant"*/}
        {/*      font="Eina-Regular"*/}
        {/*      size="15"*/}
        {/*      color="var(--dark)"*/}
        {/*      align="left"*/}
        {/*    />*/}
        {/*  </Link>*/}
        {/*</Menu.Item>*/}
        {/*<Menu.Item key="basic">*/}
        {/*  <Link to={'/account/plan'}>*/}
        {/*    <ComponentText text="Basic (Free)" size="15" color="var(--dark)" align="left" />*/}
        {/*  </Link>*/}
        {/*</Menu.Item>*/}
        {/* <Divider style={{marginTop: 10,
                  marginBottom: 10}}/> */}
        <Menu.Item key="logout">
          <ComponentText text="Logout" size="15" color="var(--cerulean)" align="left"  font={fonts.SFProDisplayRegular} />
        </Menu.Item>
      </Menu>
    );

    return (
      <Row type="flex" align="middle" style={{ height: '56px' }}>
        <Col span={12}>
          {' '}
          <Row type="flex" justify="start">
            {' '}
            <Logo height={30} location={this.props.location}/>{' '}
          </Row>
        </Col>
        <Col span={12}>
          <Row type="flex" justify="end" align="middle" >
            { this.state.update_plan && this.state.update_plan.length > 0 && <ComponentButton text={"Upgrade to "+this.state.update_plan} css={"button-upgrade"} handleClick={()=>this.props.history.push('/account?menu=billing')}/>}
            <Space width="20" />
            <a href={"http://docs.purchasestacks.com"} target="_blank">
              <ComponentText text={"Help Docs"} font={fonts.SFProDisplaySemiBold} size={13} color={"var(--white)"}/>
            </a>
            <Space width="30" />
            <Badge dot>
              <ComponentButton
                name="edit-product-group"
                text=""
                iconLeft="notification"
                css="link eina-semibold-cool-grey-14"
                iconSize={18}
                handleClick={this.onButtonClick}
                width={20}
                height={20}
              />
            </Badge>
            <Space width="32" />
            <Dropdown onClick={this.handleButtonClick} overlay={menu} overlayClassName={'top-bar'}>
              <Row type="flex" align="middle" style={{ height: '100%' }}>
                {this.state.avatar_url ?  <Avatar src={this.state.avatar_url} size={26}/> : <Avatar icon="user" />}
                <Space width="10" />
                <ComponentButton
                  name="edit-product-group"
                  text={this.state.name}
                  iconRight="caret-down"
                  antd={true}
                  css="white link eina-semibold-white-14"
                  iconSize={18}
                  handleClick={this.onButtonClick}
                />
              </Row>
            </Dropdown>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapActionToProps = {
  onLogout: logout,
};

const mapStateToProps = state => {
  const {logoutData, userAccountUpdateData, userOnFreePlanData} = state;
  return {
    data: utils.getItem(logoutData),
    userAccountUpdateData : utils.getItem(userAccountUpdateData),
    userOnFreePlanData : utils.getItem(userOnFreePlanData),
  };
};
export default connect(
  mapStateToProps,
  mapActionToProps
)(TopMenu);

