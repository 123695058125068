import { FAILED, RECEIVE, REQUEST } from './ActionCreators';
import { createReducer } from '@reduxjs/toolkit';


export function createReducerWraper(type) {
  return createReducer(
    {},
    {
      [REQUEST + type.name]: (state, action) => {
        return Object.assign({}, state, {
          isFetching: true,
          response: null,
          isFailed: false,
          message: null,
        });
      },
      [RECEIVE + type.name]: (state, action) => {
        return Object.assign({}, state, {
          isFetching: false,
          response: action.payload,
          isFailed: false,
          message: null,
        });
      },
      [FAILED + type.name]: (state, action) => {
        return Object.assign({}, state, {
          isFetching: false,
          response: null,
          isFailed: true,
          message: action.payload,
        });
      },
    }
  );
}
