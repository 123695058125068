import React from 'react';
import { Card, Row, Skeleton } from 'antd';
import { ComponentText } from '../ComponentText';
import { ComponentIcon } from '../ComponentIcon';
import Space from '../Spcae';
import moment from 'moment';

export function CardInfoTime({lastSeen, firstUsed}) {
  return <Card style={{
    margin: 8,
    borderRadius: 4,
    width: 300,
  }} hoverable bodyStyle={{padding: 22}}>

    <Row type="flex" align="middle">
      <div style={{paddingBottom: 2}}>
        <ComponentIcon type="lastSeen" width={15}/>
      </div>
      <Space width="8"/>
      <ComponentText text={"Last seen"} font={"SFProDisplay-Medium"} size="15" color="var(--dark-grey-blue-two)"/>
    </Row>
    <div style={{height:44, marginTop: 6}}>
      <Skeleton className={"card-info-medium"} loading={!lastSeen} active paragraph={0}> <ComponentText text={moment(lastSeen).utc().format("dddd, MMMM D, YYYY hh:mm A")} font={"SFProDisplay-Regular"} size="14" color="var(--slate-grey)"/> </Skeleton>
    </div>

    <ComponentText text={"First used"} size="15" color="var(--dark-grey-blue-two)" font={"SFProDisplay-Medium"}/>
    <div style={{height:28, marginTop: 6}}>
      <Skeleton className={"card-info-medium"} loading={!firstUsed} active paragraph={0}> <ComponentText text={moment(firstUsed).utc().format("dddd, MMMM D, YYYY hh:mm A")} font={"SFProDisplay-Regular"}  size="14" color="var(--slate-grey)" /> </Skeleton>
    </div>

  </Card>;
}
