import React from 'react';
import { Form, Modal } from 'antd';
import styled from 'styled-components';
import { TableListNew } from '../Table/TableListNew';
import { tableContentType } from '../Table/TableDetails';
import { ComponentText } from '../ComponentText';
import { ComponentButton } from '../ComponentButton';
import moment from 'moment';

const Styled = {};
Styled.footerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

Styled.productIdWrapper = styled.div`
  margin: 9px 0 28px;
`;

Styled.priceHistoryWrapper = styled.div`
  margin-bottom: 11px;
`;
export default class PriceHistoryModal extends React.Component {

  constructor (props) {
    super(props);
    this.state = {data:[]};
  }
  handleCancel = () => {
    this.props.handleClose();
  };

  componentDidUpdate (prevProps, prevState, snapshot) {

    if(this.props.product && this.props.product !== prevProps.product){
      let data = [];

      const { price_history } = this.props.product;

      if (price_history) {
        let isCurrentFound = false;
        const sorted = price_history.sort((a,b)=> (moment(b.updated_at).utc().format("x") - moment(a.updated_at).utc().format("x")));
        data = sorted.map((p, ind) => {
          let isCurrent = false;
          if(!isCurrentFound && this.props.product.price_usd === p.price_usd){
            isCurrent = true;
            isCurrentFound = true;
          }
          return {
            id: ind,
            date: p.updated_at,
            price: p.price_usd,
            is_current: isCurrent
          };});
      }
      this.setState({data: data});
    }
  }

  render() {
    const { product, visible } = this.props;

    return (
      <Modal
        className="price-history"
        title="Price History"
        visible={visible}
        centered
        onCancel={this.handleCancel}
        footer={null}
      >
        <ComponentText
          text="Product Identifier"
          styl={{
            fontFamily: 'SFProDisplay-Medium',
            fontSize: '13px',
            color: 'var(--greyish-brown)',
          }}
        />
        <Styled.productIdWrapper>
          <ComponentText
            text={product ? product.external_id: ""}
            styl={{
              fontFamily: 'SFProDisplay-Medium',
              fontSize: '15px',
              color: 'var(--black)',
            }}
          />
        </Styled.productIdWrapper>

        <Styled.priceHistoryWrapper>
          <ComponentText
            text="Price History"
            styl={{
              fontFamily: 'SFProDisplay-Medium',
              fontSize: '13px',
              color: 'var(--greyish-brown)',
            }}
          />
        </Styled.priceHistoryWrapper>

        {/*<Table columns={columns} dataSource={data} bordered pagination={false} />*/}
        <TableListNew
          pagination={false}
          list={this.state.data}
          attributes ={attributes}
          row={5}
        />

        <Styled.footerWrapper>
          <Form.Item className="no-bottom-margin">
            <ComponentButton
              htmlType="button"
              name="close"
              text="Close"
              css="stroke-cloudy-blue eina-semibold-cool-grey-15"
              width="122"
              height="42"
              stylText={{
                color: 'var(--cool-grey)',
                fontSize: '15px',
              }}
              handleClick={this.handleCancel}
            />
          </Form.Item>
        </Styled.footerWrapper>
      </Modal>
    );
  }
}

const attributes = [
  {
    title: '',
    field: '',
    type: tableContentType.EMPTY,
  },
  {
    title: 'Date',
    field: 'date',
    type: tableContentType.DATE_HISTORY_MODAL,
  },
  {
    title: 'Price',
    field: 'price',
    type: tableContentType.PRICE_HISTORY_MODAL,
  },
];
