import React from 'react';
import { Typography } from 'antd';
import _ from 'lodash';
import { fonts } from '../../utils/fonts';

const { Text } = Typography;

export class ComponentText extends React.Component {
  render() {
    const {
      text,
      font,
      size,
      color,
      align,
      marginTop,
      marginBottom,
      marginLeft,
      stylDiv,
      styl,
    } = this.props;

    const styleDiv = {
      marginTop: `${marginTop}px`,
      marginLeft: `${marginLeft}px`,
      marginBottom: `${marginBottom}px`,
      textAlign: align,
    };

    const styleText = {
      color: color,
      fontSize: `${size}px`,
      fontFamily: font ? font : fonts.SFProDisplayRegular,
      //fontWeight: weight,
      lineHeight: 1,
      transitionDuration: '0.5s, 0s',
    };

    return (
      <div style={stylDiv ? stylDiv : styleDiv}>
        {' '}
        {_.isString(text)
          ? text.split('<br/>').map((item, i) => (
            <Text key={i} style={styl ? styl : styleText}>
              {item} <br />{' '}
            </Text>
          ))
          : text}{' '}
      </div>
    );
  }
}

