import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  deleteData,
  loadData,
  postData,
  putData,
} from '../../../utils/ActionCreators';
import { contentType } from '../../../utils/ActionTypes';
import utils, { ToastTypes } from '../../../utils/utils';
import { prepareInput } from '../../../utils/InputFieldCongifurations';
import { ComponentText } from '../../shared/ComponentText';
import { InputComponent } from '../../shared/FormComponent/InputComponent';
import { ComponentButton } from '../../shared/ComponentButton';
import { fonts } from '../../../utils/fonts';
import '../Alert.css';
import Space from '../../shared/Spcae';
import { SelectComponent } from '../../shared/FormComponent/SelectComponent';
import { Col, Form, Row, Spin, Switch } from 'antd';
import { getUser } from '../../../utils/Repository';
import {
  alertListPath,
  appsListApiPath,
  getAlertCreatePath,
  getAlertDeletePath,
  getAlertUpdatePath,
  transactionListPath,
} from '../../../utils/routerUtils';
import AlertModal from './AlertModal';
import { alertInputs } from './AlertCreateConstant';
import { localStorageKey } from '../../../utils/LocalStorage';
import { Link } from 'react-router-dom';

const title = 'Create New Alert';

class Create extends React.Component {
  constructor (props) {
    super(props);
    this.state = _.cloneDeep(alertInputs);
  }

  loadList () {
    const { onLoadData } = this.props;
    const user = getUser();
    onLoadData(
      contentType.APPS_LIST,
      appsListApiPath, {
        filters: { organization_id: user.organizations_roles[0].organization_id },
        pager: {
          skip: 0,
          limit: 100,
        },
      },
    );
  }

  componentDidMount () {
    const { id } = this.props.match.params;
    const { onLoadData, edit } = this.props;
    this.loadList();
    if (edit) {
      onLoadData(
        contentType.ALERT, getAlertUpdatePath(id)
      );
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

    const {data, appList, createData, edit, updateData, deleteData } = this.props;
    if (appList && appList !== prevProps.appList) {
      const options = utils.prepareDropdownOptionsTitle(
        appList);
      this.setState({
        application_id: prepareInput(
          'application_id',
          'Select App',
          true,
          'Select App',
          '', 'Select App', options, data ? data.application_id: undefined
        ),
      });
    }
    if (createData && createData !== prevProps.createData) {

      this.setState({sms:_.cloneDeep(alertInputs.sms), email: _.cloneDeep(alertInputs.email), webhook: _.cloneDeep(alertInputs.webhook)});
      this.props.form.resetFields();
      let key = localStorage.getItem(localStorageKey.SELECTED_APP);
      let appId = key ? key : "all-app";
      this.props.history.push(`/alert/list?application_ids=${appId}`);

      //utils.showToast("Alert Created", ToastTypes.SUCCESS, 2);
    }
    if (data && data !== prevProps.data && edit) {

      console.log(data);
      let applicationId = this.state['application_id'];
      applicationId.value = data['application_id'];
      applicationId.disabled = true;

      let name = this.state['name'];
      name.value = data['name'];

      let criteria_name = this.state['criteria_name'];
      criteria_name.value = data.criteria.name;

      let criteria_relation = this.state['criteria_relation'];
      criteria_relation.value = data.criteria.relation;

      let criteria_value = this.state['criteria_value'];
      criteria_value.value = data.criteria.value;

      let schedule_interval = this.state['schedule_interval'];
      schedule_interval.value = data.schedule.interval;



      let email = this.state['email'];
      let sms = this.state['sms'];
      let webhook = this.state['webhook'];

      data.alert_type.forEach( e => {
        switch (e.name){
        case 'ALERT_TYPE_EMAIL':

          email.values = {email: e.value.email.addresses[0]};
          email.items[0].value.value = e.value.email.addresses[0];
          break;
        case 'ALERT_TYPE_SMS':

          sms.values = {mobile_number: e.value.sms.number};
          sms.items[0].value.value = e.value.sms.number;
          break;
        case 'ALERT_TYPE_WEBHOOK':

          webhook.values = {webhook_url: e.value.webhook.url};
          webhook.items[0].value.value = e.value.webhook.url;
          break;
        }
      });



      this.setState({
        'application_id': applicationId,
        'name': name,
        'criteria_name': criteria_name,
        'criteria_relation': criteria_relation,
        'criteria_value': criteria_value,
        'schedule_interval': schedule_interval,
        'email': email,
        'sms': sms,
        'webhook': webhook,
      });
    }

    if (updateData && updateData !== prevProps.updateData) {
      utils.showToast("Alert Updated", ToastTypes.SUCCESS, 2);
    }

    if (deleteData && deleteData !== prevProps.deleteData) {
      utils.showToast("Alert Deleted", ToastTypes.SUCCESS, 2);

      let key = localStorage.getItem(localStorageKey.SELECTED_APP);
      let appId = key ? key : "all-app";
      this.props.history.push(`/alert/list?application_ids=${appId}`);
    }
  }

    handleChange = ({ name, value }) => {
      let field = this.state[name];
      field.values = value;
      field.error.visible = false;
      this.setState({ [name]: field });
    };
    handleSubmit = e => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const user = getUser();
          values['organization_id'] = user.organizations_roles[0].organization_id;
          values['criteria'] = {
            name: values.criteria_name,
            relation: values.criteria_relation,
            value: values.criteria_value
          };
          delete values.criteria_name;
          delete values.criteria_relation;
          delete values.criteria_value;
          values['schedule'] = {
            interval: values.schedule_interval
          };
          delete values.schedule_interval;

          let types = [];
          if(!_.isEmpty(this.state.email.values)){
            types.push({
              "name": "ALERT_TYPE_EMAIL",
              "value": {
                "email": {
                  "addresses": [this.state.email.values.email]
                }
              }
            });
          }

          if(!_.isEmpty(this.state.sms.values)){
            types.push({
              "name": "ALERT_TYPE_SMS",
              "value": {
                "sms": {
                  "number": this.state.sms.values.mobile_number
                }
              }
            });
          }
          if(!_.isEmpty(this.state.webhook.values)){
            types.push({
              "name": "ALERT_TYPE_WEBHOOK",
              "value": {
                "webhook": {
                  "url": this.state.webhook.values.webhook_url
                }
              }
            });
          }
          values['alert_type'] = types;
          console.log('Received values of form: ', values);
          if(this.props.edit)
            this.props.onPutData(contentType.ALERT_UPDATE, getAlertUpdatePath(this.props.data.id), values);
          else
            this.props.onPostData(contentType.ALERT_CREATE, getAlertCreatePath,values);
        }
      });
    };

  goBackToList =() => {
    const {state} = this.props.location;

    let finalSearchParams = new URLSearchParams();
    let key = localStorage.getItem(localStorageKey.SELECTED_APP);
    finalSearchParams.set("application_ids", key ? key : "all-app");
    finalSearchParams.set("page", "1");

    const to = alertListPath +"?"+ finalSearchParams.toString();

    return state && state.goBack === true ?    <ComponentButton name="back" iconLeft="previous" text="All Alert" css="back-app-details" iconSize={16} height="36" handleClick={this.props.history.goBack} />
      : <Link key="back-transaction-list" to={to}>
        <ComponentButton name="back" iconLeft="previous" text="All Alert" css="back-app-details" iconSize={16} height="36"/>
      </Link>;
  }

  render () {
    const { edit, isFetching, createIsFetching, updateIsFetching, appIsFetching, deleteIsFetching } = this.props;
    return (
      <div className={'alert-list'}>

        {this.goBackToList()}

        <ComponentText
          text={title}
          font={fonts.SFProDisplaySemiBold}
          size="20"
          color="var(--dark-three)"
          align="left"
          marginTop="30"
          marginBottom="18"
        />
        <div style={{ maxWidth: 600 }}>
          <Spin spinning={isFetching === true || createIsFetching  === true || updateIsFetching  === true || deleteIsFetching === true}>
            <Form onSubmit={this.handleSubmit} layout="vertical">
              <div className={'alert-create-form'}>

                <SelectComponent
                  loading={appIsFetching === true}
                  form={this.props.form}
                  layout={formItemLayout}
                  values={this.state.application_id}
                />

                <InputComponent
                  form={this.props.form}
                  layout={formItemLayout}
                  values={this.state.name}
                />
                <ComponentText text={'Alert me when'} size="13"
                  font={fonts.SFProDisplayMedium}
                  color="var(--greyish-brown)"
                  marginBottom={10}/>
                <Row type={'flex'} justify={'space-between'}
                  align={'top'}>
                  <Col span={7}>
                    <SelectComponent
                      form={this.props.form}
                      layout={formItemLayout}
                      values={this.state.criteria_name}
                    />
                  </Col>
                  <Col style={{ paddingTop: 10 }}>
                    <ComponentText text={'is'} size="13"
                      font={fonts.SFProDisplayMedium}
                      color="var(--greyish-brown)"/>
                  </Col>
                  <Col span={7}>
                    <SelectComponent
                      form={this.props.form}
                      layout={formItemLayout}
                      values={this.state.criteria_relation}
                    />
                  </Col>
                  <Col span={8}>
                    <InputComponent
                      form={this.props.form}
                      layout={formItemLayout}
                      values={this.state.criteria_value}
                    />
                  </Col>
                </Row>
                <SelectComponent
                  form={this.props.form}
                  layout={formItemLayout}
                  values={this.state.schedule_interval}
                />
                <ComponentText text={'Channels'} size="13"
                  font={fonts.SFProDisplayMedium}
                  color="var(--greyish-brown)"
                  marginBottom={10}/>

                {this.prepareSwitchComponent(this.state.email)}
                {this.prepareSwitchComponent(this.state.sms)}
                {this.prepareSwitchComponent(this.state.webhook)}


              </div>
              <Space height="24"/>
              <Row type={"flex"} justify={edit ? "space-between" : "end"}>
                {edit && <ComponentButton
                  name="delete"
                  htmlType="button"
                  text="Delete"
                  css="stroke-salmon eina-semibold-grapefruit-15"
                  width="88"
                  height="42"
                  handleClick={this.handleDelete}
                />}
                <Form.Item className="no-bottom-margin">
                  <ComponentButton
                    htmlType="submit"
                    name="add-new"
                    text={edit ? "Update Alert" : "Create Alert"}
                    css="dodger-blue eina-semibold-white-15"
                    width="184"
                    height="42"
                    styl={{
                      width: '184px',
                      height: '42px',
                      borderRadius: '5px',
                      float: 'right',
                    }}
                  />
                </Form.Item>
              </Row>
            </Form>
          </Spin>
        </div>
        <AlertModal value={this.state.email}
          handleClose={this.hideDialog}/>
        <AlertModal value={this.state.sms}
          handleClose={this.hideDialog}/>
        <AlertModal value={this.state.webhook}
          handleClose={this.hideDialog}/>
      </div>
    );
  }

    handleDelete = () => {
      const { id } = this.props.match.params;
      this.props.onDeleteData(contentType.ALERT_DELETE, getAlertDeletePath(id));
    }

    showDialog = (item) => {
      item.dialog.visible = true;
      this.setState({ item });
    };

    clearSwitch (item) {
      item.values = { };
      this.setState({ item });
    }

    hideDialog = (item, value) => {
      item.dialog.visible = false;
      if(value)
        item.values = value;
      this.setState({ item });
    };

    prepareSwitchComponent = (item) => {
      return  <Row type={'flex'} align={'middle'} style={{height:42, marginBottom:0}}>
        <Switch checked={!_.isEmpty(item.values)} onChange={()=> _.isEmpty(item.values) ? this.showDialog(item): this.clearSwitch(item)}/>
        <Space width={12}/>
        <ComponentText text={item.label}
          size="14"
          font={_.isEmpty(item.values) ? fonts.SFProDisplayRegular : fonts.SFProDisplaySemiBold}
          color={!_.isEmpty(item.values)
            ? 'var(--black)'
            : 'var(--slate-grey)'}/>
        <Space width={2}/>
        {!_.isEmpty(item.values) &&
            <ComponentText text={`(${item.values[item.items[0].value.name]})`} size="14"
              font={fonts.SFProDisplayRegular}
              color="var(--charcoal-grey)"/>}
        <Space width={8}/>
        {!_.isEmpty(item.values) &&<ComponentButton
          htmlType="button"
          handleClick={() => this.showDialog(item)}
          text={'Edit'}
          css="text-only alert-switch"
        />}
      </Row>;
    }
}

const mapActionToProps = {
  onLoadData: loadData,
  onPostData: postData,
  onPutData: putData,
  onDeleteData: deleteData,
};

const mapStateToProps = state => {
  const { alertRemoteData, alertCreateRemoteData, alertUpdateRemoteData, appsListRemoteData, alertDeleteRemoteData } = state;

  return {
    data: utils.getItem(alertRemoteData),
    isFetching: utils.getIsFetching(alertRemoteData),
    isFailed: utils.getIsFailed(alertRemoteData),

    createData: utils.getItem(alertCreateRemoteData),
    createIsFetching: utils.getIsFetching(alertCreateRemoteData),

    updateIsFetching: utils.getIsFetching(alertUpdateRemoteData),
    updateData: utils.getItem(alertUpdateRemoteData),

    deleteIsFetching: utils.getIsFetching(alertDeleteRemoteData),
    deleteData: utils.getItem(alertDeleteRemoteData),

    appList: utils.getData(appsListRemoteData),
    appIsFetching: utils.getIsFetching(appsListRemoteData),
  };
};

const AlertCreate = Form.create({ name: 'alert-create' })(Create);

export default connect(mapStateToProps, mapActionToProps)(AlertCreate);

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};
