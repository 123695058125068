import React from 'react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import './Chart.css';

import {
  Button,
  Card,
  Col,
  Dropdown,
  Icon,
  Layout,
  Menu,
  message,
  Row,
} from 'antd';
import { ComponentText } from '../ComponentText';
import { ComponentDivider } from '../ComponentDivider';

const {Meta} = Card;
const {Content} = Layout;

class ChartDetails extends React.Component {

  render() {
    const data = [
      {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
      },
      {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
      },
      {
        name: 'Page C',
        uv: 2000,
        pv: 8800,
        amt: 2290,
      },
      {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },
      {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
      },
      {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
      },
      {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
      },
    ];

    const {title, value, subtitle} = this.props;
    return <div className="chart-details">
      <Row type="flex" align="middle" justify="space-between" style={{padding: 8}}>
        <PrepareSegmentSelector/>
        <Col>
          <PrepareDropDown/>
          <PrepareDropDown/>
        </Col>

      </Row>

      <ComponentDivider/>
      <Row type="flex" align="middle" style={{padding: 16}}>
        <ChartLegend icon="insurance" count= "52,00" title = "Instal"/>
        <ChartLegend icon="snippets" count= "52,00" title = "Instal"/>
      </Row>
      <Row style={{marginRight: 16}}>
        <ResponsiveContainer width="100%" height={300} >
          <BarChart

            barCategoryGap={30}

            data={data}
            margin={{
              top: 20,
              right: 0,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" strokeWidth={1} strokeMiterlimit="0"/>


            <XAxis dataKey="name"/>

            <YAxis/>
            <Tooltip offset={-75} cursor={false} content={CustomTooltip}/>


            <Bar dataKey="pv" stackId="a" fill="#8884d8" width={20} >

            </Bar>

            <Bar dataKey="uv" stackId="a" fill="#82ca9d" radius={[4, 4, 0, 0]}/>
          </BarChart>
        </ResponsiveContainer>
      </Row>
    </div>;
  }
}



export default ChartDetails;

const CustomTooltip = ({active, payload, label}) => {
  if (active) {
    return (
      <Content className="chart-tooltip">
        <Row type="flex">
          <Col span={12}>
            <Row>Installs</Row>
            <Row>Subscription</Row>
          </Col>
          <Col span={12}>
            <Row type="flex" justify="end">123</Row>
            <Row type="flex" justify="end">223</Row>
          </Col>
        </Row>
      </Content>
    );
  }

  return null;
};

function ChartLegend({icon, count, title}) {
  return <Col style={{marginRight: 16}}>
    <Row type="flex" align="top">
      <Col>

        <Icon type={icon} theme="twoTone" twoToneColor="#52c41a" style={{marginRight: 8}}/>

      </Col>
      <Col >
        <ComponentText text={count} font ="Roboto" size="18" weight="400" color="#000000" align="left" marginTop="0" marginBottom="0"/>


        <ComponentText text={title} font ="Roboto" size="16" weight="400" color="#000000" align="left" marginTop="0" marginBottom="0"/>


      </Col>
    </Row>
  </Col>;
}

function handleMenuClick(e) {
  message.info('Click on menu item.');
  console.log('click', e);
}

function PrepareSegmentSelector() {
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        <Icon type="user" />
            1st menu item
      </Menu.Item>
      <Menu.Item key="2">
        <Icon type="user" />
            2nd menu item
      </Menu.Item>
      <Menu.Item key="3">
        <Icon type="user" />
            3rd item
      </Menu.Item>
    </Menu>
  );

  return <Dropdown overlay={menu} key='app'>
    <Button>
      <Icon type="appstore" />
      Button <Icon type="down" />
    </Button>
  </Dropdown>;
}

function PrepareDropDown() {
  const menu = (
    <Menu>
      <Menu.Item key="1">1st menu item</Menu.Item>
      <Menu.Item key="2">2nd memu item</Menu.Item>
      <Menu.Item key="3">3rd menu item</Menu.Item>
    </Menu>
  );
  return <Dropdown overlay={menu}>
    <span style={{marginLeft: 16}} >
Show 10 per page <Icon type="down" />
    </span >
  </Dropdown>;

}