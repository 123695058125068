import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'antd/lib/index';
import listUtils from '../../../utils/listUtils';
import { loadData } from '../../../utils/ActionCreators';
import { contentType } from '../../../utils/ActionTypes';
import { ComponentText } from '../../shared/ComponentText';
import utils from '../../../utils/utils';
import { RangeDateTimePicker } from '../../shared/DateTime/RangeDateTimePicker';
import { metricsMRRApiPath, metricsMRRPath } from '../../../utils/routerUtils';
import moment from 'moment';
import { getUser } from '../../../utils/Repository';

import ComponentAppSelect from '../../shared/ComponentAppSelect';
import Space from '../../shared/Spcae';
import './MetricsMRR.css';
import { localStorageKey } from '../../../utils/LocalStorage';
import RechartChart, {
  DEFAULT_MIN_DURATION,
  getInterval,
} from '../../shared/Chart/RechartChart';
import { ChartSegmentTable } from '../../shared/Chart/ChartSegmentTable';

const title = "MRR";
const path = metricsMRRApiPath;
class MetricsMRR extends React.Component {
  componentDidMount() {
    this.loadListWithPageFilter();
  }

  componentDidUpdate (previousProps, prevState, snapshot) {
    const currentSearch = this.props.location.search;
    const previousSearch = previousProps.location.search;
    if (currentSearch !== previousSearch) {
      this.loadListWithPageFilter();
    }

    if(this.props.message && this.props.message !== previousProps.message){
      const text = this.props.message.error.charAt(0).toUpperCase() + this.props.message.error.slice(1);
      utils.showError(text);
    }
  }

  loadListWithPageFilter() {
    const {onLoadData} = this.props;

    const user = getUser();
    const query = new URLSearchParams(this.props.location.search);
    const applicationId = query.get('application_ids');
    const bucketInterval = query.get('bucket_interval');

    const from = query.get('from');
    const to = query.get('to');
    const between = utils.getDefaultDateRange(from, to);

    let duration = utils.getDateRangeDuration(between.from,between.to);

    let filters = listUtils.prepareFilterParamsWithDate(
      listUtils.prepareFilterParams(
        listUtils.currentFilters(
          this.props.location.search,
          []
        )
      ),
      between
    );

    if(bucketInterval){
      filters['bucket_interval'] = bucketInterval;
    }else{
      const interval = localStorage.getItem(localStorageKey.METRICS_INTERVAL);
      filters['bucket_interval'] = getInterval(duration, interval);
    }
    if(applicationId) {
      if (applicationId && applicationId !== "all-app") {
        filters["application_id"] = applicationId;
      }
      filters["organization_id"] = user.organizations_roles[0].organization_id;
      onLoadData(
        contentType.METRICS_MRR, path,
        { filters: filters },
      );
    }
  }

    onDateChanged = range => {
      const between = {from: range.from,
        to: range.to};
      const {history} = this.props;
      let filters = listUtils.prepareFilterParamsWithDate(
        listUtils.prepareFilterParams(
          listUtils.currentFilters(
            this.props.location.search,
            []
          )
        ),
        between
      );
      history.push(listUtils.prepareUrlWithoutPager(metricsMRRPath, filters));
    };

    render() {
      let {isFetching, isFailed, data, message} = this.props;
      const blur = message && message.code && message.code === 426;

      // let series = [ {
      //   name: 'series-1',
      //   data: []}
      // ];
      // let categories = [];
      //
      // if(data){
      //   data.forEach(e => {
      //     series[0].data.push(e.value.toFixed(2));
      //     categories.push(moment(e.key).format("D"));
      //   });
      // }
      let chartData = [];
      if(data){
        data.forEach(e => {
          chartData.push({
            value: Math.round(e.value),
            date: moment(e.key).format("MMM DD")
          });
        });
      }

      return (
        <div className={"metrics-container"}>
          <Row type="flex" justify="space-between" align="middle">
            <Row type="flex">
              <ComponentText text={title} size="28" weight="600" color="#212b36" align="left" marginTop="0" marginBottom="0"/>
            </Row>
            <Row type="flex">
              <ComponentAppSelect location={this.props.location} history={this.props.history}/>
              <Space width="16"/>
              <RangeDateTimePicker
                onDateChanged={this.onDateChanged}
                location={this.props.location}
                history={this.props.history}
                defaultInterval={DEFAULT_MIN_DURATION}/>
            </Row>
          </Row>
          {/*<ApexBarChart series={series} categories={categories} location={this.props.location} history={this.props.history}/>*/}
          <RechartChart data={chartData} blur = {blur}
            location={this.props.location}
            history={this.props.history}
            isFetching={isFetching}
            titles={chartTitles} keys={chartValueKeys} colors={chartColors}/>
          <Space height={20}/>
          {!blur && <ChartSegmentTable list={chartData} isFetching={isFetching}
            isStacked={false} titles={chartTitles} keys={chartValueKeys}/>}
          <Space height={20}/>
        </div>
      );
    }
}
const chartTitles = ["MRR"];
const chartValueKeys = ["value"];
const chartColors = ["#26c1c9"];

const mapActionToProps = {
  onLoadData: loadData,
};

const mapStateToProps = state => {
  const {metricsMRRRemoteData} = state;
  return {
    data: utils.getMetrics(metricsMRRRemoteData),
    isFetching: utils.getIsFetching(metricsMRRRemoteData),
    isFailed: utils.getIsFailed(metricsMRRRemoteData),
    message: utils.getMessage(metricsMRRRemoteData),
  };
};
export default connect(
  mapStateToProps,
  mapActionToProps
)(MetricsMRR);

