import React from 'react';
import { ComponentText } from '../../shared/ComponentText';
import Space from '../../shared/Spcae';

export class ListEmptyCustomerDetails extends React.Component {

  render () {
    return <div className={"empty-transaction-customer-details"}>
      <img src={this.props.image} width={120} alt={""}/>
      <Space height={44}/>
      <ComponentText text={`No transactions yet`} font={"SFProDisplay-Semibold"} size={"18"} color={"var(--greyish-brown)"}/>
      <Space height={8}/>
      <ComponentText text={`The user has not made any transactions`} font={"SFProDisplay-Regular"} size={"18"} color={"var(--dark-grey-blue)"}/>
    </div>;
  }
}