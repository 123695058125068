import React from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'antd';
import { contentType } from '@/utils/ActionTypes';
import Space from '@/components/shared/Spcae';
import './AppDetails.css';
import { removeItem } from '@/utils/LocalStorage';
import { ComponentText } from '../../shared/ComponentText';
import images from '../../../images/Images';
import { ComponentButton } from '../../shared/ComponentButton';

class ProductGroupEmpty extends React.Component {
  constructor(props) {
    super(props);
    this.state = { screenWidth: window.innerWidth - 306 };
    removeItem(contentType.PRODUCT_GROUP_LIST.name);
  }

  render() {

    return (

      <div  >
        <ComponentText
          text={"Product Groups"}
          font={"SFProDisplay-Semibold"}
          size={18}
          color={"var(--dark)"}
        />
        <Space height={22}/>
        <Row className={"empty-group-container"} type={"flex"} justify="start">
          <img height={28} width={28} src={images.info}/>
          <Space width={12}/>
          <div className={"empty-group-container-content"}>
            <ComponentText
              text="What is product groups?"
              font="SFProDisplay-Semibold"
              size={16}
              color="var(--dark)"
            />
            <Space height={10}/>
            <ComponentText
              text="Product groups allow you to choose which combination of products are shown to a user
                      on your paywall or upsell screen. For example your default group may contain a
                      monthly and annual subscription, but you might want to experiment with a different
                      combination of subscription durations, trial lengths, prices, etc."
              font="SFProDisplay-Regular"
              size={14}
              color="var(--dark)"

            />
            <Space height={20}/>
            <Row type={"flex"} justify="start">
              <ComponentButton
                name="add-product-group"
                iconLeft="add"
                text="Create Product Group"
                css="create-product-group"
                iconSize={12}
                height="32"
                width="170"
                handleClick={this.props.handleNewProductGroupClick}
              />
              <Space width={12}/>
              <Link key="leanr-more" to={'/app/list'}>
                <ComponentButton
                  name="learn-more"
                  text="Learn More"
                  css="learn-more"
                  iconSize={12}
                  height="32"
                  width="110"
                />
              </Link>
            </Row>

          </div>
        </Row>
      </div>
    );
  }
}

export default ProductGroupEmpty;
