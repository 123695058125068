import React, { Component } from 'react';
import { Dropdown, Menu, Row, Spin } from 'antd';
import { ComponentText } from '../ComponentText';
import { ComponentDivider } from '../ComponentDivider';
import { localStorageKey } from '../../../utils/LocalStorage';
import { ComponentIcon } from '../ComponentIcon';
import Space from '../Spcae';
import { fonts } from '../../../utils/fonts';
import './Chart.css';
import listUtils from '../../../utils/listUtils';
import * as queryString from 'query-string';
import moment from 'moment';
import './rechart.css';
import {
  Line,
  ComposedChart,
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import utils from '../../../utils/utils';
import FilterPopup from '../Filter/FilterPopup';
import {
  listQueryFilterParams,
  listSearchModalParams,
} from './ChartFilterConstants';
import { ComponentButton } from '../ComponentButton';
import { Link } from 'react-router-dom';

export const DEFAULT_MIN_DURATION = 30;

export function getInterval (duration, interval) {
  if(duration <= 7){
    return 'day';
  }else if(duration <= 30){
    return interval ? (interval === 'month' ? 'day' : interval) : 'day';
  }else if(duration <= 60){
    return interval ? interval: 'day';
  }else{
    return interval ? (interval === 'day' ? 'week' : interval) : 'week';
  }
}

class RechartChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: this.getSelectedOption(OPTIONS.STYLE),
      interval: this.getSelectedOption(OPTIONS.INTERVAL),
      screenWidth: window.innerWidth - 330,
    };

    this.randomData = generateRandomData(this.props.keys);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.forceUpdate();
    this.setState({ screenWidth: window.innerWidth - 336 });
  };

  getSelectedOption = (option) => {
    switch (option) {
    case OPTIONS.INTERVAL: {
      const interval = localStorage.getItem(localStorageKey.METRICS_INTERVAL);
      const duration = this.getDuration();
      return getInterval(duration, interval);
    }
    case OPTIONS.STYLE: {
      const style = localStorage.getItem(localStorageKey.METRICS_STYLE);
      return style ? style : 'bar';
    }
    case OPTIONS.TYPE: {
      const type = localStorage.getItem(localStorageKey.METRICS_TYPES);
      return type ? type : 'stacked';
    }
    case OPTIONS.SEGMENT: {
      const segment = localStorage.getItem(localStorageKey.METRICS_SEGMENT);
      return segment ? segment : '';
    }
    }
  }

  onOptionSelect =(key, option) => {
    switch (option) {
    case OPTIONS.INTERVAL: {

      let parsed = queryString.parse(this.props.location.search);
      parsed["bucket_interval"] = key;
      this.props.history.push({
        pathname: '',
        search: listUtils.prepareFilterUrl(parsed),
      });

      localStorage.setItem(localStorageKey.METRICS_INTERVAL, key);
      this.setState({ interval: key });
      break;
    }
    case OPTIONS.STYLE: {
      localStorage.setItem(localStorageKey.METRICS_STYLE, key);
      this.setState({ style: key });
      break;
    }
    case OPTIONS.TYPE: {
      localStorage.setItem(localStorageKey.METRICS_TYPES, key);
      this.setState({ type: key });
      break;
    }
    case OPTIONS.SEGMENT: {
      localStorage.setItem(localStorageKey.METRICS_SEGMENT, key);
      this.setState({ segment: key });
      break;
    }
    }
  }

  prepareMenu = (items, option) => {
    return   <Menu
      onClick={({key}) => this.onOptionSelect(key, option)}
      style={{ width: 140 }}
      defaultSelectedKeys={[this.getSelectedOption(option)]}
      selectedKeys={[this.getSelectedOption(option)]}
    >
      {items.map(e => (
        <Menu.Item key={e.value}>
          <ComponentText
            text={e.title}
            font={fonts.SFProDisplayRegular}
            fontSize="14"
            color="var(--dusk-two)"
          />
        </Menu.Item>
      ))}
    </Menu>;
  }

  prepareDropDown = (menu, items, title, option) => {
    let selected = this.getSelectedOption(option);
    let selectedOption = items[0];
    for(let i = 0; i < items.length; i++ ){
      if(items[i].value === selected){
        selectedOption = items[i];
      }
    }
    return <Dropdown className={"chart-dropdown"} overlay={menu} placement="bottomRight">
      <Row type="flex" align="middle" justify="end">
        <ComponentText
          text={title + ':  ' + selectedOption.title}
          font={fonts.SFProDisplaySemiBold}
          fontSize="14"
          color="var(--slate-grey)"
        />
        <Space width={12} />
        <ComponentIcon type="arrow-down" />
      </Row>
    </Dropdown>;
  }

  getDuration = () => {
    let range = listUtils.currentDates(this.props.location.search);
    let duration = DEFAULT_MIN_DURATION;
    if(range && range.between){
      let start = moment(range.between.to, 'YYYY-MM-DDTHH:mm:ssZ');
      let end = moment(range.between.from, 'YYYY-MM-DDTHH:mm:ssZ');
      duration = start.diff(end, 'days');
    }
    return duration;
  }

  render() {

    const {data, isStacked, isFetching, titles, keys, colors, blur, line, title} = this.props;
    //const segmentMenu = this.prepareMenu(segments, OPTIONS.SEGMENT)
    const styleMenu = this.prepareMenu(styles, OPTIONS.STYLE);
    //const typeMenu = this.prepareMenu(types, OPTIONS.TYPE)



    const intervalMenu = this.prepareMenu(this.getIntervalItems() , OPTIONS.INTERVAL);

    return (
      <div className="chart-details" style={{ width: "100%"}}>
        <Row type="flex" align="middle" justify="end" style={{ padding: 8 }}>

          <ComponentText
            text={this.getTotalCount(data, titles, keys)}
            font={fonts.SFProDisplaySemiBold}
            fontSize="14"
            color="var(--slate-grey)"
          />
          <div style={{flex:1,alignSelf:'stretch'}}/>
          {!blur && this.prepareDropDown(intervalMenu, this.getIntervalItems(), "Interval", OPTIONS.INTERVAL)}
          <Space width={16}/>
          {this.prepareDropDown(styleMenu, styles, "Style", OPTIONS.STYLE )}

        </Row>
        <ComponentDivider />
        {/*<Row type="flex" align="middle" style={{ padding: 16 }}>*/}
        {/*  <ComponentLegend icon="insurance" count="52,00" title="Install" />*/}
        {/*  <ComponentLegend icon="snippets" count="52,00" title="Install" />*/}
        {/*</Row>*/}
        {!blur && <Row type={"flex"} justify={"end"} style={{paddingRight: 12, paddingTop: 12}}>
          <FilterPopup
            history = {this.props.history}
            location = {this.props.location}
            filterParams = {listSearchModalParams}
            queryFilterParams = {listQueryFilterParams()}
          />
        </Row>}
        <Space height={20}/>

        {isFetching === true ? <Spin style={{top: "-70px"}}><div style={{height:400}}/></Spin> :
          (this.state.style === 'bar' ? (
            <div className={"chart-view"}>
              <div className={blur ? "blur-view" : "blur-view-off"}>
                <ResponsiveContainer width="100%" height={400}>
                  {line && !blur ?  <ComposedChart data={blur ? this.randomData : data} margin={{ top: 15, right: 30, left: 30, bottom: 30 }}>
                    {this.getCartesianGrid()}
                    {this.getXAxis()}
                    {this.getYAxis(isStacked)}
                    {this.getToolTip(titles, keys, title)}
                    {keys.map((e, i)=> <Bar key={i} dataKey={e} stackId="a" fill={colors[i]} radius={i === keys.length - 1 ? [4, 4, 0, 0]: [0, 0, 0, 0]}/>)}
                    <Line type="monotone" dataKey={line} stroke="#ff7300"/>
                  </ComposedChart>
                    :
                    <BarChart data={blur ? this.randomData : data} margin={{ top: 15, right: 30, left: 30, bottom: 30 }}>
                      {this.getCartesianGrid()}
                      {this.getXAxis()}
                      {this.getYAxis(isStacked)}
                      {this.getToolTip(titles, keys)}
                      {keys.map((e, i)=> <Bar key={i} dataKey={e} stackId="a" fill={colors[i]} radius={i === keys.length - 1 ? [4, 4, 0, 0]: [0, 0, 0, 0]}/>)}
                    </BarChart>}
                </ResponsiveContainer>
              </div>
              {blur && this.upgradeButton()}
            </div>
          ) :
            this.state.style === 'area' ? (
              <div className={"chart-view"}>
                <div className={blur ? "blur-view" : "blur-view-off"}>
                  <ResponsiveContainer width="100%" height={400} >
                    {line && !blur?  <ComposedChart data={blur ? this.randomData : data} margin={{ top: 15, right: 30, left: 30, bottom: 30 }}>
                      {this.getCartesianGrid()}
                      {this.getXAxis()}
                      {this.getYAxis(isStacked)}
                      {this.getToolTip(titles, keys, title)}
                      {keys.map((e, i)=> <Area key={i} type="monotone" dataKey={e} stackId="a" stroke={colors[i]} fillOpacity={1} fill={colors[i]}/>)}
                      <Line type="monotone" dataKey={line} stroke="#ff7300"/>
                    </ComposedChart>
                      :
                      <AreaChart data={blur ? this.randomData : data}
                        margin={{ top: 15, right: 30, left: 30, bottom: 30 }}>
                        <defs>

                          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#26c1c9" stopOpacity={1}/>
                            <stop offset="95%" stopColor="#26c1c9" stopOpacity={1}/>
                          </linearGradient>
                        </defs>
                        {this.getCartesianGrid()}
                        {this.getXAxis()}
                        {this.getYAxis(isStacked)}
                        {this.getToolTip(titles, keys)}
                        {keys.map((e, i)=> <Area key={i} type="monotone" dataKey={e} stackId="a" stroke={colors[i]} fillOpacity={1} fill={colors[i]}/>)}
                      </AreaChart>}
                  </ResponsiveContainer>
                </div>
                {blur && this.upgradeButton()}
              </div>
            ) : null)}
      </div>
    );
  }

  getIntervalItems () {

    const duration = this.getDuration();

    if(duration <= 7){
      return [
        {
          title: 'Daily',
          value: 'day',
        },
      ];
    }else if(duration <= 30){
      return [
        {
          title: 'Daily',
          value: 'day',
        },
        {
          title: 'Weekly',
          value: 'week',
        },
      ];
    }else if(duration <= 60){
      return [
        {
          title: 'Daily',
          value: 'day',
        },
        {
          title: 'Weekly',
          value: 'week',
        },
        {
          title: 'Monthly',
          value: 'month',
        },
      ];
    }
    else{
      return [
        {
          title: 'Weekly',
          value: 'week',
        },
        {
          title: 'Monthly',
          value: 'month',
        },
      ];
    }
  }

  getCartesianGrid () {
    return <CartesianGrid
      strokeDasharray="5 5"
      strokeWidth={1}
      stroke={"#e5eef5"}
    />;
  }

  getXAxis () {
    return    <XAxis
      dataKey="date"
      tickFormatter={(value) => (moment(value).format(this.state.interval === 'month' ?"MMM, YYYY" : "MMM DD") )}
    />;
  }

  getYAxis (isStacked) {
    return  isStacked === true ?   <YAxis
      type="number"
      tickFormatter={(value) =>  ( [ utils.formatNumber(value) ] )}
    />:
      <YAxis
        type="number"
        tickFormatter={(value) =>  ( [ "$"+utils.formatNumber(value) ] )}
      />;
  }

  getToolTip (titles, keys, title) {

    return <Tooltip
      cursor={false}
      formatter={(value, name, props) =>  {
        let i = keys.indexOf(name);
        return (props.stroke === '#ff7300' ? [ `${title}: ${ (((100 * props.payload[keys[0]] ) / (props.payload[keys[1]] === 0 ? 1 : props.payload[keys[1]]))).toFixed(2)}%` ] :  [ `${titles[i]}: ${utils.formatNumber(value)}` ] ); } }
      labelFormatter={(value) => (moment(value).format( this.state.interval === 'month' ?"MMM, YYYY" : "ddd MMM DD, YY") )}
    />;

  }

  getTotalCount (data, titles, keys) {

    if(keys.length === 1){
      let x = 0;
      data.forEach( e => x+= e.value );
      return `Total $${utils.formatNumber(x)}`;
    }else{

      let v = Array(keys.length).fill(0) ;
      data.forEach( e => {
        keys.forEach( (k,i) => {
          v[i] += e[k];
        });
      });

      let res = "";
      keys.forEach( (k,i) => {
        res += `${titles[i]}: ${utils.formatNumber(v[i])} | `;
      });
      return res.substr(0, res.length-2);
    }
  }

  upgradeButton = () => {
    return   <div className={"blur-view-overlay"}>
      <ComponentText text={"Unlock Chart"}
        font={fonts.SFProDisplaySemiBold}
        size={24} color={"var(--dark-three)"}
        marginBottom={8}/>
      <Link to={"/account?menu=billing"}>
        <ComponentButton
          name="update"
          text={`Upgrade to ${localStorage.getItem(localStorageKey.NEXT_PLAN)}`}
          css="extra-feature-upgrade"
          width="150"
          height="30"
        />
      </Link>
    </div>;
  }
}

export default RechartChart;

const styles = [
  {
    title: 'Bar',
    value: 'bar',
  },
  {
    title: 'Area',
    value: 'area',
  },
];

// const types = [
//   {
//     title: 'Stacked',
//     value: 'stacked',
//   },
//   {
//     title: 'Compare',
//     value: 'compare',
//   },
// ];

const OPTIONS = {
  STYLE: "STYLE",
  TYPE: "TYPE",
  INTERVAL: "INTERVAL",
  SEGMENT: "SEGMENT"

};

function generateRandomData (keys) {
  let list = [];
  for(let i = 0; i< 15; i++){
    const o = {};
    keys.forEach(e => o[e] = utils.getRandom(5, i> 4 && i< 10 ? 30: 50) );
    o["date"] = utils.getDateFromNow(i);
    list.push(o);
  }
  return list;
}




