import React from 'react';
import Divider, { Col, Dropdown, Menu, Row } from 'antd';
import Images from '../../images/Images';
import Space from '../shared/Spcae';
import { ComponentButton } from '../shared/ComponentButton';
import { fonts } from '../../utils/fonts';
import { ComponentText } from '../shared/ComponentText';
import { ComponentIcon } from '../shared/ComponentIcon';
import SubMenu from 'antd';

const menus = [
  {
    name: "Products",
    path: "/products",
    subMenu:[
      {
        icon: Images.subscriptionManagement,
        title: "Subscription Management",
        description: "Manage subscriptions at scale",
        path: "/subscription-management"
      },
      {
        icon: Images.plansManagement,
        title: "Plans Management",
        description: "Plan & product management simplified",
        path: "/plans-products"
      },
      {
        icon: Images.subscriptionManagement,
        title: "CustomerProfile Profile",
        description: "Know your customers",
        path: "/customer-profile"
      },
      {
        icon: Images.charting,
        title: "Charting & Metrics",
        description: "Analytics to know the real story",
        path: "/charts-metrics"
      }

    ]
  },
  {
    name: "Pricing",
    path: "/pricing",
    subMenu:[]
  },
  {
    name: "Resources",
    path: "/resources",
    subMenu:[]
  },
  {
    name: "Company",
    path: "/company",
    subMenu:[]
  }
];
export class TopBar extends React.Component {

  render() {
    return <Row type={"flex"} style={{backgroundColor:"#fafafa", paddingTop:26}}>

      <Col span={4}/>

      <Col span={16}>

        <Row type="flex" justify="space-between" align="middle" style={{height:45}}>
          <Row type="flex" justify="start" align="middle" style={{height:45}}>
            <a href={"/"}><img src={Images.logoAlternate} height={36} alt={""}/></a>
            <Space width={40}/>
            {
              menus.map( (e,index) =>
                <Row key={e.name} type="flex" justify="start" align="middle">
                  <Space width={12}/>
                  {e.subMenu.length > 0 ?  <Dropdown  key={index} overlay={this.prepareMenu(e.subMenu)}>
                    <a key={index} href={e.path}>
                      <span className="top-bar-link">
                        {e.name}
                        {e.subMenu.length > 0 && <ComponentIcon antd={true} type={"caret-down"}/>}
                      </span>
                    </a>
                  </Dropdown>:
                    <a key={index} href={e.path}><span className="top-bar-link">{e.name}</span></a>}
                  <Space width={12}/>
                </Row>
              )
            }

          </Row>
          <Row type="flex" justify="start" align="middle">
            <a href={"/login"}>
              <span className="top-bar-link">{"Login"}</span>
            </a>
            <Space width={20}/>
            <a href={"/register"}><ComponentButton text={"Sign Up"} css={"deep-sky-blue-and-white"} height={40} width={100}/></a>
          </Row>
        </Row>
      </Col>
      <Col span={4}/>

    </Row>;
  }

    prepareMenu =(subMenu)=> {
      return <Menu>
        {subMenu.map((e,i)=><Menu.Item key={i}>

          <a href={e.path}> <Row type={"flex"} align={"middle"} className={"top-bar-menu-item"}>
            <Col>
              <img src={e.icon} width={30} alt={""}/>
            </Col>
            <Col>
              <Space width={20}/>
            </Col>
            <Col>
              <ComponentText text={e.title} align="left" font={fonts.SFProDisplayMedium} color={"var(--charcoal-grey)"} size={16}/>
              <ComponentText text={e.description} align="left" font={fonts.SFProDisplayMedium} color={"var(--cool-grey-three)"} size={14}/>
            </Col>
          </Row></a>

        </Menu.Item>)}
      </Menu>;
    }
}