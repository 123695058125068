import React from 'react';
import { Divider, Row } from 'antd';
import { TableListNew } from '../Table/TableListNew';
import { tableContentType } from '../Table/TableDetails';
import { ComponentText } from '../ComponentText';
import { ComponentButton } from '../ComponentButton';
import moment from 'moment';
import Rodal from 'rodal';
import Space from '../Spcae';
import { fonts } from '../../../utils/fonts';

export default class PriceHistoryRodal extends React.Component {

  constructor (props) {
    super(props);
    this.state = {data:[]};
  }
    handleCancel = () => {
      this.props.handleClose();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {

      if(this.props.product && this.props.product !== prevProps.product){
        let data = [];

        const { price_history } = this.props.product;

        if (price_history) {
          let isCurrentFound = false;
          const sorted = price_history.sort((a,b)=> (moment(b.updated_at).utc().format("x") - moment(a.updated_at).utc().format("x")));
          data = sorted.map((p, ind) => {
            let isCurrent = false;
            if(!isCurrentFound && this.props.product.price_usd === p.price_usd){
              isCurrent = true;
              isCurrentFound = true;
            }
            return {
              id: ind,
              date: p.updated_at,
              price: p.price_usd,
              is_current: isCurrent
            };});
        }
        this.setState({data: data});
      }
    }

    render() {
      const { product, visible } = this.props;

      return (

        <Rodal customStyles={{padding: 0, width:530, height:500, borderRadius: 5}}
          visible={visible}
          onClose={this.handleCancel}
          showCloseButton={false}>
          <div>

            <ComponentText text={"Price History"} size="18"
              font={fonts.SFProDisplaySemiBold}
              color="var(--black)" marginTop={20} marginLeft={22} marginBottom={ 16}/>
            <Divider style={{marginTop:0, marginBottom:0}}/>

            <div className={'price-history-modal'}>

              <ComponentText
                text="Product Identifier"
                styl={{
                  fontFamily: 'SFProDisplay-Medium',
                  fontSize: '13px',
                  color: 'var(--greyish-brown)',
                }}
              />
              <Space height={10}/>

              <ComponentText
                text={product ? product.external_id: ""}
                styl={{
                  fontFamily: 'SFProDisplay-Medium',
                  fontSize: '15px',
                  color: 'var(--black)',
                }}
              />
              <Space height={28}/>


              <ComponentText
                text="Price History"
                styl={{
                  fontFamily: 'SFProDisplay-Medium',
                  fontSize: '13px',
                  color: 'var(--greyish-brown)',
                }}
              />

              <Space height={10}/>
              {/*<Table columns={columns} dataSource={data} bordered pagination={false} />*/}
              <TableListNew
                pagination={false}
                list={this.state.data}
                attributes ={attributes}
                row={5}
              />
              <Row type="flex" justify="end" align="middle">


                <ComponentButton
                  htmlType="button"
                  name="close"
                  text="Close"
                  css="stroke-cloudy-blue eina-semibold-cool-grey-15"
                  width="122"
                  height="42"
                  stylText={{
                    color: 'var(--cool-grey)',
                    fontSize: '15px',
                  }}
                  handleClick={this.handleCancel}
                />

              </Row>
            </div>

          </div>

        </Rodal>
      );
    }
}

const attributes = [
  {
    title: '',
    field: '',
    type: tableContentType.EMPTY,
  },
  {
    title: 'Date',
    field: 'date',
    type: tableContentType.DATE_HISTORY_MODAL,
  },
  {
    title: 'Price',
    field: 'price',
    type: tableContentType.PRICE_HISTORY_MODAL,
  },
];
