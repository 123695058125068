import React from 'react';
import { Button, Row } from 'antd';
import { ComponentIcon } from './ComponentIcon';
import Space from './Spcae';

export class ComponentButton extends React.Component {
  prepareIcon = (antd, icon, size) => {
    return <ComponentIcon antd={antd} type={icon} height={size} width={size} />;
  };
  render() {
    const {
      text,
      iconLeft,
      iconRight,
      icon,
      handleClick,
      handleClickOb = null,
      name,
      antd,
      padding,
      marginTop,
      marginRight,
      iconSize,
      iconSizeRight,
      css,
      height,
      width,
      htmlType,
      styl,
      stylText = {},
      spaceWidth,
    } = this.props;
    const style = { padding: padding + 'px', height: height + 'px', width: width + 'px' };

    if (marginRight) {
      style.marginRight = `${marginRight}px`;
    } else if (marginRight) {
      style.marginTop = `${marginTop}px`;
    }

    return (
      <Button
        className={css}
        style={styl ? styl : style}
        key={name}
        onClick={handleClick ? () => handleClick( handleClickOb ? handleClickOb : name) : null}
        htmlType={htmlType ? htmlType : null}
      >
        <Row type="flex" align="middle" justify="center">
          {iconLeft ? this.prepareIcon(antd, iconLeft, iconSize) : null}
          <Space width={iconLeft ? spaceWidth || '10' : '0'} />
          <div style={stylText}>{text}</div>
          {icon ? this.prepareIcon(antd, icon, iconSize) : null}
          <Space width={iconRight ? spaceWidth || '10' : '0'} />
          {iconRight ? this.prepareIcon(antd, iconRight, iconSizeRight) : null}
        </Row>
      </Button>
    );
  }
}
