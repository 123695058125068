import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const AdminRoute = ({isAuthenticated, component: Component, ...rest}) => {
  //console.log(isAuthenticated);
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

AdminRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { loginRemoteData, logoutData} = state;

  //console.log(loginRemoteData);

  let token;
  if(loginRemoteData && loginRemoteData.response && loginRemoteData.response.token){
    token = loginRemoteData.response.token.access_token;
  }

  //console.log(token);

  //console.log(logoutData);

  // if(logoutData != null){
  //   token = null;
  // }

  return {
    isAuthenticated: !!token,
  };
}

export default connect(mapStateToProps)(AdminRoute);
