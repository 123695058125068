import React from 'react';
import { Divider, Form, Modal, Radio, Row, Spin } from 'antd';
import { ComponentText } from '../../shared/ComponentText';
import Space from '../../shared/Spcae';
import { ComponentButton } from '../../shared/ComponentButton';
import { ComponentPromoCode } from '../../shared/ComponentPromoCode';
import { prepareInput } from '../../../utils/InputFieldCongifurations';
import { ComponentDivider } from '../../shared/ComponentDivider';
import images from '../../../images/Images';
import { fonts } from '../../../utils/fonts';
import { getCardText, isCardDefault } from './ComponentPaymentMethod';
import { postData } from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import { connect } from 'react-redux';
import { getUser } from '../../../utils/Repository';
import { contentType } from '../../../utils/ActionTypes';
import {
  subscriptionConfirmPath,
  subscriptionPurchasePath,
} from '../../../utils/routerUtils';
import { CardElement } from '@stripe/react-stripe-js';
import { PlanTypes } from './ComponentBilling';
import { InputComponent } from '../../shared/FormComponent/InputComponent';

class ModalUpdatePlan extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      card_name: prepareInput('card_name', 'Name on Card', true, 'Card Name', '', 'Enter Name'),
      planType: this.props.planType,
      selectedCard: this.getSelectedCard()
    };
  }

    handleSubmit = e => {
      e.preventDefault();
      const {stripe, elements, onPostData, accountInfo, cardList} = this.props;

      if(cardList && cardList.length > 0){
        this.purchaseSubscription( {
          card_id: this.getCardID()
        });
      } else{
        this.props.form.validateFields( async (err, values) => {
          if (!err) {
            const {error, paymentMethod} = await stripe.createPaymentMethod({
              type: 'card',
              card: elements.getElement(CardElement),
              billing_details: {
                name: values["card_name"],
              },
            });
            if(!error) {
              this.setState({newCardID:paymentMethod.id});
              this.purchaseSubscription( {
                payment_method_id: paymentMethod.id
              });
            }
          }
        });
      }


    };

    purchaseSubscription = (cardInfo) => {
      const {onPostData, accountInfo, cardList} = this.props;
      const user = getUser();


      onPostData(
        contentType.SUBSCRIPTION_PURCHASE,
        subscriptionPurchasePath,
        {
          organization_id: user.organizations_roles[0].organization_id,
          product_id : this.props.product.id,
          subscription_plan_id: this.getSubscriptionPlanID(),
          card_info: cardInfo
        }
      );
    };

    handleCancel = () => {
      this.props.form.resetFields();
      this.props.handleClose();
    };

    onChange = e => {
      this.setState({
        planType: e.target.value,
      });
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
      const {stripe, elements} = this.props;
      if(this.props.cardList && this.props.cardList !== prevProps.cardList){
        this.selectCard(this.getSelectedCard());
      }

      if(this.props.planType && this.props.planType !== prevProps.planType){
        this.setState({planType: this.props.planType});
      }

      if(this.props.purchaseData && this.props.purchaseData !== prevProps.purchaseData){
        if(this.props.purchaseData.intent_secret) {
          this.confirmPurchaseProduct(this.props.purchaseData.intent_secret);
        }else{
          this.props.handleClose("reload");
        }
      }
      if(this.props.confirmData && this.props.confirmData !== prevProps.confirmData){
        this.props.handleClose("reload");
      }

    }

    render() {
      const {isFetching, visible, product} = this.props;
      const productName = "";
      return <Modal
        className="model-upgrade-product"
        // title={ <ComponentText text="Upgrade to Startup" size="18" color="var(--black)" align="left" marginTop="0" marginBottom="0"/>}
        title= {<Row type="flex" align="middle" justify="space-between">
          <ComponentText text={`Upgrade to ${product ? product.name: ""}`} size={18} color="var(--black-two)" font={fonts.SFProDisplaySemiBold} />
          <ComponentText text={productName} size={18} color="var(--black-two)" font={fonts.SFProDisplaySemiBold} />
        </Row>}
        centered
        visible={visible}
        closable={false}
        footer={null}
        onCancel={() => this.handleCancel(false)}
      >
        <Spin spinning={isFetching === true}>
          <Form onSubmit={this.handleSubmit} layout="vertical">
            <Row type="flex" justify="space-between" align="middle">
              <Row
                type="flex"
                justify="center"
                align="middle"
                style={{
                  width: 192,
                  height: 38,
                  borderRadius: 19,
                  border: 'solid 1px #dee6ea',
                  backgroundColor: '#ffffff',
                }}
              >
                <Radio.Group name="radiogroup" defaultValue={"SubscriptionPlanTypeMonthly"} onChange={this.onChange} value={this.state.planType}>
                  <Row type="flex" justify="center" align="middle">
                    <Radio value={"SubscriptionPlanTypeYearly"} style={{ paddingBottom: 4, paddingLeft: 10 }}>
                      <a className="medium-slate-grey">Annually </a>
                    </Radio>
                    <ComponentDivider width={1} height="38px" />
                    <Radio value={"SubscriptionPlanTypeMonthly"} style={{ paddingBottom: 4, paddingLeft: 8 }}>
                      <a className="medium-slate-grey">Monthly</a>
                    </Radio>
                  </Row>
                </Radio.Group>
              </Row>
              <div style={{paddingBottom:2}}>
                <ComponentText text={"Get 2 months free when you subscribe to the annual plan"} color={"var(--dark-sky-blue)"} font={fonts.SFProDisplayMedium} size={12}/>
              </div>
            </Row>
            <Divider/>

            <Row type={"flex"} align={"middle"} justify={"space-between"}>
              <Row type={"flex"} align={"middle"}>
                <img
                  alt=""
                  src={images.startupPlan}
                  style={{ width: 45}}
                />
                <Space width={12}/>
                <ComponentText
                  font={fonts.SFProDisplaySemiBold}
                  text={product ? product.name: ""}
                  size="22"
                  color="var(--black-two)"
                  align="center"
                />
                <Space width={16}/>
                <ComponentText
                  font={fonts.SFProDisplayRegular}
                  text={product && this.getPriceMonthly(product)}
                  size="16"
                  color="var(--slate-grey)"
                  align="center"
                />
              </Row>
              <ComponentText
                font={fonts.SFProDisplaySemiBold}
                text={product && this.getPayablePrice(product)}
                size="18"
                color="var(--black-two)"
                align="center"
              />
            </Row>
            <Divider/>
            <ComponentText text="Payment Method" size="15" color="var(--water-blue)" font={fonts.SFProDisplaySemiBold} marginBottom="16"/>
            {this.props.cardList && this.props.cardList.length > 0 ? this.props.cardList.map(e => <Row key={e.id} justify="space-between" type="flex" align="middle" className={"payment-method-row-modal"}>
              <Row type="flex">
                <img src={this.getCardImage(e)} width={30}/>
                <Space width={10}/>
                <ComponentText text={getCardText(e)}
                  color="var(--slate-grey)"
                  font={fonts.SFProDisplayRegular}
                  size={14} />
                <Space width={4}/>
                <ComponentText text={e.number}
                  color="var(--slate-grey)"
                  font={fonts.SFProDisplaySemiBold}
                  size={14} />
                <Space width={10}/>
                <ComponentText text={isCardDefault(e)}
                  color="var(--dark-sky-blue)"
                  font={fonts.SFProDisplayMedium}
                  size={14} />
              </Row>
              <Row type="flex" justify={"end"}>
                <ComponentButton
                  htmlType="button"
                  name="select-card"
                  text={e.id === this.state.selectedCard ? "Selected" : "Select"}
                  css={e.id === this.state.selectedCard ? "selected-card" : "select-card"}
                  width="120" height="32"
                  handleClick={()=>this.selectCard(e.id)}/>
              </Row>
            </Row>) :  <div className={"card-input"}>
              <InputComponent
                form={this.props.form}
                layout={formItemLayout}
                values={this.state.card_name}
              />
              <CardElement  options={{
                style: {
                  base: {
                    fontSize: '15px',
                  },
                },
              }} />
            </div>}
            <Space height="24"/>
            <ComponentPromoCode/>
            <Space height="24"/>
            <Row type="flex" align="middle" justify={"space-between"}>
              <ComponentButton htmlType="button" name="cancel" text="Cancel" css="cancel-subscription" width="122" height="42" handleClick={this.handleCancel}/>
              <ComponentButton htmlType="submit" name="purchase-subscription" text={`Pay ${product && this.getPayablePrice(product)} Now`} css="purchase-subscription" width="186" height="42"/>
            </Row>
            <Space height="24"/>
          </Form>
        </Spin>
      </Modal>;
    }

    selectCard = (id) => {
      this.setState({selectedCard: id});
    };

  getCardImage = (e) => {
    let iconRef = '';
    if (e.brand === 'visa') {
      iconRef = images.visaCard;
    } else {
      iconRef = images.masterCard;
    }
    return iconRef;
  }

    getSelectedCard = () => {
      const {cardList} = this.props;
      if(cardList) {
        for (let i = 0; i < cardList.length; i++) {
          if (cardList[i].status === "STATUS_ACTIVE") {
            return cardList[i].id;
          }
        }
      }
    };

    getSubscriptionPlanID = () => {

      const plans = this.props.product.subscription_plans;
      for (let i = 0; i < plans.length; i++) {
        if (plans[i].type === this.state.planType) {
          return plans[i].id;
        }
      }
    };

    getCardID = () => {
      const {cardList} = this.props;
      for (let i = 0; i < cardList.length; i++) {
        if (cardList[i].id === this.state.selectedCard) {
          return cardList[i].id;
        }
      }
    };

  confirmPurchaseProduct = async (secret) => {
    const {stripe, elements, cardList} = this.props;
    //const user = getUser();
    // const {error, payment} = await stripe.confirmCardPayment(secret, {
    //   payment_method: this.getSelectedCard().pm_id,
    // });
    const {error, paymentIntent} = await stripe.retrievePaymentIntent(secret);
    if(!error){
      const {onPostData, accountInfo} = this.props;

      onPostData(
        contentType.SUBSCRIPTION_CONFIRM,
        subscriptionConfirmPath,
        {
          payment_intent_id: paymentIntent.id,
          card: {
            payment_method_id: cardList && cardList.length > 0 ? this.getCardID() : this.state.newCardID,
          }
        }
      );
    }
  };

  getPriceMonthly = (product) => {
    const plans = product.subscription_plans;
    for (let i = 0; i < plans.length; i++) {
      if (plans[i].type === this.state.planType) {
        return `$${Math.floor(plans[i].price /
          (plans[i].type === PlanTypes.Yearly ? 12 : 1))}/Month`;
      }
    }
  };

  getPayablePrice = (product) => {
    const plans = product.subscription_plans;
    console.log(plans);
    for (let i = 0; i < plans.length; i++) {
      if (plans[i].type === this.state.planType) {
        return `$${Math.floor(plans[i].price)}`;
      }
    }
  }
}

const ComponentModalUpdatePlan = Form.create({name: "modal-update-plan"})(ModalUpdatePlan);


const mapActionToProps = {
  onPostData: postData,
};

const mapStateToProps = state => {
  const {subscriptionPurchaseRemoteData, subscriptionConfirmRemoteData, paymentMethodRemoteData} = state;
  return {
    isFetching: utils.getIsFetching(subscriptionPurchaseRemoteData) || utils.getIsFetching(subscriptionConfirmRemoteData),
    isFailed: utils.getIsFailed(subscriptionPurchaseRemoteData),
    purchaseData: utils.getItem(subscriptionPurchaseRemoteData),
    confirmData: utils.getItem(subscriptionConfirmRemoteData),
    cardList : utils.getData(paymentMethodRemoteData),
  };
};
export default connect(
  mapStateToProps,
  mapActionToProps
)(ComponentModalUpdatePlan);

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};

