import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Row, Select, Spin } from 'antd';
import styled from 'styled-components';
import { ComponentText } from '../../shared/ComponentText';
import { loadData, postData } from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import { contentType } from '../../../utils/ActionTypes';
import {
  getProductUpdatePath,
  productCreatePath,
  productListPath,
} from '../../../utils/routerUtils';
import { ComponentIcon } from '../../shared/ComponentIcon';
import { tableContentType } from '../../shared/Table/TableDetails';
import { TableListNew } from '../../shared/Table/TableListNew';
import { ComponentButton } from '../../shared/ComponentButton';
import ComponentModalForm from '../../shared/ComponentModalForm';
import { ModalTypes, productModalWithType } from './ProductGroupModals';
import { ListEmpty } from '../../shared/ListEmpty';
import images from '../../../images/Images';
import listUtils from '../../../utils/listUtils';
import PriceHistoryModal from '../../shared/modals/PriceHistoryModal';
import PriceHistoryRodal from '../../shared/modals/PriceHistoryRodal';

const { Option } = Select;

const Styled = {};

Styled.TableTitle = styled.div`
  display: flex;
	justify-content: space-between;
	margin-top: 27px;
  margin-bottom: 27px;
`;

class ComponentProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroup: 0 ,
      selectedModal: productModalWithType(this.props.stores),
      productList:[],
      isEmptyProducts: false,
      showModalPriceHistory: false,
    };
  }


  componentDidMount() {
    const { onLoadData, id } = this.props;
    let page = listUtils.currentPage(this.props.location.search);
    let limit = listUtils.currentLimit(this.props.location.search);
    let filters = { application_id: id };
    onLoadData(contentType.PRODUCT_LIST, productListPath, listUtils.prepareActionParams(page, limit, filters) );
  }



    handleChange = value => {
      this.setState({ selectedGroup: value });
    };

    getProductFromGroup = (group) => {

      let list = [];
      if (group && group.package_infos) {
        group.package_infos.forEach(pkg => {
          if (pkg.package && pkg.package.products) {
            pkg.package.products.forEach(product => {
              product['store_name'] = product.source === 'SOURCE_APP_STORE'
                ? 'App Store'
                : 'Play Store';
              product['status'] = product.status === 'STATUS_ACTIVE'
                ? 'Active'
                : product.status === 'STATUS_READY' ? 'Ready' : 'Unknown';
              list.push(product);
            });
          }
        });
      }
      return list;
    };

    onButtonClick = (product = null) => {
      this.setState({
        showModalForm: true,
        modalType: ModalTypes.ADD_PRODUCT,
      });
    };

    onModalClose = values => {
      const { onPostData, onPutData, onDeleteData, id, stores } = this.props;

      if (values) {
        switch (this.state.modalType) {
        case ModalTypes.ADD_PRODUCT:
          values.status = 'Active';

          this.setState({ showModalForm: false }, () => {
            onPostData(contentType.PRODUCT_CREATE, productCreatePath, {
              store_id: stores[values.store],
              name: values.name,
              external_id: values.external_id,
              type: "TYPE_CONSUMABLE",
              source: values.store,
              application_id: id,
            });
          });
          break;
        case ModalTypes.EDIT_PRODUCT:
          if (values === 'delete') {
            const deletePath = getProductUpdatePath(values.id);

            this.setState({ showModalForm: false}, () => {
              onDeleteData(contentType.PRODUCT_DELETE, deletePath);
              this.showSuccess("Product deleted");
            });
          } else {


            this.setState({ showModalForm: false }, () => {
              onPutData(
                contentType.PRODUCT_CREATE,
                getProductUpdatePath(values.id),
                {
                  name: values.name,
                  product_id: values.id,
                },
              );
            });
          }
          break;

        default:
          this.setState({ showModalForm: false });
          break;
        }
      } else {
        this.setState({ showModalForm: false });
      }
    };

    prepareData = () => {
      const { data } = this.props;

      if (data) {
        if (this.state.selectedGroup === 0) {
          let list = [];
          data.forEach( e => {
            this.getProductFromGroup(e).forEach(p =>{
              list.push(p);
            });
          });
          return list;
        }
        else {
          const group = data[this.state.selectedGroup - 1];
          return this.getProductFromGroup(group);

        }
      }
      return [];
    };

    componentDidUpdate (prevProps, prevState, snapshot) {

      let page = listUtils.currentPage(this.props.location.search);
      let limit = listUtils.currentLimit(this.props.location.search);

      let previousPage = listUtils.currentPage(prevProps.location.search);
      let previousLimit = listUtils.currentLimit(prevProps.location.search);

      if(page !== previousPage || limit !== previousLimit){
        this.componentDidMount();
      }
      if(this.props.data && this.props.data !== prevProps.data){

        if(this.props.data && this.props.data.length === 0){
          this.setState({isEmptyProducts: true});
        }else{
          this.setState({isEmptyProducts: false});
        }


        const data = this.props.data ? this.props.data.map(e =>
        { return {
          id: e.id,
          external_id: e.external_id,
          price_usd: e.price_usd,
          price_history: e.price_history,
          price_history_modal: this.handleModalPriceHistory,
          store_name: utils.getSource(e.source),
          type: utils.getProductType(e.type),
          status: e.type === "TYPE_SUBSCRIPTION" ? utils.getProductStatus(e.status): 'One Time Purchase',
          created_at: e.created_at,
          actions: [
            // {
            //   url: getTransactionDetails(e.transaction.id),
            //   title: "View",
            // },

          ]


        };}): [];
        this.setState({productList: data});

      }
      if(this.props.productCreateData && this.props.productCreateData !== prevProps.productCreateData){

        this.setState({isEmptyProducts: false});

        let e = this.props.productCreateData;
        const item = {
          id: e.id,
          external_id: e.external_id,
          price_usd: e.price_usd,
          price_history: e.price_history,
          price_history_modal: this.handleModalPriceHistory,
          store_name: utils.getSource(e.source),
          type: utils.getProductType(e.type),
          status: e.type === "TYPE_SUBSCRIPTION" ? utils.getProductStatus(e.status): 'One Time Purchase',
          created_at: e.created_at,
          actions: [
            // {
            //   url: getTransactionDetails(e.transaction.id),
            //   title: "View",
            // },

          ]
        };
        const data = [item, ...this.state.productList];
        this.setState({productList: data});
      }
    }

  handleCancel = () => {
    this.setState({ showModalPriceHistory: false  });
  };
  handleModalPriceHistory = record => {
    this.setState({ showModalPriceHistory: true, record: record });
  };

  render() {
    let { isFetching, isFailed, pager } = this.props;

    return (
      <div className={"product-list"} style={{ minHeight: 400 }}>

        {/*{this.prepareGroupSelectDropdown(data)}*/}

        <Row type="flex" justify="space-between" align="middle" style={{marginTop: 28, marginBottom:28}}>
          <ComponentText
            text="Associated Products"
            size="14"
            color="var(--black)"
            weight={600}
            font="SFProDisplay-Semibold"
          />
          <ComponentButton
            name="add-product"
            text="Add Product"
            size="12"
            iconLeft="add"
            css="add-group-item"
            handleClick={this.onButtonClick}
          />
        </Row>
        {this.state.isEmptyProducts ? <ListEmpty height={window.innerHeight - 200} image={images.transactionEmpty}  title={"product"}/> :
          <TableListNew
            history={this.props.history}
            location={this.props.location}
            isLastPage = {utils.isLastPage(pager)}
            isFetching={isFetching}
            isFailed={isFailed}
            //pagination={false}
            list={this.state.productList}
            attributes ={attributes}
          />}
        <ComponentModalForm
          values={this.state.selectedModal}
          visible={this.state.showModalForm}
          handleClose={this.onModalClose}
        />
        {/*<PriceHistoryModal*/}
        {/*  product={this.state.record}*/}
        {/*  visible={this.state.showModalPriceHistory}*/}
        {/*  handleClose={this.handleCancel}*/}
        {/*/>*/}

        <PriceHistoryRodal
          product={this.state.record}
          visible={this.state.showModalPriceHistory}
          handleClose={this.handleCancel}
        />

      </div>
    );
  }

    prepareGroupSelectDropdown = (data) => {
      return   data ? (
        <Select
          suffixIcon={<div style={{marginTop:-4}}><ComponentIcon type={"arrow-down"} width={"10px"}/></div>}
          defaultValue={0}
          onChange={this.handleChange}
        >
          <Option key={0} value={0}>All</Option>
          {data.map((e, i) => (
            <Option key={i+1} value={i+1}>{e.name}</Option>
          ))}
        </Select>
      ) : (
        <div className={"select-group-spinner"}>
          <Spin/>
        </div>
      );
    }
}

const mapActionToProps = {
  onLoadData: loadData,
  onPostData: postData,
};

const mapStateToProps = state => {
  const { productListRemoteData, productCreateRemoteData, productUpdateRemoteData } = state;
  return {
    data: _.cloneDeep(utils.getData(productListRemoteData)),
    isFetching: utils.getIsFetching(productListRemoteData) || utils.getIsFetching(productCreateRemoteData) || utils.getIsFetching(productUpdateRemoteData),
    isFailed: utils.getIsFailed(productListRemoteData),
    pager: utils.getPager(productListRemoteData),
    productCreateData: utils.getItem(productCreateRemoteData),
    productUpdateData: utils.getData(productUpdateRemoteData),

  };
};

export default connect(mapStateToProps, mapActionToProps)(ComponentProducts);


const attributes = [
  {
    title: '',
    field: '',
    type: tableContentType.EMPTY,
  },
  {
    title:  'Product ID',
    field: 'external_id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Store',
    field: 'store_name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Type',
    field: 'type',
    type: tableContentType.TEXT,
  },
  {
    title: 'Price',
    field: 'price_history',
    type: tableContentType.PRICE_HISTORY,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.TEXT,
  },
  {
    title: 'Created',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
  {
    title: '',
    field: 'action',
    type: tableContentType.ACTION,
  },
];



