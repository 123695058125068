import React from 'react';
import { Divider, Form, message, Row, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { InputComponent } from '../shared/FormComponent/InputComponent';
import {
  getInputEmail,
  getInputPassword,
} from '../../utils/InputFieldCongifurations';
import { connect } from 'react-redux';
import { forgotPassword } from './authActionCreators';
import { ComponentText } from '../shared/ComponentText';
import Space from '../shared/Spcae';
import { ComponentButton } from '../shared/ComponentButton';
import { fonts } from '../../utils/fonts';
import { TopBar } from './Topbar';
import utils from '../../utils/utils';

class ForgotPassForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: getInputEmail(),
      password: getInputPassword(),
    };
  }

  componentDidMount() {
    if (utils.isLoggedIn(this.props.loginData)) {
      this.props.history.push('/dashboard');
    }
  }
  componentDidUpdate (prevProps, prevState, snapshot) {
    const { data, orgetPasswordMessage, isFailed } = this.props;

    if(isFailed){
      this.error(orgetPasswordMessage);
    }
    else if(data && data !== prevProps.data){
      this.props.history.push('/reset-email');
    }

  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.forgotPassword(values.email);
      }
    });
  };

  success = text => {
    message.success(text);
  };

  error = text => {
    if (text === null) return;
    message.error(text);
  };

  render() {
    const { isFetching,} = this.props;

    return (
      <Spin spinning={!!isFetching}>
        <TopBar/>
        <div style={{height:window.innerHeight - 71, backgroundColor:"#fafafa"}}>
          <Row justify={"center"} type={"flex"}>
            <div className={"login-container"}>
              <ComponentText
                text="Forgot Password?"
                size="23"
                color="var(--dark-grey-blue-two)"
                font={fonts.SFProDisplayRegular}
              />
              <Space height={12}/>

              <Divider />

              <div style={{paddingLeft: 60, paddingRight:60}}>
                <Form onSubmit={this.handleSubmit} layout="vertical">
                  <InputComponent
                    form={this.props.form}
                    layout={formItemLayout}
                    values={this.state.email}
                  />


                  <Form.Item className="no-bottom-margin">
                    <ComponentButton
                      htmlType="submit"
                      name="Continue"
                      text="Continue"
                      css="dark-sky-blue eina-semibold-white-15"
                      styl={{height: '42px' }}
                      stylText={{ fontFamily: 'SFProDisplay-Medium' }}
                    />
                  </Form.Item>
                </Form>


                <Row type={"flex"} justify={"center"} align={"middle"}>
                  <ComponentText
                    text="Don't have an account? "
                    size="13"
                    color="var(--greyblue)"
                  />
                  <Space width="5" />
                  <Link to={'register'}>
                    <ComponentText text="Sign Up" size="13" color="var(--azure)" />
                  </Link>
                </Row>
              </div>
            </div>
          </Row>
        </div>
      </Spin>
    );
  }
}

const ForgotPass = Form.create({ name: 'forgotpass' })(ForgotPassForm);

const mapActionToProps = {
  forgotPassword,
};


const mapStateToProps = state => {
  const {forgetPasswordRemoteData, loginRemoteData} = state;
  return {
    data: utils.getData(forgetPasswordRemoteData),
    isFetching: utils.getIsFetching(forgetPasswordRemoteData),
    isFailed: utils.getIsFailed(forgetPasswordRemoteData),
    orgetPasswordMessage: utils.getMessage(forgetPasswordRemoteData),
    loginData: utils.getItem(loginRemoteData),
  };
};
export default connect(mapStateToProps, mapActionToProps)(ForgotPass);

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};
