import axios from 'axios';
import { getToken } from './Repository';

let axiosWrapper = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers": "*",
  },
});

axiosWrapper.interceptors.request.use(
  config => {
    if (!config.headers.token) {
      const token = getToken();
      if (token) {
        config.headers.Authorization = "Bearer " + token;
      }
    }
    return config;
  },
  error => Promise.reject(error.response)
);

// Add a response interceptor
axiosWrapper.interceptors.response.use(response => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, error => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error.response);
});

export default axiosWrapper;
