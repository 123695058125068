import React from 'react';
import { Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import { ComponentButton } from './ComponentButton';
import { Link } from 'react-router-dom';
import Space from './Spcae';
import ComponentAppSelect from './ComponentAppSelect';
import { transactionListPath } from '../../utils/routerUtils';

const { RangePicker } = DatePicker;

class PageHeaderList extends React.Component {
  handleClick = name => {
    console.log(name);
  };

  prepareCreateApp = () => {
    return (
      <Link key="create" to={'create'}>
        <ComponentButton
          name="create"
          iconLeft="apps"
          text="Add new App"
          width="165"
          height="36"
          css="add-new-app"
          iconSize="20px"
        />
      </Link>
    );
  };

  render() {
    const {
      title,
      appSelecctor,
      appSelecctorNearTitle,
      createApp,
      dateSelector,
      navigateToAll,
      location,
      history,
    } = this.props;

    return (
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <Row type="flex" align="middle">
            {title}
            <Space width="20" />
            {appSelecctorNearTitle && appSelecctor ? (
              <ComponentAppSelect location={location} history={history} />
            ) : null}
          </Row>
        </Col>

        <Col>
          <Row type="flex" align="middle">
            {[
              !appSelecctorNearTitle && appSelecctor ? <ComponentAppSelect /> : null,
              dateSelector ? prepareDateSelector('Mar 8, 2016', 'Apr 8, 2016') : null,
              createApp ? this.prepareCreateApp() : null,
              navigateToAll ? prepareNavigateToAll('All Transactions') : null,
            ]}
          </Row>
        </Col>
      </Row>
    );
  }
}

function prepareNavigateToAll(title) {
  return (
    <Link to={transactionListPath} key="toAll">
      <ComponentButton
        text={title}
        css="dark-seafoam-green eina-semibold-13-dark-seafoam-green link"
        iconRight="right"
      />
    </Link>
  );
}

function prepareDateSelector(startDate, endDate) {
  return (
    <RangePicker
      key="RangePicker"
      ranges={{
        Today: [moment(), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
      }}
      showTime
      format="YYYY/MM/DD HH:mm:ss"
      onChange={onChange}
    />
  );
}

function onChange(dates, dateStrings) {
  console.log('From: ', dates[0], ', to: ', dates[1]);
  console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
}


export default PageHeaderList;
