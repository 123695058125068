import React from 'react';
import { Card, Row, Skeleton } from 'antd';
import { ComponentText } from '../ComponentText';
import { ComponentIcon } from '../ComponentIcon';
import Space from '../Spcae';
import moment from 'moment';

export function CardInfoPlan({plan}) {

  const name = plan.product_group.name;
  const renewsOn = name ? plan.renews_on : "";
  return <Card style={{
    margin: 8,
    marginBottom: 16,
    borderRadius: 4,
    width: 300,
  }} hoverable bodyStyle={{padding: 22}}>

    <Row type="flex" align="middle">
      <div style={{paddingBottom: 2}}>
        <ComponentIcon type="star" width={14}/>
      </div>
      <Space width="8"/>
      <ComponentText text={"Current plan"} size="15" font={"SFProDisplay-Medium"} color="var(--dark-grey-blue-two)" align="left" marginTop="0" marginBottom="0"/>
    </Row>
    <div style={{height:44, marginTop: 14}}>
      <Skeleton className={"card-info"} loading={!name} active paragraph={0}> <ComponentText text={name} font={"SFProDisplay-Semibold"} size="21" color=" var(--dark-sky-blue)" align="left" marginTop="8" marginBottom="16"/> </Skeleton>
    </div>

    <ComponentText text={"Renews on"} size="15" color="var(--dark-grey-blue-two)" font={"SFProDisplay-Medium"} align="left" marginTop="0" marginBottom="8"/>
    <div style={{height:28, marginTop: 6}}>
      <Skeleton className={"card-info"} loading={!renewsOn} active paragraph={0}> <ComponentText text={moment(renewsOn).utc().format("dddd, MMMM D, YYYY hh:mm A")} font="SFProDisplay-Medium" size="13" color="var(--slate-grey)" align="left" marginTop="0" marginBottom="0"/> </Skeleton>
    </div>
  </Card>;
}
