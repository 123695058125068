import FilterInputItem from '../../shared/Filter/FilterInputItems';

export const listQueryFilterParams = () =>
  listSearchModalParams.reduce((acm, cur) => [...acm, cur.name], []);

export const listSearchModalParams = [
  FilterInputItem.type,
  FilterInputItem.store,
  FilterInputItem.product_group,
];
