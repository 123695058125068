import { createReducerWraper } from '../../utils/Reducer';
import { contentType } from '../../utils/ActionTypes';

export const metricsOverviewReducer = createReducerWraper(contentType.METRICS_OVERVIEW);
export const metricsAutoRenewReducer = createReducerWraper(contentType.METRICS_AUTO_RENEW);
export const metricsMRRReducer = createReducerWraper(contentType.METRICS_MRR);
export const metricsRevenueReducer = createReducerWraper(contentType.METRICS_REVENUE);
export const metricsChurnReducer = createReducerWraper(contentType.METRICS_CHURN);
export const metricsEventCountReducer = createReducerWraper(contentType.METRICS_EVENT_COUNT);
export const metricsTrialConversionReducer = createReducerWraper(contentType.METRICS_TRIAL_CONVERSION);
export const metricsNewPurchaseTrialReducer = createReducerWraper(contentType.METRICS_NEW_PURCHASE_TRIALS);
export const metricsActivePurchaseTrialReducer = createReducerWraper(contentType.METRICS_ACTIVE_PURCHASE_TRIALS);