import React from 'react';
import { connect } from 'react-redux';
import { Form, Row, Spin, Tooltip } from 'antd';
import { ComponentText } from '../../shared/ComponentText';
import { ComponentIcon } from '../../shared/ComponentIcon';
import { ComponentButton } from '../../shared/ComponentButton';
import { postData, putData } from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import { fonts } from '../../../utils/fonts';
import { prepareInput } from '../../../utils/InputFieldCongifurations';
import { InputComponent } from '../../shared/FormComponent/InputComponent';
import Space from '../../shared/Spcae';
import images from '../../../images/Images';
import { contentType } from '../../../utils/ActionTypes';
import {
  getProductUpdatePath,
  productCreatePath,
} from '../../../utils/routerUtils';
import { SelectComponent } from '../../shared/FormComponent/SelectComponent';

class ConfigureProductsCreate extends React.Component {
  constructor (props) {
    super(props);
    let stores = {SOURCE_APP_STORE: null, SOURCE_PLAY_STORE: null};
    this.props.currentApp.application_store.forEach(e => {
      stores[[e.source]] = e.id;
    });
    const list = stores['SOURCE_APP_STORE'] &&  stores['SOURCE_PLAY_STORE'] ? [ { title: 'App Store', value: 'SOURCE_APP_STORE' }, { title: 'Play Store', value: 'SOURCE_PLAY_STORE' }] :
      stores['SOURCE_APP_STORE'] ? [{ title: 'App Store', value: 'SOURCE_APP_STORE' }] : [{ title: 'Play Store', value: 'SOURCE_PLAY_STORE' }];


    this.state = {
      name: prepareInput('name', 'Product Name', true, 'Enter Product Name', '', 'Enter Product Name', '',this.props.edit ? this.props.productList[0].name :''),
      external_id: prepareInput('external_id', 'Product Identifier', true, 'e.g. com.yourapp.products.monthly', 'Used to access the package via the SDK, cannot be edited once created', 'Enter Product Identifier', '', this.props.edit ? this.props.productList[0].external_id :''),
      store: prepareInput('store', 'Store', true, 'Select Store', '', 'Select Store', list, this.props.edit ? this.props.productList[0].source :''),
      type: prepareInput('type', 'Type', true, 'Select Type', '', 'Select Type', [{ title: 'Consumable', value: 'TYPE_CONSUMABLE' }, { title: 'Subscription', value: 'TYPE_SUBSCRIPTION' }],this.props.edit ? this.props.productList[0].type :'' ),
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    const { onPostData, onPutData, currentPackage, currentApp} = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let stores = {SOURCE_APP_STORE: null, SOURCE_PLAY_STORE: null};
        this.props.currentApp.application_store.forEach(e => {
          stores[[e.source]] = e.id;
        });

        this.props.edit ?
          onPutData(contentType.PRODUCT_UPDATE, getProductUpdatePath(this.props.productList[0].id), {
            store_id: stores[values.store],
            name: values.name,
            external_id: values.external_id,
            package_id: currentPackage.id,
            type: values.type,
          }):
          onPostData(contentType.PRODUCT_CREATE, productCreatePath, {
            store_id: stores[values.store],
            name: values.name,
            external_id: values.external_id,
            package_id: currentPackage.id,
            type: values.type,
            source: values.store,
            application_id: currentApp.id,
          });
      }
    });
  };

  render() {
    const {isFetching, productCreateIsFetching,productUpdateIsFetching, productList} = this.props;

    return (
      <div style={{width: 530}}>
        <Spin spinning={isFetching === true || productCreateIsFetching === true || productUpdateIsFetching === true}><div>
          <ComponentText text={"Get Started with Purchasefly"} font={fonts.SFProDisplaySemiBold} size={24} color={"var(--dark-three)"}/>
          <Space height={38}/>
          <Row type={"flex"} align={"middle"}>
            <div className={"step-configure-product"}>
              <ComponentText text={"1. Product Groups"} font={fonts.SFProDisplaySemiBold} size={14} color={"var(--slate-grey)"}/>
            </div>
            <Space width={12}/>
            <img src={images.arrow} width={32} alt={""}/>
            <Space width={12}/>
            <div className={"step-configure-product"}>
              <ComponentText text={"2. Packages"} font={fonts.SFProDisplaySemiBold} size={14} color={"var(--slate-grey)"}/>
            </div>
            <Space width={12}/>
            <img src={images.arrow} width={32} alt={""}/>
            <Space width={12}/>
            <div className={"step-configure-product"}>
              <ComponentText text={"3. Products"} font={fonts.SFProDisplaySemiBold} size={14} color={"var(--dark-sky-blue)"}/>
            </div>
          </Row>
		  <Space height={38}/>
          <Row type="flex">
            <ComponentText text="Add Product" size="20" color= "var(--black)" font={fonts.SFProDisplaySemiBold}/>
            <Tooltip contentStyle={{backgroundColor: '#4A4A4A'}} placement="bottomLeft" title={"Products Groups represent a App Store Connect group of products. Please arrange them how you arranged in App Store Connect."}>
              <a style={{paddingLeft: 10,
                height: 20}}><ComponentIcon type="help" width={15}/></a>
            </Tooltip>
          </Row>
          <Form onSubmit={this.handleSubmit} layout="vertical">
            <div className="on-board-connect-sdk">

              <InputComponent
                form={this.props.form}
                layout={formItemLayout}
                values={this.state.name}
              />

              <InputComponent
                form={this.props.form}
                layout={formItemLayout}
                values={this.state.currentStep === 7?productList[0].external_id : this.state.external_id}
              />
              <SelectComponent
                form={this.props.form}
                layout={formItemLayout}
                values={this.state.currentStep === 7?productList[0].store : this.state.store}
              />
              <SelectComponent
                form={this.props.form}
                layout={formItemLayout}
                values={this.state.currentStep === 7?productList[0].type : this.state.type}
              />
              <Row className={"onboard-product-instruction"} type={"flex"} justify="start">
                <img height={28} width={28} src={images.info} alt={""}/>
                <Space width={12}/>
                <div className={"empty-group-container-content"}>
                  <ComponentText
                    text="Please open PurchaseFly SDK enabled application to sync pricing with PurcahseFly after adding each product."
                    font="SFProDisplay-Regular"
                    size={14}
                    color="var(--slate-grey)"
                  />
                </div>
              </Row>

            </div>
            <Row type="flex" align="middle" justify="end">
              <ComponentButton
                htmlType="submit"
                name="connect"
                text={this.props.edit ? "Update and Proceed":"Add and Proceed"}
                css="purchase-subscription"
                height="42"
              />
            </Row>
          </Form>
        </div></Spin>

      </div>

    );
  }

}

const ConfigureProducts = Form.create({ name: 'configure-product' })(ConfigureProductsCreate);

const mapActionToProps = {
  onPostData: postData,
  onPutData: putData,
};

const mapStateToProps = state => {
  const {productCreateRemoteData, productUpdateRemoteData} = state;
  return {
    productCreateIsFetching: utils.getIsFetching(productCreateRemoteData),
    productCreateData: utils.getData(productCreateRemoteData),
    productUpdateIsFetching: utils.getIsFetching(productUpdateRemoteData),
    productUpdateData: utils.getData(productUpdateRemoteData),
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(ConfigureProducts);

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};