import React from 'react';
import { connect } from 'react-redux';
import { Avatar, Col, Empty, Row, Skeleton } from 'antd';
import { appsListApiPath } from '../../../utils/routerUtils';
import { loadData, loadLocalData } from '../../../utils/ActionCreators';
import { contentType } from '../../../utils/ActionTypes';
import { Link } from 'react-router-dom';
import { ComponentText } from '../../shared/ComponentText';
import utils from '../../../utils/utils';
import PageHeaderList from '../../shared/PageHeaderList';
import { ComponentIcon } from '../../shared/ComponentIcon';
import image from '../../../images/empty-state-illustration@3x.png';
import { ComponentDivider } from '../../shared/ComponentDivider';
import Space from '../../shared/Spcae';
import { getUser } from '../../../utils/Repository';
import { ComponentButton } from '../../shared/ComponentButton';
import '../apps.css';

const title = 'My Apps';

class AppsList extends React.Component {

  constructor(props) {
    super(props);
    let screenWidth = window.innerWidth - 320;
    let column = Math.floor(screenWidth / 308);
    let width = Math.floor(screenWidth / column) - 20;
    this.state = {column: column, width: width < 300? 300 : width };
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize);
    this.loadList();
    // if (isItemExist(contentType.APPS_LIST.name, 1)) {
    //   this.loadLocalList();
    // } else this.loadList();
  }
  handleResize = () => {
    this.forceUpdate();
    let screenWidth = window.innerWidth - 320;
    let column = Math.floor(screenWidth / 308);
    let width = Math.floor(screenWidth / column) - 20;
    this.setState({column: column, width: width < 300? 300 : width});
  };

  loadLocalList () {
    const { onLoadLocalData } = this.props;
    onLoadLocalData(contentType.APPS_LIST);
  }

  loadList () {
    const { onLoadData } = this.props;
    const user = getUser();
    onLoadData(contentType.APPS_LIST, appsListApiPath, {
      filters: { organization_id: user.organizations_roles[0].organization_id },
      pager: {
        skip: 0,
        limit: 100,
      },
    });
  }

  render () {
    let { list, isFetching } = this.props;
    return (
      <div className="app-list-container">
        <div style={{paddingLeft:8}}>
          <PageHeaderList
            title={
              <ComponentText
                size="24"
                color="var(--dark)"
                font="SFProDisplay-Semibold"
                text={title}
              />
            }
            createApp={true}
          />
        </div>

        {list && list.length > 0 ? this.prepareAppListView(list): isFetching
          ? this.prepareLoadingView()
          : this.prepareEmptyView()}
      </div>
    );
  }
    prepareEmptyView = () => {
      return <Empty
        image={image}
        imageStyle={{
          marginTop: '48px',
          height: 332,
        }}
        description=""
      >
        <div>
          <ComponentText
            text={'Nothing in here'}
            size="24"
            weight={600}
            color="var(--greyish-brown)"
            marginBottom={7}
          />
          <ComponentText
            text={'You don’t have any app yet'}
            size="22"
            weight={400}
            color="var(--cool-grey)"
            marginBottom={28}
          />
          <Link key="create" to={'create'}>
            <ComponentButton
              name="create"
              iconRight="set-up-app"
              text="Set up your app"
              css="dark-sky-blue eina-semibold-dark-12"
              width="206"
              height="42"
              iconSize="20px"
              spaceWidth={32}
              stylText={{
                fontSize: '14px',

                fontFamily: 'SFProDisplay-Semibold',
                lineHeight: 1.43,
                color: 'var(--white)',
              }}
            />
          </Link>
        </div>
      </Empty>;
    };

    prepareLoadingView = () => {
      return <div style={{paddingLeft:8}}>

        <Space height="40"/>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Skeleton active avatar={{
              size: 80,
              shape: 'square',
            }} paragraph={{ rows: 1 }}/>
          </Col>
          <Col className="gutter-row" span={12}>
            <Skeleton active avatar={{
              size: 80,
              shape: 'square',
            }} paragraph={{ rows: 1 }}/>
          </Col>
        </Row>
        <Space height="40"/>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Skeleton active avatar={{
              size: 80,
              shape: 'square',
            }} paragraph={{ rows: 1 }}/>
          </Col>
          <Col className="gutter-row" span={12}>
            <Skeleton active avatar={{
              size: 80,
              shape: 'square',
            }} paragraph={{ rows: 1 }}/>
          </Col>
        </Row>
        <Space height="40"/>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Skeleton active avatar={{
              size: 80,
              shape: 'square',
            }} paragraph={{ rows: 1 }}/>
          </Col>
          <Col className="gutter-row" span={12}>
            <Skeleton active avatar={{
              size: 80,
              shape: 'square',
            }} paragraph={{ rows: 1 }}/>
          </Col>
        </Row>


      </div>;
    };

    prepareAppView = (hasAppStore, hasPlayStore, item, index, size) => {

      return <div key={item.id} style={{
        paddingTop: 24, paddingBottom: 24, paddingRight:8, paddingLeft:0, width: this.state.width
      }}>
        <Link to={item.id}>
          <Row className="app-list-item" key={index} type="flex" align="middle">
            <Col>
              <Avatar shape="square" src={item.image_url} size={80}/>
            </Col>
            <Col>
              <Space width="20"/>
            </Col>
            <Col span={12}>
              <ComponentText
                text={item.name}
                size="18"
                weight={600}
                color="var(--black)"
                marginBottom={8}
              />
              <Row type="flex" align="middle">
                {hasAppStore && (
                  <ComponentIcon
                    type="ios"
                    styl={{
                      width: '16px',
                      height: '16px',
                      marginRight: '10px',
                    }}
                  />)}

                {hasPlayStore && (
                  <ComponentIcon
                    type="android"
                    styl={{
                      width: '16px',
                      height: '16px',
                      marginRight: '10px',
                    }}
                  />
                )}

                {/*<Link*/}
                {/*  key="create"*/}
                {/*  to={item.id}*/}
                {/*  className="eina-semibold-dark-seafoam-green-15"*/}
                {/*  style={{*/}
                {/*    color: 'var(--dark-seafoam-green)',*/}
                {/*    fontSize: '15px',*/}
                {/*    fontDamily: 'SFProDisplay-Semibold',*/}

                {/*  }}*/}
                {/*>*/}
                {/*            Edit Settings*/}
                {/*</Link>*/}
              </Row>
            </Col>
            <Col>
              { (index+1) % this.state.column  !== 0 && index !== size -1 ? (
                <ComponentDivider
                  type="vertical"
                  height={80}
                  marginLeft={16}
                  marginRight={24}
                />
              ) : null}
            </Col>
          </Row>
        </Link>
      </div>;
    };

    prepareAppListView = (list) => {
      return (
        <div className="app-list">
          <Row type="flex" align="middle">
            {list.map((item, index) => {
              const { application_store: store } = item;
              const hasAppStore = store.find(
                item => item.source === 'SOURCE_APP_STORE');
              const hasPlayStore = store.find(
                item => item.source ===
                      'SOURCE_PLAY_STORE');

              return this.prepareAppView(hasAppStore,
                hasPlayStore, item, index, list.length);
            })}
            <ComponentDivider marginBottom={32}/>
          </Row>
        </div>
      );
    }
}

const mapActionToProps = {
  onLoadData: loadData,
  onLoadLocalData: loadLocalData,
};

const mapStateToProps = state => {
  const { appsListRemoteData } = state;
  return {
    list: utils.getData(appsListRemoteData),
    isFetching: utils.getIsFetching(appsListRemoteData),
    isFailed: utils.getIsFailed(appsListRemoteData),
  };
};
export default connect(mapStateToProps, mapActionToProps)(AppsList);
