import { contentType } from '../../../utils/ActionTypes';
import { createReducerWraper } from '../../../utils/Reducer';

export const accountDetailsReducer = createReducerWraper(contentType.ACCOUNT);
export const accountUpdateReducer = createReducerWraper(contentType.ACCOUNT_UPDATE);


export const collaboratorListReducer = createReducerWraper(contentType.COLLABORATOR_LIST);
export const collaboratorCreateReducer = createReducerWraper(contentType.COLLABORATOR_CREATE);
export const collaboratorUpdateReducer = createReducerWraper(contentType.COLLABORATOR_UPDATE);
export const collaboratorDeleteReducer = createReducerWraper(contentType.COLLABORATOR_DELETE);
export const collaboratorResendReducer = createReducerWraper(contentType.COLLABORATOR_RESEND);
export const collaboratorReducer = createReducerWraper(contentType.COLLABORATOR);


export const billingProductListReducer = createReducerWraper(contentType.BILLING_PRODUCT_LIST);
export const billingPurchaseReducer = createReducerWraper(contentType.BILLING_PRODUCT_LIST);
export const billingReceiptListReducer = createReducerWraper(contentType.BILLING_RECEIPT_LIST);
export const overageListReducer = createReducerWraper(contentType.OVERAGE_LIST);

export const paymentMethodListReducer = createReducerWraper(contentType.PAYMENT_METHOD_LIST);
export const paymentMethodCreateReducer = createReducerWraper(contentType.PAYMENT_METHOD_CREATE);
export const paymentMethodActivateReducer = createReducerWraper(contentType.PAYMENT_METHOD_ACTIVATE);
export const paymentMethodDeleteReducer = createReducerWraper(contentType.PAYMENT_METHOD_DELETE);

export const subscriptionPurchaseReducer = createReducerWraper(contentType.SUBSCRIPTION_PURCHASE);
export const subscriptionConfirmReducer = createReducerWraper(contentType.SUBSCRIPTION_CONFIRM);

export const userAccountUpdateReducer = createReducerWraper(contentType.USER_ACCOUNT_UPDATED);
export const userOnFreePlanReducer = createReducerWraper(contentType.USER_ON_FREE_PLAN);
