import React from 'react';
import { connect } from 'react-redux';
import { Col, Radio, Row, Spin } from 'antd';
import styled from 'styled-components';

import { ComponentText } from '../../shared/ComponentText';
import { ComponentButton } from '../../shared/ComponentButton';
import ComponentModalUpdatePlan from './ComponentModalUpdatePlan';
import Space from '../../shared/Spcae';
import images from '../../../images/Images';
import {
  deleteData,
  loadData,
  postData,
  putData,
} from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import { contentType } from '../../../utils/ActionTypes';
import {
  getAccountDetailsPath,
  getBillingProductListPath,
} from '../../../utils/routerUtils';
import { ComponentDivider } from '../../shared/ComponentDivider';
import { fonts } from '../../../utils/fonts';
import { ComponentIcon } from '../../shared/ComponentIcon';
import ComponentPaymentMethod, { stripePromise } from './ComponentPaymentMethod';
import CurrentPlan from './CurrentPlan';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

const Styled = {};

Styled.PaymentMethodWrapper = styled.div`
  display: flex;
  flex-basis: 787px;
  flex-direction: column;
  background-color: white;
  padding: 29px 26px 24px 25px;
`;

Styled.PaymentMethodHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

Styled.AddPaymentWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const features =[
  [
    'Up to $10k MTR',
    'All Platform SDKs',
    'Customer Status Tracking',
    'Receipt Validation',
    'Necessary Metrics View',
  ],
  [
    '$100k MTR Included',
    'Attribution Source Tracking',
    'All Integrations',
    'Advanced Charting and Metrics',
    'Up to 10 Collaborators',
    'Premium Support',
    'Raw Data Exports',
  ],
  [
    '$20k MTR Included',
    'Apple Search Ads Dashboard',
    'Server-to-Server Webhooks',
    'Up to 5 Collaborators',
    'Advanced Charting and Metrics',
  ]
];

const plansContent = [
  {
    icon: images.basicPlan,
    title: 'Basic',
    price: 'Free',
    description: 'No credit card required,<br/> no trial period.',
    buttonText: 'Get Started Now',
    buttonType: 'white-dark-mint-50',
    features: features[0],
  },
  {
    icon: images.startupPlan,
    title: 'Startup',
    price: '$79',
    description: 'Per month billed annually,<br/> or $89 billed monthly',
    buttonText: 'Start 7 Day Free Trial',
    buttonType: 'dark-mint-50',
    features: features[1],
  },
  {
    icon: images.businessPlan,
    title: 'Business',
    price: '$299',
    description: 'Per month billed annually,<br/> or $329 billed monthly',
    buttonText: 'Start 7 Day Free Trial',
    buttonType: 'white-dark-mint-50',
    features: features[2],
  },
];

class ComponentBilling extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModalForm: false, modalName: '', selectedPlan: PlanTypes.Yearly };
  }

  componentDidMount() {
    const { onLoadData} = this.props;
    onLoadData(contentType.BILLING_PRODUCT_LIST, getBillingProductListPath, {
      pager: { skip: 0, limit: 15 },
    });
  }

  upgradePlan = product => {
    console.log(product);
    this.setState({ selectedProduct: product, showModalForm: true,  });
  };
  onModalClose = value => {
    if(value === "reload"){
      const {onLoadData} = this.props;
      onLoadData(
        contentType.ACCOUNT,
        getAccountDetailsPath,
      );
    }
    this.setState({ showModalForm: false });
  };

  onChange = e => {
    this.setState({
      selectedPlan: e.target.value,
    });
  };

  render() {
    const { productList, isFetching, isFailed, accountInfo } = this.props;
    const { showModalForm, modalName } = this.state;

    // if (productList)
    //   productList.forEach((e, index) => {
    //     plansContent[index].title = e.name;
    //     plansContent[index].price = e.price ? '$' + e.price : ' Free';
    //   });
    // <ComponentPlan plan={plans[1]} handleClick={this.upgradePlan}/>
    // <ComponentPlan plan={plans[2]} handleClick={this.upgradePlan}/>
    return (
      <div>
        <ComponentText text={"Plan & Billing"} font={fonts.SFProDisplaySemiBold} size={24} color={"var(--dark-three)"} marginBottom={30}/>
        <div className="subscription-plans">
          <CurrentPlan accountInfo={accountInfo} from={"billing"}/>
          <Row type="flex" justify="center">
            <ComponentText
              font={fonts.SFProDisplaySemiBold}
              text="Take full advantage of Purchasefly with"
              size="15"
              color="var(--slate-grey)"
              align="center"
            />
            <Space width={4} />
            <ComponentText
              font={fonts.SFProDisplaySemiBold}
              text="a Pro Account"
              size="15"
              color="var(--dark-seafoam-green)"
              align="center"
            />
          </Row>
          <Row type="flex" justify="center">
            <ComponentText
              font={fonts.SFProDisplayMedium}
              text="Get 2 months free when you subscribe to the yearly plan"
              size="12"
              color="var(--dodger-blue)"
              align="center"
              marginBottom="20"
              marginTop="10"
            />
          </Row>
          <Row type="flex" justify="center" align="middle">
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{
                width: 192,
                height: 38,
                borderRadius: 19,
                border: 'solid 1px #dee6ea',
                backgroundColor: '#ffffff',
              }}
            >
              <Radio.Group name="radiogroup" defaultValue={"SubscriptionPlanTypeMonthly"} onChange={this.onChange} value={this.state.selectedPlan}>
                <Row type="flex" justify="center" align="middle">
                  <Radio value={"SubscriptionPlanTypeYearly"} style={{ paddingBottom: 4, paddingLeft: 10 }}>
                    <a className="medium-slate-grey">Annually </a>
                  </Radio>
                  <ComponentDivider width={1} height="38px" />
                  <Radio value={"SubscriptionPlanTypeMonthly"} style={{ paddingBottom: 4, paddingLeft: 8 }}>
                    <a className="medium-slate-grey">Monthly</a>
                  </Radio>
                </Row>
              </Radio.Group>
            </Row>
          </Row>
          <Space height={24} />
          {productList && productList.length > 0 ? <Row type="flex" justify="center">
            {productList && productList.length > 0 && this.prepareStartUpPlan(plansContent[0], productList[0], false, false )}
            {productList && productList.length > 1 && this.prepareStartUpPlan(plansContent[1], productList[1], true, true )}
            {productList && productList.length > 2 && this.prepareStartUpPlan(plansContent[2], productList[2], false, false )}
          </Row> : <Row type={"flex"} justify={"center"}><Spin/></Row>}

          <Row type="flex" justify="center" align="middle" style={{ marginTop: 30 }}>
            <ComponentText
              font={fonts.SFProDisplayMedium}
              text="More than $100K MTR?"
              color="var(--slate-grey)"
              size="12"
            />
            <Space width={2} />
            <ComponentButton
              text="Contact us for custom plan"
              css="text-only water-blue s-12 medium"
            />
          </Row>
          <Row
            style={{ marginTop: 4, marginBottom: 12 }}
            type="flex"
            justify="center"
            align="middle"
          >
            <ComponentButton
              text="View full side by side feature comparison"
              css="text-only water-blue s-12 regular"
            />
          </Row>
        </div>
        <ComponentPaymentMethod/>
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({stripe, elements}) => (
              <ComponentModalUpdatePlan
                visible={showModalForm && modalName !== 'add-payment'}
                handleClose={this.onModalClose}
                accountInfo={this.props.accountInfo}
                product={this.state.selectedProduct}
                planType={this.state.selectedPlan}
                stripe={stripe}
                elements={elements}
              />
            )}
          </ElementsConsumer>
        </Elements>
      </div>
    );
  }

  prepareStartUpPlan = (p, product, isRecommended, isUpgradable) => {
    return (
      <Col span={8} align="center" className={"billing"}>
        { !this.isCurrentProduct(product)  && isRecommended ? <div className="recommended">
          <ComponentText text="Recommended!" size="11" color="var(--white)" align="center" />
        </div>: <div className={"not-recommended"}/>}
        <div className={isRecommended ? "recommended-plan" : "subscription-plan"}>


          <img
            alt=""
            src={p.icon}
            style={{ width: 64, marginTop: 40, borderCollapse: 'collapse' }}
          />
          <ComponentText
            font={fonts.SFProDisplaySemiBold}
            text={product.name}
            size="22"
            color="var(--black-two)"
            align="center"
            marginBottom="6"
            marginTop="6"
          />
          <ComponentText
            font={fonts.SFProDisplayRegular}
            text={this.getPrice(product)}
            size="16"
            color="var(--slate-grey)"
            align="center"
            marginBottom="4"
            marginTop="0"
          />

          { this.isCurrentProduct(product) ? <div style={{ height: 58, paddingTop: 10 }}>
            <div className="current-plan">
              <ComponentText
                font={fonts.SFProDisplayMedium}
                text="Current Plan"
                size="12"
                color="var(--dodger-blue)"
                align="center"
              />
            </div>
          </div> :

            <div style={{ height: 58 }}>
              <ComponentText
                font={fonts.SFProDisplayRegular}
                text={p.description}
                size="11"
                color="var(--cool-grey)"
                align="center"
                marginBottom="42"
                marginTop="0"
              />
            </div> }

          <div style={{ paddingLeft: 18, paddingRight: 18 }}>
            <ComponentDivider color="var(--pale-grey)" marginTop={0} marginBottom={32} />
          </div>
          <div style={{height:260}}>
            {p.features.map((s, i) => {
              return (
                <Row key={i} type="flex" align="middle">
                  <div className="Oval" style={{ marginLeft: 42 }}/>
                  <ComponentIcon type={'check'} width={12} />
                  <Space width={8} />
                  <ComponentText
                    font={fonts.SFProDisplayMedium}
                    text={s}
                    size="14"
                    color=" var(--slate-grey)"
                    align="center"
                    marginBottom="4"
                    marginTop="0"
                  />
                </Row>
              );
            })}
          </div>

          {  isUpgradable && !this.isCurrentProduct(product) &&  <div style={{ padding: 16 }}>
            <ComponentButton
              name="upgrade-startup"
              text={`Upgrade to ${product.name}`}
              css="dodger-blue-two-shadow medium white s-14"
              height="36"
              handleClick={()=>this.upgradePlan(product)}
            />
          </div>}
        </div>
      </Col>
    );
  };

  getPrice = (product) => {
    const plans = product.subscription_plans;
    for (let i = 0; i < plans.length; i++) {
      if (plans[i].type === this.state.selectedPlan && plans[i].price > 0) {
        return `$${Math.floor(plans[i].price/(plans[i].type === PlanTypes.Yearly ? 12 : 1))}`;
      }
    }
  };

  isCurrentProduct = (product) => {
    const {accountInfo} = this.props;
    return accountInfo && accountInfo.subscription_product.product.id === product.id;
  };
}

const mapActionToProps = {
  onLoadData: loadData,
  onPostData: postData,
  onPutData: putData,
  onDeleteData: deleteData,
};

const mapStateToProps = state => {
  const { billingProductListRemoteData, billingPurchaseRemoteData } = state;
  return {
    productList: utils.getData(billingProductListRemoteData),
    purchaseData: utils.getItem(billingPurchaseRemoteData),
    isFetching:
      utils.getIsFetching(billingProductListRemoteData) ||
      utils.getIsFetching(billingPurchaseRemoteData),
    isFailed: utils.getIsFailed(billingProductListRemoteData),
    purchaseIsFailed: utils.getIsFailed(billingPurchaseRemoteData),
  };
};
export default connect(mapStateToProps, mapActionToProps)(ComponentBilling);
function ComponentPlan({ plan }) {
  return (
    <Row type="flex" align="middle">
      <img alt="" src={plan.icon} style={{ width: 45, height: 45 }} />
      <Space width={12} />
      <Col>
        <ComponentText
          font={fonts.SFProDisplaySemiBold}
          text={plan.title}
          size="20"
          color="var(--black-two)"
          align="center"
          marginBottom="10"
          marginTop="10"
        />
        <ComponentText
          font={fonts.SFProDisplayRegular}
          text={plan.price}
          size="16"
          color="var(--black-two)"
          marginBottom="0"
        />
      </Col>
    </Row>
  );
}


export const PlanTypes = {
  Monthly : "SubscriptionPlanTypeMonthly",
  Yearly : "SubscriptionPlanTypeYearly"
};
