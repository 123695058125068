import React from 'react';
import { Divider, Popover, Row } from 'antd';
import { ComponentText } from '../ComponentText';
import './PopupDetails.css';
import TimeAgo from 'react-timeago';
import { ComponentCopyButton } from '../ComponentCopyButton';
import utils from '../../../utils/utils';
import { getTransactionDetails } from '../../../utils/routerUtils';
import { Link } from 'react-router-dom';

export class PopupDetails extends React.Component {

  render() {
    const {record} = this.props;

    const content = (
      <div style={{width: 300}}>
        <Row type="flex" justify="space-between" align="middle"> <
          ComponentText text="Transaction ID" size="15" color="var(--dark-grey-blue-two)" font={"SFProDisplay-Semibold"}/>
        <ComponentCopyButton data={record.id} size={14}/></Row>
        <Link key={record.id} to={getTransactionDetails(record.id)}> <ComponentText text={record.id} size="13" color="var(--azure)" font={"SFProDisplay-Semibold"}/></Link>

        {utils.getProductType(record.type) === "Subscription" && <div>
          <Divider style={{marginTop: 18,
            marginBottom: 12}}/>
          <ComponentText text="Expires In" size="15" color="var(--dark-grey-blue-two)" font={"SFProDisplay-Semibold"}/>
          <TimeAgo date={record.ends_at} style={{fontFamily: "SFProDisplay-Medium",
            fontsize: "13px",
            color: "var(--slate-grey)"}}/>
        </div>}
        <Divider style={{marginTop: 18,
          marginBottom: 12}}/>
        <ComponentText text="Type" size="15" color="var(--dark-grey-blue-two)" font={"SFProDisplay-Semibold"}/>
        <ComponentText text={utils.getProductType(record.type)} size="13" color="var(--slate-grey)" font={"SFProDisplay-Medium"}/>
        <Divider style={{marginTop: 18,
          marginBottom: 12}}/>
        <ComponentText text="Source" size="15" color="var(--dark-grey-blue-two)" font={"SFProDisplay-Semibold"}/>
        <ComponentText text={utils.getSource(record.source)} size="13" color="var(--slate-grey)" font={"SFProDisplay-Medium"}/>
        <Divider style={{marginTop: 18,
          marginBottom: 12}}/>
        <ComponentText text="Status" size="15" color="var(--dark-grey-blue-two)" font={"SFProDisplay-Semibold"}/>
        <ComponentText text={utils.getProductStatus(record.status, record.type)} size="13" color="var(--slate-grey)" font={"SFProDisplay-Medium"}/>
      </div>
    );
    return <Popover placement="left" content={content} title={<Row style={{height: 64}} type="flex" align="middle" justify="space-between">
      <ComponentText text="Transaction details" size="15" color="var(--dark-grey-blue-two)" font={"SFProDisplay-Medium"}/>
    </Row>} >
      {this.props.data}
    </Popover>;
  }
}