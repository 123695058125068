import React from 'react';

import { Table } from 'antd';
import './tableDetails.css';
import { FormatTextTableDetails } from '../FormatText/FormatTextTableDetails';

export const tableContentType = {
  DATE_TIME: "DATE_TIME",
  DATE_TIME_CUSTOMER_DETAILS: "DATE_TIME_CUSTOMER_DETAILS",
  DATE_TIME_INVOICE: "DATE_TIME_INVOICE",
  TIME_AGO: "TIME_AGO",
  ID: "ID",
  ID_VIEW: "ID_VIEW",
  LINK: "LINK",
  REVENUE: "REVENUE",
  PRICE: "PRICE",
  PRICE_HISTORY: "PRICE_HISTORY",
  PRODUCT: "PRODUCT",
  PRODUCT_CUSTOMER_DETAILS: "PRODUCT_USER_DETAILS",
  TITLE: "TITLE",
  INDICATOR: "INDICATOR",
  TEXT: "TEXT",
  STATUS: "STATUS",
  COLLABORATOR_STATUS: "COLLABORATOR_STATUS",
  INVOICE_ACTION: "INVOICE_ACTION",
  INVOICE_STATUS: "INVOICE_TYPE",
  APP_NAME: "APP_NAME",
  APP_PRODUCT: "APP_PRODUCT",
  ACTION: "ACTION",
  ALERT_ACTION: "ALERT_ACTION",
  EMPTY: "EMPTY",
  POP_OVER: "POP_OVER",
  BUTTON: "BUTTON",
  CARD_TEXT: 'CARD_TEXT',
  CARD_EXPIRE: 'CARD_EXPIRE',
  CARD_REMOVE_BUTTON: 'CARD_REMOVE_BUTTON',
  PRICE_HISTORY_MODAL: "PRICE_HISTORY_MODAL",
  DATE_HISTORY_MODAL: "DATE_HISTORY_MODAL",
  SHIMMER: "SHIMMER",
};

export class TableDetails extends React.Component {

  render() {
    let {data, isFetching, isFailed, fields, titles, types} = this.props;

    const result = data ? Object
      .keys(data)
      .filter(k => fields.find(e => e === k))
      .map((k, i) => ({key: k,
        title: titles[i],
        value: data[k]})) : [];
    const columns = [
      {
        dataIndex: "title",
        render: (text, record, index) => (<FormatTextTableDetails type={tableContentType.TITLE} record={record}/>),
        width: 200,
      },
      {
        dataIndex: "value",
        render: (text, record, index) => (<FormatTextTableDetails type={types[index]} record={record}/>),
      },
    ];
    return <Table className="table-details" dataSource={result} loading={isFetching} columns={columns} showHeader={false} bordered={true} pagination={false}/>;
  }
}

