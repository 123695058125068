import React from 'react';
import { connect } from 'react-redux';
import { Form, message, Row, Skeleton, Spin } from 'antd';
import { prepareInput } from '../../../utils/InputFieldCongifurations';
import { InputComponent } from '../../shared/FormComponent/InputComponent';
import { ComponentButton } from '../../shared/ComponentButton';
import utils from '../../../utils/utils';
import { contentType } from '../../../utils/ActionTypes';
import {
  getAppsDetailsPath,
  getWebHookTestPath,
} from '../../../utils/routerUtils';
import { postData, putData } from '../../../utils/ActionCreators';

class Webhook extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //     webhook_url: prepareInput('webhook_url', 'Webhook URL ', true, 'e.g. https://api.yourapp.com/endpoint', '', 'e.g. https://api.yourapp.com/endpoint',),
    //     // auth_header: prepareInput('auth_header', 'Authorization header value', true, 'e.g. Appname Xasoi3ijasdi3435ahgf', 'Optional. Purchasefly will send an HTTP Authorization header with this value in each POST request sent to your webhook server URL.', 'Enter bundleid'),
    // }
  }

  // componentDidUpdate(previousProps) {
  //     if (previousProps.data !== this.props.data && this.props.data) {
  //         this.setState({webhook_url: prepareInput('webhook_url', 'Webhook URL ', true, 'e.g. https://api.yourapp.com/endpoint', '', 'e.g. https://api.yourapp.com/endpoint', '', this.props.data.webhook_url)})
  //     }
  // }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onPutData(
          contentType.APPS_UPDATE,
          getAppsDetailsPath(this.props.data.id),
          values,
        );
      }
    });
  };

  handleClick = name => {
    this.props.onPostData(contentType.APPS_TEST_WEB_HOOK, getWebHookTestPath, {
      application_id: this.props.data.id,
    });
  };
  success = text => {
    message.success(text);
  };

  error = text => {
    if (text === null) return;
    message.error(text);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.testPostData !== prevProps.testPostData && this.props.postIsFetching === false) {
      if (this.props.testIsFailed === true) this.error('Webhook test failed');
      else this.success('Webhook test event successfull');
    }
  }

  render() {
    let { isFetching, data, isFailed, postIsFetching } = this.props;

    let webhook_url = prepareInput(
      'webhook_url',
      'Webhook URL ',
      true,
      'e.g. https://api.yourapp.com/endpoint',
      '',
      'e.g. https://api.yourapp.com/endpoint',
      '',
      data && data.webhook_url ? data.webhook_url : '',
    );

    return (
      <div className="webhook">
        <Spin spinning={!!postIsFetching}>
          <Form onSubmit={this.handleSubmit} layout="vertical">
            <div className="webhook-form">
              {isFetching ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <InputComponent
                  form={this.props.form}
                  layout={formItemLayout}
                  values={webhook_url}
                />
              )}

              {/* <InputComponent
                                form={this.props.form}
                                layout={formItemLayout}
                                values={this.state.auth_header}
                            />  */}
            </div>
            <Row type="flex" justify="space-between" align="middle">
              <Form.Item className="no-bottom-margin">
                <ComponentButton
                  htmlType="button"
                  name="test"
                  text="Send Test Event"
                  css="sent-test-event"
                  width="130"
                  height="42"
                  handleClick={this.handleClick}
                />
              </Form.Item>

              <Form.Item className="no-bottom-margin">
                <ComponentButton
                  htmlType="submit"
                  name="save-new"
                  text="Save Now"
                  css="save-now-webhooks"
                />
              </Form.Item>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

const ComponentWebhook = Form.create({ name: 'webhook-create' })(Webhook);

const mapActionToProps = {
  onPutData: putData,
  onPostData: postData,
};

const mapStateToProps = state => {
  const { appsUpdateRemoteData, webHookTestRemoteData } = state;
  return {
    postData: utils.getData(appsUpdateRemoteData),
    testPostData: utils.getData(appsUpdateRemoteData),
    postIsFetching:
      utils.getIsFetching(appsUpdateRemoteData) || utils.getIsFetching(webHookTestRemoteData),
    postIsFailed: utils.getIsFailed(appsUpdateRemoteData),
    testIsFailed: utils.getIsFailed(webHookTestRemoteData),
  };
};

export default connect(mapStateToProps, mapActionToProps)(ComponentWebhook);

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};
