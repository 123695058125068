import React from 'react';
import { Col, Icon, message, Row } from 'antd';
import ChartSummary from '../shared/Chart/ChartSummary';
import { TableList } from '../shared/Table/TableList';
import { ComponentText } from '../shared/ComponentText';
import { contentType } from '../../utils/ActionTypes';
import {
  getAccountDetailsPath,
  getBillingProductListPath,
  getMetricsAutoRenewPath,
  getMetricsOverviewPath,
  getTransactionDetails,
  getTransactionListPath,
  metricsMRRApiPath,
  metricsRevenueApiPath,
  productListPath,
  transactionListPath,
} from '../../utils/routerUtils';
import { loadData, userOnFreePlan } from '../../utils/ActionCreators';
import utils from '../../utils/utils';
import { connect } from 'react-redux';
import { tableContentType } from '../shared/Table/TableDetails';
import { getUser } from '../../utils/Repository';
import { UpgradeModal } from '../UpgradeModal';
import './dashboard.css';
import { ComponentCircularProgress } from '../shared/ComponentCircularProgress';
import { fonts } from '../../utils/fonts';
import ComponentAppSelect from '../shared/ComponentAppSelect';
import listUtils from '../../utils/listUtils';
import { listQueryFilterParams } from '../Customer/List/CustomerListConstants';
import { localStorageKey } from '../../utils/LocalStorage';
import moment from 'moment';
import Space from '../shared/Spcae';
import { ModalOnBoard } from './OnboardModal';
import images from '../../images/Images';
import { Link } from 'react-router-dom';
import { arrowDownAlt } from '../shared/ComponentIcon';
import * as uuid from 'uuid';
import { resendEmail } from '../Auth/authActionCreators';

const subscriptionTier = {
  SubscriptionTier_FREE: "SubscriptionTier_FREE",
  SubscriptionTier_PRO: "SubscriptionTier_PRO",
};

class Dashboard extends React.Component {
  constructor (props) {
    super(props);

    //const query = new URLSearchParams(this.props.location.search);
    // const token = query.get('application_ids');
    //const selected = localStorage.getItem(localStorageKey.SELECTED_APP) ? localStorage.getItem(localStorageKey.SELECTED_APP) : "0";
    let between = listUtils.currentDates(this.props.location.search);
    let now = new Date();
    const currentTime = moment(
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes(),
        0,
        0,
      ),
    );
    const range = {
      from: moment(currentTime).subtract(30, 'days').utc()
        .format('YYYY-MM-DDTHH:mm:ss') + 'Z',
      to: moment(currentTime).utc()
        .format('YYYY-MM-DDTHH:mm:ss') + 'Z',
    };
    this.state = between
      ? between
      : { between: range };

    // if (!token)
    //   this.props.history.push({
    //     pathname: '',
    //     search: "?" + new URLSearchParams({application_ids: selected}).toString(),
    //   });

  }

  componentDidMount () {
    const { onLoadData } = this.props;

    onLoadData(
      contentType.ACCOUNT,
      getAccountDetailsPath,
    );
    onLoadData(contentType.BILLING_PRODUCT_LIST, getBillingProductListPath,
      {
        pager: {
          skip: 0,
          limit: 15,
        },
      });
    this.loadListWithPageFilter();
    //this.intervalID = setInterval(this.loadData, 30000)
  }

    onDateChanged = range => {
      const between = {
        from: range.from,
        to: range.to,
      };
      this.setState({ between: between }, () => {
        const { history } = this.props;
        let filters = listUtils.prepareFilterParamsWithDate(
          listUtils.prepareFilterParams(
            listUtils.currentFilters(
              this.props.location.search,
              listQueryFilterParams(),
            ),
          ),
          between,
        );
        history.push(listUtils.prepareUrl('dashboard', 1, filters));
      });
    };

    loadListWithPageFilter () {
      const { onLoadData } = this.props;
      let filters = listUtils.prepareFilterParamsWithDate(
        listUtils.prepareFilterParams(
          listUtils.currentFilters(
            this.props.location.search,
            [],
          ),
        ),
        this.state.between,
      );
      const user = getUser();
      const query = new URLSearchParams(this.props.location.search);
      let applicationId = query.get('application_ids');
      if(!applicationId)
        applicationId = query.get('application_id');
      if(!applicationId){
        let key = localStorage.getItem(localStorageKey.SELECTED_APP);
        applicationId = key ? key : "all-app";
      }
      if (applicationId) {
        if (applicationId !== 'all-app') {
          filters['application_id'] = applicationId;
          filters['application_ids'] = applicationId;
        } else {
          delete filters['application_id'];
          delete filters['application_ids'];
        }

        filters['organization_id'] = user.organizations_roles[0].organization_id;
        filters['interval'] = 'month';

        onLoadData(
          contentType.TRANSACTION_LIST,
          getTransactionListPath,
          listUtils.prepareActionParams(1, 5, filters));
        onLoadData(
          contentType.METRICS_OVERVIEW,
          getMetricsOverviewPath,
          {
            filters: filters,
            pager: {
              skip: 0,
              limit: 6,
            },
          },
        );

        onLoadData(
          contentType.METRICS_AUTO_RENEW,
          getMetricsAutoRenewPath,
          {
            filters: filters,
            pager: {
              skip: 0,
              limit: 6,
            },
          },
        );

        onLoadData(
          contentType.METRICS_MRR, metricsMRRApiPath,
          { filters: filters },
        );
        onLoadData(contentType.METRICS_REVENUE, metricsRevenueApiPath,
          { filters: filters });
      }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      const currentSearch = this.props.location.search;
      const previousSearch = prevProps.location.search;
      if (currentSearch && previousSearch && currentSearch !==
          previousSearch) {
        this.loadListWithPageFilter();
      }

      if (this.props.appList && this.props.appList !== prevProps.appList) {
        if (this.props.appList.length === 0) {
          if (localStorage.getItem(localStorageKey.ON_BOARD_DIALOG) ===
                  'true') {
            this.setState({ showOnBoarding: true });
          } else {
            this.setState({ showOnBoardingModal: true });
            localStorage.setItem(localStorageKey.ON_BOARD_DIALOG,
              'true');
          }
        } else if (this.props.appList.length === 1) {
          let filters = { application_id: this.props.appList[0].id };
          this.props.onLoadData(contentType.PRODUCT_LIST, productListPath,
            listUtils.prepareActionParams(1, 10, filters));
        } else {
          localStorage.setItem(localStorageKey.SHOW_ONBOARD, 'true');
        }
      }
      if (this.props.productList && this.props.productList !==
          prevProps.productList) {
        if (this.props.productList.length === 0) {
          this.setState({ showOnBoarding: true });
        } else {
          const productList = this.props.productList;
          let isPurchased = false;
          for (let i = 0; i < productList.length; i++) {
            if (productList[i].status === 'STATUS_ACTIVE') {
              isPurchased = true;
            }
          }
          if (!isPurchased) {
            this.setState({ showOnBoarding: true });
          } else {
            localStorage.setItem(localStorageKey.SHOW_ONBOARD, 'true');
          }
        }
      }

      if (this.props.userData && this.props.userData !== prevProps.userData) {
        if (this.props.userData.user.email_verified_at === null)
          this.setState({ showEmailVerification: true });
        this.setUpgradePlanPlan();

        localStorage.setItem(localStorageKey.USER, JSON.stringify(this.props.userData.user));
      }

      if (this.props.billingProductList && this.props.billingProductList !==
          prevProps.billingProductList) {
        this.setUpgradePlanPlan();
      }
    }

    getNameFromTier = (tier) =>{
      if(this.props.billingProductList && this.props.billingProductList.length > 0){
        for(let i = 0;i < this.props.billingProductList.length;i++){
          if(this.props.billingProductList[i].tier === tier){
            return this.props.billingProductList[i].name;
          }
        }
      }
      return "";

    }

    setUpgradePlanPlan = () => {
      if (this.isFreeBillingPlan() && this.props.billingProductList && this.props.billingProductList.length >= 2) {
        this.props.onUserOnFreePlan(contentType.USER_ON_FREE_PLAN, {
          name: this.getNameFromTier(subscriptionTier.SubscriptionTier_PRO),
          free_plan: this.getNameFromTier(subscriptionTier.SubscriptionTier_FREE),
        });
      } else {
        this.props.onUserOnFreePlan(contentType.USER_ON_FREE_PLAN, {
          name: '',
          free_plan: '',
        });
      }
    };

    render () {
      const { transactionData, isFetching, metricsIsFetching,
        isFailed, metricsOverviewData, metricsAutoRenewData, mrrData, mrrMessage,
        revenueData, appList, billingProductList } = this.props;

      const blur = mrrMessage && mrrMessage.code && mrrMessage.code === 426;

      const transactions = transactionData && transactionData.length > 0
        ? transactionData.map((e) => {
          return {
            id: e.transaction.id,
            user_id: e.transaction.user_id,
            product_name: e.transaction.external_product_id.length > 15
              ? e.transaction.external_product_id.substr(0, 15) + '...'
              : e.transaction.external_product_id,
            app_image: e.application.image_url,
            revenue: e.transaction.trial === true ? 'Trial' : '$' +
                    e.transaction.revenue.toFixed(2).toString(),
            purchased_at: e.transaction.purchased_at,
            ends_at: e.transaction.ends_at,
            status: e.transaction.status,
            app_name: e.application.name,
            detailsPath: getTransactionDetails(e.transaction.id),
          };
        })
        : [];

      while (transactions.length > 0 && transactions.length < 5) {
        transactions.push({
          id: uuid(),
          user_id: '',
          product_name: '',
          app_image: '',
          revenue: '',
          purchased_at: '',
          ends_at: '',
          status: '',
          app_name: '',
          detailsPath: '',
        });
      }

      const activeUser = metricsOverviewData &&
        metricsOverviewData.active_users
        ? metricsOverviewData.active_users.toString()
        : '0';
      const activeTrial = metricsOverviewData &&
        metricsOverviewData.active_trials
        ? metricsOverviewData.active_trials.toString()
        : '0';
      const MRR = metricsOverviewData && metricsOverviewData.mrr
        ? metricsOverviewData.mrr.toString()
        : '0';
      const MRRRate = metricsOverviewData && metricsOverviewData.mrr_rate
        ? metricsOverviewData.mrr_rate.toString()
        : '0';
      const payingSubscribers = metricsOverviewData &&
        metricsOverviewData.paying_subscribers
        ? metricsOverviewData.paying_subscribers.toString()
        : '0';
      const installs = metricsOverviewData && metricsOverviewData.installs
        ? metricsOverviewData.installs.toString()
        : '0';
      const revenue = metricsOverviewData && metricsOverviewData.revenue
        ? metricsOverviewData.revenue.toString()
        : '0';
      const revenueRate = metricsOverviewData &&
        metricsOverviewData.revenue_rate
        ? metricsOverviewData.revenue_rate.toString()
        : '0';

      const totalSubscription = metricsAutoRenewData &&
        metricsAutoRenewData.total_subscription
        ? metricsAutoRenewData.total_subscription.toString()
        : '0';
      const willExpire = metricsAutoRenewData &&
        metricsAutoRenewData.will_expire
        ? metricsAutoRenewData.will_expire.toString()
        : '0';
      const willRenew = metricsAutoRenewData &&
        metricsAutoRenewData.will_renew
        ? metricsAutoRenewData.will_renew.toString()
        : '0';

      const user = getUser();
      return <div className={'dashboard'}>
        <Col style={{
          width: 1025,
          margin: 'auto',
        }}>
          {/*<PageHeaderList title={<ComponentText text="Dashboard" font="Eina-Bold" size="28" />} appSelecctorNearTitle={true} appSelecctor={true} dateSelector={true} location={this.props.location} history={this.props.history} />*/}
          <Row style={{ height: 36 }}/>
          <Row type={'flex'} justify={'space-between'} align={'middle'}>
            <Col>
              <ComponentText text={'Good morning ' + user.name}
                font={fonts.SFProDisplaySemiBold}
                size="28" color={'var(--white)'}/>
              <Space height={6}/>
              <ComponentText
                text="Here is a summary of how your apps are doing, in last 30 days"
                font={fonts.SFProDisplayMedium} size="16"
                color={'var(--white)'}/>
            </Col>
            <Col align={'right'}>

              <Row type={'flex'} align={'middle'}>
                <ComponentAppSelect history={this.props.history}
                  location={this.props.location}
                  css={'app-select-dashboard'}
                  from={'dashboard'}/>
                {/*<Space width={20}/>*/}
                {this.isFreeBillingPlan() && billingProductList &&
                            <Link to={'account?menu=billing'}>
                              {/*<ComponentButton name="plan" text={billingProductList.length > 1 ? billingProductList[1].name : ""} iconRight="right" iconSizeRight={22} css="" handleClick={this.onButtonClick} />*/}
                              <Row type={'flex'} className={'billing-upgrade'}
                                align={'middle'} justify={"center"}>
                                <img src={images.startupIconBilling}
                                  width={24} alt={""}/>
                                <Space width={8}/>
                                <ComponentText
                                  text={this.getNameFromTier(subscriptionTier.SubscriptionTier_FREE)}
                                  font={fonts.SFProDisplaySemiBold}
                                  color={'var(--white)'} size={14}/>
                                <Space width={8}/>
                                <ArrowIcon rotate={90} style={{
                                  marginRight: 8,
                                  transform: 'rotate(-90deg)',
                                  WebkitTransform: 'rotate(-90deg)',
                                  transition: '150ms ease',
                                }}/>
                              </Row>
                            </Link>}

                {/*<Space width={6}/>*/}
                {/*<RangeDateTimePicker style={{height: 36}} onDateChanged={this.onDateChanged} initialDates={this.state.between} defaultInterval={0} format={" MMM DD, YYYY"}/>*/}
              </Row>
            </Col>
          </Row>
          <Row>
          </Row>
          {this.state.showEmailVerification === true && <InfoAction
            icon={images.info}
            status={'Account not verifed!'}
            description={'We’ve sent you a verification email in your inbox. Please check and verify now.'}
            question={'Didn’t get email?'}
            color={'var(--water-blue)'}
            cancelable={true}
            onResend={() => this.resendEmail()}
            onClose={() => this.setState(
              { showEmailVerification: false })}
          />}
          {this.state.showOnBoarding && <InfoAction
            icon={images.account_verify}
            status={'Welcome to Purchasefly.'}
            description={'Welcome to Purchasefly. Get started with our enterprise-class mobile subscription management.'}
            question={'Setup your app now'}
            color={'var(--dark-seafoam-green)'}
            cancelable={false}
            onResend={() => this.toOnboard()}
          />}

          <Space height={20}/>
          <div className="panel-chart-summery">
            <Row type="flex" justify="space-around">
              <Row gutter={20}>
                <Col span={8}><ChartSummary
                  blur = {blur}
                  isFetching={metricsIsFetching}
                  title="Paying Subscribers" value={'' +
                            utils.formatNumber(Math.round(payingSubscribers))}
                  subtitle={''} color="var(--white)"/></Col>
                <Col span={8}><ChartSummary
                  blur = {blur}
                  isFetching={metricsIsFetching}
                  title="Active Trials" value={'' +
                            utils.formatNumber(Math.round(activeTrial))}
                  subtitle={''} color="var(--white)"/></Col>
                <Col span={8}><ChartSummary
                  blur = {blur}
                  isFetching={metricsIsFetching}
                  title="Monthly Recurring Revenue (MRR)"
                  value={'$' + utils.formatNumber(Math.round(MRR))}
                  subtitle={MRRRate} color="var(--white)"
                  chart={true} data={mrrData}/></Col>
              </Row>
            </Row>

            <Row style={{ height: 24 }}/>
            <Row type="flex" justify="space-around">
              <Row gutter={20}>
                <Col span={8}><ChartSummary
                  blur = {blur}
                  isFetching={metricsIsFetching}
                  title="Active Users" value={'' +
                            utils.formatNumber(Math.round(activeUser))}
                  subtitle={''} color="var(--white)"/></Col>
                <Col span={8}><ChartSummary
                  blur = {blur}
                  isFetching={metricsIsFetching} title="Installs"
                  value={'' +
                              utils.formatNumber(Math.round(installs))}
                  subtitle={''} color="var(--white)"/></Col>
                <Col span={8}><ChartSummary
                  blur = {blur}
                  isFetching={metricsIsFetching} title="Revenue"
                  value={'$' +
                              utils.formatNumber(Math.round(revenue))}
                  subtitle={revenueRate} color="var(--white)"
                  chart={true} data={revenueData}/></Col>
              </Row>
            </Row>
          </div>
        </Col>
        <Row style={{ height: 24 }}/>
        {/*<PageHeaderList title={<ComponentText text="Recent Purchases" size="20"/>} navigateToAll={true}/>*/}
        <Row style={{ height: 20 }}/>
        {appList && appList.length > 0 && <Row type="flex" style={{
          width: 1050,
          margin: 'auto',
        }} gutter={25}>
          <Col span={12}>
            <ComponentText text="Recent Purchases" size="20"
              font={fonts.SFProDisplaySemiBold}
              color={'var(--dark)'} marginBottom={16}/>
            {transactions && transactions.length === 0 && isFetching ===
                    false ?
              <div className={'dashboard-empty-transaction'}
                style={{ paddingTop: 60 }}>
                <img src={images.transactionEmpty} width={80}
                  alt={''}/>
                <Space height={30}/>
                <ComponentText
                  text={`No transactions available to show`}
                  font={'SFProDisplay-Semibold'} size={'20'}
                  color={'var(--greyish-brown)'}/>
                <Space height={10}/>
                <ComponentText
                  text={`You don’t have any transaction yet`}
                  font={'SFProDisplay-Regular'} size={'18'}
                  color={'var(--dark-grey-blue)'}/>
              </div>
              : <TableList
                pagination={false}
                list={transactions}
                isFetching={isFetching}
                isFailed={isFailed}
                fields={fields}
                titles={titles}
                types={types}
                footer={<Row type={'flex'} justify={'end'}
                  align={'middle'} style={{ marginTop: 8 }}>
                  <Link to={this.prepareToTransactionURL()}>
                    <Row type={'flex'} align={'middle'}
                      className={'footer-transaction-list'}>
                      <ComponentText text={'All Transaction'}
                        font={fonts.SFProDisplayMedium}
                        size={16}
                        color={'var(--dark-sky-blue)'}/>
                      <Space width={8}/>
                      <ArrowIcon rotate={90} style={{
                        marginRight: 8,
                        transform: 'rotate(-90deg)',
                        WebkitTransform: 'rotate(-90deg)',
                        transition: '150ms ease',
                      }}/>
                    </Row>
                  </Link>
                </Row>}
              />}
          </Col>
          <Col span={12}>
            <ComponentText text="Auto Renew" size="20"
              font={fonts.SFProDisplaySemiBold}
              color={'var(--dark)'} marginBottom={16}/>
            <ComponentCircularProgress total={totalSubscription}
              expire={willExpire}
              renew={willRenew}/>
          </Col>
        </Row>}
        {this.isFreeBillingPlan() && billingProductList && <UpgradeModal
          name={billingProductList.length > 1
            ? billingProductList[1].name
            : ''}/>}
        <ModalOnBoard visible={this.state.showOnBoardingModal}
          handleClose={() => {
            this.setState({ showOnBoardingModal: false });
          }}/>
        <Row style={{ height: 20 }}/>
      </div>;
    }

    isFreeBillingPlan = () => {
      const { userData } = this.props;
      return userData &&  userData.subscription_product && userData.subscription_product.product.tier ===
          'SubscriptionTier_FREE';
    };

    prepareToTransactionURL = () => {
      let finalSearchParams = new URLSearchParams();
      let key = localStorage.getItem(localStorageKey.SELECTED_APP);
      finalSearchParams.set('application_ids', key ? key : 'all-app');
      let env = localStorage.getItem(localStorageKey.SELECTED_ENVIRONMENT);
      const value = env ? env : 'ENV_PRODUCTION';
      finalSearchParams.set('environment', value);
      finalSearchParams.set('page', '1');
      return transactionListPath + '?' + finalSearchParams.toString();
    };

    resendEmail = () => {
      this.props.onResendEmail();
      message.success('Email Sent');
    };

    toOnboard = () => {
      console.log('onboard');
      this.props.history.push('/onboard');
    };
}

const mapActionToProps = {
  onLoadData: loadData,
  onUserOnFreePlan: userOnFreePlan,
  onResendEmail: resendEmail,
};

const mapStateToProps = state => {
  const {
    transactionListRemoteData, metricsOverviewRemoteData, appsListRemoteData, productListRemoteData,
    accountDetailsRemoteData, metricsAutoRenewRemoteData, resendEmailRemoteData,
    metricsRevenueRemoteData, metricsMRRRemoteData, billingProductListRemoteData,
  } = state;
  return {
    appList: utils.getData(appsListRemoteData),
    productList: utils.getData(productListRemoteData),
    userData: utils.getItem(accountDetailsRemoteData),

    transactionData: utils.getData(transactionListRemoteData),
    isFetching: utils.getIsFetching(transactionListRemoteData),
    isFailed: utils.getIsFailed(transactionListRemoteData),

    metricsOverviewData: utils.getItem(metricsOverviewRemoteData),
    metricsIsFetching: utils.getIsFetching(metricsOverviewRemoteData) ||
          utils.getIsFetching(metricsMRRRemoteData) ||
          utils.getIsFetching(metricsRevenueRemoteData),
    metricsAutoRenewData: utils.getItem(metricsAutoRenewRemoteData),
    metricsAutoRenewIsFetching: utils.getIsFetching(
      metricsAutoRenewRemoteData),

    mrrData: utils.getMetrics(metricsMRRRemoteData),
    mrrMessage: utils.getMessage(metricsMRRRemoteData),
    revenueData: utils.getMetrics(metricsRevenueRemoteData),
    billingProductList: utils.getData(billingProductListRemoteData),
  };
};
export default connect(
  mapStateToProps,
  mapActionToProps,
)(Dashboard);

const titles = ['', 'Product', 'Revenue', 'Purchased', ''];
const fields = [
  'indicator',
  'product_name',
  'revenue',
  'purchased_at',
  'action',
];
const types = [
  tableContentType.INDICATOR,
  tableContentType.APP_PRODUCT,
  tableContentType.REVENUE,
  tableContentType.TIME_AGO,
  tableContentType.ACTION,
];

function InfoAction ({ icon, status, description, question, color, cancelable, buttonCSS, onResend, onClose }) {
  return <div><Space height={20}/>
    <div className={'info-action'}>
      <Row type={'flex'} justify={'space-between'} align={'middle'}>
        <Row type={'flex'} align={'middle'}>
          <img src={icon} width={28} alt={""}/>
          <Space width={12}/>
          <ComponentText text={status} size="14"
            font={fonts.SFProDisplayMedium}
            color={color}/>
          <Space width={4}/>
          <ComponentText text={description} size="14"
            font={fonts.SFProDisplayMedium}
            color={'var(--slate-grey)'}/>
        </Row>
        <Row type={'flex'} align={'middle'}>
          <div onClick={() => onResend()}><a><ComponentText
            text={question}
            font={fonts.SFProDisplaySemiBold}
            color={'var(--water-blue)'}
            size={14}
          /></a></div>
          <Space width={16}/>
          {cancelable ? <div onClick={() => onClose()}><a>
            <img src={images.dismiss} width={36} alt={""}/></a></div> : <div
            style={{ height: 36 }}/>}
        </Row>
      </Row>
    </div>
  </div>;

}

const ArrowIcon = props => <Icon component={arrowDownAlt} {...props} />;