import React from 'react';
import logo from '../../images/logo@3x.png';
import logoInvoice from '../../images/logo-invoice@3x.png';
import { Link } from 'react-router-dom';
import { localStorageKey } from '../../utils/LocalStorage';

export class Logo extends React.Component {

  render() {
    const query = new URLSearchParams(this.props.location.search);

    let finalSearchParams = new URLSearchParams();

    const token = query.get('application_ids');
    if(token){
      finalSearchParams.set("application_ids", token);
    }else{
      let key = localStorage.getItem(localStorageKey.SELECTED_APP);
      finalSearchParams.set("application_ids", key ? key : "all-app");
    }

    const {height, invoice} = this.props;
    return <Link to={"/?"+finalSearchParams}><img src={invoice ? logoInvoice : logo} height={height} alt={""}/></Link>;
  }
}
