import React from 'react';
import { connect } from 'react-redux';
import { Row, Spin, Table } from 'antd';
import { ComponentText } from '../../shared/ComponentText';
import Space from '../../shared/Spcae';
import {
  deleteData,
  loadData,
  postData,
  putData,
} from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import { contentType } from '../../../utils/ActionTypes';
import { overageListPath } from '../../../utils/routerUtils';
import { fonts } from '../../../utils/fonts';
import PlanLimitWarning from './PlanLimitWarning';
import { ComponentButton } from '../../shared/ComponentButton';
import CurrentPlan from './CurrentPlan';

class ComponentCostExplorer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModalForm: false, modalName: '' };
  }

  componentDidMount() {
    const { onLoadData} = this.props;
    onLoadData(contentType.OVERAGE_LIST, overageListPath, {pager: { skip: 0, limit: 15 }});
  }

  render() {

    const { overageData, accountInfo } = this.props;

    const spentMTR = Math.floor(accountInfo
      ? accountInfo.subscription_product.subscription_state.spent_mtr
      : 0);
    const limitMTR = Math.floor(accountInfo
      ? accountInfo.subscription_product.subscription.mtr_limit
      : 10000);
    const currentTier = accountInfo ? accountInfo.subscription_product.product.tier : '';

    let total = '';
    if (overageData) {
      overageData.forEach(e => {
        if ((limitMTR) >= e.lower_bound && (limitMTR) < e.upper_bound) {
          total = `= $${utils.formatNumber(
            Math.round((spentMTR - limitMTR) / e.unit) * e.price)}`;
        }
      });
    }
    let overageList = [];

    if (overageData) {
      let checked = Array(overageData.length);
      for (let i = 0; i < overageData.length; i++) {

        if (checked[i] === true)
          continue;

        let e = {
          id: overageData[i].id,
          lower_bound: overageData[i].lower_bound,
          upper_bound: overageData[i].upper_bound,
          unit: overageData[i].unit
        };

        for (let j = 0; j < overageData.length; j++) {
          if (overageData[i].lower_bound === overageData[j].lower_bound &&
            overageData[i].upper_bound === overageData[j].upper_bound) {
            if (overageData[j].tier === "SubscriptionTier_PRO") {
              e.analyze = {
                tier: overageData[j].tier,
                price: overageData[j].price
              };
              checked[j] = true;
            } else if (overageData[j].tier === "SubscriptionTier_UNLIMITED") {
              e.business = {
                tier: overageData[j].tier,
                price: overageData[j].price
              };
              checked[j] = true;
            }
          }
        }
        overageList.push(e);
      }
    }

    let data = overageData && overageList.map(e => {

      const currentPlan = (limitMTR) >= e.lower_bound && (limitMTR) < e.upper_bound;
      return  {
        key: e.id,
        mtr: `$${Math.floor(e.lower_bound/1000)}k - $${ (Math.floor(e.upper_bound/1000))}k`,
        plan_analyze: `$${e.analyze.price} per $${utils.formatNumber(e.unit)} MTR`,
        plan_business: `$${e.business.price} per $${utils.formatNumber(e.unit)} MTR`,
        is_overage: spentMTR < limitMTR,
        overage: `$${utils.formatNumber(spentMTR)} - ${utils.formatNumber(limitMTR)}`,
        overage_value: `= ${utils.formatNumber(spentMTR - limitMTR)}`,
        total: total,
        pay_now: this.payNow,
        currentPlan: currentPlan && spentMTR > limitMTR,
        currentTier: currentTier === "SubscriptionTier_PRO" ? "plan_analyze" : currentTier === "SubscriptionTier_UNLIMITED" ? "plan_business" : "",
      };
    });

    return (
      <div>
        <ComponentText text={"Cost Explorer"} font={fonts.SFProDisplaySemiBold} size={24} color={"var(--dark-three)"} marginBottom={30}/>
        <div className="cost-explorer-container">
          <CurrentPlan accountInfo={accountInfo}/>

          {spentMTR > limitMTR ? <PlanLimitWarning/>: null}

          <Row type={"flex"} justify={"space-between"}>
            <ComponentText
              font={fonts.SFProDisplaySemiBold}
              text="Overage Breakdown"
              size="15"
              color="var(--water-blue)"
              align="left"
            />
            <Row type={"flex"}>
              <div className={"limit-overage-applicable-rectangle"}/>
              <Space width={10}/>
              <ComponentText
                font={fonts.SFProDisplayMedium}
                text="Applicable to you"
                size="12"
                color="var(--cool-grey)"
                align="left"
              />
            </Row>
          </Row>
          <Space height={20}/>
          {data ? <Table className={"cost-explorer"} columns={spentMTR > limitMTR ? columns : columnsNoOverage} dataSource={data} bordered={false} pagination={false} /> :
            <Row style={{height:60}} type={"flex"} justify={"center"}><Spin/></Row>
          }
        </div>
      </div>
    );
  }

  payNow = () => {

  }
}

const mapActionToProps = {
  onLoadData: loadData,
  onPostData: postData,
  onPutData: putData,
  onDeleteData: deleteData,
};

const mapStateToProps = state => {
  const { accountDetailsRemoteData, overageRemoteData } = state;
  return {
    isFetching: utils.getIsFetching(accountDetailsRemoteData),
    data: utils.getItem(accountDetailsRemoteData),
    isFailed: utils.getIsFailed(accountDetailsRemoteData),
    overageData: utils.getData(overageRemoteData),
  };
};

export default connect(mapStateToProps, mapActionToProps)(ComponentCostExplorer);

const columns = [
  {
    title: 'MTR',
    dataIndex: 'mtr',
    render: (text, row) => {
      return <div className={row.currentPlan ? "mtr-current-plan": "cost-explorer-default"}>
        <ComponentText text={text} font={fonts.SFProDisplayMedium} size={14} color={row.currentPlan ? "var(--water-blue)": "var(--slate-grey)"}/>
      </div>;
    },
  },
  {
    title: 'Analyze',
    dataIndex: 'plan_analyze',
    render: (text, row) => {
      return <div className={row.currentPlan ? (row.currentTier === 'plan_analyze' ? "mtr-current-plan-analyze" : "cost-explorer-default" ): "cost-explorer-default"}>
        <ComponentText text={text} font={fonts.SFProDisplayMedium} size={14}
          color={row.currentPlan && row.currentTier === 'plan_analyze' ?"var(--water-blue)": "var(--slate-grey)"}/>
      </div>;
    },
  },
  {
    title: 'Business',
    dataIndex: 'plan_business',
    render: (text, row) => {
      return <div className={row.currentPlan ? (row.currentTier === 'plan_business' ? "mtr-current-plan-business" : "cost-explorer-default" ): "cost-explorer-default"}>
        <ComponentText text={text} font={fonts.SFProDisplayMedium} size={14}
          color={row.currentPlan && row.currentTier === 'plan_business' ?"var(--water-blue)": "var(--slate-grey)"}/>
      </div>;
    },
  },
  {
    title: 'Your Overage',
    dataIndex: 'overage',
    className: "mtr-overage-cell",
    render: (text, row, index) => {
      const obj = {
        children: row.is_overage !== false ?
          <ComponentText text={'No Overage'} font={fonts.SFProDisplayMedium} size={14} color={"var(--slate-grey)"} align={'center'}/> :
          <div style={{textAlign: 'center'}}>
            <ComponentText text={"(Usage - Base MTR)"} font={fonts.SFProDisplayMedium} size={14} color={"var(--slate-grey)"} align={'center'} marginBottom={6}/>
            <ComponentText text={text} font={fonts.SFProDisplayMedium} size={14} color={"var(--slate-grey)"} align={'center'} marginBottom={6}/>
            <ComponentText text={row.overage_value} font={fonts.SFProDisplayMedium} size={14} color={"var(--slate-grey)"} align={'center'}/>
            <Space height={20}/>
            <ComponentText text={"Total Overage"} font={fonts.SFProDisplaySemiBold} size={13} color={"var(--water-blue)"} align={'center'} marginBottom={6}/>
            <ComponentText text={row.total} font={fonts.SFProDisplaySemiBold} size={13} color={"var(--water-blue)"} align={'center'}/>
            <Space height={10}/>
            <ComponentButton name="pay-now" text="Pay Now" css="dark-sky-blue eina-semibold-white-15" width="130" height="42" handleClick={row.payNow} />
          </div>
        ,
        props: {},
      };
      if (index === 0) {
        obj.props.rowSpan = 5;
      }
      // These two are merged into above cell
      else {
        obj.props.rowSpan = 0;
      }
      return obj;
    },
  },
];

const columnsNoOverage = [
  {
    title: 'MTR',
    dataIndex: 'mtr',
    render: (text, row) => {
      return <div className={row.currentPlan ? "mtr-current-plan": "cost-explorer-default"}>
        <ComponentText text={text} font={fonts.SFProDisplayMedium} size={14}
          color={row.currentPlan ? "var(--water-blue)": "var(--slate-grey)"}/>
      </div>;
    },
  },
  {
    title: 'Analyze',
    dataIndex: 'plan_analyze',
    render: (text, row) => {
      return <div className={row.currentPlan ? "mtr-current-plan": "cost-explorer-default"}>
        <ComponentText text={text} font={fonts.SFProDisplayMedium} size={14}
          color={row.currentPlan ?"var(--water-blue)": "var(--slate-grey)"}/>
      </div>;
    },
  },
  {
    title: 'Business',
    dataIndex: 'plan_business',
    render: (text, row) => {
      return <div className={row.currentPlan ? "mtr-current-plan": "cost-explorer-default"}>
        <ComponentText text={text} font={fonts.SFProDisplayMedium} size={14}
          color={row.currentPlan ?"var(--water-blue)": "var(--slate-grey)"}/>
      </div>;
    },
  },
  {
    title: 'Your Overage',
    dataIndex: 'overage',
    className: "mtr-no-overage-cell",
    render: (text, row, index) => {
      const obj = {
        children: row.is_overage !== false ?
          <ComponentText text={'No Overage'} font={fonts.SFProDisplayMedium} size={14} color={"var(--slate-grey)"} align={'center'}/> :
          <div style={{textAlign: 'center'}}>
            <ComponentText text={"(Usage - Base MTR)"} font={fonts.SFProDisplayMedium} size={14} color={"var(--slate-grey)"} align={'center'} marginBottom={6}/>
            <ComponentText text={text} font={fonts.SFProDisplayMedium} size={14} color={"var(--slate-grey)"} align={'center'} marginBottom={6}/>
            <ComponentText text={row.overage_value} font={fonts.SFProDisplayMedium} size={14} color={"var(--slate-grey)"} align={'center'}/>
            <Space height={20}/>
            <ComponentText text={"Total Overage"} font={fonts.SFProDisplaySemiBold} size={13} color={"var(--water-blue)"} align={'center'} marginBottom={6}/>
            <ComponentText text={row.total} font={fonts.SFProDisplaySemiBold} size={13} color={"var(--water-blue)"} align={'center'}/>
            <Space height={10}/>
            <ComponentButton name="pay-now" text="Pay Now" css="dark-sky-blue eina-semibold-white-15" width="130" height="42" handleClick={row.payNow} />
          </div>
        ,
        props: {},
      };
      if (index === 0) {
        obj.props.rowSpan = 5;
      }
      // These two are merged into above cell
      else {
        obj.props.rowSpan = 0;
      }
      return obj;
    },
  },
];



