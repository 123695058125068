export const boilerplateListPath = "/boilerplate/list";
export const getBoilerplateDetailsPath = (id) => `/boilerplate/${id}`;
export const boilerplateDetailsPath = "/boilerplate/:id";
export const boilerplateCreatePath = "/boilerplate/create";

export const customerListPath = "/customer/list";
export const getCustomerList = "listappusers";
export const getCustomerDetailsPath = (id) => `/customer/${id}`;
export const getCustomerDetails = `appuserdetails`;
export const getCustomerTotalSpent = (id) => `totalspent/${id}`;
export const getCustomerProductGroupList = (id) => `userproductgroups/${id}`;
export const customerDetailsPath = "/customer/:id";
export const customerCreatePath = "/customer/create";

export const transactionListPath = "/transaction/list";
export const getTransactionListPath = "listtransactions";
export const getTransactionSearchPath = "searchtransactions";
export const getTransactionDetails = (id) => `/transaction/${id}`;
export const getTransactionDetailsPath = (id) => `transaction/${id}`;
export const transactionDetailsPath = "/transaction/:id";

export const documentListPath = "/document/list";
export const getDocumentDetailsPath = (id) => `/document/${id}`;
export const documentDetailsPath = "/document/:id";
export const documentCreatePath = "/document/create";

export const supportListPath = "/support/list";
export const getSupportDetailsPath = (id) => `/support/${id}`;
export const supportDetailsPath = "/support/:id";
export const supportCreatePath = "/support/create";

export const accountDetailsPath = "/account";
export const getCollaboratorListPath = "listcustomers";
export const getAccountDetailsPath = `user`;
export const accountCreatePath = "/account/create";
export const getCollaboratorUpdate = (id) => `account?menu=3&update=${id}`;
export const getCollaboratorResend = (id) => `account?menu=3&resend=${id}`;
export const getCollaboratorDelete = (id) => `account?menu=3&remove=${id}`;

export const getCollaboratorDeletePath = (organizationId, id) => `removeuser/${organizationId}/${id}`;
export const getCollaboratorCreatePath = 'inviteuser';
export const getCollaboratorResendPath = 'resend';
export const getCollaboratorUpdatePath = `modifyaccess`;

export const getBillingReceiptListPath = 'subscription/receipthistory';
export const getBillingProductListPath = 'subscription/listproducts';
export const getBillingPurchasePath = 'purchase';

export const subscriptionListPath = "/subscription/list";
export const getSubscriptionDetailsPath = (id) => `/subscription/${id}`;
export const subscriptionDetailsPath = "/subscription/:id";
export const subscriptionCreatePath = "/subscription/create";
export const overageListPath = "subscription/listoverages";

export const appsListPath = "/app/list";
export const appsListApiPath = `listapplications`;
export const getAppsDetailsPath = (id) => `application/${id}`;
export const appsDetailsPath = "/app/:id";
export const appsCreatePath = "/app/create";
export const getWebHookTestPath = `testwebhook`;

export const getStoreDetailsPath = (id) => `store/${id}`;

export const appSecretCreatePath = "applicationsecret";
export const appSecretListPath = "listapplicationsecrets";

export const packageCreatePath = "package";
export const getPackageUpdatePath = (id) => `package/${id}`;
export const productActivatePath = "product/activate";
export const productCreatePath = "product";
export const getProductUpdatePath = (id) => `product/${id}`;
export const productGroupCreatePath = "productgroup";
export const getProductGroupUpdatePath = (id) => `productgroup/${id}`;
export const productGroupListPath = "listproductgroups";
export const productListPath = "listproducts";
export const productDeletePath = "listproducts";
export const getMetricsOverviewPath = "metrics/overview";
export const getMetricsAutoRenewPath = `metrics/autorenewdetails`;


export const metricsRevenuePath = "/metrics/revenue";
export const metricsRevenueApiPath = "metrics/revenue";

export const metricsMRRPath = "/metrics/mrr";
export const metricsMRRApiPath = "metrics/mrr";

export const metricsActivePurchasePath = "/metrics/activepurchaseandtrials";
export const metricsActivePurchaseApiPath = "metrics/activepurchaseandtrials";

export const metricsTrialConversionPath = "/metrics/trialconversion";
export const metricsTrialConversionApiPath = "metrics/trialconversion";

export const metricsChurnPath = "/metrics/churn";
export const metricsChurnApiPath = "metrics/churn";

export const metricsNewPurchasePath = "/metrics/newpurchaseandtrials";
export const metricsNewPurchaseApiPath = "metrics/newpurchaseandtrials";

export const metricsEventCountPath = "/metrics/eventcount";
export const metricsEventCountApiPath = "metrics/eventcount";

export const paymentMethodListPath = "subscription/listcards";
export const paymentMethodCreatePath = "subscription/addcard";
export const paymentMethodActivatePath = "subscription/activatecard";
export const paymentMethodDeletePath = (organizationID, id) => `subscription/${organizationID}/removecard/${id}`;

export const  subscriptionPurchasePath = "subscription/purchase";
export const subscriptionConfirmPath = "subscription/confirmpayment";

export const resendEmailApiPath = "resend";
export const forgetPasswordApiPath = "forgetpassword";
export const resetPasswordApiPath = "resetpassword";
export const verifyUserApiPath = "verifyuser";

export const alertListPath = "/alert/list";
export const getAlertListPath = "listalerts";

export const alertCreatePath = "/alert/create";
export const getAlertCreatePath = "alert";

export const alertUpdatePath = "/alert/:id";
export const getAlertUpdate = (id) => `/alert/${id}`;
export const getAlertUpdatePath = (id) => `alert/${id}`;
export const getAlertDeletePath = (id) => `/alert/${id}`;

/*_add_from_here*/
