import React from 'react';
import { connect } from 'react-redux';
import { ComponentText } from '../../shared/ComponentText';
import listUtils from '../../../utils/listUtils';
import { deleteData, loadData, postData } from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import Space from '../../shared/Spcae';
import { contentType } from '../../../utils/ActionTypes';
import {
  paymentMethodActivatePath,
  paymentMethodCreatePath,
  paymentMethodDeletePath,
  paymentMethodListPath,
} from '../../../utils/routerUtils';
import { fonts } from '../../../utils/fonts';
import { Col, Row, Spin } from 'antd';
import { ComponentButton } from '../../shared/ComponentButton';
import images from '../../../images/Images';
import moment from 'moment';
import AddPaymentModal from '../../shared/modals/AddPaymentModal';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { getUser } from '../../../utils/Repository';

export const stripePromise = loadStripe('pk_test_JlUzuu3KmFDR3wxvlKLxDaYX');
export function getCardText (e) {
  let cardText = '';
  if (e.brand === 'visa') {
    cardText += 'Visa';
  } else {
    cardText += 'MasterCard';
  }
  cardText += ` ending in `;
  return cardText;
}

export function isCardDefault (e) {
  return e.status === 'STATUS_ACTIVE' ? 'Default' : '';
}

export function getExpDate (e) {
  return moment(e.exp_year).format("MM/YYYY");
}

class ComponentPaymentMethod extends React.Component {

  constructor (props) {
    super(props);
    this.state = {paymentModalVisible: false, cardList: [], deletedID: ""};
  }

  componentDidMount() {
    const {onLoadData} = this.props;

    onLoadData(
      contentType.PAYMENT_METHOD_LIST,
      paymentMethodListPath,
      listUtils.prepareActionParams(1, 10, [])
    );
  }

  onModalClose = (values) => {
    this.setState({
      paymentModalVisible: false
    });
    if(!values)
      return;


    const {onPostData} = this.props;
    const user = getUser();
    onPostData(
      contentType.PAYMENT_METHOD_CREATE,
      paymentMethodCreatePath,
      {
        pm_id: values.pm_id,
        organization_id: user.organizations_roles[0].organization_id,
      }
    );
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    if(this.props.cardList && this.props.cardList !== prevProps.cardList){
      this.setState({cardList: this.props.cardList});
    }
    if(this.props.createData && this.props.createData !== prevProps.createData){
      // const cardList = _.cloneDeep(this.state.cardList);
      // let f = false;
      // for(let i = 0; i< cardList.length; i++){
      //   if(cardList[i].id === this.props.createData.id ){
      //     cardList[i].status = "STATUS_ACTIVE";
      //     f = true;
      //   }else{
      //     cardList[i].status = "STATUS_INACTIVE";
      //   }
      // }
      // if(!f){
      //   cardList.push(this.props.createData);
      // }
      // this.setState({ cardList: cardList });
      this.componentDidMount();
    }
    if(this.props.deletedData && this.state.deletedID !== "" && this.props !== prevProps.deletedData){
      // const cardList = _.cloneDeep(this.state.cardList);
      // for(let i = 0; i< cardList.length; i++){
      //   if(cardList[i].id === this.state.deletedID){
      //     cardList.splice(i,1);
      //     this.setState({cardList: cardList,deletedID:"" });
      //     break;
      //   }
      // }
      this.setState({deletedID:""},()=> this.componentDidMount());
    }
    if(this.props.activateData && this.props.activateData !== prevProps.activateData){
      // const cardList = _.cloneDeep(this.state.cardList);
      // for(let i = 0; i< cardList.length; i++){
      //   if(cardList[i].id === this.props.activateData.id ){
      //     cardList[i].status = "STATUS_ACTIVE";
      //   }else{
      //     cardList[i].status = "STATUS_INACTIVE";
      //   }
      // }
      // this.setState({cardList: cardList})
      this.componentDidMount();
    }
  }

  render() {
    let {isFetching, isFailed, pager, cardList} = this.props;

    return <Spin spinning={isFetching === true}>
      <div className={"payment-cards"}>
        <Row type={"flex"} justify={"space-between"}  align={"middle"}>
          <ComponentText
            text="Payment Method"
            font={fonts.SFProDisplaySemiBold}
            color={"var(--water-blue)"}
            size={15}
          />
          <ComponentButton
            name="add-payment-method"
            text="Add a payment method"
            iconLeft="plus"
            iconSize={12}
            antd={true}
            css="add-payment-method"
            handleClick={this.showAddPaymentModal}
          />
        </Row>

        <Space height={12}/>
        {this.state.cardList.map(e => <Row key={e.id} justify="space-between" type="flex" align="middle" className={"payment-method-row"}>
          <Col span={9}>
            <Row type="flex">
              <img src={this.getCardImage(e)} width={30}/>
              <Space width={10}/>
              <ComponentText text={getCardText(e)}
                color="var(--slate-grey)"
                font={fonts.SFProDisplayRegular}
                size={14} />
              <Space width={4}/>
              <ComponentText text={e.number}
                color="var(--slate-grey)"
                font={fonts.SFProDisplaySemiBold}
                size={14} />
              <Space width={10}/>
              <ComponentText text={isCardDefault(e)}
                color="var(--dark-sky-blue)"
                font={fonts.SFProDisplayMedium}
                size={14} />
            </Row>
          </Col>
          <Col span={4}>
            <Row type="flex">
              <ComponentText text={"Exp date"}
                color="var(--slate-grey)"
                font={fonts.SFProDisplayRegular}
                size={14} />
              <Space width={4}/>
              <ComponentText text={getExpDate(e)}
                color="var(--slate-grey)"
                font={fonts.SFProDisplaySemiBold}
                size={14} />
            </Row>
          </Col>
          <Col span={9}>
            <Row type="flex" justify={"end"}>
              {e.status !== "STATUS_ACTIVE" && <ComponentButton  name="remove-card" text="Remove Card" css="remove-card" width="120" height="32" handleClick={()=>this.removeCard(e.id)}/>}
              <Space width={10}/>
              {e.status !== "STATUS_ACTIVE" && <ComponentButton  name="make-default" text="Make Default" css="make-default-card" width="120" height="32" handleClick={()=>this.makeActive(e.id)}/>}
            </Row>
          </Col>
        </Row>)}

        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({stripe, elements}) => (
              <AddPaymentModal
                visible={this.state.paymentModalVisible}
                handleClose={this.onModalClose}
                stripe={stripe}
                elements={elements}
              />
            )}
          </ElementsConsumer>
        </Elements>
      </div>
    </Spin>;
  }

  showAddPaymentModal = () =>{
    this.setState({paymentModalVisible: true});
  };

  removeCard = (id) => {
    const {onDeleteData} = this.props;
    const user = getUser();
    this.setState({deletedID: id}, ()=> {
      onDeleteData(contentType.PAYMENT_METHOD_DELETE,
        paymentMethodDeletePath(user.organizations_roles[0].organization_id,
          id));
    });

  };

  makeActive = (id) => {
    const { onPostData } = this.props;
    const user = getUser();
    onPostData(contentType.PAYMENT_METHOD_ACTIVATE,
      paymentMethodActivatePath, {
        id: id,
        organization_id: user.organizations_roles[0].organization_id
      });
  }

  getCardImage = (e) => {
    let iconRef = '';
    if (e.brand === 'visa') {
      iconRef = images.visaCard;
    } else {
      iconRef = images.masterCard;
    }
    return iconRef;
  }
}


const mapActionToProps = {
  onLoadData: loadData,
  onPostData: postData,
  onDeleteData: deleteData,
};

const mapStateToProps = state => {
  const {paymentMethodRemoteData, paymentMethodCreateData, paymentMethodActivateData, paymentMethodDeleteData} = state;
  return {
    cardList : utils.getData(paymentMethodRemoteData),
    pager: utils.getPager(paymentMethodRemoteData),
    isFetching: utils.getIsFetching(paymentMethodRemoteData) || utils.getIsFetching(paymentMethodCreateData)
      || utils.getIsFetching(paymentMethodActivateData) || utils.getIsFetching(paymentMethodDeleteData),
    isFailed: utils.getIsFailed(paymentMethodRemoteData),

    createData: utils.getItem(paymentMethodCreateData),
    deletedData: utils.getItem(paymentMethodDeleteData),
    activateData: utils.getItem(paymentMethodActivateData),
  };
};
export default connect(
  mapStateToProps,
  mapActionToProps
)(ComponentPaymentMethod);


