import { InputTypes } from '../../../utils/InputTypes';
import { prepareInput } from '../../../utils/InputFieldCongifurations';

export const ModalTypes = {
  ADD_PRODUCT_GROUP: 'add-product-group',
  EDIT_PRODUCT_GROUP: 'edit-product-group',
  ADD_PACKAGE: 'add-package',
  EDIT_PACKAGE: 'edit-package',
  ADD_PRODUCT: 'add-product',
  EDIT_PRODUCT: 'edit-product',
  PRICE_HISTORY: 'price-history',
};
export const productGroupModal = (edit, name) => {

  return {
    title: (edit ? "Edit" : "Add") + " Product Group",
    acceptButtonTitle: (edit ? "Edit" : "Add") + " Product Group",
    buttonCancel: true,
    buttonDelete: !!edit,
    showWarning: !!edit,
    warningTitle: "group",
    items: [
      {
        type: InputTypes.INPUT,
        value: prepareInput('name', 'Product Group Display Name', true, 'e.g. Pro Access', '', 'Enter Name', '', name),
      },
      // {
      //     type: InputTypes.INPUT,
      //     value: prepareInput('product_group_display_name', 'Product Group Display Name', true, 'e.g. Pro Access', '', 'Enter Display Name', '', v2),
      // },
    ],
  };};
export const packageModal = (edit, name) => ({
  title: (edit ? "Edit" : "Add") + " Package",
  acceptButtonTitle: (edit ? "Edit" : "Add") + " Package",
  buttonCancel: true,
  buttonDelete: !!edit,
  showWarning: !!edit,
  warningTitle: "package",
  items: [

    // {
    //     type: InputTypes.INPUT,
    //     value: prepareInput('package_identifier', 'Package Identifier', true, 'e.g. Monthly', 'Used to access the package via the SDK, cannot be edited once created', 'Enter Odentifier', '', v1),
    // },
    {
      type: InputTypes.INPUT,
      value: prepareInput('name', 'Package Display Name', true, 'e.g. Monthly Plan', '', 'Enter Display Name', '', name),
    },
  ],
});
export const productModal = (stores) => {

  const list = stores['SOURCE_APP_STORE'] &&  stores['SOURCE_PLAY_STORE'] ? [ { title: 'App Store', value: 'SOURCE_APP_STORE' }, { title: 'Play Store', value: 'SOURCE_PLAY_STORE' }] :
    stores['SOURCE_APP_STORE'] ? [{ title: 'App Store', value: 'SOURCE_APP_STORE' }] : [{ title: 'Play Store', value: 'SOURCE_PLAY_STORE' }];


  return {
    title: "Add Product",
    acceptButtonTitle: "Add Product",
    buttonCancel: true,
    items: [
      {
        type: InputTypes.INPUT,
        value: prepareInput('name', 'Product Name', true, 'Enter Product Name', '', 'Enter Product Name', ''),
      },
      {
        type: InputTypes.INPUT,
        value: prepareInput('external_id', 'Product Identifier', true, 'e.g. com.yourapp.products.monthly', 'Used to access the package via the SDK, cannot be edited once created', 'Enter Product Identifier', ''),
      },
      {
        type: InputTypes.SELECT,
        // value: prepareInput('store', 'Store', true, 'Select Store', '', 'Select Store', ['SOURCE_APP_STORE', 'SOURCE_GOOGLE_PLAY'], store),
        value: prepareInput('store', 'Store', true, 'Select Store', '', 'Select Store', list),
      },
      // {
      //   type: InputTypes.SELECT,
      //   value: prepareInput('type', 'Type', true, 'Select Store', '', 'Select Store', [{ title: 'Subscription', value: 'TYPE_SUBSCRIPTION' }, { title: 'In-app', value: 'INNAP' }], type),
      // },
    ],
    showInfo: true
  };
};

export const productModalWithType = (stores) => {

  const list = stores['SOURCE_APP_STORE'] &&  stores['SOURCE_PLAY_STORE'] ? [ { title: 'App Store', value: 'SOURCE_APP_STORE' }, { title: 'Play Store', value: 'SOURCE_PLAY_STORE' }] :
    stores['SOURCE_APP_STORE'] ? [{ title: 'App Store', value: 'SOURCE_APP_STORE' }] : [{ title: 'Play Store', value: 'SOURCE_PLAY_STORE' }];


  return {
    title: "Add Product",
    acceptButtonTitle: "Add Product",
    buttonCancel: true,
    items: [
      {
        type: InputTypes.INPUT,
        value: prepareInput('name', 'Product Name', true, 'Enter Product Name', '', 'Enter Product Name', ''),
      },
      {
        type: InputTypes.INPUT,
        value: prepareInput('external_id', 'Product Identifier', true, 'e.g. com.yourapp.products.monthly', 'Used to access the package via the SDK, cannot be edited once created', 'Enter Product Identifier', ''),
      },
      {
        type: InputTypes.SELECT,
        // value: prepareInput('store', 'Store', true, 'Select Store', '', 'Select Store', ['SOURCE_APP_STORE', 'SOURCE_GOOGLE_PLAY'], store),
        value: prepareInput('store', 'Store', true, 'Select Store', '', 'Select Store', list),
      },
      {
        type: InputTypes.SELECT,
        value: prepareInput('type', 'Type', true, 'Select Store', '', 'Select Store', [{ title: 'Consumable', value: 'TYPE_CONSUMABLE' }], "TYPE_CONSUMABLE"),
      },
    ],
    showInfo: true
  };
};
export const productUpdateModal = (name) => ({
  title: "Edit Product",
  acceptButtonTitle: "Edit Product",
  buttonCancel: true,
  buttonDelete: false,
  items: [
    {
      type: InputTypes.INPUT,
      value: prepareInput('name', 'Product Name', true, 'e.g. monthly', '', 'Enter Product Name', '', name),
    },
  ],
});