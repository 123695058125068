import React from 'react';
import { Col, Form, Modal, Row } from 'antd';
import { SelectComponent } from '../FormComponent/SelectComponent';
import { ComponentButton } from '../ComponentButton';
import Space from '../Spcae';
import { ComponentText } from '../ComponentText';
import { fonts } from '../../../utils/fonts';
import { prepareInput } from '../../../utils/InputFieldCongifurations';

class ModalUpdateRole extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.form.resetFields();
        values["id"] = this.props.collaborator.id;
        values["email"] = this.props.collaborator.email;
        this.props.handleClose(values);
      }
    });
  };

  handleCancel = () => {
    this.props.form.resetFields();
    this.props.handleClose();
  };

  render() {
    const { visible, collaborator } = this.props;
    const role = collaborator && collaborator.role? collaborator.role:'';
    const value =  prepareInput('role', 'Role', true, 'Select Role', '', 'Select Role', [{ title: 'Admin', value: 'ROLE_ADMIN' }, { title: 'Member', value: 'ROLE_MEMBER' }], role);
    return (
      <Modal
        className="apps"
        title={
          <ComponentText
            text={"Update Role"}
            color="var(--black)"
            align="left"
            marginTop="0"
            marginBottom="0"
            styl={{}}
          />
        }
        centered
        visible={visible}
        closable={false}
        footer={null}
        onCancel={() => this.handleCancel(false)}
      >
        <Form onSubmit={this.handleSubmit} layout="vertical">
          <Row type={"flex"}>
            <Col span={12}>
              <ComponentText text={"Name"} font={fonts.SFProDisplayMedium} size={13} color={"var(--greyish-brown)"}/>
              <ComponentText text={collaborator.name} font={fonts.SFProDisplaySemiBold} size={15} color={"var(--black)"}/>
            </Col>
            <Col span={12}>
              <ComponentText text={"Email"} font={fonts.SFProDisplayMedium} size={13} color={"var(--greyish-brown)"}/>
              <ComponentText text={collaborator.email} font={fonts.SFProDisplaySemiBold} size={15} color={"var(--black)"}/>
            </Col>
          </Row>
          <Space height={32}/>

          <SelectComponent
            key={"select-role"}
            form={this.props.form}
            layout={formItemLayout}
            values={value}
          />

          <Space height="10" />
          <Row type="flex" justify="end" align="middle">

            <Form.Item className="no-bottom-margin">
              <ComponentButton
                htmlType="button"
                name="cancel"
                text="Cancel"
                css="stroke-cloudy-blue eina-semibold-cool-grey-15"
                width="122"
                height="42"
                stylText={{color: 'var(--cool-grey)', fontSize: '15px', fontWeight: 600}}
                handleClick={this.handleCancel}
              />
            </Form.Item>
            <Space width="10" />
            <Form.Item className="no-bottom-margin">
              <ComponentButton
                htmlType="submit"
                name={"Update Role"}
                text={"Update Role"}
                css="dark-sky-blue eina-semibold-white-15"
                width="186"
                height="42"
              />
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    );
  }
}

const ComponentModalUpdateRole = Form.create({ name: 'modal-form' })(ModalUpdateRole);

export default ComponentModalUpdateRole;

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};
