import React from 'react';
import { Divider, Form, message, Row, Spin } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { InputComponent } from '../shared/FormComponent/InputComponent';

import { prepareInput } from '../../utils/InputFieldCongifurations';
import { PasswordComponent } from '../shared/FormComponent/PasswordComponent';
import { connect } from 'react-redux';
import { register } from './authActionCreators';
import { ComponentText } from '../shared/ComponentText';
import Space from '../shared/Spcae';
import { ComponentButton } from '../shared/ComponentButton';
import './Auth.css';
import { fonts } from '../../utils/fonts';
import { TopBar } from './Topbar';
import utils from '../../utils/utils';

const Styled = {};

Styled.Container = styled.div`
  display: flex;
  justify-content: center;
`;

Styled.wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 482px;
  justify-content: center;
  margin-top: 120px;
  padding-bottom: 46px;
  background: var(--white);
  border: solid 1px #e5eef5;
  border-color: '#6c727b';
  border-radius: 4;
  padding-top: 32;
`;

Styled.Header = styled.div`
  display: flex;
  flex-direction: column;
`;

Styled.HeaderTitle = styled.div`
  margin: 54px 120px 11px;
  text-align: center;
`;

Styled.HeaderText = styled.div`
  margin: 0 75px 8px;
  text-align: center;
`;

Styled.FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 60px;
`;

Styled.FirstInputWrapper = styled.div`
  margin-top: 8px;
`;

Styled.InputWrapper = styled.div`
  margin-top: 6px;
`;

Styled.passwordWrapper = styled.div`
  margin-top: 6px;
`;

Styled.FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -3px;
`;

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    //const token = query.get('token');
    const email = query.get('email');
    const organizationName = query.get('organization_name');

    this.state = {
      name: prepareInput('name', 'Full Name', true, 'Full Name', '', 'Enter Name'),
      email: prepareInput(
        'email',
        'Email Address',
        true,
        'Email Address',
        '',
        'Enter Email',
        '',
        email ? email : '',
        'onBlur'
      ),
      organization_name: prepareInput(
        'organization_name',
        'Organization Name',
        true,
        'Dropbox',
        '',
        'Enter organization name',
        '',
        organizationName ? organizationName : '',
      ),
      password: prepareInput('password', 'Password', true, 'Enter Password', '', 'Enter Password'),
    };
  }

  componentDidMount() {
    if (utils.isLoggedIn(this.props.loginData)) {
      this.navigateToDashboard();
    }
  }

  success = text => {
    message.success(text);
  };

  error = text => {
    if (text === null) return;
    message.error(text);
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { data, signUpMessage, isFailed } = this.props;

    console.log(signUpMessage);
    if (isFailed === true) {
      if (prevProps.signUpMessage !== signUpMessage) this.error(signUpMessage);
    }

    //if (prevProps.isLoggedIn === isLoggedIn) return;

    if (data && prevProps.data !== data) {
      this.success("Logged In");
      this.navigateToDashboard();
    }

    // if (isLoggedIn) {
    //   this.success(message);
    //   this.navigateToDashboard();
    // }
  }


  navigateToDashboard =()=> {
    this.props.history.push('/?application_ids=all-app');
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.register(values);
      }
    });
  };

  render() {
    const { isFetching } = this.props;
    return (
      <Spin spinning={!!isFetching}>
        <TopBar/>
        <div style={{height:window.innerHeight-71, backgroundColor:"#fafafa"}}>
          <Row justify={"center"} type={"flex"}>
            <div className={"login-container"}>
              <ComponentText
                text="Sign Up with Purchasefly"
                size="23"
                color="var(--dark-grey-blue-two)"
                font={fonts.SFProDisplayRegular}
              />
              <Space height={12}/>
              <ComponentText
                text="We make your in-app subscription business fly."
                size="15"
                color="var(--cool-grey)"
                font={fonts.SFProDisplayRegular}
              />

              <Divider />

              <Styled.FormWrapper>
                <Form onSubmit={this.handleSubmit} layout="vertical">
                  <Styled.FirstInputWrapper>
                    <InputComponent
                      form={this.props.form}
                      layout={formItemLayout}
                      values={this.state.name}
                    />
                  </Styled.FirstInputWrapper>

                  <Styled.InputWrapper>
                    <InputComponent
                      disabled={!!this.state.email.value}
                      form={this.props.form}
                      layout={formItemLayout}
                      values={this.state.email}
                    />
                  </Styled.InputWrapper>
                  <Styled.InputWrapper>
                    <PasswordComponent
                      form={this.props.form}
                      layout={formItemLayout}
                      values={this.state.password}
                    />
                  </Styled.InputWrapper>
                  <Styled.InputWrapper>
                    <InputComponent
                      disabled={!!this.state.organization_name.value}
                      form={this.props.form}
                      layout={formItemLayout}
                      values={this.state.organization_name}
                    />
                  </Styled.InputWrapper>

                  <Form.Item className="no-bottom-margin">
                    <ComponentButton
                      htmlType="submit"
                      name="sign-up"
                      text="Sign Up"
                      css="dark-sky-blue eina-semibold-white-15"
                      height="42"
                    />
                  </Form.Item>
                </Form>

                <Styled.FooterWrapper>
                  <ComponentText
                    text="Already have an account? "
                    size="13"
                    color="var(--greyblue)"
                  />
                  <Space width="5" />
                  <Link to={'login'}>
                    <ComponentText text="Sign In Now" size="13" color="var(--azure)" />
                  </Link>
                </Styled.FooterWrapper>
              </Styled.FormWrapper>
            </div>
          </Row>
        </div>
      </Spin>
    );
  }
}

const SignUp = Form.create({ name: 'sign-up' })(SignUpForm);

const mapActionToProps = {
  register,
};

// const mapStateToProps = state => ({
//   isLoggedIn: !!state.auth.token,
//   isAuthenticating: state.auth.isAuthenticating,
//   isAuthenticated: state.auth.isAuthenticated,
//   isAuthFailed: state.auth.isAuthFailed,
//   message: state.auth.message,
// });

const mapStateToProps = state => {
  const { signUpRemoteData, loginRemoteData} = state;

  console.log(signUpRemoteData);
  console.log(loginRemoteData);

  return {
    loginData: utils.getItem(loginRemoteData),
    data: utils.getItem(signUpRemoteData),
    isFetching: utils.getIsFetching(signUpRemoteData),
    isFailed: utils.getIsFailed(signUpRemoteData),
    signUpMessage: utils.getMessage(signUpRemoteData),
  };
};


export default connect(mapStateToProps, mapActionToProps)(SignUp);

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};
