import React from 'react';
import { Form, message, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import { verifyUser } from './authActionCreators';
import { ComponentText } from '../shared/ComponentText';
import verifyEmail from './../../images/verify-email.png';
import { TopBar } from './Topbar';
import { fonts } from '../../utils/fonts';
import images from '../../images/Images';
import Space from '../shared/Spcae';
import utils from '../../utils/utils';

class ForgotPassForm extends React.Component {

  componentDidMount() {
    if (utils.isLoggedIn(this.props.loginData)) {
      this.props.history.push('/dashboard');
    }
  }


  componentDidUpdate (prevProps, prevState, snapshot) {
    const { isFailed, data } = this.props;

    if (isFailed === true) {
      this.error("Account verification failed");
    }
    else if(data && data !== prevProps.data){
      this.success("Account verified");
      this.props.history.push('/login');
    }
  }

  handleSubmit = () => {

    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('token');
    //const email = query.get('email');
    this.props.verifyUser(token);

  };

  success = text => {
    message.success(text);
  };

  error = text => {
    if (text === null) return;
    message.error(text);
  };

  render() {
    const { isFetching} = this.props;

    return (
      <Spin spinning={!!isFetching} >
        <TopBar/>
        <div style={{height:window.innerHeight - 71, backgroundColor:"#fafafa"}}>
          <Row justify={"center"} type={"flex"}>
            <div className={"verify-email-container"}>

              <Row type={"flex"} justify={"center"}>
                <img src={verifyEmail} alt={""} />
              </Row>
              <Space height={36}/>
              <ComponentText text="Thank you for signing up!" size="23" color="var(--black)" align={"center"} font={fonts.SFProDisplayRegular}/>
              <Space height={12}/>
              <Row type={"flex"} justify={"center"}>
                <div style={{width:330}}>
                  <ComponentText
                    text="We’ve sent you a verification email in your inbox. Please check and verify your account now 😊"
                    size="15"
                    color="var(--cool-grey)"
                    align={"center"} font={fonts.SFProDisplayRegular}
                  />
                </div>
              </Row>
              <Space height={58}/>
              <Row type={"flex"} justify={"center"} onClick={()=>this.handleSubmit()}>

                <Row type={"flex"} justify={"center"} align={"middle"} className={"button-start-your-journey"}>
                  <img src={images.apps} width={20} alt={""}/>
                  <Space width={12}/>
                  <ComponentText
                    text="Start your journey"
                    size="15"
                    color="var(--white)"
                    align={"center"} font={fonts.SFProDisplayMedium}
                  />
                </Row>

              </Row>
            </div>
          </Row>
        </div>
      </Spin>

    );
  }
}

const ForgotPass = Form.create({ name: 'forgotpass' })(ForgotPassForm);

const mapActionToProps = {
  verifyUser,
};

const mapStateToProps = state => {
  const {verifyUserRemoteData, loginRemoteData} = state;
  return {
    data: utils.getItem(verifyUserRemoteData),
    isFetching: utils.getIsFetching(verifyUserRemoteData),
    isFailed: utils.getIsFailed(verifyUserRemoteData),
    loginData: utils.getItem(loginRemoteData),
  };
};
export default connect(mapStateToProps, mapActionToProps)(ForgotPass);

// const formItemLayout = {
//   labelCol: {
//     xs: { span: 2 },
//     sm: { span: 2 },
//   },
//   wrapperCol: {
//     xs: { span: 2 },
//     sm: { span: 2 },
//   },
// };
