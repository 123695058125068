import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Col,
  Dropdown,
  Form,
  Menu,
  message,
  Modal,
  Popover,
  Row,
  Spin,
} from 'antd';
import { ComponentText } from '../../shared/ComponentText';
import { ComponentButton } from '../../shared/ComponentButton';
import Space from '../../shared/Spcae';
import ComponentModalForm from '../../shared/ComponentModalForm';
import PriceHistoryModal from '../../shared/modals/PriceHistoryModal';
import ProductGroupEmpty from './ProduceGroupEmpty';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  deleteData,
  loadData,
  loadLocalData,
  postData,
  putData,
} from '../../../utils/ActionCreators';
import utils, { ToastTypes } from '../../../utils/utils';
import { contentType } from '../../../utils/ActionTypes';
import {
  getPackageUpdatePath,
  getProductGroupUpdatePath,
  getProductUpdatePath,
  packageCreatePath,
  productActivatePath,
  productCreatePath,
  productGroupCreatePath,
  productGroupListPath,
} from '../../../utils/routerUtils';
import { getUser } from '../../../utils/Repository';
import { ComponentIcon } from '../../shared/ComponentIcon';
import { isItemExist, removeItem } from '../../../utils/LocalStorage';
import {
  ModalTypes,
  packageModal,
  productGroupModal,
  productModal,
  productUpdateModal,
} from './ProductGroupModals';
import images from '../../../images/Images';
import PriceHistoryRodal from '../../shared/modals/PriceHistoryRodal';

const MenuItems = {
  PRODUCT_EDIT: 'EDIT',
  PRODUCT_DELETE: 'DELETE',
  PRICE_HISTORY: 'PRICE_HISTORY',
  CHANGE_ASSIGNMENT: 'CHANGE_ASSIGNMENT',
  GROUP_EDIT: 'GROUP_EDIT',
  GROUP_DELETE: 'GROUP_DELETE',
  PACKAGE_EDIT: 'PACKAGE_EDIT',
  PACKAGE_DELETE: 'PACKAGE_DELETE',
};

const Styled = {};

Styled.ParaWrapper = styled.div`
  margin-top: 33px;
`;

Styled.ParaTitle = styled.div`
  display: flex;
`;

class ProductGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModalForm: false, selectedModal: productGroupModal(), data: [], showPriceHistoryModal: false, product: {}, isEmptyProducts: false};
  }

  componentDidMount() {
    const { onLoadData, onLoadLocalData, id } = this.props;
    if (isItemExist(contentType.PRODUCT_GROUP_LIST.name, 1)) {
      onLoadLocalData(contentType.PRODUCT_GROUP_LIST);
    } else
      onLoadData(contentType.PRODUCT_GROUP_LIST, productGroupListPath, {
        filters: { application_id: id },
        pager: { skip: 0, limit: 15 },
      });
  }

    getList = id => {
      const indices = id.split(',');
      let data = _.cloneDeep(this.state.data);
      return data[indices[0]].package_infos[indices[1]].package.products
        ? data[indices[0]].package_infos[indices[1]].package.products
        : [];
    };
    reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };
    move = (source, destination, droppableSource, droppableDestination) => {
      const sourceClone = Array.from(source);
      const destClone = Array.from(destination);
      const [removed] = sourceClone.splice(droppableSource.index, 1);

      destClone.splice(droppableDestination.index, 0, removed);

      let data = _.cloneDeep(this.state.data);

      const sourceIndices = droppableSource.droppableId.split(',');
      const destinationIndices = droppableDestination.droppableId.split(',');

      data[sourceIndices[0]].package_infos[sourceIndices[1]].package.products = sourceClone;
      data[destinationIndices[0]].package_infos[destinationIndices[1]].package.products = destClone;

      return data;
    };

    invalidProductMove = () => {
      message.error('Product can not moved into default package');
    };

    onDragEnd = result => {
      const { onPutData } = this.props;
      const { source, destination } = result;

      // dropped outside the list
      if (!destination) {
        return;
      }

      const destinationIndices = destination.droppableId.split(',');
      const destinationProductGroupIndex = destinationIndices[0];
      const destinationPackageIndex = destinationIndices[1];

      const sourceIndices = source.droppableId.split(',');
      const sourceProductGroupIndex = sourceIndices[0];
      const sourcePackageIndex = sourceIndices[1];

      // dropped on default package
      if (
        this.state.data[destinationProductGroupIndex].package_infos[destinationPackageIndex].package
          .name === 'default'
      ) {
        this.invalidProductMove();
        return;
      }
      if (source.droppableId === destination.droppableId) {
        const items = this.reorder(this.getList(source.droppableId), source.index, destination.index);
        let data = _.cloneDeep(this.state.data);
        data[sourceProductGroupIndex].package_infos[sourcePackageIndex].package.products = items;
        this.setState({
          data: data,
        });
      } else {
        const data = this.move(
          this.getList(source.droppableId),
          this.getList(destination.droppableId),
          source,
          destination,
        );
        const productIndex = destination.index;
        if(data[destinationProductGroupIndex].package_infos[destinationPackageIndex].package.products[productIndex].status === "STATUS_ACTIVE")
          data[destinationProductGroupIndex].package_infos[destinationPackageIndex].package.products[productIndex].status = "STATUS_READY";
        this.setState({ data: data }, () => {
          onPutData(
            contentType.PRODUCT_UPDATE,
            getProductUpdatePath(
              data[destinationProductGroupIndex].package_infos[destinationPackageIndex].package
                .products[productIndex].id,
            ),
            {
              product_id:
                      data[destinationProductGroupIndex].package_infos[destinationPackageIndex].package
                        .products[productIndex].id,
              package_id:
                      data[destinationProductGroupIndex].package_infos[destinationPackageIndex].package.id,
            },
          );
        });
      }
    };

    error = () => {
      message.error('This is an error message');
    };
    activateProduct = (productGroupIndex, packageIndex, productIndex) => {
      const { onPostData } = this.props;
      const { data } = this.state;
      onPostData(contentType.PRODUCT_ACTIVATE, productActivatePath, {
        product_id:
            data[productGroupIndex].package_infos[packageIndex].package.products[productIndex].id,
      });
      this.setState({product: data[productGroupIndex].package_infos[packageIndex].package.products[productIndex],
        productGroupIndex: productGroupIndex, packageIndex: packageIndex });
    };
    onButtonClick = (type, productGroupIndex, packageIndex, productIndex) => {
      let modal;

      const { data } = this.state;
      switch (type) {
      case ModalTypes.ADD_PRODUCT_GROUP:
        modal = productGroupModal();
        break;
      case ModalTypes.EDIT_PRODUCT_GROUP:
        modal = productGroupModal(true, data[productGroupIndex].name);

        break;
      case ModalTypes.ADD_PACKAGE:
        modal = packageModal();

        break;
      case ModalTypes.EDIT_PACKAGE:
        modal = packageModal(
          true,
          data[productGroupIndex].package_infos[packageIndex].package.name,
        );

        break;
      case ModalTypes.ADD_PRODUCT:
        modal = productModal(this.props.stores);

        break;
      case ModalTypes.EDIT_PRODUCT:
        modal = productUpdateModal(
          data[productGroupIndex].package_infos[packageIndex].package.products[productIndex].name,
        );

        break;
        // case ModalTypes.PRICE_HISTORY:
        //   break;
      default:
        break;
      }
      this.setState({
        selectedModal: modal,
        productGroupIndex: productGroupIndex,
        packageIndex: packageIndex,
        productIndex: productIndex,
        showModalForm: true,
        modalType: type,
      });
    };
    onModalClose = values => {
      const { onPostData, onPutData, onDeleteData, id, stores } = this.props;


      const user = getUser();
      const { productGroupIndex, packageIndex, productIndex } = this.state;
      let data = _.cloneDeep(this.state.data);
      if (values) {
        switch (this.state.modalType) {
        case ModalTypes.ADD_PRODUCT_GROUP:
          values.package_infos = [];
          data.push(values);

          this.setState({ showModalForm: false, data: data }, () => {
            onPostData(contentType.PRODUCT_GROUP_CREATE, productGroupCreatePath, {
              application_id: id,
              organization_id: user.organizations_roles[0].organization_id,
              name: values.name,
            });
          });
          break;
        case ModalTypes.EDIT_PRODUCT_GROUP:
          if (values === 'delete') {
            if(this.isGroupDeletable(productGroupIndex)) {
              const groupDeletePath = getProductGroupUpdatePath(data[productGroupIndex].id);
              data.splice(productGroupIndex, 1);
              this.setState({ showModalForm: false, data: data }, () => {
                onDeleteData(contentType.PRODUCT_GROUP_DELETE, groupDeletePath);
                this.showSuccess("Product group deleted");
              });
            }else{
              this.setState({ showModalForm: false});
              this.showError("Group cannot be deleted");
            }
          } else {
            data[productGroupIndex].name = values.name;
            this.setState({ showModalForm: false, data: data }, () => {
              onPutData(
                contentType.PRODUCT_GROUP_UPDATE,
                getProductGroupUpdatePath(data[productGroupIndex].id),
                {
                  product_group_id: data[productGroupIndex].id,
                  name: values.name,
                },
              );
            });
          }
          break;
        case ModalTypes.ADD_PACKAGE:
          values.products = [];

          data[productGroupIndex].package_infos.push({ package: values });

          this.setState({ showModalForm: false, data: data }, () => {
            onPostData(contentType.PACKAGE_CREATE, packageCreatePath, {
              product_group_id: data[productGroupIndex].id,
              name: values.name,
            });
          });
          break;
        case ModalTypes.EDIT_PACKAGE:
          if (values === 'delete') {
            if(this.isPackageDeletable(productGroupIndex, packageIndex )) {
              const packageDeletePath = getPackageUpdatePath(
                data[productGroupIndex].package_infos[packageIndex].package.id,
              );
              data[productGroupIndex].package_infos.splice(packageIndex, 1);
              this.setState({ showModalForm: false, data: data }, () => {
                onDeleteData(contentType.PACKAGE_DELETE, packageDeletePath);
                this.showSuccess("Package deleted");
              });
            }else{
              this.setState({ showModalForm: false});
              this.showError("Package cannot be deleted");
            }

          } else {
            data[productGroupIndex].package_infos[packageIndex].package.name = values.name;
            this.setState({ showModalForm: false, data: data }, () => {
              onPutData(
                contentType.PACKAGE_UPDATE,
                getPackageUpdatePath(
                  data[productGroupIndex].package_infos[packageIndex].package.id,
                ),
                {
                  package_id: data[productGroupIndex].package_infos[packageIndex].package.id,
                  name: values.name,
                },
              );
            });
          }
          break;
        case ModalTypes.ADD_PRODUCT:
          values.status = 'Active';
          if (
            typeof data[productGroupIndex].package_infos[packageIndex].package.products ===
                      'undefined'
          ) {
            data[productGroupIndex].package_infos[packageIndex].package['products'] = [];
          }
          data[productGroupIndex].package_infos[packageIndex].package.products.push(values);

          this.setState({ showModalForm: false, data: data }, () => {
            onPostData(contentType.PRODUCT_CREATE, productCreatePath, {
              store_id: stores[values.store],
              name: values.name,
              external_id: values.external_id,
              package_id: data[productGroupIndex].package_infos[packageIndex].package.id,
              type: "TYPE_SUBSCRIPTION",
              source: values.store,
            });
          });
          break;
        case ModalTypes.EDIT_PRODUCT:
          if (values === 'delete') {
            const deletePath = getProductUpdatePath(
              data[productGroupIndex].package_infos[packageIndex].package.products[productIndex].id,
            );
            data[productGroupIndex].package_infos[packageIndex].package.products.splice(
              productIndex,
              1,
            );
            this.setState({ showModalForm: false, data: data }, () => {
              onDeleteData(contentType.PRODUCT_DELETE, deletePath);
              this.showSuccess("Product deleted");
            });
          } else {
            let json;
            if(data[productGroupIndex].package_infos[packageIndex].package.products[productIndex].name !== values.name){
              json = {
                name: values.name,
                product_id:
                data[productGroupIndex].package_infos[packageIndex].package.products[
                  productIndex
                ].id,
              };
            }else{
              json = {
                product_id:
                data[productGroupIndex].package_infos[packageIndex].package.products[
                  productIndex
                ].id,
                package_id: data[productGroupIndex].package_infos[packageIndex].package.id,
              };
            }
            data[productGroupIndex].package_infos[packageIndex].package.products[
              productIndex
            ].name = values.name;
            // data[productGroupIndex].package_infos[packageIndex].package.products[
            //   productIndex
            // ].external_id = values.external_id;
            // data[productGroupIndex].package_infos[packageIndex].package.products[
            //   productIndex
            // ].store = values.store;
            // data[productGroupIndex].package_infos[packageIndex].package.products[
            //   productIndex
            // ].type = values.type;

            this.setState({ showModalForm: false, data: data }, () => {
              onPutData(
                contentType.PRODUCT_UPDATE,
                getProductUpdatePath(data[productGroupIndex].package_infos[packageIndex].package.products[productIndex].id), json
              );
            });
          }
          break;

        default:
          this.setState({ showModalForm: false });
          break;
        }
      } else {
        this.setState({ showModalForm: false });
      }
    };

    getTableRowTextStyl = () => {
      return {
        fontFamily: 'SFProDisplay-Semibold',
        fontSize: '13px',

        color: 'var(--greyish-brown)',
      };
    };

    getStatus = (status, productGroupIndex, packageIndex, index) => {
      switch (status) {
      case 'STATUS_ACTIVE':
        return <ComponentText marginLeft={10} text="Active" styl={this.getTableRowTextStyl()} />;
      case 'STATUS_READY':
        return (
          <div
            className="activate-product"
            onClick={() => this.activateProduct(productGroupIndex, packageIndex, index)}
          > Make Active</div>
        );
      case 'STATUS_PENDING':
        return (
          <ComponentText marginLeft={10} text="Please Sync" styl={this.getTableRowTextStyl()} />
        );
      default:
        return (
          <ComponentText marginLeft={10} text="Please Sync" styl={this.getTableRowTextStyl()} />
        );
      }
    };

    ItemPackage = ( title, lastItem, packageIndex, productGroupIndex) => {
      let products = this.state.data[productGroupIndex].package_infos[packageIndex].package.products;

      const menu = (
        <Menu onClick={e => this.handleProductMenuClick(e.key, productGroupIndex, packageIndex)}>
          <Menu.Item key={MenuItems.PACKAGE_EDIT}>
            <ComponentText text={'Edit Package'} font={'SFProDisplay-Regular'} size="14" />
          </Menu.Item>
          <Menu.Item key={MenuItems.PACKAGE_DELETE}>
            <ComponentText text={'Remove '} font={'SFProDisplay-Regular'} size="14" />
          </Menu.Item>
        </Menu>
      );

      return (
        <Row key={title} className="C1-B" type="flex">
          {lastItem === false ? <div className="line-dark-seafoam-green" /> : null}
          <div className="line-right-dark-seafoam-green" />
          <Row className="C2-H" type="flex">
            <div className="dot-dodger-blue" />
            <div className="dot-line-dodger-blue" />
            <Row className="C2-HC" type="flex" align="middle" justify="space-between">
              <Row type="flex" align="middle">
                <ComponentText
                  text={title}
                  styl={{
                    fontFamily: 'SFProDisplay-Semibold',
                    fontSize: '14px',
                    color: 'var(--dark-sky-blue)',
                  }}
                />
              </Row>

              {title !== 'default' ? (
                <Dropdown overlay={menu} placement="topLeft">
                  <a className={"more-horizontal"}>
                    <ComponentIcon type="more" width="20px" />
                  </a>
                </Dropdown>
              ) : null}
            </Row>
          </Row>
          <Row className="divider-C2" />
          <Row className="C2-B" type="flex">
            <div className="line-dodger-blue" />
            <Row className="C2-BC" type="flex" justify="space-between" align="middle">
              <ComponentText
                text="Products"
                styl={{
                  fontFamily: 'SFProDisplay-Semibold',
                  marginLeft: '0px',
                  fontSize: '14px',
                  color: 'var(--black)',
                }}
              />
              {title !== 'default' ? (
                <ComponentButton
                  name="add-product"
                  text="Add Product"
                  iconLeft="add"
                  css="add-group-item"
                  height="32"
                  handleClick={name => this.onButtonClick(name, productGroupIndex, packageIndex)}
                />
              ) : null}
            </Row>
          </Row>
          <Row className="C2-B" type="flex">
            <div className="line-right-dodger-blue" />
            <Row className="C3" type="flex">
              <TableHeader />

              <Droppable droppableId={productGroupIndex + ',' + packageIndex}>
                {(provided, snapshot) => (
                  <div
                    key={productGroupIndex + ',' + packageIndex}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver, products)}
                  >
                    {products
                      ? products.map((product, index) => {
                        const menu = (
                          <Menu
                            onClick={e =>
                              this.handleProductMenuClick(
                                e.key,
                                productGroupIndex,
                                packageIndex,
                                index,
                                product
                              )
                            }
                          >
                            <Menu.Item key={MenuItems.PRODUCT_EDIT}>
                              <ComponentText
                                text={'Edit Product'}
                                font={'SFProDisplay-Regular'}
                                size="14"
                              />
                            </Menu.Item>
                            {product.status === "STATUS_PENDING" || product.status === "STATUS_UNKNOWN" ?<Menu.Item key={MenuItems.PRODUCT_DELETE}>
                              <ComponentText
                                text={'Remove'}
                                font={'SFProDisplay-Regular'}
                                size="14"
                              />
                            </Menu.Item>: null }
                            {/*<Menu.Item key={MenuItems.CHANGE_ASSIGNMENT}>*/}
                            {/*  <ComponentText*/}
                            {/*    text={'Change Assignment'}*/}
                            {/*    font={'SFProDisplay-Regular'}*/}
                            {/*    size="14"*/}
                            {/*  />*/}
                            {/*</Menu.Item> */}
                            { product.price_history && product.price_history.length > 1 ?  <Menu.Item key={MenuItems.PRICE_HISTORY}>
                              <ComponentText
                                text={'Price History '}
                                font={'SFProDisplay-Regular'}
                                size="14"
                              />
                            </Menu.Item>: null }
                          </Menu>
                        );


                        return (
                          <Draggable
                            key={productGroupIndex + ',' + packageIndex + ',' + product.id}
                            draggableId={productGroupIndex + ',' + packageIndex + ',' + index}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                  index === products.length - 1,
                                )}
                              >
                                {this.itemProduct(product, productGroupIndex, packageIndex, index, menu)}
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                      : null}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Row>
          </Row>
          <Row className="C2-F" type="flex" />
        </Row>
      );
    };
    handleProductMenuClick = (type, groupIndex, packageIndex, productIndex, product = null) => {
      let self = this;
      switch (type) {
      case MenuItems.PRODUCT_EDIT:
        this.onButtonClick(ModalTypes.EDIT_PRODUCT, groupIndex, packageIndex, productIndex);
        break;
      case MenuItems.PRODUCT_DELETE:
        this.setState(
          {
            modalType: ModalTypes.EDIT_PRODUCT,
            productGroupIndex: groupIndex,
            packageIndex: packageIndex,
            productIndex: productIndex,
          },
          () => this.onModalClose('delete'),
        );
        break;
      case MenuItems.PRICE_HISTORY:
        this.setState({
          showPriceHistoryModal: true,
          product: product,
        });
        break;
      case MenuItems.CHANGE_ASSIGNMENT:
        break;
      case MenuItems.GROUP_EDIT:
        this.onButtonClick(ModalTypes.EDIT_PRODUCT_GROUP, groupIndex);
        break;
      case MenuItems.GROUP_DELETE:
        Modal.confirm({
          icon: <ComponentIcon type={'warning'} styl={{ width: 28, height: 28}}/>,
          content: <div className={"product-create-info-content"} >
            <ComponentText
              text={`If your app is in production and it uses this name to access the group, it might crash.`}
              font="SFProDisplay-Regular"
              size={14}
              color="var(--dark)"
            />
          </div>,
          onOk() {
            // self.setState(
            //       {
            //         modalType: ModalTypes.EDIT_PRODUCT_GROUP,
            //         productGroupIndex: groupIndex
            //       });
            // self.onModalClose('delete');
            self.setState(
              {
                modalType: ModalTypes.EDIT_PRODUCT_GROUP,
                productGroupIndex: groupIndex
              },
              () => self.onModalClose('delete'),
            );
            // if(self.isGroupDeletable(groupIndex)) {
            //   self.setState(
            //     {
            //       modalType: ModalTypes.EDIT_PRODUCT_GROUP,
            //       productGroupIndex: groupIndex
            //     },
            //     () => this.onModalClose('delete'),
            //   );
            // }else{
            //   self.showError("Group cannot be deleted");
            // }
          },
          onCancel() {
            console.log('Cancel');
          },
        });
        break;
      case MenuItems.PACKAGE_EDIT:
        this.onButtonClick(ModalTypes.EDIT_PACKAGE, groupIndex, packageIndex);
        break;
      case MenuItems.PACKAGE_DELETE:
        Modal.confirm({
          icon: <ComponentIcon type={'warning'} styl={{ width: 28, height: 28}}/>,
          content: <div className={"product-create-info-content"} >
            <ComponentText
              text={`If your app is in production and it uses this name to access the package, it might crash.`}
              font="SFProDisplay-Regular"
              size={14}
              color="var(--dark)"
            />
          </div>,
          onOk() {
            // if(self.isPackageDeletable(groupIndex, packageIndex )) {
            //   self.showError("Package cannot be deleted1");
            //   self.setState(
            //     {
            //       modalType: ModalTypes.EDIT_PACKAGE,
            //       productGroupIndex: groupIndex,
            //       packageIndex: packageIndex,
            //     },
            //     () => this.onModalClose('delete'),
            //   );
            // }else{
            //   self.showError("Package cannot be deleted");
            // }
            self.setState(
              {
                modalType: ModalTypes.EDIT_PACKAGE,
                productGroupIndex: groupIndex,
                packageIndex: packageIndex,
              },
              () => self.onModalClose('delete'),
            );
          },
          onCancel() {
            console.log('Cancel');
          },
        });
        break;
      default:
        break;
      }
    };
    ItemProductGroup = (title, productGroupIndex) => {
      const menu = (
        <Menu onClick={e => this.handleProductMenuClick(e.key, productGroupIndex)}>
          <Menu.Item key={MenuItems.GROUP_EDIT}>
            <ComponentText text={'Edit Product Group'} font={'SFProDisplay-Regular'} size="14" />
          </Menu.Item>
          <Menu.Item key={MenuItems.GROUP_DELETE}>
            <ComponentText text={'Remove '} font={'SFProDisplay-Regular'} size="14" />
          </Menu.Item>
        </Menu>
      );

      let package_infos = this.state.data[productGroupIndex].package_infos;
      return (
        <Row type="flex">
          <Row className="C1-H" type="flex">
            <div className="dot-dark-seafoam-green" />
            {package_infos.length === 0 ? null : <div className="dot-line-dark-seafoam-green" />}
            <Row className="C1-HC" type="flex" align="middle" justify="space-between">
              <Row type="flex" align="middle">
                <ComponentText
                  text={title}
                  styl={{
                    fontFamily: 'SFProDisplay-Semibold',
                    fontSize: '14px',
                    color: 'var(--dark-seafoam-green)',
                  }}
                />
              </Row>
              {title !== 'default' ? (
                <Dropdown overlay={menu} placement="topLeft">
                  <a>
                    <ComponentIcon type="more" width="20px" />
                  </a>
                </Dropdown>
              ) : null}
            </Row>
          </Row>
          <Row className="divider" />
          <Row className="C1-B" type="flex">
            {package_infos.length === 0 ? null : <div className="line-dark-seafoam-green" />}
            <Row className="C1-BC" type="flex" justify="space-between" align="middle">
              <ComponentText
                text="Packages"
                styl={{
                  fontFamily: 'SFProDisplay-Semibold',
                  marginTop: '7px',
                  marginLeft: '0px',
                  fontSize: '14px',

                  color: 'var(--black)',
                }}
              />
              <ComponentButton
                name="add-package"
                text="New Package"
                iconLeft="add"
                css="add-group-item"
                height="32"
                handleClick={name => this.onButtonClick(name, productGroupIndex)}
              />
            </Row>
          </Row>
          {package_infos.map((e, i) => {
            return (
              this.ItemPackage(e.package.name, i + 1 === package_infos.length, i, productGroupIndex)
            );
          })}
          <Row className="C1-F" type="flex" />
        </Row>
      );
    };
    componentDidUpdate(prevProps, prevState, snapshot) {


      if (this.props.data && this.props.data !== prevProps.data && this.props.isFetching === false) {
        const data = _.cloneDeep(this.props.data);

        if(data && data.length === 1){
          this.setState({isEmptyProducts: true});
        }else{
          this.setState({isEmptyProducts: false});
        }

        data.forEach((p, i) => {
          if (p.name === 'default') {
            p.package_infos.forEach((e, j) => {
              if (e.package.name === 'default') {
                if (!(e.package.products && e.package.products.length > 0)) {
                  data[i].package_infos.splice(j, 1);
                }
              }
            });
            if (p.package_infos.length === 0) data.splice(i, 1);
          } else {
            p.package_infos.forEach((e, j) => {
              if (e.package.name === 'default') {
                if (!(e.package.products && e.package.products.length > 0)) {
                  data[i].package_infos.splice(j, 1);
                }
              }
            });
          }
        });
        this.setState({ data: data });
      }

      if (
        prevProps.productGroupCreateData !== this.props.productGroupCreateData &&
          this.props.productGroupCreateIsFetching === false
      ) {
        removeItem(contentType.PRODUCT_GROUP_LIST.name);
        this.componentDidMount();
      }
      if (
        prevProps.packageCreateData !== this.props.packageCreateData &&
          this.props.packageCreateIsFetching === false
      ) {
        removeItem(contentType.PRODUCT_GROUP_LIST.name);
        this.componentDidMount();
      }
      if (
        prevProps.productCreateData !== this.props.productCreateData &&
          this.props.productCreateIsFetching === false
      ) {
        removeItem(contentType.PRODUCT_GROUP_LIST.name);
        this.componentDidMount();
      }
      if (
        prevProps.productActivateData !== this.props.productActivateData &&
          this.props.productActivateIsFetching === false
      ) {
        //removeItem(contentType.PRODUCT_GROUP_LIST.name);
        //this.componentDidMount();
        //data[productGroupIndex].package_infos[packageIndex].package.products[productIndex].id,
        let data = _.cloneDeep(this.state.data);
        const {productGroupIndex, packageIndex, product } = this.state;
        if(productGroupIndex !== null && packageIndex!==null && product){
          data[productGroupIndex].package_infos[packageIndex].package.products.map(e =>{
            if(e.store === product.store){
              if(e.status === "STATUS_ACTIVE") {
                e.status = "STATUS_READY";
              }
              if(e.id === product.id) {
                e.status = "STATUS_ACTIVE";
              }

            }

          });
          this.setState({data:data});
        }
      }

      if (
        prevProps.productUpdateIsFailed !== this.props.productUpdateIsFailed &&
          this.props.productUpdateIsFailed === true
      ) {
        removeItem(contentType.PRODUCT_GROUP_LIST.name);
        this.componentDidMount();
        this.showError('Product update failed');
      }

      if(this.props.productGroupUpdateData && this.props.productGroupUpdateData !== prevProps.productGroupUpdateData){
        utils.showToast('Group name updated', ToastTypes.SUCCESS, 2);
      }
      if(this.props.packageUpdateData && this.props.packageUpdateData !== prevProps.packageUpdateData){
        utils.showToast('Package name updated', ToastTypes.SUCCESS, 2);
      }
      if(this.props.productUpdateData && this.props.productUpdateData !== prevProps.productUpdateData){
        utils.showToast('Product name updated', ToastTypes.SUCCESS, 2);
      }

    }

    showError = text => {
      message.error(text);
    };
    showSuccess = text => {
      message.success(text);
    };

    render() {
      let {
        isFetching,
        id,
        productGroupCreateIsFetching,
        packageCreateIsFetching,
        productCreateIsFetching,
        productActivateIsFetching,
      } = this.props;

      const { showPriceHistoryModal } = this.state;

      return (
        <Spin className={"product-spinner-group"}
          spinning={
            !!isFetching ||
                !!productGroupCreateIsFetching ||
                !!packageCreateIsFetching ||
                !!productCreateIsFetching ||
                !!productActivateIsFetching
          }
        >
          <div style={{minHeight: 400}}>

            <Space height={20}/>

            {this.state.data ? this.state.data.length === 0 && this.state.isEmptyProducts ? <ProductGroupEmpty
              id={id}
              // stores={stores}
              handleNewProductGroupClick={this.onButtonClick}
            />: this.prepareProductGroup() : null}
            <ComponentModalForm
              values={this.state.selectedModal}
              visible={this.state.showModalForm}
              handleClose={this.onModalClose}
            />

            {/*<PriceHistoryModal*/}
            {/*  product={this.state.product}*/}
            {/*  visible={showPriceHistoryModal}*/}
            {/*  handleClose={() => this.setState({*/}
            {/*    showPriceHistoryModal: false,*/}
            {/*    //product: {},*/}
            {/*  })}*/}
            {/*/>*/}

            <PriceHistoryRodal
              product={this.state.product}
              visible={showPriceHistoryModal}
              handleClose={() => this.setState({
                showPriceHistoryModal: false,
                //product: {},
              })}
            />

          </div>
        </Spin>
      );
    }

    prepareProductGroup = () => {
      const popoverContent = (
        <div style={{ width: 300 }}>
          <ComponentText
            text="Products Groups represent a App Store Connect group of products. Please arrange them how you arranged in App Store Connect."
            font={'SFProDisplay-Medium'}
            size="11"
            color="var(--white)"
          />
        </div>
      );
      return  <div style={{ minHeight: 400 }}>
        <Row type="flex" justify="space-between" align="middle" style={{ height: '-10px' }}>
          <Row type="flex">
            <ComponentText text="Product Group" size="18" color= "var(--black)"/>
            <Popover overlayClassName="product-group" placement="bottomLeft" content={popoverContent} title="">
              <a style={{paddingLeft: 10,
                height: 15}}><ComponentIcon type="help" width={15}/></a>
            </Popover>
          </Row>

          {this.state.data.length > 1 ? <ComponentButton
            name="add-product-group"
            text="New Product Group"
            iconLeft="add"
            css="add-group-item"
            height="32"
            handleClick={this.onButtonClick}
          />: null}
        </Row>
        <Space height={20} />
        {this.state.data.map((e, i) => {
          return (
            <DragDropContext key={i} onDragEnd={this.onDragEnd}>
              {this.ItemProductGroup(e.name, i)}
            </DragDropContext>
          );
        })}
      </div>;
    };
    itemProduct = (product, productGroupIndex, packageIndex, index, menu) =>{
      const price = product.price_usd;
      return <Row className={"product-row"} type="flex" align="middle">
        <Col span={11}>
          <ComponentText
            text={product.external_id}
            styl={this.getTableRowTextStyl()}
          />
        </Col>
        <Col span={4}>
          <ComponentText
            text={utils.getSource(product.source)}
            styl={this.getTableRowTextStyl()}
          />
        </Col>
        <Col span={4}>
          <Row type="flex" justify="start" align="middle">
            <ComponentText
              text={price ? `$${price.toString()}` : 'Please Sync'}
              styl={this.getTableRowTextStyl()}
            />
            {product.price_history && product.price_history.length > 1 ?
              <a onClick={()=>{ this.setState({
                showPriceHistoryModal: true,
                product: product,
              });}}>
                <img className={"image-price-history"} src={images.timeMachine} width="18px" height="18px" alt={'history'}/>
              </a>: null }
          </Row>

        </Col>
        <Col span={4}>
          {this.getStatus(
            product.status,
            productGroupIndex,
            packageIndex,
            index,
          )}
        </Col>
        <Col span={1} style={{ textAlign: 'end' }}>
          <Dropdown overlay={menu} placement="topLeft">
            <a>
              <ComponentIcon type="more-vertical" />
            </a>
          </Dropdown>
        </Col>
      </Row>;

    };

    isPackageDeletable = (groupIndex, packageIndex) => {
      let res = true;
      this.state.data[groupIndex].package_infos[packageIndex].package.products.forEach(e => {
        if (e.status === "STATUS_READY" || e.status === "STATUS_ACTIVE") {
          res = res && false;
        }
      });
      return res;
    };
    isGroupDeletable = (groupIndex) => {
      return this.state.data[groupIndex].package_infos.length <= 1;
    }
}

const ComponentProductGroup = Form.create({ name: 'product-groups-create' })(ProductGroup);

const mapActionToProps = {
  onLoadData: loadData,
  onPostData: postData,
  onPutData: putData,
  onDeleteData: deleteData,
  onLoadLocalData: loadLocalData,
};

const mapStateToProps = state => {
  const {
    productGroupListRemoteData,
    productGroupCreateRemoteData,
    packageCreateRemoteData,
    productCreateRemoteData,
    productActivateRemoteData,
    productUpdateRemoteData,
    productGroupUpdateRemoteData,
    packageUpdateRemoteData
  } = state;

  return {
    data: utils.getData(productGroupListRemoteData),
    isFetching: utils.getIsFetching(productGroupListRemoteData),
    isFailed: utils.getIsFailed(productGroupListRemoteData),
    productGroupCreateIsFetching: utils.getIsFetching(productGroupCreateRemoteData),
    productGroupCreateData: utils.getData(productGroupCreateRemoteData),

    packageCreateIsFetching: utils.getIsFetching(packageCreateRemoteData),
    packageCreateData: utils.getData(packageCreateRemoteData),

    productCreateIsFetching: utils.getIsFetching(productCreateRemoteData),
    productCreateData: utils.getData(productCreateRemoteData),

    productUpdateIsFetching: utils.getIsFetching(productUpdateRemoteData),
    productUpdateIsFailed: utils.getIsFailed(productUpdateRemoteData),

    productGroupUpdateData: utils.getItem(productGroupUpdateRemoteData),
    packageUpdateData: utils.getItem(packageUpdateRemoteData),
    productUpdateData: utils.getItem(productUpdateRemoteData),

    productActivateIsFetching: utils.getIsFetching(productActivateRemoteData),
    productActivateData: utils.getData(productActivateRemoteData),
  };
};

export default connect(mapStateToProps, mapActionToProps)(ComponentProductGroup);

function getTableHeaderStyl() {
  return {
    fontFamily: 'SFProDisplay-Semibold',
    fontSize: '14px',

    color: 'var(--pale-red)',
  };
}

function TableHeader() {
  return (
    <Row type="flex" className="product-header" align="middle">
      <Col span={11}>
        <ComponentText text="Product ID" styl={getTableHeaderStyl()} />
      </Col>
      <Col span={4}>
        <ComponentText text="Store" styl={getTableHeaderStyl()} />
      </Col>
      <Col span={4}>
        <ComponentText text="Price" styl={getTableHeaderStyl()} />
      </Col>
      <Col span={4}>
        <ComponentText marginLeft={10} text="Status" styl={getTableHeaderStyl()} />
      </Col>
      <Col span={1} />
    </Row>
  );
}

const getItemStyle = (isDragging, draggableStyle, last) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // padding: grid * 2,
  padding: '15px 18px 17px',
  width: '100%',
  // margin: `0 0 ${grid}px 0`,
  // change background colour if dragging
  background: 'white',
  borderTop: isDragging ? 'solid 1px var(--dark-seafoam-green)' : 'none',
  borderLeft: isDragging ? 'solid 1px var(--dark-seafoam-green)' : 'none',
  borderRight: isDragging ? 'solid 1px var(--dark-seafoam-green)' : 'none',
  borderBottom: isDragging
    ? 'solid 1px var(--dark-seafoam-green)'
    : last ? 'none'
      : 'solid 1px var(--pale-grey-two) ',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver, products) => ({
  background: isDraggingOver ? 'var(--pale-grey-two)' : 'white',
  // padding: grid,
  width: '100%',
  height: products.length === 0 ? '49px' : '',
  // paddingBottom: '10px',
  border: 'solid 1px var(--pale-grey-two) ',
  borderTop: 'none',
  borderRadius: '0px 0px 5px 5px',
});
