import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import AdminRoute from './AdminRoute';
import App from './App';
import SignUp from './components/Auth/SignUp';
import VerifyEmail from './components/Auth/VerifyEmail';
import Login from './components/Auth/Login';
import Join from './components/Auth/Join';
import ForgotPass from './components/Auth/Forgot';
import ResetPassword from './components/Auth/ResetPassword';
import ResetEmailSent from './components/Auth/ResetEmailSent';
import { localStorageKey } from './utils/LocalStorage';
import SocialSignUp from './components/Auth/SocialSignUp';

const AppRoutes = ({location, history}) => {
  if(location.pathname !== "/login"){
    localStorage.setItem(localStorageKey.REDIRECT_TO, location.pathname+location.search);
  }
  return (
    <div className="ui">
      <Switch>
        <Route
          location={location}
          path="/login"
          exact
          component={Login}
        />
        <Route
          location={location}
          path="/register"
          exact
          component={SignUp}
        />
        <Route
          location={location}
          path="/confirmaccount"
          exact
          component={VerifyEmail}
        />
        <Route
          location={location}
          path="/reset-email"
          exact
          component={ResetEmailSent}
        />
        <Route
          location={location}
          path="/join"
          exact
          component={Join}
        />
        <Route
          location={location}
          path="/forgot"
          exact
          component={ForgotPass}
        />
        <Route
          location={location}
          path="/forget"
          exact
          component={ResetPassword}
        />
        <Route
          location={location}
          path="/social-sign-up"
          exact
          component={SocialSignUp}
        />
        <AdminRoute location={location} path="*" exact component={App} />
      </Switch>
    </div>
  );
};

AppRoutes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default AppRoutes;
