import React from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import 'moment-timezone';
import { Row } from 'antd';
import { PopupDetails } from './PopupDetails';
import TimeAgo from 'react-timeago';
import { ComponentText } from '../ComponentText';
import Space from '../Spcae';
import { ComponentTag } from '../ComponentTag';
import { tableContentType } from '../Table/TableDetails';
import images from '../../../images/Images';
import { fonts } from '../../../utils/fonts';
import InvoiceModal from '../modals/InvoiceModal';
import { Link } from 'react-router-dom';

const generate = require('string-to-color');

export class FormatText extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModalPriceHistory: false, showModalInvoice: false };
  }
  isValidDate(item) {

    let date = moment(item, "YYYY-MM-DDTHH:mm:ssZ");

    return date.isValid();
  }

  isValidJson(item) {
    if (typeof item !== "string") return false;
    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }
    return typeof item === "object" && item !== null;

  }

  isArray(item) {
    return item instanceof Array;
  }
  handleModalInvoice = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showModalInvoice: true });
  };


  handleCancel = () => {
    this.setState({ showModalInvoice: false});
  };


  getTableRowTextStyl = () => {
    return {
      fontFamily: 'SFProDisplay-Medium',
      fontSize: '13px',
      color: 'var(--greyish-brown)',
    };
  };

  prepareText(data, type, record) {

    switch (type) {
    case tableContentType.ID:
      return <ComponentText text={data} color="var(--azure)" styl={this.getTableRowTextStyl()}/>;
    case tableContentType.LINK:
      return data;
    case tableContentType.TIME_AGO:
      return data === "NO_EXPIRATION"? "No Expiration" : <TimeAgo date={data} />;
    case tableContentType.DATE_TIME:
      if (this.isValidDate(data)) {
        return <ComponentText text={ <Moment format="Y-M-D hh:mm:ss A">{data}</Moment>} color="var(--slate-grey)" styl={this.getTableRowTextStyl()}/>;

      } else {
        return <ComponentText text={data} color="var(--slate-grey)" styl={this.getTableRowTextStyl()}/>;
      }

    case tableContentType.DATE_TIME_CUSTOMER_DETAILS:
      if (this.isValidDate(data)) {
        return <ComponentText text={ <Moment format="dddd, MMMM D, YYYY hh:mm A">{data}</Moment>} color="var(--slate-grey)" styl={this.getTableRowTextStyl()}/>;

      } else {
        return <ComponentText text={data} color="var(--slate-grey)" styl={this.getTableRowTextStyl()}/>;
      }

    case tableContentType.DATE_TIME_INVOICE:
      if (this.isValidDate(data)) {
        return <ComponentText text={ <Moment format="MMM D, YYYY hh:mm A">{data}</Moment>} color="var(--slate-grey)" styl={this.getTableRowTextStyl()}/>;

      } else {
        return <ComponentText text={data} color="var(--slate-grey)" styl={this.getTableRowTextStyl()}/>;
      }
    case tableContentType.REVENUE: {
      return <ComponentText text={data === "$0.00" ? "Trial" : data} styl={this.getTableRowTextStyl()}/>;
    }
    case tableContentType.PRICE: {
      const price = (((record || {}).price_history || [])[0] || {}).original_price;
      return <ComponentText text={price ? "$" + price : "—"} styl={this.getTableRowTextStyl()}/>;
    }

    case tableContentType.PRICE_HISTORY: {
      const price = record.price_usd;
      return <Row type="flex" justify="start" align="middle">
        <ComponentText text={price ? `$${price.toString()}` : '—'} styl={this.getTableRowTextStyl()}/>
        {record.price_history.length > 1 ?
          <a onClick={() => record.price_history_modal(record)}>
            <img className={"image-price-history"} src={images.timeMachine} width="18px" height="18px" alt={'history'}/>
          </a>: null }
      </Row>;
    }
    case tableContentType.PRODUCT_CUSTOMER_DETAILS: {
      return <Row><ComponentText text={record.product_name} color="var(--greyish-brown)"
        size={15} font={"SFProDisplay-Medium"}/>
      <Space width={16}/>
      <ComponentText text={record.status} color="var(--dark-grey-blue)" size={15}
        font={"SFProDisplay-Medium"}/></Row>;
    }
    case tableContentType.APP_NAME:
      return <ComponentTag fontSize={"13"} color={record.color} text={data}/>;
    case tableContentType.APP_PRODUCT:
      return <Row type={"flex"} align={"middle"}>
        <img src={record.app_image} width={24} className={"app-icon-transaction-list"} alt={""}/>
        <Space width={8}/>
        <ComponentText text={data} size={13} color="var(--dodger-blue-two)" font={fonts.SFProDisplayMedium}/>
      </Row>;
    case tableContentType.TEXT:
      return <ComponentText text={data} color="var(--slate-grey)" styl={this.getTableRowTextStyl()}/>;
    case tableContentType.STATUS:
      return data === 'Expired' ? <ComponentText text={data} color="var(--tomato)" font={"SFProDisplay-Semibold"} size={"13"}/> : data;
    case tableContentType.POP_OVER:
      return <div><a><PopupDetails data={data} record={record}/></a></div>;
    case tableContentType.COLLABORATOR_STATUS:
      switch (data) {
      case 'STATUS_INACTIVE':
        return <ComponentTag fontSize={"12"} color={"var(--pale-red)"} text={"Inactive"}  font={fonts.SFProDisplaySemiBold}/>;
      case 'STATUS_ACTIVE':
        return <ComponentTag fontSize={"12"} color={"var(--dark-seafoam-green)"}  text={"Active"} font={fonts.SFProDisplaySemiBold}/>;
      case 'STATUS_INVITED':
        return <ComponentTag fontSize={"12"} color={"var(--dusty-orange)"} text={"Invitation Sent"} font={fonts.SFProDisplaySemiBold}/>;
      default:
        return <ComponentTag fontSize={"12"} color={"var(--dark)"} text={"Unknown"} font={fonts.SFProDisplaySemiBold}/>;

      }

    case tableContentType.INVOICE_STATUS:
      switch (data) {
      case 'STATUS_UNPAID':
        return <ComponentTag fontSize={"12"} color={"var(--dark-grey-blue)"} text={"Unpaid"}  font={fonts.SFProDisplaySemiBold}/>;
      case 'STATUS_PAID':
        return <ComponentTag fontSize={"12"} color={"var(--dark-mint)"}  text={"Paid"} font={fonts.SFProDisplaySemiBold}/>;
      default:
        return <ComponentTag fontSize={"12"} color={"var(--dark-grey-blue)"} text={"Unknown"} font={fonts.SFProDisplaySemiBold}/>;

      }
    case tableContentType.ALERT_ACTION:

      return <Row><Link to={data}>Edit Alert</Link></Row> ;

    case tableContentType.INVOICE_ACTION:
      switch (data) {
      case 'ACTION_VIEW':
        return <Row>     <a onClick={this.handleModalInvoice}>
              View Invoice
        </a>
        <InvoiceModal
          invoice={record}
          visible={this.state.showModalInvoice}
          handleClose={this.handleCancel}
        />
        </Row> ;
      case 'ACTION_PAY':
        return <Row  type={"flex"}>
          <a onClick={this.handleModalInvoice}>View Invoice</a>
          <Space width={10}/>
          <ComponentText text={"|"} font={fonts.SFProDisplayRegular} size={16} color={"var(--dark-grey-blue)"}/>
          <Space width={10}/>
          <a>Pay Now</a>
          <InvoiceModal
            invoice={record}
            visible={this.state.showModalInvoice}
            handleClose={this.handleCancel}
          />
        </Row> ;
      default:
        return "";
      }

    case tableContentType.PRICE_HISTORY_MODAL: {
      return record.is_current === true ? <ComponentText text={'$'+data} font={"SFProDisplay-Semibold"} size={15} color={"var(--dark-sky-blue)"}/> : <ComponentText text={'$'+data} font={"SFProDisplay-Medium"} size={15} color={"var(--black)"} />;
    }
    case tableContentType.DATE_HISTORY_MODAL: {
      return record.is_current === true ?<Row type="flex"> <ComponentText text={ moment(data).utc().format("DD/MM/YYYY hh:mm A")+" "} font={"SFProDisplay-Medium"} size={15} color={"var(--black)"} /><Space width={4}/> <ComponentText text={"(current)"} font={"SFProDisplay-Medium"} size={15} color={"var(--slate-grey)"} /> </Row>: <ComponentText text={ moment(data).utc().format("DD/MM/YYYY hh:mm A")} font={"SFProDisplay-Medium"} size={15} color={"var(--black)"} />;
    }

    default:
      return data + "";
    }
  }

  render() {

    let {data, type, record} = this.props;
    return this.prepareText(data, type, record);
  }
}
