import { combineReducers } from 'redux';
import {
  forgetPasswordReducer, joinReducer, loginReducer,
  logoutReducer,
  resendEmailReducer,
  resetPasswordReducer, signUpGoogleReducer, signUpReducer,
  verifyUserReducer,
} from './components/Auth/authReducer';
import { appsListReducer } from './components/Apps/List/AppsListReducer';
import {
  appSecretCreateReducer,
  appSecretListReducer,
  appsReducer,
  appsUpdateReducer,
  packageCreateReducer, packageUpdateReducer,
  productActivateReducer,
  productCreateReducer,
  productGroupCreateReducer,
  productGroupListReducer, productGroupUpdateReducer,
  productListReducer,
  productUpdateReducer,
  storeCreateReducer,
  storeReducer,
  storeUpdateReducer,
  webHookTestReducer,
} from './components/Apps/Details/AppsDetailsReducer';
import { appsCreateReducer } from './components/Apps/Create/AppsCreateReducer';
import { transactionListReducer } from './components/Transaction/List/TransactionListReducer';
import { transactionReducer } from './components/Transaction/Details/TransactionDetailsReducer';
import { customerListReducer } from './components/Customer/List/CustomerListReducer';
import {
  customerProductGroupReducer,
  customerReducer,
  customerTotalSPentReducer,
} from './components/Customer/Details/CustomerDetailsReducer';
import {
  accountDetailsReducer,
  accountUpdateReducer,
  billingProductListReducer,
  billingPurchaseReducer,
  billingReceiptListReducer,
  collaboratorCreateReducer,
  collaboratorDeleteReducer,
  collaboratorListReducer,
  collaboratorReducer,
  collaboratorResendReducer,
  collaboratorUpdateReducer,
  overageListReducer,
  paymentMethodActivateReducer,
  paymentMethodCreateReducer,
  paymentMethodDeleteReducer,
  paymentMethodListReducer,
  subscriptionConfirmReducer,
  subscriptionPurchaseReducer,
  userAccountUpdateReducer,
  userOnFreePlanReducer,
} from './components/Account/Details/AccountReducer';
import {
  metricsActivePurchaseTrialReducer,
  metricsAutoRenewReducer,
  metricsChurnReducer, metricsEventCountReducer,
  metricsMRRReducer,
  metricsNewPurchaseTrialReducer,
  metricsOverviewReducer,
  metricsRevenueReducer,
  metricsTrialConversionReducer,
} from './components/Analytics/AnalyticsReducer';
import {
  alertCreateReducer, alertDeleteReducer,
  alertListReducer, alertReducer, alertUpdateReducer,
} from './components/Alert/AlertReducer';

export default combineReducers({

  //authRemoteData: authReducer,

  loginRemoteData: loginReducer,
  signUpRemoteData: signUpReducer,
  signUpGoogleRemoteData: signUpGoogleReducer,

  logoutData: logoutReducer,
  userAccountUpdateData: userAccountUpdateReducer,
  userOnFreePlanData:userOnFreePlanReducer,

  resendEmailRemoteData: resendEmailReducer,
  forgetPasswordRemoteData: forgetPasswordReducer,
  resetPasswordRemoteData: resetPasswordReducer,
  verifyUserRemoteData: verifyUserReducer,

  accountDetailsRemoteData: accountDetailsReducer,
  accountUpdateRemoteData: accountUpdateReducer,

  appsListRemoteData: appsListReducer,
  appsRemoteData: appsReducer,
  appsCreateRemoteData: appsCreateReducer,
  appsUpdateRemoteData: appsUpdateReducer,
  webHookTestRemoteData: webHookTestReducer,

  storeRemoteData: storeReducer,
  storeUpdateRemoteData: storeUpdateReducer,
  storeCreateRemoteData: storeCreateReducer,

  appSecretListRemoteData: appSecretListReducer,
  appSecretCreateRemoteData: appSecretCreateReducer,


  productGroupListRemoteData: productGroupListReducer,
  productListRemoteData: productListReducer,

  productGroupCreateRemoteData: productGroupCreateReducer,
  productGroupUpdateRemoteData: productGroupUpdateReducer,

  packageCreateRemoteData: packageCreateReducer,
  packageUpdateRemoteData: packageUpdateReducer,

  productCreateRemoteData: productCreateReducer,
  productUpdateRemoteData: productUpdateReducer,
  productActivateRemoteData: productActivateReducer,

  collaboratorRemoteData: collaboratorReducer,
  collaboratorListRemoteData: collaboratorListReducer,
  collaboratorCreateRemoteData: collaboratorCreateReducer,
  collaboratorUpdateRemoteData: collaboratorUpdateReducer,
  collaboratorDeleteRemoteData: collaboratorDeleteReducer,
  collaboratorResendRemoteData: collaboratorResendReducer,

  transactionListRemoteData: transactionListReducer,
  transactionRemoteData: transactionReducer,

  customerListRemoteData: customerListReducer,
  customerRemoteData: customerReducer,
  customerTotalSpentRemoteData: customerTotalSPentReducer,
  customerProductGroupRemoteData: customerProductGroupReducer,

  metricsOverviewRemoteData: metricsOverviewReducer,
  metricsAutoRenewRemoteData:metricsAutoRenewReducer,
  metricsRevenueRemoteData: metricsRevenueReducer,
  metricsMRRRemoteData: metricsMRRReducer,
  metricsChurnReducer: metricsChurnReducer,
  metricsEventCountReducer: metricsEventCountReducer,
  metricsTrialConversionReducer: metricsTrialConversionReducer,
  metricsNewPurchaseTrialReducer: metricsNewPurchaseTrialReducer,
  metricsActivePurchaseTrialReducer: metricsActivePurchaseTrialReducer,

  billingReceiptListRemoteData: billingReceiptListReducer,
  billingProductListRemoteData: billingProductListReducer,
  billingPurchaseRemoteData: billingPurchaseReducer,

  overageRemoteData: overageListReducer,

  paymentMethodRemoteData: paymentMethodListReducer,
  paymentMethodCreateData: paymentMethodCreateReducer,
  paymentMethodActivateData: paymentMethodActivateReducer,
  paymentMethodDeleteData: paymentMethodDeleteReducer,

  subscriptionPurchaseRemoteData: subscriptionPurchaseReducer,
  subscriptionConfirmRemoteData: subscriptionConfirmReducer,

  alertListRemoteData: alertListReducer,
  alertCreateRemoteData: alertCreateReducer,
  alertRemoteData: alertReducer,
  alertUpdateRemoteData: alertUpdateReducer,
  alertDeleteRemoteData: alertDeleteReducer,

/*_add_from_here*/
});
