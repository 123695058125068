import React from 'react';
import { connect } from 'react-redux';
import utils from '../../../utils/utils';
import { Link } from 'react-router-dom';
import { Form } from 'antd';
import { ComponentButton } from '../../shared/ComponentButton';
import '@/components/Apps/apps.css';
import { createApp } from './AppsActionCreators';
import ComponentAppsCreate from './ComponentAppsCreate';

class Create extends React.Component {

  componentDidUpdate (prevProps, prevState, snapshot) {

    if (this.props.data && prevProps.data !== this.props.data) {
      this.props.history.push('/app/list');
    }
  }

  render() {
    const { isFetching, isFailed } = this.props;


    return (
      <div className="apps create-app-form-wrapper" style={{ marginBottom: '64px' }}>
        <Link key="back" to={'list'}>
          <ComponentButton
            name="back"
            iconLeft="previous"
            color="white"
            text="My Apps"
            css="stroke-cloudy-blue eina-regular-slate-grey-14"
            iconSize={16}
            height="36"
            width="124"
          />
        </Link>
        <ComponentAppsCreate/>
      </div>
    );
  }
}

const mapActionToProps = {
  onCreateApp: createApp,
};

const mapStateToProps = state => {
  const { appsCreateRemoteData } = state;
  return {
    data: utils.getData(appsCreateRemoteData),
    isFetching: utils.getIsFetching(appsCreateRemoteData),
    isFailed: utils.getIsFailed(appsCreateRemoteData),
    message: utils.getMessage(appsCreateRemoteData),
  };
};

const AppsCreate = Form.create({ name: 'apps-create' })(Create);

export default connect(mapStateToProps, mapActionToProps)(AppsCreate);

