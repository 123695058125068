import { BASEURL } from './constants';

export function prepareUrl(params, path) {
  if (params !== null || params !== undefined) {
    const {skip, limit} = params.pager;
    const {filters} = params;
    let filtersQuery = filters
      ? Object.keys(filters).reduce((accm, cur, ind) => {
        // if (ind) accm += '&';
        return accm + `&${cur}=${filters[cur]}`;
      }, "")
      : "";

    return `${BASEURL}${path}?pager.skip=${skip}&pager.limit=${limit}` + filtersQuery;
  }
  return `${BASEURL}${path}?pager.skip=${15}&pager.limit=15`;
}


export function prepareUrlWithoutPager(params, path) {
  if (params !== null || params !== undefined) {
    const {filters} = params;
    let filtersQuery = filters
      ? Object.keys(filters).reduce((accm, cur, ind) => {
        if (ind) accm += '&';
        return accm + `${cur}=${filters[cur]}`;
      }, "")
      : "";
    return `${BASEURL}${path}?` + filtersQuery;
  }
  return `${BASEURL}${path}?`;
}
