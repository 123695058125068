import React from 'react';
import { Form, Modal } from 'antd';
import styled from 'styled-components';
import { ComponentButton } from '../ComponentButton';
import { InputComponent } from '@/components/shared/FormComponent/InputComponent';
import { prepareInput } from '@/utils/InputFieldCongifurations';
import { CardElement } from '@stripe/react-stripe-js';
import { ComponentText } from '../ComponentText';
import { fonts } from '../../../utils/fonts';
import Space from '../Spcae';

const Styled = {};
Styled.footerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

Styled.productIdWrapper = styled.div`
  margin: 9px 0 28px;
`;

Styled.priceHistoryWrapper = styled.div`
  margin-bottom: 11px;
`;
class AddPaymentModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card_name: prepareInput('card_name', 'Name on Card', true, 'Card Name', '', 'Enter Name'),
      card_number: prepareInput(
        'card_number',
        'Card Number',
        true,
        'Card Number',
        '',
        'Card Number',
      ),
      cvc: prepareInput('cvc', 'CVC', true, 'CVC', '', 'CVC'),
      default: true
    };
  }
  handleSubmit = e => {
    const {stripe, elements} = this.props;
    this.props.form.validateFields( async (err, values) => {
      if (!err) {
        const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            name: values["card_name"],
          },
        });
        if(!error) {
          this.props.form.resetFields();
          elements.getElement(CardElement).clear();
          this.props.handleClose({
            pm_id: paymentMethod.id,
            default: this.state.default
          });
        }
      }
    });
  };

  handleCancel = () => {
    this.props.handleClose();
  };

  handleDefaultPayment = e => {
    this.setState({default:false});
  };
  render() {
    const { visible } = this.props;

    return (
      <Modal
        className="add-payment"
        title="Add Payment Method"
        visible={visible}
        centered
        onCancel={this.handleCancel}
        footer={null}
      >
        <Form layout="vertical">

          <InputComponent
            form={this.props.form}
            layout={formItemLayout}
            values={this.state.card_name}
          />
          <ComponentText text={"Credit or Debit Card"} font={fonts.SFProDisplayMedium} color={"var(--greyish-brown)"} size={13} marginBottom={5}/>
          <div className={"card-input"}>
            <CardElement  options={{
              style: {
                base: {
                  fontSize: '15px',
                },
              },
            }} />
          </div>

          {/*<Checkbox*/}
          {/*  onChange={this.handleDefaultPayment}*/}
          {/*  checked={this.state.default}*/}
          {/*  style={{ marginTop: '20px' }}*/}
          {/*>*/}
          {/*  Make Default*/}
          {/*</Checkbox>*/}

        </Form>

        <Styled.footerWrapper>
          <Form.Item className="no-bottom-margin">
            <ComponentButton
              htmlType="button"
              name="cancel"
              text="Cancel"
              css="stroke-cloudy-blue eina-semibold-cool-grey-15"
              width="122"
              height="42"
              stylText={{
                color: 'var(--cool-grey)',
                fontSize: '15px',

              }}
              handleClick={this.handleCancel}
            />
          </Form.Item>
          <Form.Item className="no-bottom-margin">
            <ComponentButton
              htmlType="button"
              name="save-payment-method"
              text="Save Payment Method"
              css="stroke-cloudy-blue eina-semibold-cool-grey-15"
              width="122"
              styl={{
                backgroundColor: 'var(--dodger-blue)',
                height: '42px',
                padding: '8px 30px',
              }}
              stylText={{
                color: 'var(--white)',
                fontSize: '15px',

              }}
              handleClick={this.handleSubmit}
            />
          </Form.Item>
        </Styled.footerWrapper>
        <Space height={12}/>
      </Modal>
    );
  }
}

const AddPaymentModal =  Form.create({ name: 'add-payment-modal' })(AddPaymentModalComponent);
export default AddPaymentModal;

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};
