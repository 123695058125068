import React from 'react';
import { Col, Menu, Row, Spin, Tabs } from 'antd';

import './OnBOarding.css';
import { loadData } from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import { connect } from 'react-redux';

import { getUser } from '../../../utils/Repository';
import MakeTestPurchase from './MakeTestPurchase';
import ComponentAppsCreate from '../../Apps/Create/ComponentAppsCreate';
import { ComponentText } from '../../shared/ComponentText';
import { fonts } from '../../../utils/fonts';
import Space from '../../shared/Spcae';
import ConnectSDK from './ConnectSDK';
import ConfigureGroups from './ConfigureGroups';
import ConfigurePackages from './ConfigurePackages';
import ConfigureProducts from './ConfigureProducts';
import { contentType } from '../../../utils/ActionTypes';
import {
  appsListApiPath,
  getTransactionListPath,
  productGroupListPath,
  productListPath,
} from '../../../utils/routerUtils';
import listUtils from '../../../utils/listUtils';
import { ComponentIcon } from '../../shared/ComponentIcon';
import { localStorageKey } from '../../../utils/LocalStorage';

const { TabPane } = Tabs;
const menuItems = [
  'Add your app',
  'Configure Products',
  'Connect the SDK',
  'Make a test Purchase',
  ''
];


class OnBoarding extends React.Component {

  constructor (props) {
    super(props);
    this.state = { screenWidth: window.innerWidth - 306 };
  }

  componentDidMount () {

    const onBoard = localStorage.getItem(localStorageKey.SHOW_ONBOARD);
    if( onBoard !==null){
      let finalSearchParams = new URLSearchParams();
      let key = localStorage.getItem(localStorageKey.SELECTED_APP);
      finalSearchParams.set("application_ids", key ? key : "all-app");
      this.props.history.push('/?'+finalSearchParams.toString());
    }
    window.addEventListener('resize', this.handleResize);
    const { onLoadData } = this.props;

    const user = getUser();
    onLoadData(
      contentType.APPS_LIST,
      appsListApiPath, {filters: {organization_id: user.organizations_roles[0].organization_id},
        pager: {skip: 0,
          limit: 10}}
    );

    //let filters = { application_id: "" };
    //this.props.onLoadData(contentType.PRODUCT_LIST, productListPath, listUtils.prepareActionParams(1, 10, filters) );

    // onLoadData(
    //   contentType.TRANSACTION_LIST,
    //   path,
    //   listUtils.prepareActionParams(1, 10, filters),
    // );
  }



  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize);
  }

    handleResize = () => {
      this.forceUpdate();
      this.setState({ screenWidth: window.innerWidth - 306 });
    };

    onClick = ({ key }) => {
      //this.addQuery({ menu: key });
      if(key === menuItems[1] && this.state.currentStep === 4 ){

        this.setState({currentStep: 7});
      }
      if(key === menuItems[2] && this.state.currentStep === 7){
        this.setState({currentStep: 4});
      }

    };

    // addQuery = (query) => {
    //   this.props.history.push({
    //     pathname: '',
    //     search: '?' + new URLSearchParams(query).toString(),
    //   });
    // };

    getSelectedMenu = () => {

      switch (this.state.currentStep) {
      case 0:
        return menuItems[0];
      case 1:
      case 2:
      case 3:
        return menuItems[1];
      case 4:
        return menuItems[2];
      case 5:
        return menuItems[3];
      case 7:
        return menuItems[1];
      default:
        return menuItems[4];

      }

      // const query = new URLSearchParams(this.props.location.search);
      // const token = query.get('menu');
      // let index = menuItems.findIndex(e => e === token);
      //
      // return index > -1 && index < menuItems.length
      //   ? menuItems[index]
      //   : menuItems[0];


    };

    componentDidUpdate (prevProps, prevState, snapshot) {
      if (this.props.appList && this.props.appList !== prevProps.appList) {
        if (this.props.appList.length === 0) {
          this.setState({currentStep: 0});
        }else if (this.props.appList.length === 1){
          let filters = { application_id: this.props.appList[0].id };
          this.setState({currentStep: 6, currentApp: this.props.appList[0]});
          this.props.onLoadData(contentType.PRODUCT_GROUP_LIST, productGroupListPath, {
            filters: filters,
            pager: { skip: 0, limit: 15 },
          });
        }
      }
      if(this.props.productGroupData && this.props.productGroupData !== prevProps.productGroupData) {
        let filters = { application_id: this.state.currentApp.id };
        this.props.onLoadData(contentType.PRODUCT_LIST, productListPath, listUtils.prepareActionParams(1, 5, filters) );
      }

      if(this.props.productListData && this.props.productListData !== prevProps.productListData && this.state.currentStep === 6){
        const data = this.props.productGroupData;
        if (!this.isGroupCreated(data)) {
          this.setState({currentStep: 1});
        } else {
          if(!this.isPackageCreated(data)){
            this.setState({currentStep: 2, currentProductGroup: this.getCurrentProductGroup(data)});
          }else{
            if(this.props.productListData.length > 0){
              this.setState({currentStep: 4, currentPackage: this.getCurrentPackage(this.getCurrentProductGroup(data))});
              this.loadTransactionList();
            }else {
              this.setState({
                currentStep: 3,
                currentPackage: this.getCurrentPackage(this.getCurrentProductGroup(data))
              });
            }
          }
        }
      }


      // if(this.props.transactionList && this.props.transactionList !== prevProps.transactionList){
      //   if(this.props.transactionList.length === 0){
      //     this.setState({currentStep: 4});
      //   }else{
      //     this.setState({currentStep: 5});
      //   }
      // }

      if (this.props.appCreateData && prevProps.appCreateData !== this.props.appCreateData) {
        const user = getUser();
        this.props.onLoadData(
          contentType.APPS_LIST,
          appsListApiPath, {filters: {organization_id: user.organizations_roles[0].organization_id},
            pager: {skip: 0,
              limit: 10}}
        );
      }

      if(this.props.productGroupCreateData && this.props.productGroupCreateData !== prevProps.productGroupCreateData){
        this.setState({currentStep: 2, currentProductGroup: this.props.productGroupCreateData});
      }
      if(this.props.packageCreateData && this.props.packageCreateData !== prevProps.packageCreateData){
        this.setState({currentStep: 3, currentPackage: this.props.packageCreateData});
      }
      if(this.props.productCreateData && this.props.productCreateData !== prevProps.productCreateData){
        this.setState({currentStep: 4});
      }
      if(this.props.productUpdateData && this.props.productUpdateData !== prevProps.productUpdateData){
        this.setState({currentStep: 4});
      }


    }

    loadTransactionList = () =>{
      this.props.onLoadData(
        contentType.TRANSACTION_LIST,
        getTransactionListPath,
        {pager: {skip: 0,
          limit: 5}}
      );
    };

    render () {

      const role = getUser().organizations_roles[0].role;
      const { isFetching } = this.props;
      let leftMenu = Math.ceil(24 * 185 / this.state.screenWidth);

      return (

        <div className={'on-boarding'}>
          <div className="on-boarding-left-menu">
            <Col span={leftMenu}>
              <Menu
                className="details"
                mode="inline"
                onClick={this.onClick}
                defaultSelectedKeys={[this.getSelectedMenu()]}
                selectedKeys={[this.getSelectedMenu()]}
                style={{ width: 185 }}>

                <Menu.Item key={menuItems[0]}><Row type={"flex"} align={"middle"}> { this.isChecked(0) && <ComponentIcon type={"check"} /> }Add your app </Row> </Menu.Item>
                <Menu.Item key={menuItems[1]}><Row type={"flex"} align={"middle"}> { this.isChecked(3) && <ComponentIcon type={"check"} /> }Configure
                  Products</Row></Menu.Item>
                <Menu.Item key={menuItems[2]}><Row type={"flex"} align={"middle"}> { this.isChecked(4) && <ComponentIcon type={"check"} /> }Connect the
                  SDK</Row></Menu.Item>
                <Menu.Item key={menuItems[3]}><Row type={"flex"} align={"middle"}> { this.isChecked(5) && <ComponentIcon type={"check"} /> }Make a test Purchase</Row></Menu.Item>

              </Menu>

            </Col>
          </div>
          <Row type="flex">
            <Col span={leftMenu} style={{ pointerEvents: 'none' }}>
            </Col>
            <Col span={24 - leftMenu} style={{ paddingLeft: 20 }}>

              <Tabs defaultActiveKey={menuItems[0]} tabPosition={'left'}
                activeKey={this.getSelectedMenu()}>
                <TabPane tab={`Add your app`} key={menuItems[0]}>

                  <div>
                    <ComponentText text={"Get Started with Purchasefly"} font={fonts.SFProDisplaySemiBold} size={24} color={"var(--dark-three)"}/>
                    <Space height={20}/>
                    <ComponentAppsCreate from={"onboard"}/>
                  </div>
                </TabPane>

                <TabPane tab={`Configure Products`} key={menuItems[1]}>
                  {this.state.currentStep === 1 ?   <ConfigureGroups currentApp={this.state.currentApp} isFetching={isFetching}/> :
                    this.state.currentStep === 2 ? <ConfigurePackages currentProductGroup={this.state.currentProductGroup} isFetching={isFetching}/> :
                      this.state.currentStep === 3 ?<ConfigureProducts currentApp={this.state.currentApp} currentPackage={this.state.currentPackage} isFetching={isFetching}/>:
                        this.state.currentStep === 7 ?<ConfigureProducts currentApp={this.state.currentApp} currentPackage={this.state.currentPackage} isFetching={isFetching} productList={this.props.productListData} edit={true}/>:

                          <div style={{width: 530}}>
                            <Space height={100}/>
                            <Row type={"flex"} justify={"center"}>
                              <Spin/>
                            </Row>
                            <Space height={200}/>
                          </div>

                  }

                </TabPane>
                <TabPane tab={`Connect the SDK`} key={menuItems[2]}>
                  <div>
                    <ComponentText text={"Get Started with Purchasefly"} font={fonts.SFProDisplaySemiBold} size={24} color={"var(--dark-three)"}/>
                    <Space height={38}/>
                    <ConnectSDK currentApp={this.state.currentApp}
                      isFetching={isFetching} currentStep={this.state.currentStep}
                      onNext={this.onSDKConnect}
                    />
                  </div>
                </TabPane>
                <TabPane tab={`Make a test Purchase`} key={menuItems[3]}>
                  <div>
                    <ComponentText text={"Get Started with Purchasefly"} font={fonts.SFProDisplaySemiBold} size={24} color={"var(--dark-three)"}/>
                    <Space height={38}/>
                    <MakeTestPurchase
                      currentApp={this.state.currentApp}
                      isFetching={isFetching}
                      onNext={this.onSDKConnect}

                    />
                  </div>
                </TabPane>
                <TabPane tab={`Empty`} key={menuItems[4]}>
                  <div style={{width: 530}}>
                    <Space height={100}/>
                    <Row type={"flex"} justify={"center"}>
                      <Spin/>
                    </Row>
                    <Space height={200}/>
                  </div>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      );
    }

  onSDKConnect = () =>{
    this.setState({currentStep: 5});
  };
  isChecked = (menu) =>{
    if(this.state.currentStep === 7){
      if(menu === 0 || menu === 3)
        return true;
    }
    else
      return (this.state.currentStep > menu && this.state.currentStep < 6) ;
  }

  getCurrentProductGroup = (data)=> {
    if(data[0].name !== "default")
      return data[0];
    else return data[1];
  }

  isGroupCreated = (data) => {
    let isCreated = false;
    data.forEach(e => {
      if(e.name !== "default")
        isCreated = true;
    });
    return isCreated;
  }

  isPackageCreated =(data)=> {
    const group = this.getCurrentProductGroup(data);
    let isCreated = false;
    group.package_infos.forEach(e => {
      if(e.package.name !== "default")
        isCreated = true;
    });
    return isCreated;
  }

  getCurrentPackage (group) {
    if(group.package_infos[0].package.name !== "default" )
      return group.package_infos[0].package;
    else
      return group.package_infos[1].package;
  }
}

const mapActionToProps = {
  onLoadData: loadData,
};

const mapStateToProps = state => {
  const { productGroupListRemoteData, transactionListRemoteData, appsListRemoteData, appsCreateRemoteData,
    productGroupCreateRemoteData, packageCreateRemoteData, productCreateRemoteData, productListRemoteData,
    productUpdateRemoteData} = state;
  return {
    appList: utils.getData(appsListRemoteData),
    productGroupData: utils.getData(productGroupListRemoteData),
    transactionList: utils.getData(transactionListRemoteData),

    productGroupCreateData: utils.getItem(productGroupCreateRemoteData),
    packageCreateData: utils.getItem(packageCreateRemoteData),
    productCreateData: utils.getItem(productCreateRemoteData),
    productListData: utils.getData(productListRemoteData),
    appCreateData: utils.getData(appsCreateRemoteData),
    productUpdateData: utils.getItem(productUpdateRemoteData),

    isFetching: utils.getIsFetching(productGroupListRemoteData) || utils.getIsFetching(productListRemoteData)
      || utils.getIsFetching(productGroupCreateRemoteData) || utils.getIsFetching(packageCreateRemoteData) || utils.getIsFetching(productCreateRemoteData)||
      utils.getIsFetching(appsCreateRemoteData) || utils.getIsFetching(productUpdateRemoteData),
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(OnBoarding);

