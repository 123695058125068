import React from 'react';
import { FormatText } from '../FormatText/FormatText';
import { ItemAction, TableActionTypes } from '../ItemAction/ItemAction';
import {
  Col,
  Dropdown,
  Icon,
  Layout,
  Menu,
  Pagination,
  Row,
  Table,
} from 'antd';
import './tableList.css';
import { ComponentButton } from '../ComponentButton';
import { localStorageKey } from '../../../utils/LocalStorage';
import { tableContentType } from './TableDetails';
import { ComponentText } from '../ComponentText';
import { Link } from 'react-router-dom';
import images from '@/images/Images';

export class TableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { row_0: false, row_1: false, row_2: false };
  }

  onClick = ({ key }) => {
    localStorage.setItem(localStorageKey.TABLE_ROW_COUNT, key);
    let pathname = this.props.location.pathname;
    let searchParams = new URLSearchParams(this.props.location.search);
    searchParams.set('limit', key);
    searchParams.set('page', '1');
    this.props.history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  getSelectedMenu = () => {
    const c = localStorage.getItem(localStorageKey.TABLE_ROW_COUNT);
    return c ? c : '10';
  };

  onChange = page => {
    let pathname = this.props.location.pathname;
    let searchParams = new URLSearchParams(this.props.location.search);
    searchParams.set('page', page);
    this.props.history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  getCurrentPage = () => {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('page');
    return token ? Number(token) : 1;
  };

  render() {
    let {
      isFetching,
      isFailed,
      list,
      fields,
      titles,
      types,
      actions,
      pagination,
      isLastPage,
      footer,
      tableClass,
    } = this.props;

    const columns = types.map((e, i) => {
      switch (e) {
      case tableContentType.ID:
        return {
          title: titles[i],
          dataIndex: fields[i],
          render: (text, record) => (
            <Link key={text} to={record.detailsPath}>
              <ComponentText text={text} color="var(--azure)" font="Eina-Semibold" size={'13'} />
            </Link>
          ),
        };
      case tableContentType.EMPTY:
        return {
          title: '',
          dataIndex: 'empty',
          // render: (text, record, index) => (this.state["row_" + index] ? <Layout className="border">{}</Layout> : null),
          width: 1,
        };
      case tableContentType.INDICATOR:
        return {
          title: '',
          dataIndex: 'indicator',
          render: (text, record, index) =>
            this.state['row_' + index] ? <Layout className="border">{}</Layout> : null,
          width: 1,
        };
      case tableContentType.BUTTON:
        return {
          title: '',
          render: (text, record, index) => {
            const ob = {};
            if (index) {
              ob.color = 'red';
              ob.text = 'Revoked';
              ob.border = '1px solid red';
            } else {
              ob.color = 'green';
              ob.text = 'Active';
              ob.border = '1px solid green';
            }
            return (
              <ComponentButton
                // htmlType="submit"
                name={ob.text}
                text={ob.text}
                css="dodger-blue eina-semibold-white-15"
                styl={{
                  width: '70px',
                  height: '32px',
                  borderRadius: '5px',
                  float: 'right',
                  border: ob.border,
                  backgroundColor: 'var(--white)',
                  marginRight: '50px',
                }}
                stylText={{ color: ob.color }}
              />
            );
          },
        };
      case tableContentType.CARD_TEXT:
        return {
          title: '',
          render: (text, record, index) => {
            let cardText = '';
            let iconRef = '';
            if (record.brand === 'visa') {
              cardText += 'Visa';
              iconRef = images.visaCard;
            } else {
              cardText += 'MasterCard';
              iconRef = images.masterCard;
            }

            cardText += ` ending in `;
            return (
              <p style={{ fontSize: '14px' }}>
                <span>
                  <img src={iconRef} width="30px" height="22px" alt={'card'}></img>
                </span>{' '}
                {cardText}
                <span style={{ fontFamily: 'SFProDisplay-Semibold' }}>
                  {record.number}
                </span>&nbsp;{' '}
                <span
                  style={{
                    fontSize: '14px',

                    fontFamily: 'SFProDisplay-Medium',
                    color: 'var(--dark-sky-blue)',
                  }}
                >
                  {record.status === 'STATUS_ACTIVE' ? 'Default' : ''}
                </span>
              </p>
            );
          },
        };
      case tableContentType.CARD_EXPIRE:
        return {
          title: '',
          render: (text, record, index) => {
            return (
              <p style={{ fontSize: '14px' }}>
                  Expires{' '}
                <span style={{ fontFamily: 'SFProDisplay-Semibold' }}>
                  {record.exp_month.padStart(2, '0')}/{record.exp_year}
                </span>
              </p>
            );
          },
        };
      case tableContentType.CARD_REMOVE_BUTTON: {
        const { onRemoveCard, onMakeDefaultCard } = this.props;

        return {
          title: '',
          render: (text, record, index) => {

            return (
              <>
              {record.status !== 'STATUS_ACTIVE' && (
                <ComponentButton
                  name="make-default"
                  text="Make Default"
                  css="dodger-blue eina-semibold-white-15"
                  styl={{
                    width: '108px',
                    height: '32px',
                    marginLeft: '10px',
                    borderRadius: '3.3px',
                    float: 'right',
                    border: 'solid 1px var(--cool-grey)',
                    backgroundColor: 'var(--cool-grey)',
                  }}
                  stylText={{color: 'var(--white)'}}
                  handleClick={onMakeDefaultCard}
                  handleClickOb={record}
                />
              )}
              <ComponentButton
                name="remove-card"
                text="Remove Card"
                css="dodger-blue eina-semibold-white-15"
                styl={{
                  width: '108px',
                  height: '32px',
                  borderRadius: '3.3px',
                  float: 'right',
                  border: 'solid 1px var(--cool-grey)',
                  backgroundColor: 'var(--white)',
                }}
                stylText={{ color: 'var(--slate-grey)' }}
                handleClick={onRemoveCard}
                handleClickOb={record}
              />
              </>
            );
          },
        };
      }
      case tableContentType.ACTION:
        return {
          title: '',
          dataIndex: 'action',
          render: (text, record, index) =>
            // this.state['row_' + index] ? (
            <ItemAction
              record={record}
              details={record.detailsPath}
              update={record.updatePath}
              alternateUpdate={record.alternateUpdate}
              remove={record.removePath}
              actions={
                actions ? actions : [{ type: TableActionTypes.VIEW, title: 'View', icon: '' }]
              }
            />,
          // ) : (
          //   <Icon type="more" style={{ color: '#ffffff00' }} />
          // ),
          width: 1,
        };
      default:
        return {
          title: titles[i],
          dataIndex: fields[i],
          render: (text, record) => <FormatText data={text} type={e} record={record} />,
        };
      }
    });

    const rowCount = [
      {
        title: 'Show 10 per page',
        value: '10',
      },
      {
        title: 'Show 20 per page',
        value: '20',
      },
      {
        title: 'Show 30 per page',
        value: '30',
      },
    ];

    const menu = (
      <Menu
        onClick={this.onClick}
        defaultSelectedKeys={[this.getSelectedMenu()]}
        selectedKeys={[this.getSelectedMenu()]}
      >
        {rowCount.map(e => (
          <Menu.Item key={e.value}>
            <Icon type="user" />
            {e.title}
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Table
        rowKey={record => record.id}
        className={`table-list ${tableClass ? tableClass : ''}`}
        columns={columns}
        dataSource={list}
        loading={!!isFetching}
        bordered={false}
        pagination={false}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onMouseEnter: event => {
              this.setState({ ['row_' + rowIndex]: true });
            },
            onMouseLeave: event => {
              this.setState({ ['row_' + rowIndex]: false });
            },
          };
        }}
        footer={
          list.length >= 0 && pagination !== false
            ? () => (
              <Row className="footer-row" justify="space-between" type="flex" align="middle">
                <Col>
                  <Dropdown overlay={menu}>
                    <span style={{ marginLeft: 16 }}>
                      {rowCount.find(e => e.value === this.getSelectedMenu()).title}{' '}
                      <Icon type="down" />
                    </span>
                  </Dropdown>
                </Col>
                <Col>
                  <Pagination
                    showQuickJumper
                    size="small"
                    pageSize={parseInt(this.getSelectedMenu())}
                    total={
                      isLastPage
                        ? this.getCurrentPage() * this.getSelectedMenu()
                        : (this.getCurrentPage() + 2) * this.getSelectedMenu()
                    }
                    itemRender={itemRender}
                    onChange={this.onChange}
                    current={this.getCurrentPage()}
                  />
                </Col>
              </Row>
            )
            : footer
              ? () => footer
              : undefined
        }
      />
    );
  }
}
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return (
      <ComponentButton
        name="previous"
        iconLeft="previous"
        text=""
        css="icon"
        width="25"
        iconSize={14}
      />
    );
  }
  if (type === 'next') {
    return (
      <ComponentButton name="create" iconLeft="next" text="" css="icon" width="25" iconSize={25} />
    );
  }
  return originalElement;
}
