import React from 'react';
import { Link } from 'react-router-dom';
//import {Icon} from "semantic-ui-react"
import './itemAction.css';
import { Dropdown, Icon, Menu } from 'antd';
import styled from 'styled-components';
import PriceHistoryModal from '../../shared/modals/PriceHistoryModal';
import { ComponentIcon } from '../ComponentIcon';

const Styled = {};
Styled.footerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

Styled.productIdWrapper = styled.div`
  margin: 9px 0 28px;
`;

Styled.priceHistoryWrapper = styled.div`
  margin-bottom: 11px;
`;

export const TableActionTypes = {
  VIEW: 'VIEW',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  PRICE_HISTORY: 'PRICE_HISTORY',
};
export class ItemAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  handleModal = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showModal: true });
  };

  handleCancel = () => {
    this.setState(() => ({ showModal: false }));
  };

  render() {
    const { details, update, alternateUpdate, remove, record } = this.props;

    const menu = (
      <Menu>
        {this.props.actions.map(action => {
          switch (action.type) {
          case TableActionTypes.VIEW:
            return (
              <Menu.Item key={action.type}>
                <Link key={action.type} to={details}>
                  {action.icon ? <Icon type="file" /> : null} {action.title}
                </Link>
              </Menu.Item>
            );
          case TableActionTypes.EDIT:
            return (
              <Menu.Item key={action.type}>
                <Link key={action.type} to={update}>
                  {action.icon ? <Icon type="file" /> : null}{' '}
                  {alternateUpdate ? alternateUpdate : action.title}
                </Link>
              </Menu.Item>
            );
          case TableActionTypes.DELETE:
            return (
              <Menu.Item key={action.type}>
                <Link key={action.type} to={remove}>
                  {action.icon ? <Icon type="file" /> : null} {action.title}
                </Link>
              </Menu.Item>
            );
          case TableActionTypes.PRICE_HISTORY: {
            return (
              <Menu.Item
                key={action.type}
                // style={{ display: this.state.showModalPriceHistory ? 'none' : 'block' }}
              >
                <Link key={action.type} onClick={this.handleModal}>
                  {action.icon ? <Icon type="file" /> : null} {action.title}
                </Link>
                {/* <Link
                  key={action.type}
                  onClick={this.handleModalPriceHistory}
                >
                  <ComponentText
                    text={'Price History '}
                    font={'SFProDisplay-Regular'}
                    size="14"
                  />
                </Link> */}
                <PriceHistoryModal
                  product={record}
                  visible={this.state.showModal}
                  handleClose={this.handleCancel}
                />
              </Menu.Item>
            );
          }
          default:
            return '';
          }
        })}
      </Menu>
    );

    return ( details ? <Dropdown overlay={menu} placement="topLeft">
      <a>
        <ComponentIcon type="more-vertical" width="20px" styl={{marginRight:10, marginLeft:16}}/>
      </a>
    </Dropdown>: <div/>
    );
  }
}
