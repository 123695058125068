import React from 'react';
import { Link } from 'react-router-dom';
//import {Icon} from "semantic-ui-react"
import './itemAction.css';
import { Dropdown, Icon, Menu } from 'antd';
import { ComponentIcon } from '../ComponentIcon';

export class TableRowAction extends React.Component {

  render() {

    const {actions} = this.props;
    const menu = (
      <Menu >
        {
          actions.map((action) =>
            <Menu.Item key={action.title}>
              {action.function ? <div onClick={()=>action.function(action.url)}> {action.title} </div> :<Link key={action.title} to={{pathname:action.url,state :{goBack: action.go_back}}}>
                {action.icon ? <Icon type="file" /> : null} {action.title}
              </Link>}
            </Menu.Item>
          )
        }
      </Menu>
    );


    return (
      actions.length === 0 ? <div/> : <Dropdown overlay={menu} placement="topLeft">
        <a><ComponentIcon type="more-vertical" /></a>
      </Dropdown>
    );
  }
}
