export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_GOOGLE_LOGIN_REQUEST = "ADMIN_GOOGLE_LOGIN_REQUEST";

export const ADMIN_REGISTER_REQUEST = "ADMIN_REGISTER_REQUEST";
export const ADMIN_LOGGED_IN = "ADMIN_LOGGED_IN";
export const ADMIN_LOGGED_OUT = "ADMIN_LOGGED_OUT";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";
export const ADMIN_LOGIN_FAIL_ORG_NAME = "ADMIN_LOGIN_FAIL_ORG_NAME";
export const FORGOT_PASS = "FORGOT_PASS";
export const FORGOT_PASS_SUCCESS = "FORGOT_PASS_SUCCESS";
export const FORGOT_PASS_FAIL = "FORGOT_PASS_FAIL";
export const RESET_PASS = "RESET_PASS";
export const RESET_PASS_SUCCESS = "RESET_PASS_SUCCESS";

