import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Tooltip } from 'antd';
import { ComponentText } from '../../shared/ComponentText';
import { ComponentIcon } from '../../shared/ComponentIcon';
import Space from '../../shared/Spcae';
import { ComponentButton } from '../../shared/ComponentButton';
import { loadData } from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import { contentType } from '../../../utils/ActionTypes';
import {
  getTransactionListPath,
  productListPath,
} from '../../../utils/routerUtils';
import { fonts } from '../../../utils/fonts';
import listUtils from '../../../utils/listUtils';
import { Link } from 'react-router-dom';

class MakeTestPurchase extends React.Component {

	intervalID;
	componentDidMount () {
	  const { onLoadData, currentApp } = this.props;
	  let filters = { application_id: currentApp.id };
	  onLoadData(contentType.PRODUCT_LIST, productListPath, listUtils.prepareActionParams(1, 10, filters) );
	  this.intervalID = setInterval(this.getData.bind(this), 10000);
	}
	getData = () => {
	  this.props.onLoadData(
	    contentType.TRANSACTION_LIST,
	    getTransactionListPath,
	    {pager: {skip: 0,
	      limit: 5}}
	  );
	};
	componentWillUnmount() {
	  clearInterval(this.intervalID);
	}

	componentDidUpdate (prevProps, prevState, snapshot) {

	}

	render () {
	  const { isFetching, isFailed, productListData } = this.props;

	  let purchased = false;

	  productListData && productListData.forEach(e =>  {
    	if(e.status === "STATUS_ACTIVE")
    		purchased = true;
	  });

	  return (

	    <div style={{ width: 650 }}>
	      <Row type="flex">
	        <ComponentText text="Make a Test Purchase"
	          font={fonts.SFProDisplaySemiBold} size="20"
	          color="var(--black)"/>
	        <Tooltip contentStyle={{ backgroundColor: '#4A4A4A' }}
	          placement="bottomLeft"
	          title={'Products Groups represent a App Store Connect group of products. Please arrange them how you arranged in App Store Connect.'}>
	          <a style={{
	            paddingLeft: 10,
	            height: 20,
	          }}><ComponentIcon type="help" width={15}/></a>
	        </Tooltip>
	      </Row>
	      <Space height={20}/>

	      <div>
	        <Row type={'flex'} className={'product-table-header'}
	          align={'middle'}>
	          <Col span={6}>
	            <ComponentText text="Product ID"
	              font={fonts.SFProDisplayRegular}
	              size="13"
	              color=" var(--greyish-brown)"/>
	          </Col>
	          <Col span={6}>
	            <ComponentText text="Store"
	              font={fonts.SFProDisplayRegular}
	              size="13"
	              color=" var(--greyish-brown)"/>
	          </Col>
	          <Col span={6}>
	            <ComponentText text="Price"
	              font={fonts.SFProDisplayRegular}
	              size="13"
	              color=" var(--greyish-brown)"/>
	          </Col>
	          <Col span={6}>
	          </Col>
	        </Row>
				  {productListData && productListData.map(e =>
				  <div key={e.id} className={'product-table-row'}>
	            <Space height={22}/>
	            <Row type={'flex'} align={'middle'}>
	              <Col span={6}>
	                <ComponentText text={e.external_id}
	                  font={fonts.SFProDisplayRegular}
	                  size="13"
	                  color=" var(--greyish-brown)"/>
	              </Col>
	              <Col span={6}>
	                <ComponentText text={e.source === "SOURCE_APP_STORE" ? "App Store" : "Play Store"}
	                  font={fonts.SFProDisplayRegular}
	                  size="13"
	                  color=" var(--greyish-brown)"/>
	              </Col>
	              <Col span={6}>
	                <ComponentText text={`$${e.price_usd}`}
	                  font={fonts.SFProDisplayRegular}
	                  size="13"
	                  color=" var(--greyish-brown)"/>
	              </Col>
	              <Col span={6}>
	                <ComponentText text={e.status === "STATUS_ACTIVE"? "Purchased": "Test Purchase Now"}
								   font={fonts.SFProDisplaySemiBold}
								   size="12"
								   color=" var(--dark-sky-blue)"/>
	              </Col>
	            </Row>
	            <Space height={16}/>
					  {e.status === "STATUS_ACTIVE"? <div>
						  <div className={'product-table-row-purchased'}>
	                <Row type={'flex'} align={'middle'}
	                  justify={'space-between'}>
	                  <Row type={'flex'} align={'middle'}>
	                    <ComponentIcon type={'check'}/>
	                    <Space width={8}/>
	                    <ComponentText
	                      text="Test purchased successfully"
	                      font={fonts.SFProDisplaySemiBold} size="14"
	                      color="var(--dark-mint)"/>
	                  </Row>
	                  <ComponentText text="Price"
	                    font={fonts.SFProDisplayRegular}
	                    size="12"
	                    color="var(--slate-grey)"/>
	                </Row>
	                <Space height={16}/>

	                <Row type={'flex'} align={'middle'}>
	                  <ComponentText text="Transaction ID"
	                    font={fonts.SFProDisplaySemiBold}
	                    size="13"
	                    color="var(--greyish-brown)"/>
	                  <ComponentText text="llll"
	                    font={fonts.SFProDisplaySemiBold}
	                    size="13"
	                    color="var(--slate-grey)"/>
	                </Row>


	              </div>
						  <Space height={16}/></div>
					  : <Space height={8}/> }

	          </div>)  }
				  </div>
	      <Space height={24}/>


		   {purchased === true &&  <Row type="flex" align="middle" justify="space-between">
	        <Row type={'flex'}>
	          <ComponentText text="Congrats! "
	            font={fonts.SFProDisplaySemiBold} size="14"
	            color="var(--dark-mint)"/>
	          <Space width={4}/>
	          <ComponentText text="All Done."
	            font={fonts.SFProDisplaySemiBold} size="14"
	            color="var(--slate-grey)"/>
	        </Row>
	        <Link to={'dashboard'}>
	          <ComponentButton name="connect"
	            text="Go to Dashboard Home"
	            css="purchase-subscription" height="42"/></Link>
	      </Row>}
	    </div>

	  );
	}

}

const mapActionToProps = {
  onLoadData: loadData,
};

const mapStateToProps = state => {
  const { productListRemoteData, transactionListRemoteData } = state;
  return {
    isFetching: utils.getIsFetching(productListRemoteData),
    productListData: utils.getData(productListRemoteData),
    isFailed: utils.getIsFailed(productListRemoteData),
    transactionList: utils.getData(transactionListRemoteData),
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(MakeTestPurchase);

