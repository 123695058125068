import React from 'react';
import { FormatText } from '../FormatText/FormatText';
import {
  Dropdown,
  Layout,
  Menu,
  Pagination,
  Row,
  Skeleton,
  Table,
} from 'antd';
import './tableList.css';
import { ComponentButton } from '../ComponentButton';
import { localStorageKey } from '../../../utils/LocalStorage';
import { tableContentType } from './TableDetails';
import { ComponentText } from '../ComponentText';
import { Link } from 'react-router-dom';
import { TableRowAction } from '../ItemAction/TableRowAction';
import { ComponentIcon } from '../ComponentIcon';

export class TableListNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {row_0: false,
      // row_1: false,
      // row_2: false
    };
  }

	onClick = ({key}) => {
	  localStorage.setItem(localStorageKey.TABLE_ROW_COUNT, key);
	  let pathname = this.props.location.pathname;
	  let searchParams = new URLSearchParams(this.props.location.search);
	  searchParams.set("limit", key);
	  searchParams.set("page", "1");
	  this.props.history.push({
	    pathname: pathname,
	    search: searchParams.toString(),
	  });
	};

	getSelectedMenu = () =>{
	  const c = localStorage.getItem(localStorageKey.TABLE_ROW_COUNT);
	  return c ? c : "10";
	};

	onChange = page => {

	  if(this.getCurrentPage + 1 !== page || !this.props.isLastPage){

	    let pathname = this.props.location.pathname;
	    let searchParams = new URLSearchParams(this.props.location.search);
	    searchParams.set("page", page);
	    this.props.history.push({
	      pathname: pathname,
	      search: searchParams.toString(),
	    });
	  }
	};

	getCurrentPage = () => {
	  if (this.props.location) {
	    const query = new URLSearchParams(this.props.location.search);
	    const token = query.get('page');
	    return token ? Number(token) : 1;
	  }else{
	    return  1;

	  }
	};

	render() {

	  let {
	    isFetching,
	    list,
	    attributes,
	    pagination,
	    isLastPage,
	  } = this.props;

	  if(list.length === 0){
	    attributes = defaultAttributes(this.props.column);
	  }
	  const columns = attributes.map((e, i) => {
	    switch (e.type) {
	    case tableContentType.ID:
	      return {
	        title: e.title,
	        dataIndex: e.field,
	        render: (text, record) => (<Link key={text} to={{pathname:record.actions[0].url, state :{goBack: record.go_back}}}><ComponentText text={text} color="var(--azure)" font="SFProDisplay-Medium" size={"13"}/></Link>),
	      };
	    case tableContentType.EMPTY:
	      return {
	        title: '',
	        dataIndex: 'empty',
	        // render: (text, record, index) => (this.state["row_" + index] ? <Layout className="border">{}</Layout> : null),
	        width: 1,
	      };
	    case tableContentType.INDICATOR:
	      return {
	        title: '',
	        dataIndex: 'indicator',
	        render: (text, record, index) => (this.state["row_" + index] ? <Layout className="border">{}</Layout> : null),
	        width: 1,
	      };
	    case tableContentType.ACTION:
	      return {
	        title: '',
	        dataIndex: 'action',
	        render: (text, record, index) => (<TableRowAction actions={record.actions}/>),
	        width: 1,
	      };
	    case tableContentType.SHIMMER:
	      return {
	        title: '',
	        dataIndex: '',
	        render: () => (<Skeleton  active paragraph={{ rows: 0 }} />),
	        width: 1,
	      };
	    default:
	      return {
	        title: e.title,
	        dataIndex: e.field,
	        render: (text, record) => (<FormatText data={text} type={e.type} record={record}/>),
	      };
	    }
	  });

	  const rowCount = [
	    {
	      title: "Show 10 per page",
	      value: "10",
	    },
	    {
	      title: "Show 20 per page",
	      value: "20",
	    },
	    // {
	    // 	title: "Show 30 per page",
	    // 	value: "30",
	    // },
	  ];

	  const menu = <Menu onClick={this.onClick}
						   defaultSelectedKeys={[this.getSelectedMenu()]}
						   selectedKeys={[this.getSelectedMenu()]}>
	    {rowCount.map((e) => (
	      <Menu.Item key={e.value}>
	        {/*<Icon type="user"/>*/}
	        {/*{e.title}*/}
	        <ComponentText text={e.title} font={"SFProDisplay-Medium"} size={12} color={"var(--slate-grey)"}/>
	      </Menu.Item>
	    ))}
	  </Menu>;

	  return (
	    <Table
	      rowKey={record => record.id}
	      className="table-list"
	      columns={columns}
	      dataSource={list.length === 0 ? defaultList(this.props.row): list}
	      loading={!!isFetching}
	      bordered={false}
	      pagination={this.getSelectedMenu() < list.length ? { pageSize: this.props.row } : false}
	      //pagination={false}
	      size="small"
	      onRow={(record, rowIndex) => {
	        return {
	          onMouseEnter: event => {this.setState({["row_" + rowIndex]: true});},
	          onMouseLeave: event => {this.setState({["row_" + rowIndex]: false});},
	        };
	      }}
	      footer={this.showPagination(list, pagination, isLastPage) ? () => <Row className="footer-row" justify="space-between" type="flex" align="middle">
	        {/*<Col>*/}
	        {/*	<Row type="flex" align="middle">*/}
	        {/*		*/}
	        {/*	</Row>*/}
	        {/*</Col>*/}
	        {/*<Col>*/}
	        {/*</Col>*/}
	        <Dropdown overlay={menu}>
	          <span className={"row-count"} >
				  <Row type="flex" align="middle">
                		<label> {rowCount.find((e)=> e.value === this.getSelectedMenu()).title}</label> <ComponentIcon type="arrow-down" />
	            </Row>
	          </span >
	        </Dropdown>
	        <Pagination size="small"
	          pageSize={parseInt(this.getSelectedMenu())}
	          total={isLastPage ? this.getCurrentPage() * this.getSelectedMenu() : (this.getCurrentPage() + 1) * this.getSelectedMenu()}
	          itemRender={itemRender}
	          onChange={this.onChange}
	          current={this.getCurrentPage()} />

	      </Row> : undefined
	      }
	    />
	  );
	}

	showPagination (list, pagination, isLastPage) {
	  if(pagination === false){
	    return false;
	  }
	  if(isLastPage && this.getCurrentPage() === 1){
	    return  false;
	  }
	  return list.length !== 0;

	}
}
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <ComponentButton name="previous" iconLeft="previous" text="" css="icon" width="25" iconSize={14}/>;
  }
  if (type === 'next') {
    return <ComponentButton name="create" iconLeft="next" text="" css="icon" width="25" iconSize={25}/>;
  }
  return originalElement;
}

function defaultAttributes (column = 5) {
  let list = [];

  for(let i = 0; i< column ; i++){
    list.push( {
      title: '',
      field: '',
      type: tableContentType.SHIMMER,
    });
  }
  return list;
}
function defaultList(row = 10){
  let list = [];
  for(let i = 0; i< row ; i++){
    list.push({id: i, });
  }
  return list;
}





