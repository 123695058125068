import axios from './AxiosWrapper';

import { prepareUrl, prepareUrlWithoutPager } from './actionCreatorUtils';
import { BASEURL } from './constants';
import { getItem, localStorageKey } from './LocalStorage';
import { contentType } from './ActionTypes';
import { createAction } from '@reduxjs/toolkit';

export const REQUEST = "REQUEST_";
export const RECEIVE = "RECEIVE_";
export const FAILED = "FAILED_";

function handleError(error,  authError, failed) {
  if(error && error.status && error.status === 401){
    return authError({
      error: error.data.message,
      code: error.status
    });
  }
  else if(error && error.status && error.status === 426){
    return failed({
      error: error.data.message,
      code: error.status
    });
  }
  else if(error && error.data && error.data.message && error.status) {
    return failed({
      error: error.data.message,
      code: error.status
    });
  }else{
    return failed({
      error: "Unknown error",
      code: 400
    });
  }
}

export function loadData(type, path, params) {
  const request = createAction(REQUEST + type.name);
  const receive = createAction(RECEIVE + type.name);
  const failed = createAction(FAILED + type.name);

  const authError = createAction(RECEIVE + contentType.INVALID_ACCESS_TOKEN.name);

  let url = "";
  switch (type.value) {
  case 0:
    url = prepareUrl(params, type.prefix + path);
    break;
  case 5:
    url = prepareUrlWithoutPager(params, type.prefix + path);
    break;
  default:
    url = BASEURL + type.prefix + path;
    break;
  }
  return dispatch => {
    dispatch(request());
    return axios
      .get(url)
      .then(function (response) {
        dispatch(receive(response.data));
      })
      .catch(function (error) {
        dispatch(handleError(error, authError, failed));
      });
  };
}

export function postData(type, path, json) {
  const request = createAction(REQUEST + type.name);
  const receive = createAction(RECEIVE + type.name);
  const failed = createAction(FAILED + type.name);

  const authError = createAction(RECEIVE + contentType.INVALID_ACCESS_TOKEN.name);

  const url = BASEURL + type.prefix + path;

  return dispatch => {
    dispatch(request());
    return axios
      .post(url, json)
      .then(function (response) {
        dispatch(receive(response.data));
      })
      .catch(function (error) {
        dispatch(handleError(error, authError, failed));
      });
  };
}

export function putData(type, path, json) {
  const request = createAction(REQUEST + type.name);
  const receive = createAction(RECEIVE + type.name);
  const failed = createAction(FAILED + type.name);
  const authError = createAction(RECEIVE + contentType.INVALID_ACCESS_TOKEN.name);
  const url = BASEURL + type.prefix + path;
  return dispatch => {
    dispatch(request());
    return axios
      .put(url, json)
      .then(function (response) {
        dispatch(receive(response.data));
      })
      .catch(function (error) {
        handleError(error, authError, failed);
      });
  };
}

export function deleteData(type, path) {
  const request = createAction(REQUEST + type.name);
  const receive = createAction(RECEIVE + type.name);
  const failed = createAction(FAILED + type.name);

  const authError = createAction(RECEIVE + contentType.INVALID_ACCESS_TOKEN.name);

  const url = BASEURL + type.prefix + path;
  return dispatch => {
    dispatch(request());
    return axios
      .delete(url)
      .then(function (response) {
        dispatch(receive(response.data));
      })
      .catch(function (error) {
        dispatch(handleError(error, authError, failed));
      });
  };
}

export function loadLocalData(type) {
  const receive = createAction(RECEIVE + type.name);
  return dispatch => {
    dispatch(receive(getItem(type.name)));
  };
}

export function userAccountUpdated(type, data) {
  const receive = createAction(RECEIVE + type.name);
  return dispatch => {
    dispatch(receive(data));
  };
}

export function userOnFreePlan(type, data) {
  localStorage.setItem(localStorageKey.UPGRADE_PLAN, data.name);
  const receive = createAction(RECEIVE + type.name);
  return dispatch => {
    dispatch(receive(data));
  };
}
