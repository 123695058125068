import FilterInputItem from '../../shared/Filter/FilterInputItems';

export const listQueryFilterParams = () =>
  listSearchModalParams.reduce((acm, cur) => [...acm, cur.name], []);

export const listSearchModalParams = [
  FilterInputItem.external_product_id,
  FilterInputItem.store,

];
