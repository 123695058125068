import React from 'react';
import { Divider, Form, message, Row, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { InputComponent } from '../shared/FormComponent/InputComponent';
import { prepareInput } from '../../utils/InputFieldCongifurations';
import { PasswordComponent } from '../shared/FormComponent/PasswordComponent';
import { connect } from 'react-redux';
import { register } from './authActionCreators';
import { ComponentText } from '../shared/ComponentText';
import Space from '../shared/Spcae';
import { ComponentButton } from '../shared/ComponentButton';
import './Auth.css';
import { TopBar } from './Topbar';
import { fonts } from '../../utils/fonts';
import utils from '../../utils/utils';

class JoinForm extends React.Component {
  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('token');
    const email = query.get('email');
    const organizationName = query.get('organization');


    this.state = {
      invitation_token: token,
      invitation_user_name: prepareInput('invitation_user_name', 'Full Name', true, 'Full Name', '', 'Enter Name'),
      email: prepareInput('email', 'Email Address', true, 'Email Address', '', 'Enter Email', '', email ? email : ''),
      organization_name: prepareInput('organization_name', 'Organization Name', true, 'Dropbox', '', 'Enter organization name', '', organizationName ? organizationName : ''),
      invitation_user_password: prepareInput('invitation_user_password', 'Password', true, 'Enter Password', '', 'Enter Password'),

    };
  }

  componentDidMount() {
  	if (utils.isLoggedIn(this.props.loginData)) {
      this.props.history.push("/dashboard");
    }
  }

    success = (text) => {
    	message.success(text);
    };

    error = (text) => {
    	if (text === null)
    		return;
    	message.error(text);
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
    	const {data, isFetching, isFailed, signUpMessage} = this.props;

    	if (isFailed === true) {
    		if (prevProps.signUpMessage !== signUpMessage)
    			this.error(signUpMessage);
    	}

    	// if (previousProps.isLoggedIn === isLoggedIn)
    	// 	return;


      if (data && prevProps.data !== data) {
        this.success("Logged In");
        this.props.history.push("/dashboard");
      }
    }


    handleSubmit = e => {
    	e.preventDefault();
    	this.props.form.validateFields((err, values) => {
    		if (!err) {
    			delete values.email;
    			delete values.organization_name;
    			values['invitation_token'] = this.state.invitation_token;
    			//console.log(values)
    			this.props.register(values, 'joinuser');
    		}
    	});
    }



    render() {
    	const {isFetching} = this.props;
    	return (
    		<Spin spinning={!!isFetching} >
          <TopBar/>
          <div style={{height:window.innerHeight-71, backgroundColor:"#fafafa"}}>
    			<Row type="flex" justify="center">
    				<div className="login-container">
    					<ComponentText text=" Sign Up with Purchasefly"
									   size="23"
									   font={fonts.SFProDisplayRegular}
									   color="var(--black)"/>
									   <Space height={12}/>
    					<ComponentText text="We make your in-app subscription business fly."
									   font={fonts.SFProDisplayRegular} size="15" color="var(--cool-grey)"/>

    					<Divider />
    					<Row
    						style={{
    							paddingLeft: 64,
    							paddingRight: 64,
    							textAlign: "left",
    						}}>
    						<Form onSubmit={this.handleSubmit} layout="vertical">
    							<InputComponent
    								form={this.props.form}
    								layout={formItemLayout}
    								values={this.state.invitation_user_name}
    							/>
    							<InputComponent
    								disabled = {true}
    								form={this.props.form}
    								layout={formItemLayout}
    								values={this.state.email}
    							/>
    							<PasswordComponent
    								form={this.props.form}
    								layout={formItemLayout}
    								values={this.state.invitation_user_password}
    							/>
    							<InputComponent
    								disabled = {true}
    								form={this.props.form}
    								layout={formItemLayout}
    								values={this.state.organization_name}
    							/>

    							<Form.Item className="no-bottom-margin">
    								<ComponentButton htmlType="submit" name="sign-up" text="Sign Up" css="dark-sky-blue eina-semibold-white-15" height="42"/>

    							</Form.Item>
    						</Form>
    					</Row>
    					<Row type="flex" justify="center">
    						<ComponentText text="Already have an account? " size="13" color="var(--greyblue)"/>
    						<Space width="6"/>
    						<Link to={"login"}><ComponentText text="Sign In Now" size="13" color="var(--azure)"/></Link>
    					</Row>
    					<Space height="20"/>
    				</div>
    			</Row>
          </div>
    		</Spin>
    	);
    }
}

const Join = Form.create({name: "join-up"})(JoinForm);

const mapActionToProps = {
  register,
};

// const mapStateToProps = state => ({
//   isLoggedIn: !!state.auth.token,
//   isAuthenticating: state.auth.isAuthenticating,
//   isAuthenticated: state.auth.isAuthenticated,
//   isAuthFailed: state.auth.isAuthFailed,
//   message: state.auth.message,
// });
const mapStateToProps = state => {
  const { signUpRemoteData, loginRemoteData} = state;

  console.log(signUpRemoteData);
  console.log(loginRemoteData);

  return {
    loginData: utils.getItem(loginRemoteData),
    data: utils.getItem(signUpRemoteData),
    isFetching: utils.getIsFetching(signUpRemoteData),
    isFailed: utils.getIsFailed(signUpRemoteData),
    signUpMessage: utils.getMessage(signUpRemoteData),
  };
};


export default connect(
  mapStateToProps,
  mapActionToProps
)(Join);

const formItemLayout = {
  labelCol: {
    xs: {span: 2},
    sm: {span: 2},
  },
  wrapperCol: {
    xs: {span: 2},
    sm: {span: 2},
  },
};
