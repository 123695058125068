import React from 'react';
import { connect } from 'react-redux';
import utils from '../../../utils/utils';

import { prepareInput } from '../../../utils/InputFieldCongifurations';
import { ComponentText } from '../../shared/ComponentText';
import { Form, message, Row, Spin } from 'antd';
import styled from 'styled-components';
import { InputComponent } from '../../shared/FormComponent/InputComponent';
import { ComponentButton } from '../../shared/ComponentButton';
import { ComponentDivider } from '../../shared/ComponentDivider';
import { ComponentIcon } from '../../shared/ComponentIcon';
import Space from '../../shared/Spcae';
import '@/components/Apps/apps.css';
import { createApp } from './AppsActionCreators';
import { getUser } from '../../../utils/Repository';

const title = 'Add your app';

const Styled = {};

Styled.FormCon = styled.div`
  padding: 24px 25px 30px 25px;
  background-color: var(--white);
`;

class Create extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onboardLoaderStarted: false,
      name: prepareInput(
        'name',
        'App Name',
        true,
        'Enter App Name',
        'Only used inside Purchasefly',
        'Enter Name',
      ),
      bundle_id: prepareInput(
        'bundle_id',
        'App Bundle ID',
        false,
        'e.g. com.myapp.bundleid',
        'Specified in your Xcode project',
        'Enter Bundle ID',
      ),
      password: prepareInput(
        'password',
        'iTunesConnect App-Specific Shared Secret',
        false,
        'e.g. b43fasd3rfasdr3faefde3r5qaddaf3tfa',
        'Specified in your Xcode project',
        'Enter Secret',
      ),
      android_package: prepareInput(
        'android_package',
        'Google Play package',
        false,
        'e.g. com.myapp.bundleid',
        'Specified in your apps build.gradle',
        'Enter Package Name',
      ),
      service_credential: prepareInput(
        'service_credential',
        'Service Account credentials JSON',
        false,
        'e.g. {"type": "service_account"...}',
        <a
          href="#"
          className="eina-semibold-dark-seafoam-green-10"
          style={{
            color: ' var(--dark-sky-blue)',
            width: '84px',
            height: '14px',
            fontFamily: 'SFProDisplay-Medium',
            fontSize: '12px',

            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
          }}
        >
          How to get this?
        </a>,
        'Enter Account',
      ),
    };
  }

  success = text => {
    if(this.props.from !== "onboard") {
      message.success(text);
    }
  };

  error = text => {
    if (text === null) return;
    message.error(text);
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const user = getUser();
        const app = {
          name: values.name,
          organization_id: user.organizations_roles[0].organization_id,
        };
        const store = [];
        const { bundle_id, password, android_package, service_credential } = values;

        if (bundle_id && password) {
          store.push({
            appstore_config: {
              bundle_id: values.bundle_id,
              password: values.password,
              p8_key: 'string',
              p8_key_id: 'string',
            },
          });
        }
        if (android_package && service_credential) {
          store.push({
            playstore_config: {
              package: values.android_package,
              key: values.service_credential,
            },
          });
        }
        if(store.length === 0){
          this.error("Please enter at least one store information");
        }else{
          this.setState({onboardLoaderStarted: true});
          this.props.onCreateApp(app, store, this.props.history);
        }

      }
    });
  };

  componentDidUpdate(previousProps) {
    const { message, isFailed } = this.props;

    if (isFailed === true) {
      if (previousProps.message !== this.props.message) this.error(message);
    }

    if(this.props.isFailed === true && this.props.isFailed !== previousProps.isFailed){
      this.setState({onboardLoaderStarted: false});
    }
    if (previousProps.data !== this.props.data && this.props.data) {
      if(this.props.from !== "onboard") {
        this.props.form.resetFields();
      }
      this.success("App Created");
    }
  }

  render() {
    const { isFetching, isFailed } = this.props;
    const styl = {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '30px',
      maxWidth: '534px',
    };

    return (

      <div style={{maxWidth: 534}}>
        <Spin spinning={this.props.from === "onboard" ? this.state.onboardLoaderStarted : isFetching ? true : false}>
          <div >
            <ComponentText
              text={title}
              size="20"
              color="var(--dark)"
              font="SFProDisplay-Semibold"
              marginTop={30}
              marginBottom={18}
            />
            <Form onSubmit={this.handleSubmit} style={styl} layout="vertical">
              <Styled.FormCon>
                <InputComponent
                  form={this.props.form}
                  layout={formItemLayout}
                  values={this.state.name}
                />

                <ComponentDivider marginTop={20} marginBottom={16} />

                <Row type="flex" justify="start">
                  <ComponentIcon type="ios" styl={{ width: 13, height: 16, marginRight: 8 }} />
                  <ComponentText
                    text="iOS Configuration"
                    size="14"
                    weight="600"
                    color="var(--greyish-brown)"
                    align="left"
                    marginTop="0"
                    marginBottom="0"
                    styl={{
                      width: '108px',
                      height: '19px',
                      fontFamily: 'SFProDisplay-Semibold',
                      fontSize: '14px',

                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: '1.36',
                      letterSpacing: 'normal',
                      color: '#404040',
                    }}
                  />
                  <Space width="6" />
                </Row>
                <Space height="20" />
                <InputComponent
                  form={this.props.form}
                  layout={formItemLayout}
                  values={this.state.bundle_id}
                />
                <InputComponent
                  form={this.props.form}
                  layout={formItemLayout}
                  values={this.state.password}
                />

                <ComponentDivider marginTop={20} marginBottom={16} />

                <Row type="flex" justify="start">
                  <ComponentIcon type="android" styl={{ width: 14, height: 16, marginRight: 8 }} />
                  <ComponentText
                    text="Android Configuration"
                    styl={{
                      width: '108px',
                      height: '19px',
                      fontFamily: 'SFProDisplay-Semibold',
                      fontSize: '14px',

                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: '1.36',
                      letterSpacing: 'normal',
                      color: '#404040',
                    }}
                  />
                  <Space width="6" />
                </Row>
                <Space height="20" />
                <InputComponent
                  form={this.props.form}
                  layout={formItemLayout}
                  values={this.state.android_package}
                />
                <InputComponent
                  form={this.props.form}
                  layout={formItemLayout}
                  values={this.state.service_credential}
                />
              </Styled.FormCon>
              <Space height="24" />
              <Form.Item className="no-bottom-margin">
                <ComponentButton
                  htmlType="submit"
                  name="add-new"
                  text="Add Now"
                  css="dodger-blue eina-semibold-white-15"
                  width="184"
                  height="42"
                  styl={{
                    width: '184px',
                    height: '42px',
                    borderRadius: '5px',
                    float: 'right',
                  // backgroundColor: 'var(--dark-sky-blue)',
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </div>

    );
  }
}

const mapActionToProps = {
  onCreateApp: createApp,
};

const mapStateToProps = state => {
  const { appsCreateRemoteData } = state;
  return {
    data: utils.getData(appsCreateRemoteData),
    isFetching: utils.getIsFetching(appsCreateRemoteData),
    isFailed: utils.getIsFailed(appsCreateRemoteData),
    message: utils.getMessage(appsCreateRemoteData),
  };
};

const ComponentAppCreate = Form.create({ name: 'apps-create' })(Create);

export default connect(mapStateToProps, mapActionToProps)(ComponentAppCreate);

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};
