import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Row, Skeleton } from 'antd';
import TimeAgo from 'react-timeago';
import { ComponentText } from '../ComponentText';
import Space from '../Spcae';
import { tableContentType } from '../Table/TableDetails';
import { Link } from 'react-router-dom';

const generate = require('string-to-color');


export class FormatTextTableDetails extends React.Component {
    prepareText = (type, record) => {
      switch (type) {
      case tableContentType.ID:
        return <ComponentText text={record.value} color="var(--azure)" size={15} font={"SFProDisplay-Medium"} />;
      case tableContentType.ID_VIEW: {
        let x = (record.value).split(',');
        return x[0].length > 0 && <Link key={x[0]} to={x[1]}><ComponentText text={x[0]} color="var(--azure)"
          size={15}
          font={"SFProDisplay-Medium"}/></Link>;
      }
      case tableContentType.LINK:
        return <ComponentText text={record.value} color="var(--azure)" size={15} font={"SFProDisplay-Medium"} />;
      case tableContentType.DATE_TIME:
        return <ComponentText text={ moment(record.value).utc().format("dddd, MMMM D, YYYY hh:mm A")} font={"SFProDisplay-Medium"} size={15} color={"var(--greyish-brown)"} />;
      case tableContentType.TIME_AGO:
        return <TimeAgo date={record.value} />;
      case tableContentType.PRODUCT: {
        let x = (record.value).split(',');
        return <Row type="flex" justify={"space-between"} align="middle"> <Row type="flex" align="middle">
          <ComponentText text={x[0]} color="var(--greyish-brown)"
            size={15} font={"SFProDisplay-Medium"}/>
          <Space width={16}/>
          <ComponentText text={x[1]} color="var(--dark-grey-blue)" size={15}
            font={"SFProDisplay-Medium"}/></Row>
        {x[2] && <Link key={x[0]} to={x[2]}><ComponentText text={"See Purchase History"} color="var(--dark-seafoam-green)" size={15}
          font={"SFProDisplay-Semibold"}/></Link>}
        </Row>;
      }
      case tableContentType.PRICE:
        return (
          <ComponentText
            text={'USD $' + record.value.toString()}
            color="var(--greyish-brown)"
            font={"SFProDisplay-Medium"}
            size={'15'}
          />
        );
      case tableContentType.TITLE:
        return <ComponentText text={record.title} font={"SFProDisplay-Semibold"} color="var(--dark-grey-blue-two)" size={'15'} />;
      case tableContentType.SHIMMER:
        return <Skeleton  active paragraph={{ rows: 0 }} />;
      default:
        return record.value;
      }
    };

    render() {
      let { type, record } = this.props;
      return this.prepareText(type, record);
    }
}
