import React from 'react';
import { Col, Row } from 'antd';
import images from '../images/Images';
import Space from './shared/Spcae';
import { ComponentText } from './shared/ComponentText';
import { ComponentIcon } from './shared/ComponentIcon';
import { ComponentButton } from './shared/ComponentButton';
import './UIComponent.css';
import { Fade } from 'react-reveal';
import { fonts } from '../utils/fonts';
import { Link } from 'react-router-dom';

export class UpgradeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  handleClick = () => {
    this.setState({ show: true });
  };

  render() {
    const {name} = this.props;
    return (
      <Row type="flex" align="middle" justify="space-between" className="upgrade-modal-view">
        {/*<ApexBarChart/>*/}
        <Col align={'right'}>
          <Fade delay={100} duration={500} force bottom when={this.state.show}>
            <Col
              className="upgrade-modal"
              align={'left'}
              style={{ display: this.state.show ? '' : 'none' }}
            >
              <img src={images.upgradeBanner} width={328}  alt={""}/>
              <Row className="upgrade-modal-top" type="flex" align="middle">
                <img src={images.startupIcon} width={84} alt={""} />
                <Space width="14" />
                <div className={"extra-feature-title"}>
                  Get even more return with a timely upgrade of your current plan.
                </div>
              </Row>
              <div className="upgrade-modal-title">
                <span className="extra-feature-subtext-regular">
                  Your subscription is currently on Purchasefly’s Free plan.
                  <span className="extra-feature-subtext-bold">
                    {' '}
                    The extra benefits with<a className="extra-feature-subtext-bold-dark-min">
                      {' '}
                      {name}
                    </a>{' '}
                    plan are:
                  </span>
                </span>
              </div>
              <Row className="upgrade-modal-features" type="flex" align="middle">
                <ComponentIcon type="upgrade-trending" />
                <Space width="12" />
                <ComponentText
                  text="Advanced charts and metrics"
                  color="var(--black)"
                  size="12"
                  font={fonts.SFProDisplaySemiBold}
                />
              </Row>
              <Row className="upgrade-modal-features" type="flex" align="middle">
                <ComponentIcon type="upgrade-team" />
                <Space width="12" />
                <ComponentText text="Team collaboration"  color="var(--black)"
                  size="12"
                  font={fonts.SFProDisplaySemiBold} />
              </Row>
              <Row className="upgrade-modal-features" type="flex" align="middle">
                <ComponentIcon type="upgrade-code-alt" />
                <Space width="12" />
                <ComponentText text="Backend integration"  color="var(--black)"
                  size="12"
                  font={fonts.SFProDisplaySemiBold} />
              </Row>
              <Row className="upgrade-modal-features" type="flex" align="middle">
                <ComponentIcon type="upgrade-notification" />
                <Space width="12" />
                <ComponentText
                  text="Alerts when something goes wrong"
                  color="var(--black)"
                  size="12"
                  font={fonts.SFProDisplaySemiBold}
                />
              </Row>
              <Row type="flex" align="middle" className="upgrade-modal-buttons">
                <Link to={"account?menu=billing"}>
                  <ComponentButton
                    name="update"
                    text={`Upgrade to ${name}`}
                    css="extra-feature-upgrade"
                    width="150"
                    height="30"
                  />
                </Link>
                <Space width="12" />
                <a
                  className="extra-feature-cancel"
                  onClick={() => {
                    this.setState({ show: false });
                  }}
                >
                  No, thanks
                </a>
              </Row>
            </Col>
          </Fade>

          <Fade bottom when={!this.state.show}>
            <div>
              <a
                onClick={() => {
                  this.setState({ show: true });
                }}
              >
                <Row type="flex" align="middle" className="upgrade-modal-button">
                  <div className="dot" />
                  <Space width="10" />
                  <ComponentText text="Get extra benefits" color="var(--white)" font={fonts.SFProDisplayMedium} size="12" />
                  <Space width="10" />
                  <div className="arrow-up" />
                </Row>
              </a>
            </div>
          </Fade>
        </Col>
      </Row>
    );
  }
}
