import React from 'react';
import { Radio } from 'antd';
import { localStorageKey } from '../../../utils/LocalStorage';
import './EnvironmentSelect.css';
import queryString from 'query-string';

export class EnvironmentSelect extends React.Component {

    onEnvironmentSelect = (value) =>{
      localStorage.setItem(localStorageKey.SELECTED_ENVIRONMENT, value);
      //this.setState({environment: value});
      let pathname = this.props.location.pathname;
      let searchParams = new URLSearchParams(this.props.location.search);
      searchParams.set("environment", value);
      searchParams.set("page", "1");
      this.props.history.push({
        pathname: pathname,
        search: searchParams.toString(),
      });
    };

    render () {
      return  <Radio.Group onChange={(e) => this.onEnvironmentSelect(e.target.value)} value={this.getSelectedValue()} className={"radio-environment"}>
        <Radio value={"ENV_PRODUCTION"}> Production </Radio>
        <Radio value={"ENV_SANDBOX"}> Staging </Radio>
      </Radio.Group>;
    }

    getSelectedValue () {
      const values = queryString.parse(this.props.location.search);
      return values["environment"] ? values["environment"] :"ENV_PRODUCTION";
    }
}