import React from 'react';
import { Col, Form, Modal, Row } from 'antd';
import { InputComponent } from './FormComponent/InputComponent';
import { InputTypes } from '../../utils/InputTypes';
import Space from './Spcae';
import { ComponentText } from './ComponentText';
import { ComponentButton } from './ComponentButton';
import { SelectComponent } from './FormComponent/SelectComponent';
import images from '../../images/Images';
import { ComponentIcon } from './ComponentIcon';

class ModalForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.form.resetFields();
        this.props.handleClose(values);
      }
    });
  };

  handleDelete = () => {
    this.props.form.resetFields();
    this.props.handleClose('delete');
    // this.setState({
    //     visible: false,
    // }, ()=>{this.props.handleClose('delete')})
  };

  handleCancel = () => {
    this.props.form.resetFields();
    this.props.handleClose();
    // this.setState({
    //     visible: false,
    // }, ()=>{this.props.handleClose()})
  };

  render() {
    const { values, visible } = this.props;

    const { title, items, buttonCancel, buttonDelete, acceptButtonTitle, showInfo, showWarning, warningTitle } = values;

    return (
      <Modal
        className="apps"
        title={
          <ComponentText
            text={title}
            color="var(--black)"
            align="left"
            marginTop="0"
            marginBottom="0"
            styl={{}}
          />
        }
        centered
        visible={visible}
        closable={false}
        footer={null}
        onCancel={() => this.handleCancel(false)}
      >

        { showWarning === true && <Row className={"product-create-warning"} type={"flex"} justify="start">
          {/*<img height={28} width={28} src={images.info} alt={""}/>*/}
          <ComponentIcon type={'warning'} styl={{ width: 28, height: 28}}/>
          <div className={"product-create-info-content"} >
            <ComponentText
              text={`If your app is in production and it uses this name to access the ${warningTitle}, it will crash.`}
              font="SFProDisplay-Regular"
              size={14}
              color="var(--dark)"
            />
          </div>
        </Row>}
        <Form onSubmit={this.handleSubmit} layout="vertical">
          {items.map((e, i) =>
            e.type === InputTypes.INPUT ? (
              <InputComponent
                key={i}
                form={this.props.form}
                layout={formItemLayout}
                values={e.value}
              />
            ) : (
              <SelectComponent
                key={i}
                form={this.props.form}
                layout={formItemLayout}
                values={e.value}
              />
            ),
          )}
          <Space height="10" />
          { showInfo === true ? <Row className={"product-create-info"} type={"flex"} justify="start">
            <img height={28} width={28} src={images.info} alt={""}/>
            <div className={"product-create-info-content"} >
              <ComponentText
                text="Please open PurchaseFly SDK enabled application to sync pricing with PurcahseFly after adding each product."
                font="SFProDisplay-Regular"
                size={14}
                color="var(--dark)"
              />
            </div>
          </Row>: null}
          <Space height="10" />
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              {buttonDelete === true ? (
                <Form.Item className="no-bottom-margin">
                  <ComponentButton
                    name="delete"
                    htmlType="button"
                    text="Delete"
                    css="stroke-salmon eina-semibold-grapefruit-15"
                    width="88"
                    height="42"
                    handleClick={this.handleDelete}
                  />
                </Form.Item>
              ) : null}
            </Col>
            <Col>
              <Row type="flex" justify="end" align="middle">
                {buttonCancel === true ? (
                  <Form.Item className="no-bottom-margin">
                    <ComponentButton
                      htmlType="button"
                      name="cancel"
                      text="Cancel"
                      css="stroke-cloudy-blue eina-semibold-cool-grey-15"
                      width="122"
                      height="42"
                      stylText={{color: 'var(--cool-grey)', fontSize: '15px', fontWeight: 600}}
                      handleClick={this.handleCancel}
                    />
                  </Form.Item>
                ) : null}
                <Space width="10" />
                <Form.Item className="no-bottom-margin">
                  <ComponentButton
                    htmlType="submit"
                    name={acceptButtonTitle}
                    text={acceptButtonTitle}
                    css="dark-sky-blue eina-semibold-white-15"
                    width="186"
                    height="42"
                  />
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

const ComponentModalForm = Form.create({ name: 'modal-form' })(ModalForm);

export default ComponentModalForm;

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};
