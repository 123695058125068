import React from 'react';

class Space extends React.Component {

  render() {
    const {height, width, color} = this.props;
    const style = {height: height + "px",
      width: width + "px",
      backgroundColor: color};
    return <div style={style}/>;
  }
}
export default Space;
