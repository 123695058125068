export const BASEURL = process.env.REACT_APP_BASE_URL;
export const CUSTOMER = process.env.REACT_APP_CUSTOMER;
export const USER = process.env.REACT_APP_USER;


export const APPLE_VERIFY_RECEIPT_URL =
  process.env.REACT_APP_APPLE_VERIFY_RECEIPT_URL;
export const APPLE_VERIFY_RECEIPT_SANDBOX_URL =
  process.env.REACT_APP_APPLE_VERIFY_RECEIPT_SANDBOX_URL;
export const APPLE_VERIFY_RECEIPT_PASSWORD =
  process.env.REACT_APP_APPLE_VERIFY_RECEIPT_PASSWORD;
export const ANDROID_APP_PACKAGE_NAME =
  process.env.REACT_APP_ANDROID_PACKAGE_NAME;
export const GOOGLE_VERIFY_RECEIPT_URL =
  process.env.REACT_APP_GOOGLE_VERIFY_RECEIPT_URL;
export const CORS_ANYWHERE_PROXY_URL =
  process.env.REACT_APP_CORS_ANYWHERE_PROXY_URL;
