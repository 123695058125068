import React from 'react';
import { Badge, Col, Row, Tooltip } from 'antd';
import { ComponentButton } from './shared/ComponentButton';
import { ComponentIcon } from './shared/ComponentIcon';
import { ComponentPromoCode } from './shared/ComponentPromoCode';
import Space from './shared/Spcae';
import { ComponentText } from './shared/ComponentText';
import { Logo } from './shared/Logo';
import { CardInfoSmall } from './shared/Table/CardInfoSmall';
import images from '../images/Images';
import { ComponentTag } from './shared/ComponentTag';
import { ComponentCopyButton } from './shared/ComponentCopyButton';
import './UIComponent.css';
import { UpgradeModal } from './UpgradeModal';
import ChartDetails from './shared/Chart/ChartDetails';
import { ComponentCircularProgress } from './shared/ComponentCircularProgress';
import { fonts } from '../utils/fonts';

const generate = require('string-to-color');

export class UIComponents extends React.Component {

    state = {
    	items: getItems(3),
    	selected: getItems(3, 8),
    };


    render() {
    	return <div>
    		<Row type="flex" justify="space-between" style={style}>
    			<ComponentCircularProgress total={43522} renew={20000} expire={6500} />
    			<div style={{backgroundColor: "#000000"}}>
    				<Badge dot>
    					<ComponentButton name="edit-product-group" text="" iconLeft="notification" css="link eina-semibold-cool-grey-14" iconSize={18} handleClick={this.onButtonClick} width={20}/>
    				</Badge>

    			</div>
    			<ComponentTag fontSize={"13"} color={generate("phoneLine")} text={"PhoneLine"}/>
    			<ComponentTag fontSize={"13"} color={generate("preankdial")} text={"PrankDial"}/>
    			<ComponentIcon type="transactions" width={15}/>
    			<Tooltip contentStyle={{backgroundColor: '#4A4A4A'}} placement="bottomLeft" title={"Products Groups represent a App Store Connect group of products. Please arrange them how you arranged in App Store Connect."}>
    				<a style={{paddingLeft: 10,
    					height: 15}}><ComponentIcon type="help" width={15}/></a>
    			</Tooltip>


    			<ComponentButton name="create" iconLeft="apps" text="Add new App" css="stroke-cloudy-blue eina-semibold-dark-12"/>
    			<ComponentButton name="add-collaborator" iconLeft="add" text="Add Collaborator" css="stroke-cloudy-blue eina-semibold-dark-12" height="32"/>
    			<ComponentButton name="back" iconLeft="previous" text="My Apps" css="stroke-cloudy-blue eina-regular-slate-grey-14" iconSize={20} height="36"/>
    		</Row>
    		<Row type="flex" justify="space-between" style={style}>
    			<ComponentButton htmlType="submit" name="add-collaborator" text="Add Collaborator" css="dark-mint eina-semibold-white-15" width="186" height="42"/>
    			<ComponentButton htmlType="submit" name="change-card" text="Change card" css="cool-grey eina-semibold-white-14" width="186" height="42"/>
    			<ComponentButton name="delete" htmlType="button" text="Delete" css="stroke-salmon eina-semibold-grapefruit-15" width="88" height="42"/>
    			<ComponentButton htmlType="button" name="cancel" text="Cancel" css="stroke-cloudy-blue eina-semibold-cool-grey-15" width="122" height="42" handleClick={this.handleCancel}/>
    		</Row>
    		<Row type="flex" justify="space-between" style={style}>
    			<ComponentPromoCode/>
    			{/* <ComponentAppSelect/> */}
    			<CardInfoSmall icon={images.purchases} title="PURCHASES" count="121"/>

    			<ComponentButton name="create" iconLeft="previous" text="" css="icon" width="25" iconSize={14}/>
    			<ComponentButton name="create" iconLeft="next" text="" css="icon" width="25" iconSize={25}/>

    			<ComponentButton name="copy" icon="copy" text="" css="icon-only" width="25" iconSize={14}/>
    			<ComponentCopyButton data={"12345"}/>


    		</Row>
    		<Row type="flex" justify="space-between" style={style}>
    			<ComponentButton name="add-product-group" text="New Product Group" iconLeft="add" css="stroke-cloudy-blue eina-semibold-dark-12" height="32" handleClick={this.onButtonClick}/>
    			<ComponentButton name="edit-product-group" text="Edit" iconLeft="edit" css="link eina-semibold-dark-12" handleClick={this.onButtonClick} />
    		</Row>
    		<div style={style}>
    			<Row type="flex" align="middle" justify="space-between">
    				<ComponentText text="Order/Invoice ID: 239589" size="16" color="var(--black)"/>
    				<Col>
    					<ComponentButton name="edit-product-group" text="Print" iconLeft="print" css="link eina-semibold-cool-grey-14" iconSize={22} handleClick={this.onButtonClick} />
    					<ComponentButton name="edit-product-group" text="Download" iconLeft="download" css="link eina-semibold-cool-grey-14" iconSize={22} handleClick={this.onButtonClick} />
    				</Col>
    			</Row>
    			<Row type="flex" style={{marginTop: 30}}>
    				<Col span={12}>
    					<ComponentText text="Greg Mori" size="18" color="var(--black)"/>
    					<ComponentText text="greg@dropbox.com" size="14" color="var(--greyish-brown)"/>
    					<ComponentText text="MasterCard ending in" font="Eina-Regular" size="14" color="var(--slate-grey"/>
    					<ComponentText text="0423" size="14" color="var(--slate-grey"/>

    				</Col>
    				<Col span={12}>
    					<ComponentText text="Nadim Hussain" size="18" color="var(--black)"/>
    					<ComponentText text="750 Grand Street," size="14" color="var(--greyish-brown)"/>
    					<ComponentText text="Brooklyn, New York 11211" size="14" color="var(--greyish-brown)"/>
    				</Col>

    			</Row>
          <Row>
            <Col span={6}>
              <ComponentText font={fonts.SFProDisplaySemiBold} text={"Item"} color={"var(--dark-grey-blue)"} size={14}/>

              <ComponentText font={fonts.SFProDisplaySemiBold} text={"Item"} color={"var(--dark)"} size={14}/>

            </Col>
          </Row>

    			<Row type="flex" align="middle" justify="center">
    				<ComponentText text="Thank you." size="25" color="var(--cool-grey)" marginBottom="36"/>
    			</Row>
    			<Space height="3" color="var(--cool-grey)"/>
    			<Row type="flex" align="middle" justify="space-between" style={{marginTop: 20}}>
    				<Logo invoice={true} height="30"/>

    				<ComponentText text="Questions about your order? Contact us at: support@purchasefly.com" size="12" color="var(--cool-grey)"/>

    			</Row>

    			<Row type="flex" align="middle" justify="space-between" style={{marginTop: 20}}>
                    credit-card <ComponentIcon type="credit-card" width={15}/>
                    home <ComponentIcon type="home" width={15}/>
                    analytics <ComponentIcon type="analytics" width={15}/>
                    apps <ComponentIcon type="apps" width={15}/>
                    yes <ComponentIcon type="yes" width={15}/>
                    no <ComponentIcon type="no" width={15}/>
                    notification <ComponentIcon type="notification" width={15}/>
                    documents <ComponentIcon type="documents" width={15}/>
                    support <ComponentIcon type="support" width={15}/>
                    account <ComponentIcon type="account" width={15}/>
                    customer <ComponentIcon type="customer" width={15}/>
                    next <ComponentIcon type="next" width={15}/>
    			</Row>
    			<Row type="flex" align="middle" justify="space-between" style={{marginTop: 20}}>
                    previous <ComponentIcon type="previous" width={15}/>
                    ios <ComponentIcon type="ios" width={15}/>
                    android <ComponentIcon type="android" width={15}/>
                    add <ComponentIcon type="add" width={15}/>
                    edit <ComponentIcon type="edit" width={15}/>
                    print <ComponentIcon type="print" width={15}/>
                    download <ComponentIcon type="download" width={15}/>
                    cancel <ComponentIcon type="cancel" width={15}/>
                    copy <ComponentIcon type="copy" width={15}/>
                    star <ComponentIcon type="star" width={15}/>
                    promotion <ComponentIcon type="promotion" width={15}/>
                    lastSeen <ComponentIcon type="lastSeen" width={15}/>
    			</Row>
    			<Row type="flex" align="middle" justify="space-between" style={{marginTop: 20}}>
                    calender <ComponentIcon type="calender" width={15}/>
                    install <ComponentIcon type="install" width={15}/>
                    segment <ComponentIcon type="segment" width={15}/>
                    subscription <ComponentIcon type="subscription" width={15}/>
                    users <ComponentIcon type="users" width={15}/>
                    arrow-down <ComponentIcon type="arrow-down" width={15}/>
                    search <ComponentIcon type="search" width={15}/>
                    help <ComponentIcon type="help" width={15}/>
                    transactions <ComponentIcon type="transactions" width={15}/>
                    more <ComponentIcon type="more" width={15}/>
    			</Row>
    			<Row type="flex" align="middle" justify="space-between" style={{marginTop: 20}}>
    				{/*<ApexBarChart/>*/}
    				<ChartDetails/>
    			</Row>
    			<UpgradeModal/>
    		</div>
    		{/* <Row type="flex">

                <DragDropContext onDragEnd={this.onDragEnd}>

                    <Col span={24}>
                        <Row>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}>
                                        {this.state.items.map((item, index) => (
                                            <Draggable
                                                isDragDisabled={this.state.items.length === 1}
                                                key={item.id}
                                                draggableId={item.id}
                                                index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}>
                                                        {item.content}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Row>
                        <Space height="10"></Space>
                        <Row>
                            <TableHeader/>
                            <Droppable droppableId="droppable2">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}>
                                        {this.state.selected.map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id}
                                                index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style,
                                                            index === this.state.selected.length - 1
                                                        )}>
                                                        {item.content + "P"}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Row>
                    </Col></DragDropContext>

            </Row>
         */}
    	</div>;
    }
}



const style = {marginTop: 10,
  marginBottom: 10};



// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({length: count}, (v, k) => k).map(k => ({
    id: `item-${k + offset}`,
    content: `item ${k + offset}`,
  }));

