import axios from '../../../utils/AxiosWrapper';

import { message } from 'antd';
import { BASEURL, CUSTOMER } from '../../../utils/constants';
import { contentType } from '../../../utils/ActionTypes';
import { createAction } from '@reduxjs/toolkit';

export const REQUEST = 'REQUEST_';
export const RECEIVE = 'RECEIVE_';
export const FAILED = 'FAILED_';

export function createApp(app, store) {
  const type = contentType.APPS_CREATE;
  const request = createAction(REQUEST + type.name);
  const receive = createAction(RECEIVE + type.name);
  const failed = createAction(FAILED + type.name);
  const url = BASEURL + CUSTOMER + 'application';
  const storeUrl = BASEURL + CUSTOMER + 'store';

  return async dispatch => {
    dispatch(request());

    try {
      const resp = await axios.post(url, app);

      const storeReq = {
        application_id: resp.data.id,
        organization_id: app.organization_id,
        config: store
      };

      const respStore = await axios.post(storeUrl, storeReq );
      dispatch(receive(respStore.data));

    } catch (error) {

      dispatch(failed(error.data));
    }
  };
}
export function createStore(store, appID, orgID) {
  const type = contentType.STORE_CREATE;
  const request = createAction(REQUEST + type.name);
  const receive = createAction(RECEIVE + type.name);
  const failed = createAction(FAILED + type.name);

  const storeUrl = BASEURL + CUSTOMER + 'store';

  return async dispatch => {
    dispatch(request());

    try {

      const storeReq = {
        application_id: appID,
        organization_id: orgID,
        config: store
      };

      const respStore = await axios.post(storeUrl, storeReq );
      dispatch(receive(respStore.data));

    } catch (error) {
      dispatch(failed(error.response.data));
    }
  };
}
export function storeUpdate(req) {
  const { appstore_config, playstore_config, id } = req;
  const type = contentType.STORE_UPDATE;
  const request = createAction(REQUEST + type.name);
  const receive = createAction(RECEIVE + type.name);
  const failed = createAction(FAILED + type.name);

  return async dispatch => {
    dispatch(request());

    try {
      let resp = '';
      if (appstore_config) {
        const url = BASEURL + CUSTOMER + `store/${id}`;
        resp = await axios.put(url, { config: { appstore_config }});
      }
      else if (playstore_config) {
        const url = BASEURL + CUSTOMER + `store/${id}`;
        resp = await axios.put(url, { config: { playstore_config }});
      }

      // no need to dispatch actually
      dispatch(receive(req));
      // history.push('/app/list');

    } catch (error) {
      console.log(error);
      dispatch(failed(error));
    }
  };
}
