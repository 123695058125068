import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import utils from '../../../utils/utils';

export class ChartSegmentTable extends React.Component {

  render () {

    const {interval, list, isStacked, isFetching, titles, keys} = this.props;

    let columns = [];
    let data = [];

    titles.forEach((e,i) =>{
      data.push({
        name: e,
        key: e,
      });
    });
    columns.push({
      title: 'Segment',
      width: 160,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    });
    list.forEach((e,i)=> {
      columns.push(
        {
          title: moment(e.date).format( interval === null ?"MMM’YY" : "MMM’DD"),
          key: e.date + i,
          width: list.length > 5 ? 100 : null,
          render: (text, record, index) =>
            (isStacked === true ? ((list[i][keys[index]]))
              : `$${utils.formatNumber(list[i].value)}`  ),
        });
    });
    return <Table
      className={"segment-table"}
      loading={isFetching} columns={columns}
      dataSource={data}
      scroll={{ x: 1300 }}
      pagination={false} />;
  }

}


