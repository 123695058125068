
import { contentType } from '../../utils/ActionTypes';
import { createReducerWraper } from '../../utils/Reducer';


export const alertCreateReducer = createReducerWraper(contentType.ALERT_CREATE);
export const alertReducer = createReducerWraper(contentType.ALERT);
export const alertUpdateReducer = createReducerWraper(contentType.ALERT_UPDATE);
export const alertListReducer = createReducerWraper(contentType.ALERT_LIST);
export const alertDeleteReducer = createReducerWraper(contentType.ALERT_DELETE);
