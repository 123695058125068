import React from 'react';
import { Col, Divider, Modal, Row } from 'antd';
import { ComponentText } from './../ComponentText';
import { fonts } from '../../../utils/fonts';
import Space from '../Spcae';
import { ComponentIcon } from '../ComponentIcon';
import { getUser } from '../../../utils/Repository';
import * as uuid from 'uuid';
import moment from 'moment';
import { ComponentTag } from '../ComponentTag';
import images from '../../../images/Images';

export default class InvoiceModal extends React.Component {

  handleCancel = () => {
    this.props.handleClose();
  };

  getPlan = (product) => {
    if(product && product.subscription_plans) {
      let p = product.subscription_plans.find(e => e.product_id === product.id);
      return p.type.replace("SubscriptionPlanType", "");
    }
  };


  render() {
    const { invoice, visible } = this.props;
    const paid =  invoice.status !== 'STATUS_UNPAID';
    const item = [];
    if(invoice) {
      item.push({
        id: uuid(),
        title: "Item",
        value: `${invoice.product.product.name} (${this.getPlan(invoice.product.product)})`
      });
      item.push({
        id: uuid(),
        title: "Date Purchased",
        value: moment(invoice.product.purchased_at,"YYYY-MM-DDTHH:mm:ssZ" ).format("MMM DD, YYYY hh:mm A")
      });
      item.push({
        id: uuid(),
        title: "Expires",
        value: moment(invoice.product.expires_at, "YYYY-MM-DDTHH:mm:ssZ").format("MMM DD, YYYY hh:mm A")
      });
      item.push({
        id: uuid(),
        title: "Amount",
        value: "$"+this.getPrice(invoice.product, false)
      });
    }
    const overage = [];

    if(invoice && invoice.product.overage_cost && invoice.product.overage_cost > 0) {
      overage.push({
        id: uuid(),
        title: "Item",
        value: `Overage`
      });
      overage.push({
        id: uuid(),
        title: "Date Purchased",
        value: ""
      });
      overage.push({
        id: uuid(),
        title: "Expires",
        value: ""
      });
      overage.push({
        id: uuid(),
        title: "Amount",
        value: "$"+Math.round(invoice.product.overage_cost)
      });
    }
    const user = getUser();
    return  <Modal
      className="model-invoice"
      title={<Row type="flex" align="middle">
        <Space width={12}/><ComponentText text={"Order/Invoice ID: "+invoice.id} size="16" color="var(--black)" font={fonts.SFProDisplaySemiBold}/>
        {/*<Row type="flex" align="middle">*/}
        {/*  <ComponentButton name="edit-product-group" text="Print" iconLeft="print" css="link eina-semibold-cool-grey-14" iconSize={22} handleClick={this.onButtonClick} />*/}
        {/*  <ComponentButton name="edit-product-group" text="Download" iconLeft="download" css="link eina-semibold-cool-grey-14" iconSize={22} handleClick={this.onButtonClick} />*/}
        {/*  <Space width={64}/>*/}
        {/*</Row>*/}
      </Row>}
      closeIcon={<ComponentIcon type="cancel" width={15}/>}
      visible={visible}
      centered
      // onOk={this.handleOk}
      onCancel={this.handleCancel}
      footer={null}>
      <div className={"invoice-content"}>
        <Row style={{marginTop: 6}} type="flex"  justify="space-between">
          <Col span={8}>
            <ComponentText text={user.name} size="18" color="var(--black)" font={fonts.SFProDisplaySemiBold}/>
            <ComponentText text={user.email} size="14" color="var(--greyish-brown)" font={fonts.SFProDisplaySemiBold}/>
            { invoice.product.card && <Row type="flex">
              <ComponentText text={invoice.product.card.brand + " ending in"}  size="14" color="var(--slate-grey" font={fonts.SFProDisplayRegular}/>
              <Space width={4}/>
              <ComponentText text={invoice.product.card.number} size="14" color="var(--slate-grey" font={fonts.SFProDisplaySemiBold}/>
            </Row>}
          </Col>
          <Col span={8}/>
          <Col span={8}>
            <ComponentText text="Purchasefly, Inc." size="18" color="var(--black)"  font={fonts.SFProDisplaySemiBold}/>
            <ComponentText text="750 Grand Street," size="14" color="var(--greyish-brown)"  font={fonts.SFProDisplaySemiBold}/>
            <ComponentText text="Brooklyn, New York 11211" size="14" color="var(--greyish-brown)"  font={fonts.SFProDisplaySemiBold}/>
          </Col>
        </Row>
        <Space height={44}/>
        <Row>
          {
            item.map((e,index) =>  <Col span={index < 3 ? 7: 3} key={e.id} className={"invoice-col-"+index}>
              <ComponentText font={fonts.SFProDisplaySemiBold} text={e.title} color={"var(--dark-grey-blue)"} size={14}/>
              <div className={"invoice-item"}>
                <ComponentText font={fonts.SFProDisplaySemiBold} text={e.value} color={index === 0 ? "var(--dark)" : "var(--greyish-brown)"} size={14}/>
              </div>
            </Col>)
          }
        </Row>
        { invoice.product.overage_cost && invoice.product.overage_cost > 0 ? <Row>
          {
            overage.map((e,index) =>  <Col span={index < 3 ? 7: 3} key={e.id} className={"invoice-col-"+index}>

              <div className={"invoice-item-overage"}>
                <ComponentText font={fonts.SFProDisplaySemiBold} text={e.value} color={index === 0 ? "var(--dark)" : "var(--greyish-brown)"} size={14}/>
              </div>
            </Col>)
          }
        </Row> : <div className={"invoice-item-divider"}/>}

        <Space height={16}/>
        <Row type="flex"  justify="space-between">
          {paid ? <ComponentTag fontSize={"13"} color={"var(--dark-seafoam-green)"} text={"Paid"}/> :
            <ComponentTag fontSize={"12"} color={"var(--dark-grey-blue)"} text={"Unpaid"}  font={fonts.SFProDisplaySemiBold}/>}
          <Row type="flex" >
            <ComponentText font={fonts.SFProDisplaySemiBold} text={"Total"} color={"var(--dark-grey-blue)"} size={14}/>
            <Space width={58}/>
            <ComponentText font={fonts.SFProDisplaySemiBold} text={"$"+this.getPrice(invoice.product, true)} color={"var(--dark)"} size={18}/>
          </Row>
        </Row>
        <Space height={240}/>
        <Row type="flex" align="middle" justify="center">
          <ComponentText text="Thank you." size="25" color="var(--cool-grey)" marginBottom="36" font={fonts.SFProDisplayBold}/>
        </Row>
        <Divider style={{height: 4}}/>
        <Row type="flex" justify="space-between">
          <img src={images.logoInvoice} height={30}/>
          <ComponentText text="Questions about your order? Contact us at: support@purchasefly.com" size="12" color="var(--cool-grey)" font={fonts.SFProDisplaySemiBold}/>
        </Row>
        <Space height={20}/>
      </div>
    </Modal>;
  }

  getPrice = (invoice, f) => {

    const overage = f === true ? Math.round(invoice.overage_cost) : 0;

    switch (invoice.type) {
    case 'TYPE_BOOKING':
    case 'TYPE_PRODUCTION':
      return invoice.discount_price + overage;
    case 'TYPE_OVERAGE':
      return invoice.original_price + overage;
    default:
      return '0';
    }
  };
}


