import React from 'react';
import { Tag } from 'antd';
import { fonts } from '../../utils/fonts';

export class ComponentTag extends React.Component {

  render() {
    const {text, font, size, color} = this.props;
    const style = {color: color,
      height: "24px",
      background: "var(--white)",
      borderColor: color,
      borderRadius: 12,
      fontSize: `${size}px`,
      fontFamily: font ? font: fonts.SFProDisplayRegular,
      textAlign: "center",
      paddingTop: 1};

    return <Tag style={style} >{text}</Tag>;
  }

}