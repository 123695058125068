import React from 'react';

import { Table } from 'antd';
import './tableDetails.css';
import { FormatTextTableDetails } from '../FormatText/FormatTextTableDetails';
import { tableContentType } from './TableDetails';

export class TableDetailsNew extends React.Component {

  render() {
    let {data, isFetching, isFailed, attributes} = this.props;

    const result = attributes.map((e, i) => { return {
      key: i,
      title: e.title,
      value: data ? data[e.field]: ""
    };});
    const columns = [
      {
        dataIndex: "title",
        render: (text, record, index) => (<FormatTextTableDetails type={tableContentType.TITLE} record={record}/>),
        width: 200,
      },
      {
        dataIndex: "value",
        render: (text, record, index) => (<FormatTextTableDetails type={data ? attributes[index].type : tableContentType.SHIMMER} record={record}/>),
      },
    ];
    return <Table className="table-details" dataSource={result} loading={false} columns={columns} showHeader={false} bordered={true} pagination={false}/>;
  }
}

