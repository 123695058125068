
import { FAILED, RECEIVE, REQUEST } from '../../../utils/ActionCreators';
import { contentType } from '../../../utils/ActionTypes';
import { saveItem } from '../../../utils/LocalStorage';
import moment from 'moment';
import { createReducer } from '@reduxjs/toolkit';

const type = contentType.APPS_LIST;
export const appsListReducer = createReducer(
  {},
  {
    [REQUEST + type.name]: (state, action) => {
      return Object.assign({}, state, {
        isFetching: true,
        response: null,
        isFailed: false,
        message: null,
      });
    },
    [RECEIVE + type.name]: (state, action) => {
      //localStorage.setItem(type.name, JSON.stringify(action.payload))
      saveItem(type.name, action.payload, moment());
      return Object.assign({}, state, {
        isFetching: false,
        response: action.payload,
        isFailed: false,
        message: null,
      });
    },
    [FAILED + type.name]: (state, action) => {
      return Object.assign({}, state, {
        isFetching: false,
        response: null,
        isFailed: true,
        message: action.payload.message,
      });
    },
  }
);
