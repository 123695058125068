
import { FAILED, RECEIVE, REQUEST } from '../../../utils/ActionCreators';
import { contentType } from '../../../utils/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

export const appsCreateReducer = createReducer(
  {},
  {
    [REQUEST + contentType.APPS_CREATE.name]: (state, action) => {
      return Object.assign({}, state, {
        isFetching: true,
        response: null,
        isFailed: false,
        message: null,
      });
    },
    [RECEIVE + contentType.APPS_CREATE.name]: (state, action) => {

      return Object.assign({}, state, {
        isFetching: false,
        response: action.payload,
        isFailed: false,
        message: "App created",
      });
    },
    [FAILED + contentType.APPS_CREATE.name]: (state, action) => {
      console.log(action.payload);
      return Object.assign({}, state, {
        isFetching: false,
        response: null,
        isFailed: true,
        message: action.payload ? action.payload.message: "App created failed",
      });
    },
  }
);
