import React from 'react';
import forgotPassEmailSent from '../../images/forgot-pass-email-sent.png';
import { ComponentText } from '../shared/ComponentText';
import Space from '../shared/Spcae';
import { Row, Spin } from 'antd';
import { TopBar } from './Topbar';
import { forgotPassword } from './authActionCreators';
import { connect } from 'react-redux';
import { fonts } from '../../utils/fonts';
import utils from '../../utils/utils';

class ResetEmailSent extends React.Component{

    handleResendEmail = () => {
      const query = new URLSearchParams(this.props.location.search);
      const email = query.get('email');
      this.props.forgotPassword(email);
    };
    componentDidMount() {
      if (utils.isLoggedIn(this.props.loginData)) {
        this.props.history.push('/dashboard');
      }
    }

    render () {

      const query = new URLSearchParams(this.props.location.search);
      const email = query.get('email');

      return   <Spin spinning={ this.props.isFetching === true} >
        <TopBar/>
        <div style={{height:window.innerHeight - 71, backgroundColor:"#fafafa"}}>
          <Row justify={"center"} type={"flex"}>
            <div className={"reset-email-container"}>

              <Row type={"flex"} justify={"center"} align={"middle"}>
                <img src={forgotPassEmailSent} alt={""}/>
              </Row>
              <Space height={36}/>
              <ComponentText
                text="Password reset email sent"
                size={23}
                font={fonts.SFProDisplayRegular}
                color="var(--black)"
                align={"center"}
              />
              <Space height={12}/>

              <ComponentText
                text={`We have sent an email to ${email}.<br/> Follow the instructions in the email to reset your<br/> password.`}
                size={15}
                font={fonts.SFProDisplayRegular}
                color="var(--cool-grey)"
                align={"center"}
              />
              <Space height={24}/>

              <Row type={"flex"} justify={"center"} align={"middle"}>
                <ComponentText text="Didn't receive code?"  font={fonts.SFProDisplayRegular} size="13" color="var(--cool-grey)" />
                <Space width="5" />
                <a onClick={this.handleResendEmail}>
                  <ComponentText text="Resend Email" size="13"  font={fonts.SFProDisplayRegular} color="var(--azure)" />
                </a>
              </Row>
            </div>
          </Row>
        </div>
      </Spin>;
    }

}

const mapActionToProps = {
  forgotPassword,
};

const mapStateToProps = state => {
  const {forgetPasswordRemoteData, loginRemoteData} = state;

  return {
    data: utils.getItem(forgetPasswordRemoteData),
    isFetching: utils.getIsFetching(forgetPasswordRemoteData),
    isFailed: utils.getIsFailed(forgetPasswordRemoteData),
    loginData: utils.getItem(loginRemoteData),
  };
};


export default connect(mapStateToProps, mapActionToProps)(ResetEmailSent);
