import { CUSTOMER } from './constants';

export const REQUEST = 'REQUEST_';
export const RECEIVE = 'RECEIVE_';

export const contentType = {
  BOILERPLATE_LIST: { name: 'BOILERPLATE_LIST', value: 0 },
  BOILERPLATE: { name: 'BOILERPLATE', value: 1 },
  BOILERPLATE_CREATE: { name: 'BOILERPLATE_CREATE', value: 3 },

  APPS_LIST: { name: 'APPS_LIST', value: 0, prefix: CUSTOMER },
  APPS: { name: 'APPS', value: 1, prefix: CUSTOMER },
  APPS_CREATE: { name: 'APPS_CREATE', prefix: CUSTOMER, value: 3 },
  APPS_TEST_WEB_HOOK: { name: 'APPS_TEST_WEB_HOOK', prefix: CUSTOMER, value: 3 },
  APPS_UPDATE: { name: 'APPS_UPDATE', value: 4, prefix: CUSTOMER },

  STORE: { name: 'STORE', value: 1, prefix: CUSTOMER },
  STORE_UPDATE: { name: 'STORE_UPDATE', value: 4, prefix: CUSTOMER },
  STORE_CREATE: { name: 'STORE_UPDATE', value: 3, prefix: CUSTOMER },

  APP_SECRET_LIST: { name: 'APP_SECRET_LIST', value: 0, prefix: CUSTOMER },
  APP_SECRET_CREATE: { name: 'APP_SECRET_CREATE', value: 3, prefix: CUSTOMER },
  PRODUCT_LIST: { name: 'PRODUCT_LIST', value: 0, prefix: CUSTOMER },
  PRODUCT_GROUP_LIST: { name: 'PRODUCT_GROUP_LIST', value: 0, prefix: CUSTOMER },
  PRODUCT_GROUP_DELETE: { name: 'PRODUCT_GROUP_DELETE', value: 2, prefix: CUSTOMER },
  PRODUCT_GROUP_CREATE: { name: 'PRODUCT_GROUP_CREATE', value: 3, prefix: CUSTOMER },
  PRODUCT_GROUP_UPDATE: { name: 'PRODUCT_GROUP_UPDATE', value: 4, prefix: CUSTOMER },
  PACKAGE_DELETE: { name: 'PACKAGE_DELETE', value: 2, prefix: CUSTOMER },
  PACKAGE_CREATE: { name: 'PACKAGE_CREATE', value: 3, prefix: CUSTOMER },
  PACKAGE_UPDATE: { name: 'PACKAGE_UPDATE', value: 4, prefix: CUSTOMER },
  PRODUCT_DELETE: { name: 'PRODUCT_DELETE', value: 2, prefix: CUSTOMER },
  PRODUCT_CREATE: { name: 'PRODUCT_CREATE', value: 3, prefix: CUSTOMER },
  PRODUCT_ACTIVATE: { name: 'PRODUCT_ACTIVATE', value: 3, prefix: CUSTOMER },
  PRODUCT_UPDATE: { name: 'PRODUCT_UPDATE', value: 4, prefix: CUSTOMER },

  ACCOUNT_LIST: { name: 'ACCOUNT_LIST', value: 0, prefix: CUSTOMER },
  ACCOUNT: { name: 'ACCOUNT', prefix: CUSTOMER, value: 1 },
  ACCOUNT_CREATE: { name: 'ACCOUNT_CREATE', prefix: CUSTOMER, value: 3 },
  ACCOUNT_UPDATE: { name: 'ACCOUNT_UPDATE', prefix: CUSTOMER, value: 4 },

  METRICS_OVERVIEW: { name: 'METRICS_OVERVIEW', prefix: CUSTOMER, value: 0 },
  METRICS_AUTO_RENEW: { name: 'METRICS_AUTO_RENEW', prefix: CUSTOMER, value: 0 },

  TRANSACTION_LIST: { name: 'TRANSACTION_LIST', prefix: CUSTOMER, value: 0 },
  TRANSACTION: { name: 'TRANSACTION', prefix: CUSTOMER, value: 1 },

  CUSTOMER_LIST: { name: 'CUSTOMER_LIST', prefix: CUSTOMER, value: 0 },
  CUSTOMER: { name: 'CUSTOMER', prefix: CUSTOMER, value: 0 },
  CUSTOMER_TOTAL_SPENT: { name: 'CUSTOMER_TOTAL_SPENT', prefix: CUSTOMER, value: 1 },
  CUSTOMER_PRODUCT_GROUP_LIST: { name: 'CUSTOMER_PRODUCT_GROUP_LIST', prefix: CUSTOMER, value: 1 },

  METRICS_REVENUE: { name: 'METRICS_REVENUE', prefix: CUSTOMER, value: 5 },
  METRICS_MRR: { name: 'METRICS_MRR', prefix: CUSTOMER, value: 5 },
  METRICS_CHURN: { name: 'METRICS_CHURN', prefix: CUSTOMER, value: 5 },
  METRICS_EVENT_COUNT: { name: 'METRICS_EVENT_COUNT', prefix: CUSTOMER, value: 5 },
  METRICS_TRIAL_CONVERSION: { name: 'METRICS_TRIAL_CONVERSION', prefix: CUSTOMER, value: 5 },
  METRICS_NEW_PURCHASE_TRIALS: { name: 'METRICS_NEW_PURCHASE_TRIALS', prefix: CUSTOMER, value: 5 },
  METRICS_ACTIVE_PURCHASE_TRIALS: {
    name: 'METRICS_ACTIVE_PURCHASE_TRIALS',
    prefix: CUSTOMER,
    value: 5,
  },

  COLLABORATOR_LIST: { name: 'COLLABORATOR_LIST', value: 0, prefix: CUSTOMER },
  COLLABORATOR: { name: 'COLLABORATOR', prefix: CUSTOMER, value: 1 },
  COLLABORATOR_DELETE: { name: 'COLLABORATOR_DELETE', prefix: CUSTOMER, value: 2 },
  COLLABORATOR_CREATE: { name: 'COLLABORATOR_CREATE', prefix: CUSTOMER, value: 3 },
  COLLABORATOR_UPDATE: { name: 'COLLABORATOR_UPDATE', prefix: CUSTOMER, value: 4 },
  COLLABORATOR_RESEND: { name: 'COLLABORATOR_RESEND', prefix: CUSTOMER, value: 3 },

  BILLING_PRODUCT_LIST: { name: 'BILLING_PRODUCT_LIST', value: 0, prefix: CUSTOMER },
  OVERAGE_LIST: { name: 'OVERAGE_LIST', value: 0, prefix: CUSTOMER },
  BILLING_PURCHASE: { name: 'BILLING_PURCHASE', value: 3, prefix: CUSTOMER },

  BILLING_RECEIPT_LIST: { name: 'BILLING_RECEIPT_LIST', value: 0, prefix: CUSTOMER },

  PAYMENT_METHOD_LIST: { name: 'PAYMENT_METHOD_LIST', value: 0, prefix: CUSTOMER },
  PAYMENT_METHOD_CREATE: { name: 'PAYMENT_METHOD_CREATE', value: 3, prefix: CUSTOMER },
  PAYMENT_METHOD_ACTIVATE: { name: 'PAYMENT_METHOD_ACTIVATE', value: 3, prefix: CUSTOMER },
  PAYMENT_METHOD_DELETE: { name: 'PAYMENT_METHOD_DELETE', value: 2, prefix: CUSTOMER },

  SUBSCRIPTION_PURCHASE: { name: 'SUBSCRIPTION_PURCHASE', value: 3, prefix: CUSTOMER },
  SUBSCRIPTION_CONFIRM: { name: 'SUBSCRIPTION_CONFIRM', value: 3, prefix: CUSTOMER },

  INVALID_ACCESS_TOKEN: {name:'INVALID_ACCESS_TOKEN'},
  USER_ACCOUNT_UPDATED: {name:'USER_ACCOUNT_UPDATED'},
  USER_ON_FREE_PLAN: {name:'USER_ON_FREE_PLAN'},

  LOGIN:{name:'LOGIN',  prefix: CUSTOMER},
  SIGN_UP:{name:'SIGN_UP',  prefix: CUSTOMER},
  SIGN_UP_GOOGLE:{name:'SIGN_UP_GOOGLE',  prefix: CUSTOMER},
  FORGOT_PASSWORD:{name:'FORGOT_PASSWORD',  prefix: CUSTOMER},
  RESET_PASSWORD:{name:'RESET_PASSWORD',  prefix: CUSTOMER},
  RESEND_EMAIL:{name:'RESEND_EMAIL',  prefix: CUSTOMER},
  VERIFY_USER:{name:'VERIFY_USER',  prefix: CUSTOMER},

  ALERT_LIST: {name: 'ALERT_LIST',prefix: CUSTOMER, value: 0},
  ALERT: {name: 'ALERT',prefix: CUSTOMER, value: 1},
  ALERT_CREATE: {name: 'ALERT_CREATE',prefix: CUSTOMER, value: 3},
  ALERT_UPDATE: { name: 'ALERT_UPDATE', prefix: CUSTOMER, value: 4 },
  ALERT_DELETE: { name: 'ALERT_DELETE', prefix: CUSTOMER, value: 2 },

/*_add_from_here*/
};
