import {
  getInputAlertEmail,
  prepareInput,
} from '../../../utils/InputFieldCongifurations';
import { InputTypes } from '../../../utils/InputTypes';

export const alertInputs = {
  application_id: prepareInput(
    'application_id',
    'Select App',
    true,
    'Select App',
    '', 'Select App', [],
  ),
  name: prepareInput(
    'name',
    'Alert Name',
    true,
    'e.g. Daily Active Users Below 5k ',
    '',
    'Enter Alert Name',
  ),
  criteria_name: prepareInput('criteria_name', '', true, 'Select', '',
    'Select Criteria',
    [
      {
        title: 'Revenue',
        value: 'CRITERIA_REVENUE',
      },
      {
        title: 'Subscribers',
        value: 'CRITERIA_SUBSCRIBER',
      },
    ]),
  criteria_relation: prepareInput('criteria_relation', '', true, 'Select', '',
    'Select Relation',
    [
      {
        title: 'Above',
        value: 'RELATION_ABOVE',
      },
      {
        title: 'Below',
        value: 'RELATION_BELOW',
      },
    ]),
  criteria_value: prepareInput(
    'criteria_value',
    '',
    true,
    'Enter value',
    '',
    'Enter value',
  ),
  schedule_interval: prepareInput('schedule_interval', 'Send Alerts Once Every', true,
    'Select Interval', '', 'Select Interval',
    [
      {
        title: 'Daily',
        value: 'INTERVAL_DAILY',
      },
      {
        title: 'Weekly',
        value: 'INTERVAL_WEEKLY',
      },
      {
        title: 'Monthly',
        value: 'INTERVAL_MONTHLY',
      },
      {
        title: 'Yearly',
        value: 'INTERVAL_YEARLY',
      },
    ]),
  email: {
    name: 'email',
    values: {},
    dialog: {
      title: "Edit Email Address",
      visible: false

    },
    label: "Email Address",
    items: [
      {
        type: InputTypes.INPUT,
        value: getInputAlertEmail()
      },
    ]
  },

  sms: {
    name: 'sms',
    values: {},
    dialog: {
      title: "Add your number",
      visible: false
    },
    label: "SMS Push",
    items: [
      {
        type: InputTypes.INPUT_COUNTRY_CODE,
        value: prepareInput(
          'mobile_number',
          '',
          true,
          'Enter mobile number',
          '',
          'Enter mobile number','',''
        )
      },
    ]
  },
  webhook: {
    name: 'webhook',
    values: {},
    dialog: {
      title: "Add webhook URL",
      visible: false
    },
    label: "Webhook",
    items: [
      {
        type: InputTypes.INPUT,
        value: prepareInput(
          'webhook_url',
          'Enter webhook url',
          true,
          'Enter webhook url',
          '',
          'Enter webhook url',
          '',''
        )
      },
    ]
  },
};