import moment from 'moment';
import _ from 'lodash';

export const localStorageKey = {
  TABLE_ROW_COUNT: "BORON_TABLE_ROW_COUNT",
  METRICS_STYLE: "BORON_METRICS_STYLE",
  METRICS_TYPES: "BORON_METRICS_TYPES",
  METRICS_INTERVAL: "BORON_METRICS_INTERVAL",
  METRICS_SEGMENT: "BORON_METRICS_SEGMENT",
  AUTH_TOKEN: "BORON_AUTH_TOKEN",
  USER: "BORON_USER",
  AUTH_RESPONSE: "AUTH_RESPONSE",
  SELECTED_APP: "BORON_SELECTED_APP",
  SELECTED_ENVIRONMENT: "BORON_SELECTED_ENVIRONMENT",
  ON_BOARD_DIALOG: "BORON_ON_BOARD_DIALOG",
  SHOW_ONBOARD: "BORON_SHOW_ONBOARD",
  UPGRADE_PLAN: "BORON_UPGRADE_PLAN",
  REDIRECT_TO: "BORON_REDIRECT_TO",
  NEXT_PLAN: "NEXT_PLAN",
  APP_LIST: "APP_LIST"
};
export function saveItem(key, value, time) {
  localStorage.setItem(key, JSON.stringify({time: time,
    value: value}));
}

export function removeItem(key) {
  localStorage.removeItem(key);
}

export function isItemExist(key, time) {
  const data = JSON.parse(localStorage.getItem(key));
  if (data) {
    const savedOn = moment(data.time);
    if (moment().diff(savedOn, 'minutes') > time) {
      localStorage.removeItem(key);
      return false;
    } else
      return !_.isEmpty(data.value);
  } else
    return false;
}

export function getItem(key) {

  const data = JSON.parse(localStorage.getItem(key));
  return data.value;
}

