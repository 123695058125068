import React from 'react';
import { Col, Progress, Row } from 'antd';
import Space from './Spcae';
import '../../css/components.css';
import { ComponentText } from './ComponentText';
import { fonts } from '../../utils/fonts';

export class ComponentCircularProgress extends React.Component {

  render() {
    const {total, renew, expire} = this.props;

    return <div className="auto-renew">
      <Space height={38}/>
      <Row type={"flex"} align={"middle"} justify={"center"}>
        <Legend text={"Will Renew"} css={"legend-square-dodger-blue"}/>
        <Space width={20}/>
        <Legend text={"Will Expire"} css={"legend-square-very-light-blue"}/>
      </Row>
      <Space height={32}/>
      <Progress type="circle" percent={calculatePercentage(total, renew)} width={185} format={percent => {return <Col>
        <ComponentText text={percent + "%"} font={fonts.SFProDisplaySemiBold} size={34} color={"var(--dusk-two)"}/>
        <ComponentText text={"Will renew"} font={fonts.SFProDisplayRegular} size={13} color={"var(--light-grey-blue)"}/>
      </Col>;}}/>
      <Space height={8}/>
      <Row type={"flex"} align={"middle"} justify={"space-between"} style={{paddingLeft: 30,
        paddingRight: 30}}>
        <Col>
          <ComponentText text={"Total Subscriptions"} font={fonts.SFProDisplaySemiBold} size={14} color={"var(--cool-grey-five)"}/>
          <ComponentText text={total.toString()} font={fonts.SFProDisplaySemiBold} size={20} color={"var(--black)"}/>
        </Col>

        <Col>
          <LegendCount text={"Will renew"} count={renew.toString()}/>
          <LegendCount text={"Will expire"} count={expire.toString()}/>
        </Col>

      </Row>
      <Space height={24}/>
    </div>;
  }

}

function calculatePercentage(total, renew) {
  return Math.round(100 * renew / total);

}

function LegendCount({text, count}) {
  return <Row type={"flex"} align={"middle"}>
    <ComponentText text={text} font={fonts.SFProDisplaySemiBold} size={14} color={"var(--cool-grey-five)"}/>
    <Space width={2}/>
    <ComponentText text={count} font={fonts.SFProDisplaySemiBold} size={14} color={"var(--black)"}/>
  </Row>;

}

function Legend({text, css}) {
  return <Row type={"flex"} align={"middle"} justify={"center"}>
    <div className={css}/>
    <Space width={8}/>
    <ComponentText text={text} font={fonts.SFProDisplaySemiBold} size={14} color={"var(--steel)"}/>
  </Row>;

}
