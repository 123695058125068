import React, { Component } from 'react';
import './App.css';
import './components/shared/FormComponent/FormComponent.css';
import SideBar from './components/shared/SideBar/SideBar';
import MainWindow from './components/shared/MainWindow/mainWindow';
import { Layout } from 'antd';
import TopMenu from './components/shared/TopMenu/TopMenu';
import Helmet from 'react-helmet';

const {Header, Content, Sider} = Layout;
class App extends Component {

  render() {

    return (
      <Layout style={{height: "100vh"}}>
        <Helmet>
          <title>{this.getTitle(this.props.location.pathname, this.props.location.search)}</title>
        </Helmet>
        <Header>
          <TopMenu location={this.props.location} history={this.props.history}/>
        </Header>
        <Layout>
          <Sider theme="light">
            <SideBar location={this.props.location} history={this.props.history} />
          </Sider>
          <Content style={{background: "var(--pale-grey)"}}>
            <MainWindow location={this.props.location} history={this.props.history}/>
          </Content>
        </Layout>
      </Layout>
    );
  }

    getTitle = (pathname, search) => {
      //console.log(pathname);
      //console.log(search);

      const query = new URLSearchParams(search);
      if(routes[pathname] && query.get("menu")){
        const t = pathname+"/"+query.get("menu");
        //console.log(t);
        return routes[t];
      }
      else if(routes[pathname]){
        return routes[pathname];
      }else{
        let x = pathname.split('/');
        //console.log(x);
        if(x.length === 3){
          //console.log(x[1]);
          return routes[x[1]];
        }else{
          return "Purchasefly";
        }

      }

    }
}

const routes = {
  "/": "Dashboard",

  "customer": "Customer Details",
  "/customer/list": "Customers",

  "/transaction/list": "Transactions",
  "transaction": "Transaction Details",

  "/alert/create": "Alert Create",
  "/alert/list": "Alerts",
  "alert": "Alert Details",

  "app": "Application Details",
  "/app/list": "Applications",
  "/app/create": "Application Create",

  "/account": "Account",
  "/account/billing": "Account | Billing",
  "/account/invoices": "Account | Invoices",
  "/account/explorer": "Account | Cost Explorer",
  "/account/collaborators": "Account | Collaborators",

  "/metrics": "Analytics",
  "/metrics/mrr": "Analytics | MRR",
  "/metrics/revenue": "Analytics | Revenue",
  "/metrics/eventcount": "Analytics | Event Count",
  "/metrics/newpurchaseandtrials": "Analytics | New Purchase and Trials",
  "/metrics/churn": "Analytics | Churn",
  "/metrics/trialconversion": "Analytics | Trail Conversion",
  "/metrics/activepurchaseandtrials": "Analytics | Active Subscription",
};

export default App;

