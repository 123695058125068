import React from 'react';
import { Col, Divider, Progress, Row } from 'antd';
import Space from '@/components/shared/Spcae';
import { ComponentText } from '../../shared/ComponentText';
import images from '../../../images/Images';
import { fonts } from '../../../utils/fonts';
import utils from '../../../utils/utils';

const plans = [
  {
    icon: images.basicPlan,
    title: 'Basic',
    price: 'Free',
    buttonText: 'Subscribed',
    buttonType: 'stroke-dark-mint eina-semibold-dark-mint-14',
  },
  {
    icon: images.startupPlan,
    title: 'Startup',
    price: '$79',
    buttonText: 'Upgrade',
    buttonType: 'dark-mint eina-semibold-white-14',
  },
  {
    icon: images.businessPlan,
    title: 'Business',
    price: '$299',
    buttonText: 'Upgrade',
    buttonType: 'dark-mint eina-semibold-white-14',
  },
];
class CurrentPlan extends React.Component {

  render() {

    const {accountInfo} = this.props;
    const planType = accountInfo ? accountInfo.subscription_product.subscription_plan.type: "";
    const name = accountInfo ? accountInfo.subscription_product.product.name : "";
    const price = accountInfo ? `$${ Math.floor(accountInfo.subscription_product.subscription_plan.price/(planType === "SubscriptionPlanTypeMonthly"? 1: 12))}/mo` : "";

    const spentMTR = Math.floor(accountInfo ? accountInfo.subscription_product.subscription_state.spent_mtr: 0);
    const limitMTR = Math.floor(accountInfo ? accountInfo.subscription_product.subscription.mtr_limit : 20000);
    //const spentMTR = 123456;
    //const limitMTR = 100000;
    const mtrPercentage = accountInfo ? Math.floor((100*spentMTR/limitMTR)):0;
    const mtrPercentageText = `(${mtrPercentage}%)`;

    return (
      <div>
        <ComponentText
          font={fonts.SFProDisplaySemiBold}
          text="Current Plan"
          size="15"
          color="var(--water-blue)"
          align="left"
          marginBottom="16"
        />

        <Row type="flex" justify="space-between" align={"middle"}>
          <ComponentPlan plan={plans[0]} name={name} price={price}/>
          <Col>
            <Row type="flex" justify={"end"}>
              <ComponentText
                font={fonts.SFProDisplaySemiBold}
                text={`$${utils.formatNumber(spentMTR)}`}
                size="14"
                color= {spentMTR > limitMTR && this.props.from === "billing" ? "var(--tomato)":"var(--slate-grey)"}
                align="left"
              />
              <ComponentText
                font={fonts.SFProDisplaySemiBold}
                text={`/${utils.formatNumber(limitMTR)}`}
                size="14"
                color= {"var(--slate-grey)"}
                align="left"
              />
              <Space width={4} />
              <ComponentText
                font={fonts.SFProDisplayRegular}
                text={mtrPercentageText}
                size="14"
                color="var(--cool-grey)"
                align="left"
              />
            </Row>
            <div style={{ width: 200 }}>
              <Progress percent={mtrPercentage} showInfo={false} status="active" strokeColor={spentMTR > limitMTR && this.props.from === "billing" ? "var(--tomato)": "var(--ocean)"} />
            </div>
          </Col>
        </Row>
        <Divider/>
      </div>
    );
  }
}

export default CurrentPlan;

function ComponentPlan({ plan, name, price }) {
  return (
    <Row type="flex" align="middle">
      <img alt="" src={plan.icon} style={{ width: 45, height: 45 }} />
      <Space width={12} />
      <Col>
        <ComponentText
          font={fonts.SFProDisplaySemiBold}
          text={name}
          size="20"
          color="var(--water-blue))"
          marginBottom="4"
          marginTop="4"
        />
        <ComponentText
          font={fonts.SFProDisplayRegular}
          text={price}
          size="16"
          color="var(--slate-grey)"
        />
      </Col>
    </Row>
  );
}
