import React from 'react';
import { Select } from 'antd';
import { loadData, loadLocalData } from '../../utils/ActionCreators';
import utils from '../../utils/utils';
import { connect } from 'react-redux';
import { getUser } from '../../utils/Repository';
import { contentType } from '../../utils/ActionTypes';
import { appsListApiPath } from '../../utils/routerUtils';
import { localStorageKey } from '../../utils/LocalStorage';
import { ComponentIcon } from './ComponentIcon';
import images from '../../images/Images';

const { Option } = Select;
class ComponentAppSelect extends React.Component {

  constructor (props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('application_ids');
    this.state = {selectedApp: token};
  }
  componentDidMount() {
    this.loadList();
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

    if(this.props.location !== prevProps.location) {
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('application_ids');
      if (token){
        this.setState({selectedApp: token});
      }else {
        this.getSelectedMenu();
      }
    }

    if(this.props.list && this.props.list !== prevProps.list){
      console.log(this.props.list);
      localStorage.setItem(localStorageKey.APP_LIST, JSON.stringify(this.props.list));
    }

  }

  loadList() {
    const {onLoadData} = this.props;
    const user = getUser();
    onLoadData(
      contentType.APPS_LIST,
      appsListApiPath, {filters: {organization_id: user.organizations_roles[0].organization_id},
        pager: {skip: 0,
          limit: 100}}
    );
  }
    onClick = (key) => {
      localStorage.setItem(localStorageKey.SELECTED_APP, key);
      let pathname = this.props.location.pathname;
      let searchParams = new URLSearchParams(this.props.location.search);
      searchParams.set("application_ids", key);
      const query = new URLSearchParams(this.props.location.search);
      const page = query.get('page');
      if(page) {
        searchParams.set("page", "1");
      }
      searchParams.delete("product_group_name");
      this.setState({selectedApp: key});
      this.props.history.push({
        pathname: pathname,
        search: searchParams.toString(),
      });
    };

    setApp = () =>{
      let key = localStorage.getItem(localStorageKey.SELECTED_APP);
      key ? this.onClick(key): this.onClick("all-app");//this.setState({selectedApp: "all-app"});
    };

    getSelectedMenu = () =>{
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('application_ids');
      token ? this.setState({selectedApp: token}) : this.setApp();
    };

    render() {

      let { list, isFetching, from } = this.props;

      const apps = list ? list.map((e) => {
        return <Option key={e.id}>{e.image_url ? <img src={e.image_url} height={16} width={16} className={"app-icon"} alt={""}/>: from === 'dashboard' ? <ComponentIcon type={"apps-dashboard"} width={16}/>: <img src={images.apps} height={16} width={16} className={"app-icon-default"} alt={""}/>} <label className="app-name">{e.name}</label></Option>;
      }): [];

      apps.push(<Option key={"all-app"}> {from === 'dashboard' ? <ComponentIcon type={"apps-dashboard"} width={16}/>: <img src={images.apps} height={16} width={16} className={"app-icon-default"} alt={""}/>} <label className="app-name">{"All Apps"}</label></Option>);

      return <Select
        className={this.props.css ? this.props.css : "app-select"}
        defaultValue={isFetching ? "": this.state.selectedApp}
        onChange={this.onClick}
        value={isFetching ? "":this.state.selectedApp}
        style={{ width: 200 }}
        suffixIcon={isFetching ? null : <ComponentIcon type="caret-down" width={15}/>}
        loading={isFetching}>
        {apps}
      </Select>;
    }

}

const mapActionToProps = {
  onLoadData: loadData,
  onLoadLocalData: loadLocalData,
};

const mapStateToProps = state => {
  const {appsListRemoteData} = state;
  return {
    list: utils.getData(appsListRemoteData),
    isFetching: utils.getIsFetching(appsListRemoteData),
    isFailed: utils.getIsFailed(appsListRemoteData),
  };
};
export default connect(
  mapStateToProps,
  mapActionToProps
)(ComponentAppSelect);