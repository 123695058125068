export const TEXT_INPUT = "text";
export const SELECT_INPUT = "select";
export const TOGGLE_INPUT = "toggle";
export const CHECKBOX = "checkbox";

export default {
  TEXT_INPUT,
  SELECT_INPUT,
  TOGGLE_INPUT,
  CHECKBOX
};