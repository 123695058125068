import React from 'react';
import { Divider, Form, message, Row, Spin } from 'antd';
import { prepareInput } from '../../utils/InputFieldCongifurations';
import { PasswordComponent } from '../shared/FormComponent/PasswordComponent';
import { connect } from 'react-redux';
import { resetPassword } from './authActionCreators';
import { ComponentText } from '../shared/ComponentText';
import { ComponentButton } from '../shared/ComponentButton';
import './Auth.css';
import { TopBar } from './Topbar';
import { fonts } from '../../utils/fonts';
import Space from '../shared/Spcae';
import utils from '../../utils/utils';

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: prepareInput(
        'password',
        'Password',
        true,
        '•••••••••',
        '',
        'Enter New Password',
      ),
      passwordConfirm: prepareInput(
        'passwordConfirm',
        'Confirm new password',
        true,
        'Confirm new password',
        '',
        'Enter New Password',
      ),
    };
  }

  componentDidMount() {
    if (utils.isLoggedIn(this.props.loginData)) {
      this.props.history.push('/dashboard');
    }
  }

  success = text => {
    message.success(text);
  };

  error = text => {
    if (text === null) return;
    message.error(text);
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { data, isFailed } = this.props;

    console.log(data);
    if(isFailed === true){
      this.error("Password update failed");
    }
    else if(data && data !== prevProps.data){
      this.success("Password Updated");
      this.props.history.push('/login');
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const { password, passwordConfirm } = values;
      if (!err && password === passwordConfirm) {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token');
        const email = query.get('email');
        console.log(token, email);

        const req = {
          email,
          token,
          new_password: password,
          confirm_new_password: passwordConfirm,
        };

        this.props.resetPassword(req);
      }
    });
  };

  render() {

    return (
      <Spin spinning={ this.props.isFetching === true} >
        <TopBar/>
        <div style={{height:window.innerHeight - 71, backgroundColor:"#fafafa"}}>
          <Row justify={"center"} type={"flex"}>
            <div className={"reset-password-container"}>

              <ComponentText
                text="Set a new password"
                size="23"
                color="var(--dark-grey-blue-two)"
                align={"center"}
                font={fonts.SFProDisplayRegular}
              />
              <Space height={12}/>
              <Divider />
              <div style={{paddingLeft: 60,paddingRight: 60 }}>
                <Form onSubmit={this.handleSubmit} layout="vertical">

                  <PasswordComponent
                    form={this.props.form}
                    layout={formItemLayout}
                    values={this.state.password}
                  />


                  <PasswordComponent
                    form={this.props.form}
                    layout={formItemLayout}
                    values={this.state.passwordConfirm}
                  />

                  <Form.Item className="no-bottom-margin">
                    <ComponentButton
                      htmlType="submit"
                      name="confirm"
                      text="Confirm"
                      css="dark-sky-blue eina-semibold-white-15"
                      height="42"
                    />
                  </Form.Item>
                </Form>
              </div>
            </div></Row></div>

      </Spin>
    );
  }
}

const ResetPassword = Form.create({ name: 'reset-password' })(ResetPasswordForm);

const mapActionToProps = {
  resetPassword,
};

const mapStateToProps = state => {
  const {resetPasswordRemoteData, loginRemoteData} = state;
  return {
    data: utils.getItem(resetPasswordRemoteData),
    isFetching: utils.getIsFetching(resetPasswordRemoteData),
    isFailed: utils.getIsFailed(resetPasswordRemoteData),
    resetPasswordMessage: utils.getMessage(resetPasswordRemoteData),
    loginData: utils.getItem(loginRemoteData),
  };
};

export default connect(mapStateToProps, mapActionToProps)(ResetPassword);

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};
