import React from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { ComponentIcon } from '../ComponentIcon';
import { ComponentText } from '../ComponentText';
import { fonts } from '../../../utils/fonts';
import countryCode from '../../../json/CountryCodes.json';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const {Option} = Select;

export class CountrySelectMobile extends React.Component {

  constructor (props) {
    super(props);
    this.state = {country_code:""};
  }

    onCountrySelect = (value) => {
      this.setState({
        country_code:value
      });
    }

  checkMobileNumber = (rule, value, callback) => {

    if(!value){
      callback('Enter mobile number');
    }else{
      try {
        const code = this.state.country_code ? this.state.country_code: this.getInitialCountryCode(value);
        const phoneNumber = parsePhoneNumberFromString(code+value);

        if(!phoneNumber.isValid()){
          callback('Enter valid mobile number');
        }
        callback();
      } catch (error) {
        callback('Enter valid mobile number');
      }
    }
  }
  getInitialCountryCode = (value) => {
    if(value) {
      const phoneNumber = parsePhoneNumberFromString(value);
      return "+"+phoneNumber.countryCallingCode;
    }
  }

  getInitialNumber = (value) => {
    if(value) {
      const phoneNumber = parsePhoneNumberFromString(value);
      return value.replace("+"+phoneNumber.countryCallingCode, "");
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

    if(this.props.values && this.props.values.value && this.state.country_code === ""){
      const phoneNumber = parsePhoneNumberFromString(this.props.values.value);
      this.setState({
        country_code:"+"+phoneNumber.countryCallingCode
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { disabled, values = {} } = this.props;
    const { name, label, placeholder, rules, value, extra, validateTrigger = "onChange" } = values;

    return (

      <div key={"country_code_select"}>
        <ComponentText text={"Enter your mobile number"} font={fonts.SFProDisplayMedium}  size={13} color={"var(--greyish-brown)"} marginBottom={8}/>
        <Row  type={"flex"} align={"top"}>
          <Col span={11}>
            <Form.Item name={'code'}>
              {getFieldDecorator('code', {
                initialValue: this.getInitialCountryCode(value),
              })(
                <Select
                  dropdownClassName={"country_code"}
                  showSearch
                  placeholder={"Select Country"}
                  size='large'
                  onChange={this.onCountrySelect}
                  suffixIcon={<div style={{marginTop:-4}}><ComponentIcon type="select" width={15}/></div>}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children">
                  {countryCode.map(e => <Option key={e.code} value={e.dial_code}>{`${e.name} (${e.dial_code})`}</Option>)}
                </Select>
              )}
            </Form.Item>

          </Col>

          <Col span={1}/>

          <Col span={12}>
            <Form.Item className="no-bottom-margin" label={label} extra={extra} >
              {getFieldDecorator(name, {
                rules: [
                  {
                    validator: this.checkMobileNumber,
                  }
                ],
                initialValue: this.getInitialNumber(value),
                validateTrigger: validateTrigger,
              })
              (<Input className={"country-code-number"}
                prefix={this.state.country_code ? this.state.country_code: this.getInitialCountryCode(value)}
                placeholder={placeholder}
                disabled={disabled} />)}
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
}
