import React from 'react';
import { connect } from 'react-redux';
import { Input, Row } from 'antd';
import listUtils from '../../../utils/listUtils';
import '../transaction.css';
import {
  getTransactionDetails,
  getTransactionDetailsPath,
  getTransactionListPath,
  getTransactionSearchPath,
  transactionListPath,
} from '../../../utils/routerUtils';
import { loadData } from '../../../utils/ActionCreators';
import { contentType } from '../../../utils/ActionTypes';

import { ComponentText } from '../../shared/ComponentText';
import FilterPopup from '../../shared/Filter/FilterPopup';
import utils from '../../../utils/utils';
import { RangeDateTimePicker } from '../../shared/DateTime/RangeDateTimePicker';
import { ComponentIcon } from '../../shared/ComponentIcon';
import Space from '../../shared/Spcae';
import { tableContentType } from '../../shared/Table/TableDetails';
import { TableListNew } from '../../shared/Table/TableListNew';
import queryString from 'query-string';
import ComponentAppSelect from '../../shared/ComponentAppSelect';
import { ListEmpty } from '../../shared/ListEmpty';
import images from '../../../images/Images';
import {
  listQueryFilterParams,
  listSearchModalParams,
} from './TransactionListConstants';
import { EnvironmentSelect } from '../../shared/EnvironmentSelect/EnvironmentSelect';

const path = transactionListPath;
const title = "Transaction List";
const subtitle = "Transactions";
const detailsPath = getTransactionDetailsPath;


class TransactionList extends React.Component {

  componentDidMount() {
    this.loadListWithPageFilter();
  }

  componentDidUpdate (previousProps, prevState, snapshot) {
    const currentSearch = this.props.location.search;
    const previousSearch = previousProps.location.search;
    if (currentSearch !== previousSearch) {
      const query = new URLSearchParams(currentSearch);
      const appUserId = query.get('app_user_id');
      const values = queryString.parse(this.props.location.search);
      this.setState({appUserId: appUserId});

      if(values["environment"]){
        this.loadListWithPageFilter();
      }else{
        this.componentDidMount();
      }
    }
  }

    loadListWithPageFilter = ()=> {
      const {onLoadData} = this.props;
      let page = listUtils.currentPage(this.props.location.search);
      let limit = listUtils.currentLimit(this.props.location.search);

      let currentFilter =  listUtils.currentFilters(this.props.location.search, listQueryFilterParams());
      const query = new URLSearchParams(this.props.location.search);
      const from = query.get('from');
      const to = query.get('to');

      let filters = listUtils.prepareFilterParamsWithDate(currentFilter, utils.getDefaultDateRange(from, to));

      if(filters['environment'] && filters['application_ids']){
        let path = getTransactionListPath;
        if(filters['store'] || (filters['external_product_id'] && filters['application_ids'] !== 'all-app') || filters['app_user_id']){
          if(filters['application_ids'] !== 'all-app') {
            filters['application_id'] = filters['application_ids'];
          }else{
            delete filters['external_product_id'];
          }

          path = getTransactionSearchPath;
        }
        if(filters['application_ids'] === 'all-app'){
          delete filters['application_ids'];
        }
        onLoadData(
          contentType.TRANSACTION_LIST,
          path,
          listUtils.prepareActionParams(page, limit, filters),
        );
      }

    };

    onDateChanged = range => {
      const between = {from: range.from, to: range.to};
      let pathname = this.props.location.pathname;
      let searchParams = new URLSearchParams(this.props.location.search);
      searchParams.set("from", between.from);
      searchParams.set("to", between.to);
      this.props.history.push({
        pathname: pathname,
        search: searchParams.toString(),
      });

    };

    render() {
      const page = listUtils.currentPage(this.props.location.search);
      let {list, pager, isFetching, isFailed} = this.props;

      const transactions = list && list.length > 0 ? list.map((e)=>{
        return {
          id: e.transaction.id,
          user_id: e.transaction.user_id,
          product_name: e.transaction.external_product_id,
          revenue: "$" + e.transaction.revenue.toFixed(2).toString(),
          purchased_at: e.transaction.purchased_at,
          ends_at: e.transaction.type === "TYPE_SUBSCRIPTION" ? e.transaction.ends_at : "NO_EXPIRATION",
          status: e.transaction.type === "TYPE_SUBSCRIPTION" ? e.transaction.status: "One Time Purchase",
          app_name: e.application.name,
          color: e.application.color,
          go_back: true,
          actions: [
            {
              url: getTransactionDetails(e.transaction.id),
              title: "View",
              go_back: true
            },
          ],
        };
      }) : [];
      let totalCount = "";

      return (
        <div className={"transaction-list"}>
          <Row type="flex" justify="space-between" align="middle">
            <ComponentText text={title} size="28" color="var(--dark)" align="left"/>
            <Row type="flex" align="middle">
              <EnvironmentSelect location={this.props.location} history={this.props.history}/>
              <Space width={12}/>
              <ComponentAppSelect location={this.props.location} history={this.props.history} border={true}/>
              <Space width={12}/>
              <RangeDateTimePicker
                onDateChanged={this.onDateChanged}
                location={this.props.location}
                history={this.props.history}
                defaultInterval={30}/>
            </Row>
          </Row>
          <Row type="flex" justify="space-between" align="middle" style={{marginTop: 16,
            marginBottom: 8}}>
            <Row type="flex" align={"middle"}>
              <ComponentIcon type="transactions" width={15}/>
              <Space width={"14"}/>
              <ComponentText text={`${totalCount} ${subtitle} `} size="15" color="var(--slate-grey)" align="left"/>
            </Row>
            <Row type="flex">
              <Input.Search
                value={this.state && this.state.appUserId ? this.state.appUserId : ""}
                onChange={this.onAppUserIdChanged}
                allowClear
                placeholder="Search by app user id"
                onSearch={this.searchByAppUserID}
                style={{width: 300,
                  marginRight: 8}}
              />
              <FilterPopup
                history = {this.props.history}
                location = {this.props.location}
                filterParams = {listSearchModalParams}
                queryFilterParams = {listQueryFilterParams()}
              />
            </Row>
          </Row>
          {transactions && transactions.length === 0 && isFetching === false ? <ListEmpty height={window.innerHeight} image={images.transactionEmpty} title={"transaction"}/>:
            <TableListNew
              history={this.props.history}
              location={this.props.location}
              isLastPage = {utils.isLastPage(pager)}
              list={transactions}
              isFetching={isFetching}
              isFailed={isFailed}
              attributes={attributes}
            />}
        </div>
      );
    }

    searchByAppUserID = (id) => {
      let pathname = this.props.location.pathname;
      let searchParams = new URLSearchParams(this.props.location.search);
      if(id)
        searchParams.set("app_user_id",id);
      else
        searchParams.delete("app_user_id");

      searchParams.set("page", "1");
      this.props.history.push({
        pathname: pathname,
        search: searchParams.toString(),
      });

    };

    onAppUserIdChanged = (e) => {
      this.setState({appUserId: e.target.value});
    }
}

const mapActionToProps = {
  onLoadData: loadData,
};

const mapStateToProps = state => {
  const {transactionListRemoteData} = state;
  return {
    list: utils.getData(transactionListRemoteData),
    pager: utils.getPager(transactionListRemoteData),
    isFetching: utils.getIsFetching(transactionListRemoteData),
    isFailed: utils.getIsFailed(transactionListRemoteData),
  };
};
export default connect(
  mapStateToProps,
  mapActionToProps
)(TransactionList);

const attributes = [
  {
    title: '',
    field: 'indicator',
    type: tableContentType.INDICATOR,
  },
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'Product',
    field: 'product_name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Revenue',
    field: 'revenue',
    type: tableContentType.REVENUE,
  },
  {
    title: 'Purchased',
    field: 'purchased_at',
    type: tableContentType.TIME_AGO,
  },
  {
    title: 'Expiration',
    field: 'ends_at',
    type: tableContentType.TIME_AGO,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.STATUS,
  },
  {
    title: 'App',
    field: 'app_name',
    type: tableContentType.APP_NAME,
  },
  {
    title: '',
    field: 'action',
    type: tableContentType.ACTION,
  },
  {
    title: '',
    field: '',
    type: tableContentType.EMPTY,
  },
];
