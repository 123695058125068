import React from 'react';
import { Form, Select } from 'antd';
import { ComponentIcon } from '../ComponentIcon';

const {Option} = Select;

export class SelectComponent extends React.Component {
  render() {
    const {getFieldDecorator} = this.props.form;
    const {loading} = this.props;
    const {name, label, placeholder, rules, options, value, disabled } = this.props.values;

    return (
      <Form.Item className="no-bottom-margin" label={label} name={name}>
        {getFieldDecorator(name, {
          initialValue: value,
          rules: rules,
        })(
          <Select disabled={disabled} loading={loading} placeholder={placeholder} size='large' suffixIcon={loading ? null : <div style={{marginTop:-4}}><ComponentIcon type="select" width={15}/></div>}>
            {options.map((e, i)=><Option key={i} value={e.value ? e.value : e}>{e.title ? e.title : e }</Option>)}
          </Select>,
        )}
      </Form.Item>
    );
  }
}
