import React from 'react';
import { ComponentText } from './ComponentText';
import Space from './Spcae';

export class ListEmpty extends React.Component {

  render () {
    return <div className={"empty-transaction"} style={{height: this.props.height - 220, paddingTop: (this.props.height - 220 - 340)/2}}>
      <img src={this.props.image} width={150} alt={""}/>
      <Space height={40}/>
      <ComponentText text={`No ${this.props.title}s available to show`} font={"SFProDisplay-Semibold"} size={"22"} color={"var(--greyish-brown)"}/>
      <Space height={10}/>
      <ComponentText text={`You don’t have any ${this.props.title} yet`} font={"SFProDisplay-Regular"} size={"22"} color={"var(--dark-grey-blue)"}/>
    </div>;
  }
}