import React from 'react';
import { connect } from 'react-redux';
import { Form, Row, Spin, Tooltip } from 'antd';
import { ComponentText } from '../../shared/ComponentText';
import { ComponentIcon } from '../../shared/ComponentIcon';
import { ComponentButton } from '../../shared/ComponentButton';
import { postData } from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import { fonts } from '../../../utils/fonts';
import { prepareInput } from '../../../utils/InputFieldCongifurations';
import { InputComponent } from '../../shared/FormComponent/InputComponent';
import Space from '../../shared/Spcae';
import images from '../../../images/Images';
import { contentType } from '../../../utils/ActionTypes';
import { packageCreatePath } from '../../../utils/routerUtils';

class ConfigurePackagesCreate extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      name: prepareInput(
        'name',
        'Package Identifier',
        true,
        'e.g. Pro ',
        'Used to access the entitlement via the SDK, cannot be edited once created',
        'Enter Product Package Identifier',
      ),
      // name: prepareInput(
      //   'bundle_id',
      //   'Package Display Name',
      //   true,
      //   'e.g. Pro Access',
      //   '',
      //   'Enter Product Package Display Name',
      // ),
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    const { onPostData, currentProductGroup} = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        //this.props.forgotPassword(values.email);
        //const user = getUser();
        onPostData(contentType.PACKAGE_CREATE, packageCreatePath, {
          product_group_id: currentProductGroup.id,
          name: values.name,
        });
      }
    });
  };

  render() {
    const {isFetching, currentProductGroup, packageCreateIsFetching} = this.props;
    console.log(currentProductGroup);

    return (

      <div style={{width: 530}}>
        <Spin spinning={isFetching === true || packageCreateIsFetching === true }><div>
          <ComponentText text={"Get Started with Purchasefly"} font={fonts.SFProDisplaySemiBold} size={24} color={"var(--dark-three)"}/>
          <Space height={38}/>
          <Row type={"flex"} align={"middle"}>
            <div className={"step-configure-product"}>
              <ComponentText text={"1. Product Groups"} font={fonts.SFProDisplaySemiBold} size={14} color={"var(--slate-grey)"}/>
            </div>
            <Space width={12}/>
            <img src={images.arrow} width={32}  alt={""}/>
            <Space width={12}/>
            <div className={"step-configure-product"}>
              <ComponentText text={"2. Packages"} font={fonts.SFProDisplaySemiBold} size={14} color={"var(--dark-sky-blue)"}/>
            </div>
            <Space width={12}/>
            <img src={images.arrow} width={32}  alt={""}/>
            <Space width={12}/>
            <ComponentText text={"3. Products"} font={fonts.SFProDisplaySemiBold} size={14} color={"var(--slate-grey)"}/>
          </Row>
		  <Space height={38}/>
          <Row type="flex">
            <ComponentText text="Add Product Package" size="20" color= "var(--black)" font={fonts.SFProDisplaySemiBold}/>
            <Tooltip contentStyle={{backgroundColor: '#4A4A4A'}} placement="bottomLeft" title={"Products Groups represent a App Store Connect group of products. Please arrange them how you arranged in App Store Connect."}>
              <a style={{paddingLeft: 10,
                height: 20}}><ComponentIcon type="help" width={15}/></a>
            </Tooltip>
          </Row>
          <Form onSubmit={this.handleSubmit} layout="vertical">
            <div className="on-board-connect-sdk">


              <InputComponent
                form={this.props.form}
                layout={formItemLayout}
                values={this.state.name}
              />
              {/*<InputComponent*/}
              {/*  form={this.props.form}*/}
              {/*  layout={formItemLayout}*/}
              {/*  values={this.state.name}*/}
              {/*/>*/}
            </div>
            <Row type="flex" align="middle" justify="end">
              <ComponentButton
                htmlType="submit"
                name="connect"
                text="Add and Proceed"
                css="purchase-subscription"
                height="42"
              />
            </Row>
          </Form>
        </div></Spin>
      </div>


    );
  }

}

const ConfigurePackages = Form.create({ name: 'configure-package' })(ConfigurePackagesCreate);

const mapActionToProps = {

  onPostData: postData,
};

const mapStateToProps = state => {
  const {packageCreateRemoteData} = state;
  return {
    packageCreateIsFetching: utils.getIsFetching(packageCreateRemoteData),
    packageCreateData: utils.getData(packageCreateRemoteData),
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(ConfigurePackages);

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};