export const getInputName = () => {
  return {
    name: "name",
    required: true,
    label: `Name`,
    placeholder: `Enter Name`,
    rules: [
      {required: true,
        message: 'Please input your name'},
    ],

  };
};

export const getInputOrganization = () => {
  return {
    name: "organization_name",
    required: true,
    label: `Organization`,
    placeholder: `Enter organization name`,
    rules: [
      {required: false,
        message: 'Please input your organization name'},
    ],

  };
};

export const prepareInput = (name, label, required, placeholder, extra, message, options, value, validateTrigger = 'onChange') => {
  return {
    name: name,
    required: true,
    label: label,
    placeholder: placeholder,
    rules: [
      {required: required,
        message: message},
    ],
    extra: extra,
    options: options,
    value: value,
    validateTrigger
  };
};

export const getInputName2 = name => {
  return {
    name: "name",
    required: true,
    value: "",
    label: `Name`,
    placeholder: `Enter ${name} name`,
    error: {
      value: `Please enter ${name} name`,
      visible: false,
    },
    type: "string",
  };
};

export const getInputEmail = () => {
  return {
    name: "email",
    label: `Email`,
    placeholder: `Enter Email`,
    validateTrigger: "onBlur",
    rules: [
      {
        type: 'email',
        message: 'The input is not valid E-mail!',
      },
      {
        required: true,
        message: 'Please input your E-mail!',
      },
    ],

  };
};

export const getInputPassword = () => {
  return {
    name: "password",
    label: `Password`,
    placeholder: `Enter Password`,
    rules: [
      {
        required: true,
        message: 'Please enter your password!',
      },
    ],

  };
};

export const getInputEpisodeNumber = value => {
  return {
    name: "episode_no",
    required: true,
    value: value,
    label: `Episode number`,
    placeholder: `Enter episode number.`,
    error: {
      value: {
        content: `Please enter episode number`,
        pointing: "below",
      },
      visible: false,
    },
    type: "string",
  };
};

export const getInputDescription = () => {
  return {
    name: "description",
    required: true,
    value: "",
    label: "Description",
    placeholder: "Enter description",
    error: {
      value: {content: "Please enter description",
        pointing: "below"},
      visible: false,
    },
    type: "string",
  };
};

export const getInputPhotoUrl = () => {
  return {
    name: "photo_url",
    required: true,
    value: "",
    label: "Image Url",
    placeholder: "Enter image url",
    error: {
      value: {content: "Please enter image url",
        pointing: "below"},
      visible: false,
    },
    type: "string",
  };
};

export const getInputCategory = (name, isRequired, type) => {
  return {
    name: name,
    required: isRequired,
    value: type === "array" ? [] : "",
    label: "Categories",
    placeholder: "Select categories",
    error: {
      value: {content: "Please select category",
        pointing: "below"},
      visible: false,
    },
    options: [],
    type: type,
  };
};

export const getInputAccess = () => {
  return {
    name: "accesses",
    required: true,
    value: [],
    label: "Accesses",
    placeholder: "Select accesses",
    error: {
      value: {content: "Please select access",
        pointing: "below"},
      visible: false,
    },
    options: [],
    type: "array",
  };
};

export const getInputParticipant = () => {
  return {
    name: "participants",
    required: true,
    value: [],
    label: "Participants",
    placeholder: "Select participants",
    error: {
      value: {content: "Please select participants",
        pointing: "below"},
      visible: false,
    },
    options: [],
    type: "array",
  };
};

export const getInputAlertEmail = () => {
  return {
    name: "email",
    label: `Email Address`,
    placeholder: `Enter email address`,
    validateTrigger: "onBlur",
    rules: [
      {
        type: 'email',
        message: 'The input is not valid email address',
      },
      {
        required: true,
        message: 'Please input your email address',
      },
    ],
    value:''

  };
};
