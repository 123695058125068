import React from 'react';
import { Col, Menu, Row, Tabs } from 'antd';
import './AccountDetails.css';
import ComponentBilling from './ComponentBilling';
import ComponentInvoices from './ComponentInvoices';
import ComponentCollaborators from './ComponentCollaborators';
import UpdateAccountDetails from './UpdateAccountDetails';
import ComponentCostExplorer from './ComponentCostExplorer';
import { loadData } from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import { connect } from 'react-redux';
import { contentType } from '../../../utils/ActionTypes';
import { getAccountDetailsPath } from '../../../utils/routerUtils';
import { getUser } from '../../../utils/Repository';

const {TabPane} = Tabs;
const menuItems = ["details","collaborators", "billing","invoices","explorer" ];

class AccountDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {screenWidth: window.innerWidth - 306};
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
	  const {onLoadData} = this.props;

	  onLoadData(
	  contentType.ACCOUNT,
	  getAccountDetailsPath,
	  );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

	handleResize = () => {
	  this.forceUpdate();
	  this.setState({screenWidth: window.innerWidth - 306});
	};

	onClick = ({key}) => {
	  this.addQuery({menu: key});
	};

	addQuery = (query) =>{
	  this.props.history.push({
	    pathname: '',
	    search: "?" + new URLSearchParams(query).toString(),
	  });
	};

	getSelectedMenu = () =>{
	  const query = new URLSearchParams(this.props.location.search);
	  const token = query.get('menu');
	  let index = menuItems.findIndex(e => e === token);

	  return index > -1 && index < menuItems.length ? menuItems[index] : menuItems[0];
	};

	render() {

	  const role = getUser().organizations_roles[0].role;
	  const {isFetching, data} = this.props;
	  let leftMenu = Math.ceil(24 * 185 / this.state.screenWidth);

	  return (

	    <div className={"account-details"}>
	      <div className="account-details-left-menu">
	        <Col span={leftMenu}>
	          <Menu
	            className ="details"
	            mode="inline"
	            onClick={this.onClick}
	            defaultSelectedKeys={[this.getSelectedMenu()]}
	            selectedKeys={[this.getSelectedMenu()]}
	            style={{width: 185}}>

	            <Menu.Item key={menuItems[0]}>Account Details</Menu.Item>
	            {isAdmin(role)  && <Menu.Item key={menuItems[2]}>Billing</Menu.Item>}
	            {isAdmin(role)  && <Menu.Item key={menuItems[3]}>Invoices</Menu.Item>}
	            {isAdmin(role)  && <Menu.Item key={menuItems[4]}>Cost Explorer</Menu.Item>}
	            <Menu.Item key={menuItems[1]}>Collaborators</Menu.Item>
	          </Menu>

	        </Col>
	      </div>
	      <Row type="flex">
	        <Col span={leftMenu} style={{pointerEvents: "none"}}>
	        </Col>
	        <Col span={24 - leftMenu} style={{paddingLeft:20}}>

	          <Tabs defaultActiveKey={menuItems[0]} tabPosition={"left"} activeKey={this.getSelectedMenu()}>
	            <TabPane tab={`Account Details`} key={menuItems[0]}>
	              <UpdateAccountDetails data={data} isFetching={isFetching}/>
	            </TabPane>
	            {isAdmin(role)  && <TabPane tab={`Billing`} key={menuItems[2]}>
	              <ComponentBilling location={this.props.location} history={this.props.history} accountInfo={data} userIsFetching={isFetching}/>
	            </TabPane>}
	            {isAdmin(role) && <TabPane tab={`Invoices`} key={menuItems[3]}>
	              <ComponentInvoices location={this.props.location} history={this.props.history}/>
	            </TabPane>}
	            {isAdmin(role)  && <TabPane tab={`Collaborators`} key={menuItems[4]}>
	              <ComponentCostExplorer location={this.props.location} history={this.props.history} accountInfo={data} userIsFetching={isFetching}/>
	            </TabPane>}
	            <TabPane tab={`Collaborators`} key={menuItems[1]}>
	              <ComponentCollaborators location={this.props.location} history={this.props.history}/>
	            </TabPane>
	          </Tabs>
	        </Col>
	      </Row>
	    </div>
	  );
	}
}

const mapActionToProps = {
  onLoadData: loadData,
};

const mapStateToProps = state => {
  const {accountDetailsRemoteData, accountUpdateRemoteData} = state;
  return {
    isFetching: utils.getIsFetching(accountDetailsRemoteData),
    data: utils.getItem(accountDetailsRemoteData),
    isFailed: utils.getIsFailed(accountDetailsRemoteData),
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(AccountDetails);

function isAdmin (role) {
  return role === "ROLE_SUPER_ADMIN" || role === "ROLE_ADMIN";
}

