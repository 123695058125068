import { contentType } from '../../../utils/ActionTypes';
import { createReducerWraper } from '../../../utils/Reducer';

export const appsReducer = createReducerWraper(contentType.APPS);
export const appsUpdateReducer = createReducerWraper(contentType.APPS_UPDATE);
export const storeReducer = createReducerWraper(contentType.STORE);
export const storeUpdateReducer = createReducerWraper(contentType.STORE_UPDATE);
export const storeCreateReducer = createReducerWraper(contentType.STORE_CREATE);
export const appSecretCreateReducer = createReducerWraper(contentType.APP_SECRET_CREATE);
export const appSecretListReducer = createReducerWraper(contentType.APP_SECRET_LIST);
export const productGroupListReducer = createReducerWraper(contentType.PRODUCT_GROUP_LIST);
export const productListReducer = createReducerWraper(contentType.PRODUCT_LIST);

export const productGroupCreateReducer = createReducerWraper(contentType.PRODUCT_GROUP_CREATE);
export const productGroupUpdateReducer = createReducerWraper(contentType.PRODUCT_GROUP_UPDATE);

export const packageCreateReducer = createReducerWraper(contentType.PACKAGE_CREATE);
export const packageUpdateReducer = createReducerWraper(contentType.PACKAGE_UPDATE);

export const productCreateReducer = createReducerWraper(contentType.PRODUCT_CREATE);
export const productUpdateReducer = createReducerWraper(contentType.PRODUCT_UPDATE);

export const productActivateReducer = createReducerWraper(contentType.PRODUCT_ACTIVATE);
export const webHookTestReducer = createReducerWraper(contentType.APPS_TEST_WEB_HOOK);





