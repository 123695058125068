import React from 'react';
import { Row } from 'antd';
import { contentType } from '@/utils/ActionTypes';
import Space from '@/components/shared/Spcae';
import { removeItem } from '@/utils/LocalStorage';
import { ComponentText } from '../../shared/ComponentText';
import images from '../../../images/Images';
import { fonts } from '../../../utils/fonts';

class PlanLimitWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = { screenWidth: window.innerWidth - 306 };
    removeItem(contentType.PRODUCT_GROUP_LIST.name);
  }

  render() {

    return (

      <div>
        <Row className={"limit-overage-container"} type={"flex"} justify="start">
          <img height={28} width={28} src={images.info}/>
          <Space width={12}/>
          <div className={"limit-overage-container-content"}>
            <ComponentText
              text="Monthly plan limit crossed. Overage applied."
              font={fonts.SFProDisplaySemiBold}
              size={16}
              color="var(--dark)"
            />
            <Space height={10}/>
            <ComponentText
              text="Each plan includes a base MTR. On Startup and Business plans, overages are automatically charged if you exceed the included base MTR. Overages scale down as your MTR goes up."
              font={fonts.SFProDisplayRegular}
              size={14}
              color="var(--dark)"

            />

          </div>
        </Row>
        <Space height={30}/>
      </div>
    );
  }
}

export default PlanLimitWarning;
