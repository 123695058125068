import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import rootReducer from './RootReducer';
import AppRoutes from './AppRoutes';
import { successLogin } from './components/Auth/authActionCreators';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { localStorageKey } from './utils/LocalStorage';
import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({reducer: rootReducer});

if (localStorage.getItem(localStorageKey.AUTH_RESPONSE)) {
  store.dispatch(successLogin(JSON.parse(localStorage.getItem(localStorageKey.AUTH_RESPONSE))));
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Route component={AppRoutes} />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
