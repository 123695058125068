import React from "react";
import  { Col, Divider, Form, Modal, Row, Select } from 'antd';
import { InputTypes } from '../../../utils/InputTypes';
import { InputComponent } from '../../shared/FormComponent/InputComponent';
import { SelectComponent } from '../../shared/FormComponent/SelectComponent';
import Space from '../../shared/Spcae';
import images from '../../../images/Images';
import { ComponentText } from '../../shared/ComponentText';
import { ComponentButton } from '../../shared/ComponentButton';
import Rodal from 'rodal';
import { fonts } from '../../../utils/fonts';
import 'rodal/lib/rodal.css';
import '../Alert.css';
import _ from 'lodash';
import { CountrySelectMobile } from '../../shared/FormComponent/CountrySelectMobile';

class ComponentAlertModal extends React.Component{

    handleSubmit = e => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if(values.mobile_number){
          values.mobile_number = values.code + values.mobile_number;
          delete  values.code;
        }
        if (!err) {
          this.props.form.resetFields();
          this.props.handleClose(this.props.value, values);
        }
      });
    };

    render() {

      const {value, showInfo, handleClose} = this.props;

      value.items.forEach(e=> {
        if(!_.isEmpty(value.values) && value.values[e.value.name]){
          e.value.value = value.values[e.value.name];
        }
      });
      return  <Rodal customStyles={{padding: 0, height:260, width:530, borderRadius: 5}} visible={value.dialog.visible}
        onClose={()=>handleClose(value)}
        showCloseButton={false}>
        <div>
          <ComponentText text={value.dialog.title} size="18"
            font={fonts.SFProDisplaySemiBold}
            color="var(--black)" marginTop={20} marginLeft={22} marginBottom={ 16}/>
          <Divider style={{marginTop:0, marginBottom:24}}/>

          <Form onSubmit={this.handleSubmit} layout="vertical" className={'alert-create-form'} style={{marginLeft:22, marginRight:22, padding:0}}>
            <div style={{height:100}}>
              {value.items.map((e, i) =>
                e.type === InputTypes.INPUT ? (
                  <InputComponent
                    key={i}
                    form={this.props.form}
                    layout={formItemLayout}
                    values={e.value}
                  />
                ) : e.type === InputTypes.SELECT ? (
                  <SelectComponent
                    key={i}
                    form={this.props.form}
                    layout={formItemLayout}
                    values={e.value}
                  />
                ):  <CountrySelectMobile
                  key={i}
                  form={this.props.form}
                  layout={formItemLayout}
                  values={e.value}
                />
              )}
            </div>

            { showInfo === true &&<div> <Space height="10" /> <Row className={"product-create-info"} type={"flex"} justify="start">
              <img height={28} width={28} src={images.info} alt={""}/>
              <div className={"product-create-info-content"} >
                <ComponentText
                  text="Please open PurchaseFly SDK enabled application to sync pricing with PurcahseFly after adding each product."
                  font="SFProDisplay-Regular"
                  size={14}
                  color="var(--dark)"
                />
              </div>
            </Row> <Space height="10" /> </div>}

            <Row type="flex" justify="space-between" align="middle" style={{marginTop:10}}>
              <Form.Item className="no-bottom-margin">
                <ComponentButton
                  htmlType="button"
                  text="Cancel"
                  css="cancel-subscription"
                  width="122"
                  height="42"
                  handleClick={()=>handleClose(value)}
                />
              </Form.Item>

              <Form.Item className="no-bottom-margin">
                <ComponentButton
                  htmlType="submit"
                  text={"Submit"}
                  css="purchase-subscription"
                  width="122"
                  height="42"
                />
              </Form.Item>
            </Row>
          </Form>
        </div>
      </Rodal>;
    }
}

const AlertModal = Form.create({ name: 'alert-modal' })(ComponentAlertModal);

export default AlertModal;

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};

function ValidateEmail(mail)
{
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
}