const images = {
  basicPlan: require('./basic-icon@3x.png'),
  startupPlan: require('./startup-icon-billing@3x.png'),
  businessPlan: require('./business-icon@3x.png'),
  emptyIllustration: require('./empty-state-illustration@3x.png'),
  logoInvoice: require('./logo-invoice@3x.png'),
  logo: require('./logo@3x.png'),
  logoAlternate: require('./purchasefly-logo.png'),
  masterCard: require('./master-card@3x.png'),
  visaCard: require('./visa-card.png'),
  prankDial: require('./prankdial@3x.png'),
  purchases: require('./purchases@3x.png'),
  totalSpent: require('./total-spent@3x.png'),
  startupIcon: require('./startup-icon@3x.png'),
  startupIconBilling: require('./startup-icon-billing@3x.png'),
  upgradeBanner: require('./upgrade-banner-blue@3x.png'),
  forgotPassEmailSent: require('./forgot-pass-email-sent.png'),
  info: require('./account-verify-info@3x.png'),
  timeMachine: require('./time-machine.png'),
  transactionEmpty: require('./transactions-empty@3x.png'),
  apps: require('./apps@3x.png'),
  customerEmpty: require('./customer_empty@3x.png'),
  arrow: require('./arrow@3x.png'),
  how_1: require('./how_1@3x.png'),
  how_2: require('./how_2@3x.png'),
  how_3: require('./how_3@3x.png'),
  how_4: require('./how_4@3x.png'),
  account_verify: require('./account_verify_info@3x.png'),
  billing_basic_icon: require('./basic-icon-billing@3x.png'),
  dismiss: require('./dismiss@3x.png'),
  // upgradeBannerBlue: require('./upgrade-banner-blue@3x.png'),
  charting: "https://ik.imagekit.io/purchasefly/purchasefly-v2/charting_3x_SQyVBRrdL.png",
  mtr: "https://ik.imagekit.io/purchasefly/purchasefly-v2/mtr_manage_3x_I2cxi19niC.png",
  plansManagement: "https://ik.imagekit.io/purchasefly/purchasefly-v2/plans_management_3x_mYeqIvZP1.png",
  subscriptionManagement: "https://ik.imagekit.io/purchasefly/purchasefly-v2/sub_management_3x_nVUYBejQB.png",
  reportGen: "https://ik.imagekit.io/purchasefly/purchasefly-v2/report_gen_3x_QQcF0tMty.png",
};

export default images;



