import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'antd';
import { ComponentText } from '../../shared/ComponentText';
import { loadData, postData } from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import Space from '../../shared/Spcae';
import { ComponentButton } from '../../shared/ComponentButton';
import { contentType } from '../../../utils/ActionTypes';
import {
  appSecretCreatePath,
  appSecretListPath,
} from '../../../utils/routerUtils';
import { tableContentType } from '../../shared/Table/TableDetails';
import _ from 'lodash';
import * as uuid from 'uuid';
import { TableListNew } from '../../shared/Table/TableListNew';

class ComponentKeys extends React.Component {
  constructor(props) {
    super(props);
    this.state = { list: [] };
  }

  componentDidMount() {
    const { onLoadData, id } = this.props;

    onLoadData(contentType.APP_SECRET_LIST, appSecretListPath, {
      filters: { application_id: id },
      pager: { skip: 0, limit: 15 },
    });
  }

  addKey = name => {
    const { onPostData, id } = this.props;
    onPostData(contentType.APP_SECRET_CREATE, appSecretCreatePath, {
      application_id: id,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.createData &&
      prevProps.createData !== this.props.createData &&
      this.props.createIsFetching === false
    ) {
      this.componentDidMount();
    }
    if (this.props.list && prevProps.list !== this.props.list && this.props.isFetching === false) {
      this.setState({ list: this.props.list });
    }
  }

  render() {
    let { isFetching, isFailed, createIsFetching } = this.props;
    let { list } = this.state;

    let data = _.cloneDeep(list);

    data = data ? data.map(e => {
      return{ id : uuid(), api_key: e.api_key, api_secret: e.api_secret, created_at: e.created_at };
    }): [];

    return (
      <div className="keys-table">
        <Row type="flex" justify="space-between" align="middle">
          <ComponentText
            text="Api Key and Secrets"
            size="18"
            color="var(--black)"
            align="left"
            marginTop="0"
            marginBottom="0"
            styl={{
              fontFamily: 'SFProDisplay-Semibold',
              fontSize: '18px',
              fontStyle: 'normal',
              color: '#262626',
            }}
          />
          <ComponentButton
            name="add-key"
            handleClick={this.addKey}
            iconLeft="add"
            text="New Key"
            css="add-group-item"
            height="32"
          />
        </Row>
        <Space height="20" />
        <TableListNew
          isFetching={isFetching}
          isFailed={isFailed}
          pagination={false}
          list={data}
          attributes ={attributes}
          row={2}
          column={3}
        />
      </div>
    );
  }
}

const mapActionToProps = {
  onLoadData: loadData,
  onPostData: postData,
};

const mapStateToProps = state => {
  const { appSecretListRemoteData, appSecretCreateRemoteData } = state;
  return {
    list: utils.getData(appSecretListRemoteData),
    isFetching: utils.getIsFetching(appSecretListRemoteData),
    isFailed: utils.getIsFailed(appSecretListRemoteData),
    createData: utils.getItem(appSecretCreateRemoteData),
    createIsFetching: utils.getIsFetching(appSecretCreateRemoteData),
    createIsFailed: utils.getIsFailed(appSecretCreateRemoteData),
  };
};
export default connect(mapStateToProps, mapActionToProps)(ComponentKeys);

const attributes = [
  {
    title: '',
    field: '',
    type: tableContentType.EMPTY,
  },
  {
    title:  'API Key',
    field: 'api_key',
    type: tableContentType.TEXT,
  },
  {
    title: 'API Secret',
    field: 'api_secret',
    type: tableContentType.TEXT,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];
