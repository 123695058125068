import { localStorageKey } from './LocalStorage';

export const getUser = () => {
  //console.log(localStorage.getItem(localStorageKey.AUTH_RESPONSE))
  try {
    const serializedState = localStorage.getItem(localStorageKey.AUTH_RESPONSE);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState).user;
  } catch (err) {
    return undefined;
  }
};
export const getToken = () => {

  try {
    const serializedState = localStorage.getItem(localStorageKey.AUTH_RESPONSE);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState).token.access_token;
  } catch (err) {
    return undefined;
  }
};

export const updateUserImage = (imageUrl) => {
  let user = getUser();
  user.avatar_url = imageUrl;
  localStorage.setItem(localStorageKey.USER, JSON.stringify(user));
};
export const updateUserName = (name) => {
  let user = getUser();
  user.name = name;
  localStorage.setItem(localStorageKey.USER, JSON.stringify(user));
};
export const getUserId = () => {
  try {
    const user = getUser();
    if (user) {
      return user.id;
    }
    return null;
  } catch (err) {
    return undefined;
  }
};
export const getOrgId = () => {
  const user = getUser();
  let orgId = null;
  if (user) {
    orgId = user.organizations_roles[0].organization_id;
  }

  return orgId;
};
