import React from 'react';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import { Card, Row, Skeleton, Tooltip } from 'antd';
import { ComponentText } from '../ComponentText';
import Space from '../Spcae';
import { fonts } from '../../../utils/fonts';
import utils from '../../../utils/utils';

class ChartSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isEnter: false };
  }

  onMouseEnter = () => {
    this.setState({
      isEnter: true,
    });
  };
  onMouseLeave = () => {
    this.setState({
      isEnter: false,
    });
  };

  handleClick = name => {
    console.log(name);
  };

  render() {
    const { title, value, subtitle, color, chart, data, isFetching, blur } = this.props;
    const { isEnter } = this.state;
    return (
      isFetching ? <div style={{width:308}}>
        <Skeleton loading={true} active paragraph={{ rows: 0 }}/>
      </div> : <div className="chart-summery">
        <Card
          bordered={false}
          bodyStyle={{ backgroundColor: color }}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <Row type="flex" justify="space-between" align="middle" style={{ height: 24 }}>
            <ComponentText
              text={title}
              font={fonts.SFProDisplaySemiBold}
              size="16"
              color="var(--cool-grey-four)"
              align="left"
              marginTop="0"
              marginBottom="0"
            />
            {/*{ isEnter ? <ComponentButton iconRight="next" iconSizeRight={32} css="link fill-blue" handleClick={this.handleClick} name="next"/> : null}*/}
          </Row>
          <Row type={'flex'} align="bottom">

            <ComponentText
              text={value}
              font={fonts.SFProDisplayBold}
              size="30"
              color={isEnter ? 'var(--azure)' : 'var(--dark)'}
              align="left"
              marginTop="12"
              marginBottom="30"
            />
            <Space width={6} />


            {subtitle !== "0" && <Tooltip title="Compared to last 30 Days"  placement="top" key={color}> <ComponentText
              font={fonts.SFProDisplaySemiBold}
              text={subtitle ? (  (subtitle > 0 ? "+":"") + (parseFloat(subtitle).toFixed(2)).toString()+"%") : ""}
              size="12"
              color={subtitle >= 0 ? 'var(--algae-green)' : 'var(--reddish)'}
              align="left"
              marginTop="0"
              marginBottom="30"
            /></Tooltip>}

          </Row>
          <div style={{width: 268, position: "absolute"}}>
            <div style={chart === true && blur ? {filter:"blur(3px)"} : {}}>
              {chart === true && <ResponsiveContainer width="100%" height={44}>
                {/*{isEnter ? (*/}
                <AreaChart data={blur ? randomData : data && data.length > 0 ? data : demoData}>
                  <Area
                    isAnimationActive={false}
                    type="monotone"
                    dataKey="value"
                    stroke="#0093ee"
                    fillOpacity={1}
                    fill=" var(--ice-blue-three)"
                    strokeWidth={1.5}
                  />
                </AreaChart>
                {/*) : (*/}
                {/*  <LineChart data={data && data.length > 0? data : demoData}>*/}
                {/*    <Line*/}
                {/*      isAnimationActive={false}*/}
                {/*      type="monotone"*/}
                {/*      dataKey="value"*/}
                {/*      stroke="var(--dodger-blue)"*/}
                {/*      dot={null}*/}
                {/*      strokeWidth={1.5}*/}
                {/*    />*/}
                {/*  </LineChart>*/}
                {/*)}*/}
              </ResponsiveContainer>}
            </div>
            {chart === true && blur && <div className={"blur-view-overlay-dashboard-background"}/>}
            {chart === true && blur &&  <div className={"blur-view-overlay-dashboard"}><ComponentText text={"Upgrade To See"}
              font={fonts.SFProDisplaySemiBold}
              size={16} color={"var(--dark-three)"}/></div>}
          </div>
        </Card>
      </div>
    );
  }
}

export default ChartSummary;

const demoData = [
  {
    name: 'Page A',
    value: 0,
  },
  {
    name: 'Page B',
    value: 0,
  },
  {
    name: 'Page C',
    value: 0,
  },
  {
    name: 'Page D',
    value: 0,
  },
];

const randomData = [
  {
    name: 'Page A',
    value: utils.getRandom(0,20),
  },
  {
    name: 'Page B',
    value: utils.getRandom(0,40),
  },
  {
    name: 'Page C',
    value: utils.getRandom(0,40),
  },
  {
    name: 'Page D',
    value: utils.getRandom(0,40),
  },
  {
    name: 'Page E',
    value: utils.getRandom(0,20),
  },
];
