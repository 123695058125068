import React from 'react';
import Search from 'antd/lib/input/Search';

export class ComponentPromoCode extends React.Component {

  render() {

    return <Search
      placeholder="Promo Code"
      enterButton="Apply"
      size="large"
      onSearch={value => console.log(value)}
    />;
  }
}
