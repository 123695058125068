import axios from '../../utils/AxiosWrapper';

import {
  ADMIN_GOOGLE_LOGIN_REQUEST,
  ADMIN_LOGGED_IN,
  ADMIN_LOGGED_OUT,
  ADMIN_LOGIN_FAIL, ADMIN_LOGIN_FAIL_ORG_NAME,
  ADMIN_LOGIN_REQUEST,
  ADMIN_REGISTER_REQUEST,
} from './authActionTypes';
import { BASEURL } from '../../utils/constants';
import { FAILED, RECEIVE, REQUEST } from '../../utils/ActionCreators';
import { contentType } from '../../utils/ActionTypes';
import {
  forgetPasswordApiPath,
  resendEmailApiPath,
  resetPasswordApiPath,
  verifyUserApiPath,
} from '../../utils/routerUtils';
import { createAction } from '@reduxjs/toolkit';
import { localStorageKey } from '../../utils/LocalStorage';

const requestLogin = createAction(ADMIN_LOGIN_REQUEST);
const requestGoogleLogin = createAction(ADMIN_GOOGLE_LOGIN_REQUEST);
const requestRegister = createAction(ADMIN_REGISTER_REQUEST);
const failLogin = createAction(ADMIN_LOGIN_FAIL);
const failLoginOrgName = createAction(ADMIN_LOGIN_FAIL_ORG_NAME);



export const successLogin = createAction(RECEIVE + contentType.LOGIN.name);

export function login(credentials) {

  const type = contentType.LOGIN;
  const request = createAction(REQUEST + type.name);
  //const receive = createAction(RECEIVE + type.name);
  const failed = createAction(FAILED + type.name);

  const url = BASEURL + `customer/api/v1/login`;
  return dispatch => {
    dispatch(request());
    return axios
      .post(url, credentials)
      .then(function(response) {
        dispatch(successLogin(response.data));
      })
      .catch(function(error) {
        if(error.status === 400) {
          error.data.message = "Email/password did not match";
        }
        dispatch(failed(error.data));
      });
  };
}

export function logout() {
  localStorage.clear();
  return dispatch => {
    dispatch(successLogin(null));
  };
}

export function googleLogin(credentials) {

  const type = contentType.SIGN_UP_GOOGLE;
  const request = createAction(REQUEST + type.name);
  const receive = createAction(RECEIVE + type.name);
  const failed = createAction(FAILED + type.name);

  const url = BASEURL + `customer/api/v1/oauth/login`;
  return dispatch => {
    dispatch(request());
    return axios
      .post(url, credentials)
      .then(function(response) {
        dispatch(successLogin(response.data));
        dispatch(receive(response.data));
      })
      .catch(function(error) {
        if(error.status === 500) {
          console.log(error);
          //error.data.message = "Authentication failed";
          dispatch(failed(error.data));
        }else
          dispatch(failed(error.data));
      });
  };
}

export function register(credentials, path) {
  const type = contentType.SIGN_UP;
  const request = createAction(REQUEST + type.name);
  const receive = createAction(RECEIVE + type.name);
  const failed = createAction(FAILED + type.name);


  const url = BASEURL + 'customer/api/v1/' + (path ? path : 'user');

  return dispatch => {
    dispatch(request());
    return axios
      .post(url, credentials)
      .then(function(response) {
        dispatch(successLogin(response.data));
        dispatch(receive(response.data));
      })
      .catch(function(error) {
        dispatch(failed(error.data.message ? error.data.message : error.data));
      });
  };
}

export const resetPassword = ob => {

  const type = contentType.RESET_PASSWORD;
  const request = createAction(REQUEST + type.name);
  const receive = createAction(RECEIVE + type.name);
  const failed = createAction(FAILED + type.name);

  const url = BASEURL + type.prefix + resetPasswordApiPath;

  return dispatch => {
    dispatch(request());

    return axios
      .post(url, ob)
      .then(response => {
        dispatch(successLogin(response.data));
        dispatch(receive(response));
      })
      .catch(error => {
        dispatch(failed(error.data));
      });
  };
};

export const resendEmail = () => {

  const type = contentType.RESEND_EMAIL;
  const request = createAction(REQUEST + type.name);
  const receive = createAction(RECEIVE + type.name);
  const failed = createAction(FAILED + type.name);

  const url = BASEURL + type.prefix + resendEmailApiPath;

  return dispatch => {
    dispatch(request());

    return axios
      .post(url, {
        "type": "RESEND_VERIFICATION",
        "verification_req": {}
      })
      .then(resp => {
        dispatch(receive(resp));
      })
      .catch(err => {
        dispatch(failed(err.data));
      });
  };
};

export const forgotPassword = email => {
  const type = contentType.FORGOT_PASSWORD;
  const request = createAction(REQUEST + type.name);
  const receive = createAction(RECEIVE + type.name);
  const failed = createAction(FAILED + type.name);

  const url = BASEURL + type.prefix + forgetPasswordApiPath;

  return dispatch => {
    dispatch(request());

    return axios
      .post(url, {
        user_email: email,
      })
      .then(resp => {
        dispatch(receive({ data: email }));
      })
      .catch(err => {
        dispatch(failed(err.data));
      });
  };
};

export const verifyUser = token => {
  const type = contentType.VERIFY_USER;
  const request = createAction(REQUEST + type.name);
  const receive = createAction(RECEIVE + type.name);
  const failed = createAction(FAILED + type.name);
  const url = BASEURL + type.prefix + verifyUserApiPath;
  return dispatch => {
    dispatch(request());
    return axios
      .post(url, {
        token: token,
      })
      .then(response => {
        dispatch(receive(response));
        //dispatch(successLogin(response.data));
      })
      .catch(error => {
        dispatch(failed(error.data));
      });
  };
};
