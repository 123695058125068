import React from 'react';
import { connect } from 'react-redux';
import { Input, Row } from 'antd';
import listUtils from '../../../utils/listUtils';
import {
  listQueryFilterParams,
  listSearchModalParams,
} from './CustomerListConstants';
import {
  customerListPath,
  getCustomerDetailsPath,
  getCustomerList,
} from '../../../utils/routerUtils';
import { loadData } from '../../../utils/ActionCreators';
import { contentType } from '../../../utils/ActionTypes';
import { ComponentText } from '../../shared/ComponentText';
import utils from '../../../utils/utils';
import { RangeDateTimePicker } from '../../shared/DateTime/RangeDateTimePicker';
import Space from '../../shared/Spcae';
import { ComponentIcon } from '../../shared/ComponentIcon';
import ComponentAppSelect from '../../shared/ComponentAppSelect';
import { tableContentType } from '../../shared/Table/TableDetails';
import { ListEmpty } from '../../shared/ListEmpty';
import images from '../../../images/Images';
import '../customer.css';
import { TableListNew } from '../../shared/Table/TableListNew';
import { getUser } from '../../../utils/Repository';
import queryString from 'query-string';
import { EnvironmentSelect } from '../../shared/EnvironmentSelect/EnvironmentSelect';
import FilterPopup from '../../shared/Filter/FilterPopup';

const path = customerListPath;
const title = "Customer List";
const subtitle = "Customers";

function getLatestProduct (products) {
  console.log(products);
  var list = products.slice().sort((a,b) => Date(a.purchase_at) - Date(b.purchase_at));
  if(list.length > 0){
    return list[0].product_id;
  }
  return "";
}

class CustomerList extends React.Component {

  constructor(props) {
    super(props);
    const values = queryString.parse(this.props.location.search);
    this.state = {appUserId: values["search_id"]};
  }

  componentDidMount() {
    this.loadListWithPageFilter();
  }

  componentDidUpdate (previousProps, prevState, snapshot) {
    const currentSearch = this.props.location.search;
    const previousSearch = previousProps.location.search;
    if (currentSearch !== previousSearch) {
      this.loadListWithPageFilter();
      const query = new URLSearchParams(currentSearch);
      const appUserId = query.get('search_id');
      this.setState({appUserId: appUserId});
    }
  }

    loadListWithPageFilter =()=> {
      const {onLoadData} = this.props;
      const user = getUser();
      let page = listUtils.currentPage(this.props.location.search);
      let limit = listUtils.currentLimit(this.props.location.search);
      let currentFilter =  listUtils.currentFilters(this.props.location.search, listQueryFilterParams());
      const query = new URLSearchParams(this.props.location.search);
      const from = query.get('from');
      const to = query.get('to');

      let filters = listUtils.prepareFilterParamsWithDate(currentFilter, utils.getDefaultDateRange(from, to));

      if(filters['application_ids']) {
        filters['organization_id'] = user.organizations_roles[0].organization_id;
        if (filters['application_ids'] === 'all-app') {
          delete filters['application_ids'];
        }
        onLoadData(
          contentType.CUSTOMER_LIST,
          getCustomerList,
          listUtils.prepareActionParams(page, limit, filters),
        );
      }
    };

    onDateChanged = range => {
      const between = {from: range.from,
        to: range.to};
      const {history} = this.props;
      let filters = listUtils.prepareFilterParamsWithDate(
        listUtils.prepareFilterParams(
          listUtils.currentFilters(
            this.props.location.search,
            listQueryFilterParams()
          )
        ),
        between
      );
      history.push(listUtils.prepareUrl(path, 1, filters));
    };

    searchByAppUserID = (id) => {
      let pathname = this.props.location.pathname;
      let searchParams = new URLSearchParams(this.props.location.search);
      if(id)
        searchParams.set("search_id",id);
      else
        searchParams.delete("search_id");

      searchParams.set("page", "1");
      this.props.history.push({
        pathname: pathname,
        search: searchParams.toString(),
      });

    };

  onAppUserIdChanged = (e) => {
    this.setState({appUserId: e.target.value});
  };

  render() {
    const page = listUtils.currentPage(this.props.location.search);
    let {list, pager, isFetching, isFailed, appList} = this.props;

    const customers = list && list.length > 0 ? list.map((e)=> {

      return {
        id: e.user.id,
        user_id: e.user.id,
        external_user_id: e.user.external_user_id ? e.user.external_user_id: "",
        email: e.user.email ? e.user.email : "",
        created_at: e.user.created_at,
        app_name: e.application_name,
        product_external_id: getLatestProduct(e.products),
        purchased_at:e.user.last_purchase_at,
        color: e.application_color,
        go_back: true,
        actions: [
          {
            url: getCustomerDetailsPath(e.user.id),
            title: "View",
            go_back: true,
          },
        ]
      };
    }) : [];

    return (
      <div className="customer-list">

        <Row type="flex" justify="space-between" align="middle">

          <ComponentText text={title} size="28" weight="600" color="#212b36" align="left" marginTop="0" marginBottom="0"/>
          <Row type="flex">
            <EnvironmentSelect location={this.props.location} history={this.props.history}/>
            <Space width={12}/>
            <ComponentAppSelect history={this.props.history} location={this.props.location}/>
            <Space width="20"/>
            <RangeDateTimePicker style={{height: 36}} onDateChanged={this.onDateChanged} location={this.props.location} history={this.props.history} defaultInterval={30}/>
          </Row>
        </Row>

        <Row type="flex" justify="space-between" align="middle" style={{marginTop: 16,
          marginBottom: 8}}>
          <Row type="flex" align="middle">
            <ComponentIcon height={12} width={19} type="users"/>
            <Space width="10"/>
            <ComponentText text={` ${subtitle} `} size="15" color="var(--slate-grey)" align="left" marginTop="0" marginBottom="0"/>
          </Row>
          <Row type="flex">
            <Input.Search
              value={this.state.appUserId ? this.state.appUserId : ""}
              onChange={this.onAppUserIdChanged}
              allowClear
              placeholder="Search app user id or email"
              onSearch={this.searchByAppUserID}
              style={{width: 300,
                marginRight: 8}}
            />
            <FilterPopup
              history = {this.props.history}
              location = {this.props.location}
              filterParams = {listSearchModalParams}
              queryFilterParams = {listQueryFilterParams()}
            />
          </Row>
        </Row>
        {customers && customers.length === 0 && isFetching === false ? <ListEmpty height={window.innerHeight} image={images.customerEmpty}  title={"customer"}/>:
          <TableListNew
            history={this.props.history}
            location={this.props.location}
            isLastPage = {utils.isLastPage(pager)}
            list={customers}
            isFetching={isFetching}
            isFailed={isFailed}
            attributes={attributes}
          />
        }
      </div>
    );
  }
}

const mapActionToProps = {
  onLoadData: loadData,
};

const mapStateToProps = state => {
  const {customerListRemoteData, appsListRemoteData} = state;
  return {
    appList: utils.getData(appsListRemoteData),
    list: utils.getData(customerListRemoteData),
    pager: utils.getPager(customerListRemoteData),
    isFetching: utils.getIsFetching(customerListRemoteData),
    isFailed: utils.getIsFailed(customerListRemoteData),
  };
};
export default connect(
  mapStateToProps,
  mapActionToProps
)(CustomerList);

const attributes = [
  {
    title: '',
    field: 'indicator',
    type: tableContentType.INDICATOR,
  },
  {
    title: 'App User ID',
    field: 'user_id',
    type: tableContentType.ID,
  },
  {
    title: 'External User ID',
    field: 'external_user_id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Email',
    field: 'email',
    type: tableContentType.TEXT,
  },
  {
    title: 'Latest Product',
    field: 'product_external_id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Latest Purchased',
    field: 'purchased_at',
    type: tableContentType.TIME_AGO,
  },
  {
    title: 'User Since',
    field: 'created_at',
    type: tableContentType.TIME_AGO,
  },
  {
    title: 'App',
    field: 'app_name',
    type: tableContentType.APP_NAME,
  },
  {
    title: '',
    field: 'action',
    type: tableContentType.ACTION,
  },
  {
    title: '',
    field: '',
    type: tableContentType.EMPTY,
  },
];