import { Link } from 'react-router-dom';
import React from 'react';

import {
  alertListPath,
  appsListPath,
  customerListPath,
  metricsActivePurchasePath, metricsChurnPath, metricsEventCountPath,
  metricsMRRPath,
  metricsNewPurchasePath,
  metricsRevenuePath, metricsTrialConversionPath,
  subscriptionListPath,
  transactionListPath,
} from '../../../utils/routerUtils';
import { Col, Divider, Menu, Row } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { ComponentIcon } from '../ComponentIcon';
import { localStorageKey } from '../../../utils/LocalStorage';
import { ComponentText } from '../ComponentText';
import { fonts } from '../../../utils/fonts';
import utils from '../../../utils/utils';
import { connect } from 'react-redux';
import "./Sidebar.css";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    icon: "home",
    appSelect: true,
    environment: false,
    page: false
  },
  {
    path: customerListPath,
    key: "/customer/",
    name: "Customers",
    icon: "customer",
    appSelect: true,
    environment: true,
    page: true
  },
  {
    path: subscriptionListPath,
    name: "Analytics",
    icon: "analytics",
    subRoutes: [
      {
        name: "MRR",
        path: metricsMRRPath,
        appSelect: true,
        environment: false,
        page: false,
      },
      {
        name: "Revenue",
        path: metricsRevenuePath,
        appSelect: true,
        environment: false,
        page: false,
      },
      {
        name: "Events",
        path: metricsEventCountPath,
        appSelect: true,
        environment: false,
        page: false,
      },
      {
        name: "New Purchase",
        path: metricsNewPurchasePath,
        appSelect: true,
        environment: false,
        page: false,
      },
      {
        name: "Churn",
        path: metricsChurnPath,
        appSelect: true,
        environment: false,
        page: false,
      },
      {
        name: "Trial Conversion",
        path: metricsTrialConversionPath,
        appSelect: true,
        environment: false,
        page: false,
      },
      {
        name: "Active subscriptions",
        path: metricsActivePurchasePath,
        appSelect: true,
        environment: false,
        page: false,
      },
    ]},
  {
    path: transactionListPath,
    name: "Transactions",
    key:"/transaction/",
    icon: 'credit-card',
    appSelect: true,
    environment: true,
    page: true,
  },
  {
    path: alertListPath,
    name: "Alerts",
    key: "/alert/",
    icon: "alert",
    appSelect: true,
    environment: false,
    page: true,
  },
  {
    path: appsListPath,
    name: "Apps",
    key: "/app/",
    icon: "apps",
    appSelect: false,
    environment: false,
    page: false,
  },

  // {path: boilerplateListPath,
  //     name: "Boilerplate List",
  //     icon: "appstore"},
  // {path: getBoilerplateDetailsPath(8),
  //     name: "Boilerplate Details",
  //     icon: "appstore"},
  // {path: boilerplateCreatePath,
  //     name: "Boilerplate Create",
  //     icon: "next"},
  // {path: "/components",
  // 	name: "Components",
  // 	icon: "next"},
  /*_add_from_here*/
];

class SideBar extends React.Component {

  constructor(props) {
    super(props);
    let initialKey = this.getSelectedKey();
    this.state = {selectedKey: [initialKey],  update_plan:localStorage.getItem(localStorageKey.UPGRADE_PLAN)};
  }

  getSelectedKey = () =>{
    let initialKey = "";

    if(this.props.location.pathname === '/account')
      return '/account';

    routes.forEach( (e, index) => {
      if(e.path === this.props.location.pathname || (e.key && this.props.location.pathname.includes(e.key) )){
        initialKey = e.name+index;
      }
      if(e.subRoutes){
        e.subRoutes.forEach((s,i)=>{
          if(s.path === this.props.location.pathname) {
            initialKey = s.name + i;
          }
        });
      }
    });
    // if(!initialKey)
    //   initialKey = 'Dashboard0';
    return initialKey;
  };

  onClick = ({key}) => {
    if(key !== "Document" && key !== "Support")
      this.setState({selectedKey: [key]});
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    if(this.props.location && this.props.location !== prevProps.location){
      let initialKey = this.getSelectedKey();
      this.setState({selectedKey: [initialKey]});
    }
    if(this.props.userOnFreePlanData && this.props.userOnFreePlanData !== prevProps.userOnFreePlanData){
      const {name} = this.props.userOnFreePlanData;
      if(name && name.length > 0){
        this.setState({update_plan: name});
      }

    }
  }

  prepareUrl = (route) => {
    const query = new URLSearchParams(this.props.location.search);

    let finalSearchParams = new URLSearchParams();
    if(route.appSelect){
      const token = query.get('application_ids');
      if(token){
        finalSearchParams.set("application_ids", token);
      }else{
        let key = localStorage.getItem(localStorageKey.SELECTED_APP);
        finalSearchParams.set("application_ids", key ? key : "all-app");
      }
    }
    if(route.environment){
      let env = localStorage.getItem(localStorageKey.SELECTED_ENVIRONMENT);
      const value = env? env : "ENV_PRODUCTION";
      finalSearchParams.set("environment", value);
    }
    if(route.page){
      finalSearchParams.set("page", "1");
    }
    return route.path +"?"+ finalSearchParams.toString();
  };

  render() {
    const currentPlan = this.props.userOnFreePlanData? this.props.userOnFreePlanData.free_plan: "Free Plan";
    let menu = routes.map((route, index) => {
      if (route.subRoutes)
        return (<SubMenu
          key={"sub" + index}
          title={
            <span>
    						<ComponentIcon type={route.icon} />
    						<span>{route.name}</span>
    					</span>
          }>
          {route.subRoutes.map((subRoute, index) => (
            <Menu.Item className="submenu" key={subRoute.name + index} ><Link to={this.prepareUrl(subRoute)}>{subRoute.name}</Link></Menu.Item>
          ))}
        </SubMenu>);
      else
        return <Menu.Item className="menu" key={route.name + index} >  <Link to={this.prepareUrl(route)}> <ComponentIcon type={route.icon}/>{route.name}</Link></Menu.Item>;
    });

    return (
      <div style={{display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        marginLeft: 12,
        borderRight: "1px solid #e8e8e8"}}
      className={"side-bar"}>
        <div className={this.state.update_plan && this.state.update_plan.length > 0 ? "menu-top-upgrade": "menu-top"}>
          <Menu
            key={"menu"}
            onClick={this.onClick}
            defaultSelectedKeys={this.state.selectedKey}
            defaultOpenKeys={['sub2']}
            selectedKeys={this.state.selectedKey}
            mode="inline">
            {menu}
          </Menu>
        </div>

        <div className={ this.state.update_plan && this.state.update_plan.length > 0 ? "menu-bottom-upgrade": "menu-bottom"}>
          {this.state.update_plan && this.state.update_plan.length > 0 && <div>
            <Divider style={{backgroundColor: "#d8e5ee", marginBottom: 14, marginTop: 8}}/>
            <Row type={"flex"} align={"middle"} justify={"space-between"} style={{paddingLeft:26, marginBottom:12, paddingRight:16}}>
              <Col span={12}>
                <ComponentText text={currentPlan} font={fonts.SFProDisplayRegular} size={14} color={"var(--battleship-grey)"}/>
                <ComponentText text={"Free"} font={fonts.SFProDisplayRegular} size={12} color={"var(--cool-grey)"}/>
              </Col>
              <Col span={12}>
                <Link to={"/account?menu=billing"}> <ComponentText text={"Upgrade"} font={fonts.SFProDisplayRegular} size={14} color={"var(--water-blue)"} align={"right"}/></Link>
              </Col>
            </Row>
          </div>}
          <Menu
            key={"menu2"}
            onClick={this.onClick}
            defaultSelectedKeys={['0']}
            selectedKeys={this.state.selectedKey}
            mode="inline">
            <Menu.Divider style={{backgroundColor: "#d8e5ee"}}/>
            <Menu.Item className="footer" key="Document"> <a href={"http://docs.purchasestacks.com"} target="_blank"><ComponentIcon type="documents" />Document</a></Menu.Item>
            <Menu.Item className="footer" key="Support"> <a href={"http://docs.purchasestacks.com"} target="_blank"><ComponentIcon type="support" />Support</a></Menu.Item>
            <Menu.Item className="footer" key="/account"> <Link to={"/account"}><ComponentIcon type="account" />Account</Link></Menu.Item>
          </Menu>
        </div>
      </div>
    );
  }
}

const mapActionToProps = {

};

const mapStateToProps = state => {
  const {userOnFreePlanData} = state;
  return {
    userOnFreePlanData : utils.getItem(userOnFreePlanData),
  };
};
export default connect(
  mapStateToProps,
  mapActionToProps
)(SideBar);

